<script setup lang="ts">
import { DtwinLifeCycleState, type DtwinModelI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { NO_CONTENT } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import type { LxcDtwinsFirmwareInformationI } from "~/components/dtwins/dtwinsForm/firmware/information/LxcDtwinsFirmwareInformation.vue";
import { useDtwinOperationModels } from "~/composables/useDtwinOperationManager";
import { APP_NAME, DTWIN_OPERATION_TYPE } from "~/constants/constants";
import { SearchMode } from "~/core/composables/useSearch";
import LxcError from "~/core/utils/LxcError";
import { formatIsoDate } from "~/core/utils/date";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import { Roles } from "~/modules/user/models/Roles.enum";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import { Filters } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

enum FirmwareUpdateSourceOption {
  FACTORY = 1,
  APP = 2,
  MOBILE_APP = 3,
}

const props = defineProps<{
  dtwin?: Dtwin;
  dtwinModel?: DtwinModelI;
}>();

const { t } = useI18n();
const store = useUserSession();

/**
 * Dtwin operation model
 */
const isLoadingDtwinOperationModel = ref(false);
const errorDtwinOperationModel = ref();
const dtwinOperationModel = ref();

const {
  error: dtwinOperationModelsError,
  fetchData: fetchDtwinOperationModels,
  results: dtwinOperationModelsResults,
  setFilter: setDtwinOperationModelsFilter,
} = useDtwinOperationModels(SearchMode.FILTER_SEARCH);

async function fetchDtwinOperationModel() {
  if (props.dtwinModel) {
    errorDtwinOperationModel.value = undefined;

    isLoadingDtwinOperationModel.value = true;

    setDtwinOperationModelsFilter(
      Filters.DTWIN_OPERATION_MODEL_TYPE,
      DTWIN_OPERATION_TYPE.FIRMWARE_UPDATE,
    );
    setDtwinOperationModelsFilter(Filters.DTWIN_OPERATION_MODEL_DEVICE_TYPE, [
      props.dtwinModel.name,
    ]);

    await fetchDtwinOperationModels();

    if (LxcError.check(dtwinOperationModelsError.value)) {
      errorDtwinOperationModel.value = dtwinOperationModelsError;
    } else {
      const models = dtwinOperationModelsResults.value?.results;
      dtwinOperationModel.value = models?.length ? models[0] : undefined;
    }

    isLoadingDtwinOperationModel.value = false;
  }
}
/**
 * End Dtwin operation model
 */

const updateFirmwareModalShow: Ref<boolean> = ref(false);
const reloadOperationList: Ref<boolean> = ref(false);
const dateFormat: string = t("dateFormat.datetime");
let firmwareUpdateSource: string | undefined;
const firmwareUpdateSourceCode =
  props.dtwin?.features.firmware?.reported?.firmwareUpdateSource;

if (
  firmwareUpdateSourceCode !== undefined &&
  !isNaN(firmwareUpdateSourceCode)
) {
  switch (firmwareUpdateSourceCode) {
    case FirmwareUpdateSourceOption.FACTORY:
      firmwareUpdateSource = t(
        "dtwins.form.firmware.information.attributes.firmwareUpdateSourceOption.factory",
      );
      break;
    case FirmwareUpdateSourceOption.APP:
      firmwareUpdateSource = APP_NAME;
      break;
    case FirmwareUpdateSourceOption.MOBILE_APP:
      firmwareUpdateSource = t(
        "dtwins.form.firmware.information.attributes.firmwareUpdateSourceOption.mobileApp",
      );
      break;
    default:
      // Keep being undefined. This case will be processed in the LxcDtwinsFirmwareInformation.vue component
      break;
  }
}

const information: Ref<LxcDtwinsFirmwareInformationI> = ref({
  firmwareVersion:
    props.dtwin?.features.firmware?.reported?.firmwareVersion ?? NO_CONTENT,
  firmwareUpdateSource: firmwareUpdateSource ?? NO_CONTENT,
  latestFirmwareUpdate: props.dtwin?.features.firmware?.reported?.$metadata
    ?.firmwareVersion?.$lastValueUpdate
    ? formatIsoDate(
        props.dtwin?.features.firmware?.reported?.$metadata?.firmwareVersion
          ?.$lastValueUpdate,
        dateFormat,
      )
    : NO_CONTENT,
} as LxcDtwinsFirmwareInformationI);

const isAllowedToUpdate = computed(
  () =>
    store.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE) &&
    store.userSession?.roles.includes(Roles.DVTM_FIRM_VIEW) &&
    props.dtwin?.lifeCycleState !== DtwinLifeCycleState.DISABLED,
);

const showUpdateFirmwareModal = () => {
  updateFirmwareModalShow.value = true;
};

const doReloadOperationList = () => {
  reloadOperationList.value = !reloadOperationList.value;
};

onMounted(async () => {
  await fetchDtwinOperationModel();
});
</script>

<template>
  <container-component
    :px="0"
    :py="0"
    :is-loading="isLoadingDtwinOperationModel"
    :error="errorDtwinOperationModel"
  >
    <lxc-dtwins-firmware-information :model-value="information" />

    <!-- Firmware update operations are available only if a model operation exists for the device type -->
    <template v-if="dtwin && dtwinModel && dtwinOperationModel">
      <div>
        <lxc-button
          type="primary"
          html-type="button"
          :icon="ILxcPlus"
          :title="t('dtwins.form.firmware.updateFirmware')"
          :disabled="!isAllowedToUpdate"
          @click="showUpdateFirmwareModal"
        >
          {{ t("dtwins.form.firmware.updateFirmware") }}
        </lxc-button>
      </div>

      <h3 class="mt-12">
        {{ t("section.operations.title") }}
      </h3>
      <lxc-dtwin-firmware-operation-list
        :dtwin="dtwin"
        :dtwin-model="dtwinModel"
        :dtwin-operation-model="dtwinOperationModel"
        :reload="reloadOperationList"
      />
      <lxc-dtwin-update-firmware-modal
        v-model:show="updateFirmwareModalShow"
        :dtwin="dtwin"
        :dtwin-model="dtwinModel"
        :dtwin-operation-model="dtwinOperationModel"
        @change="doReloadOperationList"
      />
    </template>
  </container-component>
</template>
