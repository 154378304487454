<script setup lang="ts">
import type { UniqueLabel } from "@lxc/app-device-common";
import { filterEmptyValues } from "@lxc/app-device-common";
import type { Ref } from "vue";
import { ref } from "vue";
import filtersUtils from "~/core/utils/filters.utils";
import type { UserData } from "~/modules/user/models/UserData.interface";

const { t } = useI18n();

const props = defineProps<{
  modelValue: UserData[];
  label: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const userName: Ref<string> = ref("");

function deleteTagFromList(id: string, list?: UserData[]) {
  if (list) {
    const index = list.findIndex((user) => user.id === id);

    if (index != null && index >= 0) {
      list.splice(index, 1);
    }
  }
}

const selectedUsers = computed({
  get(): UserData[] {
    return props.modelValue ?? [];
  },
  set(selected?: UserData[]) {
    emit("update:modelValue", selected);
  },
});

const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0;
});

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(
    props.modelValue.map((user) => filtersUtils.getUserTag(user, props.label)),
  );
});

const onTagDeleteClick = (label: string, uid?: string) => {
  deleteTagFromList(uid ?? label, selectedUsers.value);
};
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>

  <div class="rounded-lg bg-white text-gray-900 pt-6">
    <log-user-name-filter v-model="userName" />
    <log-user-list-filter v-model="selectedUsers" :name="userName" />
  </div>
</template>
