<script setup lang="ts">
import type { Ref } from "vue";
import { ref } from "vue";
import { SearchMode } from "~/core/composables/useSearch";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import { Filters } from "~/core/models/filters";
import { useActionsActionTypes } from "~/modules/log/composables/useActionsActionTypes";
import { ActionsOrActionTypesContext } from "~/modules/log/models/ActionsOrActionTypesContext.enum";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";
import ILxcAlertCircle from "~icons/lxc/alert-circle";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps<{
  context: ActionsOrActionTypesContext;
  header?: string;
  name?: string;
  modelValue?: TranslatedLogAttribute[];
}>();

const { t } = useI18n();
const { isLoading, results, error, fetchData, setFilter, onSearch } =
  useActionsActionTypes(props.context, SearchMode.FILTER_SEARCH);

const emptyListLabel = computed(() => {
  if (props.context === ActionsOrActionTypesContext.ACTION) {
    return t("logs.filters.actions.empty");
  }
  return t("logs.filters.actionTypes.empty");
});
const filterMap: Ref<Map<Filters, string> | undefined> = ref();
const page = ref(DEFAULT_FIRST_PAGE);
const pageSize = ref(DEFAULT_PAGE_SIZE);

function loadData() {
  fetchData(page.value, pageSize.value);
}

const itemsNumber: ComputedRef<number> = computed(() => {
  return results.value?.context.totalCount ?? 0;
});

const itemsNumberLabel = computed(() => {
  if (props.context === ActionsOrActionTypesContext.ACTION) {
    return t("logs.filters.actions.actionFound", itemsNumber.value);
  }
  return t("logs.filters.actionTypes.actionTypesFound", itemsNumber.value);
});

// Apply provided default filters
const updateListFilter = () => {
  if (filterMap.value) {
    for (const [filter, value] of filterMap.value) {
      setFilter(filter, value ?? "");
    }
  }
};

watch(
  () => props.name,
  (filterByName) => {
    if (!filterMap.value) {
      filterMap.value = new Map<Filters, string>();
    }
    if (!filterByName) {
      if (filterMap.value?.has(Filters.NAME)) {
        filterMap.value.set(Filters.NAME, "");
      }
    } else {
      filterMap.value.set(Filters.NAME, filterByName);
    }

    page.value = DEFAULT_FIRST_PAGE;
    updateListFilter();
    onSearch(loadData);
  },
);

const visibleData: ComputedRef<TranslatedLogAttribute[] | undefined> = computed(
  () => {
    return (results.value?.data.length ?? 0 <= 10)
      ? results.value?.data
      : (results.value?.data.slice(0, 10) ?? []);
  },
);

const selectedItems = computed({
  get() {
    if (props.modelValue) {
      return props.modelValue.map((item) => item.rawValue);
    }

    return [];
  },
  set(selected?: string[]) {
    if (selected) {
      const items: (TranslatedLogAttribute | undefined)[] = selected
        .map((selectedItem?: string) => {
          // If the item is not in the item list found, use modelValue where there was the previous-selected item list
          let item: TranslatedLogAttribute | undefined =
            results.value?.data.find(
              (currentItem) => currentItem.rawValue === selectedItem,
            );

          if (!item) {
            item = props.modelValue?.find(
              (currentItem) => currentItem.rawValue === selectedItem,
            );
          }
          return item;
        })
        .filter((currentItem) => currentItem?.rawValue !== undefined);
      emit("update:modelValue", items);
    } else {
      emit("update:modelValue", []);
    }
  },
});

watch(() => page.value, loadData);

const itemListClass = computed(() => {
  const itemClass = ["mb-2", "transition-opacity"];
  if (isLoading.value) {
    itemClass.push("opacity-50");
  }
  return itemClass;
});

onSearch(loadData);
</script>

<template>
  <div class="pb-6 px-6">
    <h6 v-if="props.header != null" class="font-bold text-base mb-2 underline">
      {{ props.header }}
    </h6>
    <div v-if="error">
      <lxc-alert :icon="ILxcAlertCircle" type="error" class="flex items-center">
        <template #title>{{ t("errors.UNEXPECTED.title") }}</template>
        <span>{{ t("errors.UNEXPECTED.subtitle") }}</span>
      </lxc-alert>
    </div>
    <div v-else>
      <div v-if="itemsNumber" class="flex justify-between mb-2">
        <span class="flex items-center font-medium">
          {{ itemsNumberLabel }}
        </span>
        <pagination-buttons
          v-model:page="page"
          :page-size="pageSize"
          :total-count="itemsNumber"
        />
      </div>
      <div class="relative">
        <lxc-checkbox
          v-for="data in visibleData"
          :key="data.rawValue"
          v-model="selectedItems"
          :label="data.translation"
          :value="data.rawValue"
          :class="itemListClass"
        />
        <div v-if="visibleData?.length === 0 && !isLoading">
          {{ emptyListLabel }}
        </div>

        <div v-if="isLoading" class="absolute inset-0">
          <div class="flex items-center justify-center h-full">
            <lxc-loader :size="20" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
