<script setup lang="ts">
import { saveAs } from "file-saver";
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { AsyncTaskId } from "~/core/models/AsyncTaskId.interface";
import type { AsyncTaskStatus } from "~/core/models/AsyncTaskStatus.interface";
import alertService from "~/core/services/alert.service";
import LxcError from "~/core/utils/LxcError";
import { NotificationKey } from "~/core/utils/notifications";

const { t } = useI18n();
const acl = useAcl();

const isLoading = ref(false);

const canDownloadLogs = computed(() => acl.can(ACLRoles.BSCOMMON_ERRDESC_LIST));

async function downloadLogs() {
  isLoading.value = true;
  const asyncTaskId: AsyncTaskId = await alertService.getExportLogsTaskId();

  if (LxcError.check(asyncTaskId)) {
    asyncTaskId.notify(NotificationKey.error);
    isLoading.value = false;
  } else {
    const intervalId = setInterval(async () => {
      const asyncTaskStatus: AsyncTaskStatus =
        await alertService.getExportLogsTaskStatus(asyncTaskId.taskId);

      if (LxcError.check(asyncTaskStatus)) {
        asyncTaskStatus.notify(NotificationKey.error);
        isLoading.value = false;
        clearInterval(intervalId);
      } else {
        if (asyncTaskStatus.finished) {
          clearInterval(intervalId);

          const response = await alertService.downloadExportLogs(
            asyncTaskId.taskId,
          );

          if (LxcError.check(response)) {
            response.notify(NotificationKey.error);
          } else {
            const blob = new Blob([response], {
              type: "application/octet-stream",
            });
            const fileName = "logExport.tar";
            saveAs(blob, fileName);
          }

          isLoading.value = false;
        }
      }
    }, 5000);
  }
}
</script>

<template>
  <container-component v-if="canDownloadLogs" :px="0">
    <h2 class="title">
      {{ t("page.about.downloadTitle") }}
    </h2>

    <div>
      <lxc-button
        type="secondary"
        html-type="button"
        :title="t('button.download')"
        :is-loading="isLoading"
        @click="downloadLogs"
      >
        {{ t("button.download") }}
      </lxc-button>
    </div>
  </container-component>
</template>
