import type { DtwinModelI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import dtwinsService from "~/services/dtwins.service";

export interface DeviceTypeFilter {
  label: string;
  value: string;
  uids?: string[] | undefined;
}

// Hardcoded magic strings defined in the old device twin (eSoftlink).
const eSoftMagicS4GPLDeviceType = "S4G-PL";
const eSoftMagicDeviceType: DeviceTypeFilter[] = [
  {
    value: eSoftMagicS4GPLDeviceType,
    label: eSoftMagicS4GPLDeviceType,
  },
];

export function isESoftMagicDeviceTypeByValue(value: string) {
  return eSoftMagicDeviceType.map((type) => type.value).includes(value);
}

export async function useDeviceTypeFilters(dtwinModelsOnly?: boolean) {
  const deviceTypeFilters: Ref<DeviceTypeFilter[]> = ref([]);

  const deviceModels: DtwinModelI[] = await dtwinsService.getAllDtwinModels();

  const deviceTypes: DeviceTypeFilter[] = deviceModels.reduce(
    (acc: DeviceTypeFilter[], model: DtwinModelI) => {
      const existingModel = acc.find(
        (item: DeviceTypeFilter) => item.value === model.name,
      );
      if (existingModel) {
        existingModel.uids?.push(model.uid);
      } else {
        acc.push({
          value: model.name,
          label: model.friendlyName,
          uids: [model.uid],
        });
      }
      return acc;
    },
    [],
  );
  if (dtwinModelsOnly) {
    deviceTypeFilters.value = deviceTypes;
  } else {
    deviceTypeFilters.value = [...eSoftMagicDeviceType, ...deviceTypes].sort(
      (a, b) => (a.label > b.label ? 1 : -1),
    );
  }

  return {
    deviceTypeFilters,
  };
}
