import type { RouteLocation } from "vue-router";
import { PATHS } from "~/core/constants/paths";
import { UnexpectedError } from "~/core/models/UnexpectedError.enum";
import LxcError from "~/core/utils/LxcError";
import type { UserSession } from "~/modules/user/models/UserSession.interface";
import UsersService from "~/modules/user/services/user.service";
import { router } from "~/plugins/router";

/**
 * Retrieve keycloak login url and redirect
 */
async function login() {
  const currentUrl = getCurrentUrl();
  const loginUrlResponse = await UsersService.getLoginUrl(currentUrl);
  if (LxcError.check(loginUrlResponse)) {
    await router.push(
      `${PATHS.ERROR}?errorType=${UnexpectedError.URL_LOGIN_ERROR}&redirectUri=${currentUrl}`,
    );
  } else {
    window.location.href = loginUrlResponse.loginUrl;
  }
}

/**
 * log the current user out and redirect to keycloak
 */
async function logout() {
  const currentUrl = getCurrentUrl();
  const logoutUrlResponse = await UsersService.logout(currentUrl);

  if (LxcError.check(logoutUrlResponse)) {
    await router.push(
      `${PATHS.ERROR}?errorType=${UnexpectedError.URL_LOGIN_ERROR}&redirectUri=${currentUrl}`,
    );
  } else {
    window.location.href = logoutUrlResponse.logoutUrl;
  }
}

/**
 * Retrieve session from url
 * @param route
 */
async function getSessionFromUrl(
  route: RouteLocation,
): Promise<UserSession | null> {
  if (!route.query.code) {
    return null;
  }

  const code = route.query.code.toString();
  return await UsersService.setSession(code, getCurrentUrl());
}

/**
 * Retrieve current url without query params
 */
function getCurrentUrl() {
  return window.location.href.split("?")[0];
}

export { login, logout, getSessionFromUrl };
