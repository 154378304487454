import type { CartographySettings } from "~/modules/cartography/models/CartographySettings.interface";
import cartographyService from "~/modules/cartography/services/cartography.service";

export function useCartography() {
  const cartographySettings: Ref<CartographySettings | undefined> = ref();

  async function getCartographySettings() {
    cartographySettings.value =
      await cartographyService.getCartographySettings();
  }

  return {
    cartographySettings,
    getCartographySettings,
  };
}
