<script setup lang="ts">
import { saveAs } from "file-saver";
import { useContent } from "~/core/composables/useContent";
import { SearchMode } from "~/core/composables/useSearch";
import type { ContentResource } from "~/core/models/ContentResource.interface";
import { ContentTags } from "~/core/models/ContentTags";
import { Filters } from "~/core/models/filters";
import contentService from "~/core/services/content.service";
import LxcError from "~/core/utils/LxcError";
import { formatIsoDate } from "~/core/utils/date";
import { NotificationKey } from "~/core/utils/notifications";
import ILxcDownload from "~icons/lxc/download";
import ILxcSearch from "~icons/lxc/search";

const props = defineProps<{
  dtwinUid?: string;
}>();

const { isLoading, results, error, fetchData, setFilter } = useContent(
  SearchMode.FILTER_SEARCH,
);
const route = useRoute();
const { t } = useI18n();

const lastOperationReportContentResource: ComputedRef<
  ContentResource | undefined
> = computed(() => {
  if (results.value && results.value.results.length > 0) {
    return results.value.results[0];
  }
});

const dtwinUid: ComputedRef<string> = computed(() => {
  return props.dtwinUid ?? route.params.id.toString();
});

const isLoadingOperationReportFile: Ref<boolean> = ref(false);

async function loadData() {
  setFilter(Filters.DTWIN_UID, dtwinUid.value);
  setFilter(Filters.CONTENT_TAGS, ContentTags.LOGS);
  await fetchData();
}

async function downloadLastOperationReport() {
  isLoadingOperationReportFile.value = true;
  if (
    lastOperationReportContentResource.value &&
    lastOperationReportContentResource.value.files
  ) {
    const response = await contentService.getFile(
      lastOperationReportContentResource.value.uid,
      lastOperationReportContentResource.value.files[0].uid,
    );

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
    } else {
      const blob = new Blob([response], {
        type: "application/octet-stream",
      });
      const fileName = lastOperationReportContentResource.value.files[0].name;
      saveAs(blob, fileName);
    }
  }
  isLoadingOperationReportFile.value = false;
}

onMounted(loadData);
</script>

<template>
  <container-component :error="error" :is-loading="isLoading" :py="0" :px="0">
    <h3 class="!mb-0">{{ t("dtwins.form.logs.title") }}</h3>
    <div v-if="lastOperationReportContentResource" class="mt-4">
      <lxc-form-item
        :label="t('dtwins.form.logs.lastOperationReportUpdateDate.label')"
      >
        <lxc-input
          :value="
            formatIsoDate(
              lastOperationReportContentResource?.modifiedAt,
              t('dateFormat.date'),
            )
          "
          type="text"
          read-only
        />
      </lxc-form-item>
      <div>
        <lxc-button
          html-type="button"
          :icon="ILxcDownload"
          :title="t('button.download')"
          class="mt-8"
          :is-loading="isLoadingOperationReportFile"
          @click="downloadLastOperationReport"
        >
          {{ t("button.download") }}
        </lxc-button>
      </div>
    </div>
    <div
      v-else
      class="mt-8 bg-gray-25 flex flex-col justify-center items-center py-4 gap-4"
    >
      <div
        class="p-3 bg-primary-100 border-8 border-primary-50 rounded-full text-primary-600"
      >
        <i-lxc-search :width="24" :height="24" />
      </div>
      <h5>{{ t("dtwins.form.logs.noLogsFound") }}</h5>
    </div>
  </container-component>
</template>
