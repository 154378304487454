import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type {
  FilterSelectionValue,
  FiltersSelection,
} from "~/core/models/filters";
import RestService from "~/core/services/rest.service";
import type { AsyncLog } from "~/modules/log/models/AsyncLog.interface";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";

export class LogsService extends RestService {
  protected BASE_URL = "/logs";

  getLogs(
    page?: number,
    pageSize?: number,
    searchParams?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<AsyncLog>> {
    const iterator = (searchParams as FiltersSelection).entries();
    const searchObject: Record<string, FilterSelectionValue> = {};

    for (const entry of iterator) {
      const filterDefinition = entry[1];
      searchObject[filterDefinition.key] = filterDefinition.value;
    }

    const params = { page, pageSize, ...searchObject, sort };

    return this.$api.get(`${this.BASE_URL}`, {
      headers: { Accept: "application/json" },
      params,
      timeout: Infinity,
    });
  }

  getActionTypes(
    page?: number,
    pageSize?: number,
    search?: string | FiltersSelection,
  ): Promise<ApiListResult<TranslatedLogAttribute>> {
    const params = {
      page: page ?? DEFAULT_FIRST_PAGE,
      pageSize: pageSize ?? DEFAULT_PAGE_SIZE,
      search,
    };

    return this.$api.get(`${this.BASE_URL}/actionTypes`, { params });
  }
}

export default new LogsService();
