import RestService from "~/core/services/rest.service";
import type { CartographySettings } from "~/modules/cartography/models/CartographySettings.interface";

class CartographyService extends RestService {
  private BASE_URL = "/cartography/settings";

  getCartographySettings(): Promise<CartographySettings> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new CartographyService();
