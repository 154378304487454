import type { DtwinModelI, PaginatedListI } from "@lxc/app-device-types";
import LxcError from "~/core/utils/LxcError";
import dtwinsService from "~/services/dtwins.service";

export function useDtwinModels() {
  const results: Ref<PaginatedListI<DtwinModelI> | undefined> = ref();
  const error: Ref<LxcError | undefined> = ref();
  const isLoading: Ref<boolean> = ref(false);

  async function fetchAllModels() {
    const response = await fetchAllModelsNoPagination();
    if (LxcError.check(response)) {
      error.value = response;
    } else {
      results.value = {
        results: response,
        pagination: {
          page: 1,
          pageSize: response.length,
          totalCount: response.length,
          count: response.length,
        },
      };
    }
  }

  async function fetchAllModelsNoPagination(): Promise<
    DtwinModelI[] | LxcError
  > {
    isLoading.value = true;
    const response = await dtwinsService.getAllDtwinModels();
    isLoading.value = false;
    return response;
  }

  return {
    results,
    isLoading,
    error,
    fetchAllModels,
    fetchAllModelsNoPagination,
  };
}
