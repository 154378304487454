export enum CampaignOperationState {
  PENDING = "PENDING",
  DELIVERED = "DELIVERED",
  RETRY = "RETRY",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
  PAUSED = "PAUSED",
  OP_PENDING = "OP_PENDING",
  OP_RETRY = "OP_RETRY",
  OP_RUNNING = "OP_RUNNING",
  OP_ERROR = "OP_ERROR",
  OP_CANCELLING = "OP_CANCELLING",
  OP_CANCELLED = "OP_CANCELLED",
  DONE = "DONE",
}
