<script setup lang="ts">
import { AppDeviceState } from "~/core/models/AppDeviceState.enum";
import { OperationType } from "~/core/models/OperationType.enum";
import { formatIsoDate } from "~/core/utils/date";
import type { Application } from "~/modules/application/models/Application.interface";
import { Roles } from "~/modules/user/models/Roles.enum";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import { ObjectType } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  application: Application;
}>();

const { t } = useI18n();
const userSessionStore = useUserSession();
const [confirmUpdateCertificateVisible, toggleConfirmUpdateCertificate] =
  useToggle();

const activeName = ref("unitary");
const operationsListRef = ref();
const certificateFormRef = ref();
const certificateForm = reactive({
  expirationDate: "",
  certificateOrigin: "",
  lastUpdate: "",
});
const disabledUpdateCertificateButton = computed(
  () =>
    props.application.state !== AppDeviceState.ACTIVATED ||
    !userSessionStore.userSession?.roles.includes(Roles.DVTM_APP_CRT_RENEW),
);

onMounted(() => {
  Object.assign(certificateForm, {
    expirationDate: props.application?.certificate?.notValidAfter
      ? formatIsoDate(
          props.application?.certificate?.notValidAfter,
          t("dateFormat.date"),
        )
      : "-",
    certificateOrigin: "n/a",
    lastUpdate: "n/a",
  });
});

function updateOperationsList() {
  operationsListRef.value.updateTable();
}
</script>
<template>
  <div class="flex flex-col">
    <h3>{{ t("application.certificate") }}</h3>
    <lxc-form ref="certificateFormRef" :model="certificateForm">
      <div class="flex">
        <div class="w-1/3">
          <lxc-form-item
            :label="t('application.certificateForm.expirationDate')"
          >
            <lxc-input
              v-model="certificateForm.expirationDate"
              type="text"
              read-only
            />
          </lxc-form-item>
          <lxc-form-item
            :label="t('application.certificateForm.certificateOrigin')"
          >
            <lxc-input
              v-model="certificateForm.certificateOrigin"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
        <div class="w-1/3">
          <lxc-form-item :label="t('application.certificateForm.lastUpdate')">
            <lxc-input
              v-model="certificateForm.lastUpdate"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </div>
    </lxc-form>
    <lxc-button
      class="w-fit"
      :icon="ILxcPlus"
      :title="t('application.updateCertificate')"
      :disabled="disabledUpdateCertificateButton"
      @click="toggleConfirmUpdateCertificate(true)"
    >
      {{ t("application.updateCertificate") }}
    </lxc-button>
  </div>
  <div class="mt-12">
    <h3>
      {{ t("section.operations.title") }}
    </h3>
    <lxc-tabs v-model="activeName" class="tabs">
      <lxc-tab-pane :label="t('application.operation.unitary')" name="unitary">
        <application-operations-list
          v-if="application?.id"
          ref="operationsListRef"
          :application-id="application.id"
          :application="application"
          :operation-type="OperationType.CRTCLT_RENEWAL_APP"
          :has-callback-url="false"
        />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="t('application.operation.campaign')"
        name="campaign"
      >
        <application-campaign-operations-list
          v-if="application?.id"
          :application-id="application.id"
          :operation-type="OperationType.CRTCLT_RENEWAL_APP"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </div>

  <lxc-update-certificate-modal
    :is-dialog-visible="confirmUpdateCertificateVisible"
    :object="application"
    :object-type="ObjectType.APPLICATION"
    @update:toggle-dialog="toggleConfirmUpdateCertificate"
    @change="updateOperationsList"
  />
</template>
