<script setup lang="ts">
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { AppFeatures } from "~/core/models/AppFeatures.enum";
import { useConfigStore } from "~/core/stores/useConfigStore";

const acl = useAcl();
const { featureToggle } = useConfigStore();
const canViewLogs = computed(
  () =>
    featureToggle.isVisible(AppFeatures.LOGS) &&
    acl.anyCan([
      ACLRoles.CYBER_LOGS_VIEW,
      ACLRoles.DEVICE_FLEET_LOGS_VIEW,
      ACLRoles.SYSTEM_LOGS_VIEW,
      ACLRoles.DEVICE_LOGS_VIEW,
    ]),
);
</script>
<template>
  <page-component v-if="canViewLogs">
    <log-list />
  </page-component>
</template>
