import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { FiltersSelection } from "~/core/models/filters";
import RestService from "~/core/services/rest.service";
import type { ApplicationMgt } from "~/modules/applicationManagement/models/ApplicationMgt.interface";
import type { CreateInterfaceRequestPayload } from "~/modules/applicationManagement/models/CreateInterfaceRequestPayload.interface";
import type { CreateInterfaceResponse } from "~/modules/applicationManagement/models/CreateInterfaceResponse.interface";
import type { Interface } from "~/modules/applicationManagement/models/Interface.interface";
import type { UpdateInterfaceRequestPayload } from "~/modules/applicationManagement/models/UpdateInterfaceRequestPayload.interface";

export class ApplicationMgtService extends RestService {
  protected BASE_URL = "/applicationMgts";
  protected INTERFACE_PATH = "/interfaces";

  /**
   * Retrieve application management list
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   */
  getApplicationMgts(
    page: number,
    pageSize: number,
    search?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<ApplicationMgt>> {
    const params = { page, pageSize, sort, search };

    return this.$api.get(this.BASE_URL, { params });
  }

  /**
   * Retrieve an application management
   * @param id
   */
  getApplicationMgtById(id: string): Promise<ApplicationMgt> {
    return this.$api.get(`${this.BASE_URL}/${id}`);
  }

  /**
   * Generate an application management QR code
   * @param id
   */
  generateApplicationMgtQrCodeById(id: string): Promise<any> {
    return this.$api.get(`${this.BASE_URL}/${id}/qrCode`, {
      headers: { Accept: "image/png" },
      responseType: "arraybuffer",
    });
  }

  /**
   * Create an application management
   * @param applicationMgt
   * @returns
   */
  createApplicationMgt(applicationMgt: ApplicationMgt) {
    return this.$api.post(`${this.BASE_URL}`, { applicationMgt });
  }

  /**
   * update an application management
   * @param applicationMgt
   * @returns
   */
  updateApplicationMgt(applicationMgt: ApplicationMgt) {
    return this.$api.patch(`${this.BASE_URL}/${applicationMgt.id}`, {
      applicationMgt,
    });
  }

  /**
   * delete an application management
   * @param applicationMgt
   */
  deleteApplicationMgt(applicationMgt: ApplicationMgt) {
    return this.$api.delete(`${this.BASE_URL}/${applicationMgt.id}`);
  }

  /**
   * get App Interfaces
   * @param page
   * @param pageSize
   * @param sort
   * @param filter
   * @returns
   */
  getAppInterfaces(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<Interface>> {
    const params = { page, pageSize, filter, sort };
    return this.$api.get(`${this.BASE_URL}${this.INTERFACE_PATH}`, { params });
  }

  /**
   * Create an application interface
   * @param appInterface the new interface payload
   * @returns
   */
  createAppInterface(
    appInterface: CreateInterfaceRequestPayload,
  ): Promise<CreateInterfaceResponse | any> {
    return this.$api.post(`${this.BASE_URL}${this.INTERFACE_PATH}`, {
      appInterface,
    });
  }

  /**
   * Get an interface
   * @param clientId
   * @returns
   */
  getAppInterfaceByClientId(clientId: string): Promise<Interface> {
    return this.$api.get(`${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`);
  }

  /**
   * Update an interface and return it updated
   * @param clientId
   * @param updateInterfacePayload
   * @returns
   */
  updateAppInterface(
    clientId: string,
    updateInterfacePayload: UpdateInterfaceRequestPayload,
  ) {
    return this.$api.put(`${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`, {
      updateInterfacePayload,
    });
  }

  /**
   * Delete an interface
   * @param clientId
   * @returns
   */
  deleteAppInterface(clientId: string) {
    return this.$api.delete(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}`,
    );
  }

  /**
   * Deactivate an interface
   * @param clientId
   * @returns
   */
  deactivateAppInterface(clientId: string) {
    return this.$api.post(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}/deactivate`,
    );
  }

  /**
   * Activate an interface
   * @param clientId
   * @returns
   */
  activateAppInterface(clientId: string) {
    return this.$api.post(
      `${this.BASE_URL}${this.INTERFACE_PATH}/${clientId}/activate`,
    );
  }
}

export default new ApplicationMgtService();
