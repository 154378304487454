<script setup lang="ts">
import type { UniqueLabel } from "@lxc/app-device-common";
import { filterEmptyValues } from "@lxc/app-device-common";
import type { Ref } from "vue";
import { ref } from "vue";
import type { DeviceTypeFilter } from "~/composables/useDeviceTypeFilters";
import { isESoftMagicDeviceTypeByValue } from "~/composables/useDeviceTypeFilters";
import type { Device } from "~/core/models/Device.interface";
import filtersUtils from "~/core/utils/filters.utils";

const { t } = useI18n();

const props = defineProps<{
  label: string;
  modelValue: Device[];
}>();
const emit = defineEmits(["update:modelValue"]);

const deviceName = ref("");
const deviceTypes: Ref<DeviceTypeFilter[]> = ref([]);
const deviceDeclinations: Ref<string[]> = ref([]);

const selectedDevices = computed({
  get(): Device[] {
    return props.modelValue ?? [];
  },
  set(selected?: Device[]) {
    emit("update:modelValue", selected);
  },
});
const isAnyAppliedFilter = computed(() => {
  return props.modelValue.length !== 0;
});

const filterTags = computed((): UniqueLabel[] => {
  return filterEmptyValues<UniqueLabel>(
    props.modelValue.map((deviceDvtmEsoft) =>
      filtersUtils.getDeviceDvtmEsoftTag(deviceDvtmEsoft, props.label),
    ),
  );
});

const isAtLeastOneDeviceTypeSelected = computed(() => {
  return deviceTypes.value.length !== 0;
});
const isEsoftDeviceTypeSelected = computed(() => {
  return (
    isAtLeastOneDeviceTypeSelected.value &&
    deviceTypes.value.every((type) => isESoftMagicDeviceTypeByValue(type.value))
  );
});

function deleteTagFromList(id: string) {
  if (selectedDevices.value) {
    const index = selectedDevices.value.findIndex(
      (deviceDvtmEsoft) => deviceDvtmEsoft.id === id,
    );

    if (index != null && index >= 0) {
      selectedDevices.value.splice(index, 1);
    }
  }
}

function onTagDeleteClick(label: string, uid?: string) {
  deleteTagFromList(uid ?? label);
}

watch(deviceTypes, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    emit("update:modelValue", []);
  }
});
</script>
<template>
  <div
    v-if="isAnyAppliedFilter"
    class="flex items-center justify-start pt-2 pb-6"
  >
    <lxc-tag-set
      border-visible
      deletable
      type="primary"
      :data="filterTags"
      :delete-tooltip="t('filters.deleteSelectedFilter')"
      @delete="onTagDeleteClick"
    />
  </div>
  <div class="rounded-lg bg-white text-gray-900">
    <div class="flex p-6 gap-6 flex-nowrap">
      <div class="flex-1 items-start">
        <log-device-type-filter v-model="deviceTypes" />
      </div>
      <div class="flex-1 items-start pt-1">
        <log-device-declination-filter
          v-model="deviceDeclinations"
          :types="deviceTypes.map((type) => type.value)"
        />
      </div>
    </div>
    <div v-if="isAtLeastOneDeviceTypeSelected">
      <log-device-name-filter v-model="deviceName" />
      <log-device-list-filter
        v-if="isEsoftDeviceTypeSelected"
        v-model="selectedDevices"
        :name="deviceName"
        :types="deviceTypes"
        :declinations="deviceDeclinations"
      />
      <log-device-list-filter
        v-else
        v-model="selectedDevices"
        :name="deviceName"
        :types="deviceTypes"
      />
    </div>
  </div>
</template>
