import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/core/composables/useFetchPage";
import { SearchMode, useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import {
  Filters,
  type FiltersSelection,
  FiltersType,
} from "~/core/models/filters";
import type { UserGroup } from "~/modules/user/models/UserGroup.interface";
import userGroupService from "~/modules/user/services/userGroup.service";

export function useUserGroup() {
  const service = userGroupService.getUserGroups.bind(userGroupService);
  const itemType = "userGroup";

  const { can } = useAcl();

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RAW,
    appliedFilters,
    SearchMode.FILTER_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: false,
  });

  function getDescription(item: UserGroup): string {
    return item?.label ?? "";
  }

  function canManageItems() {
    return can(ACLRoles.DISPLAY_USERS);
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map((item) => item.code);
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  };
}
