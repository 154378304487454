<script setup lang="ts">
import type { Ref } from "vue";
import LxcError from "~/core/utils/LxcError";
import { CampaignOMStatus } from "~/modules/campaign/models/CampaignOMStatus.enum";
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";
import CampaignOperationMgrService from "~/modules/campaign/services/campaignOperationMgr.service";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const route = useRoute();
const uid = route.params.uid.toString();
const campaign: Ref<CampaignOperationMgr | undefined> = ref();
const error: Ref<LxcError | undefined> = ref();
const isLoading: Ref<boolean> = ref(false);

async function fetchCampaign() {
  isLoading.value = true;
  const response = await CampaignOperationMgrService.find(uid);
  if (LxcError.check(response)) {
    error.value = response;
  } else {
    campaign.value = response;
  }
  isLoading.value = false;
}

const canUpdateOrCancelCampaign = computed(
  () =>
    campaign.value?.status === CampaignOMStatus.SCHEDULED ||
    campaign.value?.status === CampaignOMStatus.RUNNING,
);

const showCampaignOperationMgrFunnel: Ref<boolean> = ref(false);

function saved() {
  fetchCampaign();
  showCampaignOperationMgrFunnel.value = false;
}

onMounted(fetchCampaign);
</script>

<template>
  <page-component
    :is-loading="isLoading"
    :error="error"
    :name="campaign?.name ?? ''"
    back
  >
    <template #top-right>
      <lxc-button
        v-if="canUpdateOrCancelCampaign"
        :icon="ILxcPlus"
        size="large"
        type="primary"
        :title="t('campaign.details.buttons.edit.label')"
        :disabled="isLoading"
        @click="showCampaignOperationMgrFunnel = true"
      >
        {{ t("campaign.details.buttons.edit.label") }}
      </lxc-button>
    </template>
    <div class="mt-8 flex gap-[1.875rem]">
      <campaign-operation-mgr-characteristics
        :campaign="campaign"
        class="flex-1"
      />
      <campaign-operation-mgr-statistics :campaign="campaign" class="flex-1" />
    </div>
    <div class="mt-12">
      <campaign-operation-mgr-operation-list :campaign="campaign" />
    </div>

    <campaign-operation-mgr-funnel
      v-model:show="showCampaignOperationMgrFunnel"
      :campaign="campaign"
      @saved="saved"
    />
  </page-component>
</template>
