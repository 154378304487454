<script setup lang="ts">
import type { AsyncDeviceTwinsFirmwareCountByVersion } from "@lxc/app-device-types";
import type { ChartData, ChartOptions } from "chart.js";
import { Chart, registerables } from "chart.js";
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import type { LocationQueryRaw } from "vue-router";
import {
  DONNUT_FONT_FAMILY,
  DONNUT_TITLE_COLOR,
} from "~/core/constants/constants";
import { Filters } from "~/core/models/filters";
import { simplifyVisibleVersions } from "~/modules/dashboard/utils/dashboard";

const { t } = useI18n();
const router = useRouter();
const DONNUT_PART_COLORS = ["#f79009", "#4c72a3", "#002f5d", "#bfccde"];

const props = defineProps<{
  firmwareName: string;
  versions: AsyncDeviceTwinsFirmwareCountByVersion;
  urlRedirect: string;
}>();

const localOthersLabel = t("dashboard.firmwares.others");

Chart.register(...registerables);
// Ovveride here the tooltip to display % after the value
Chart.overrides.doughnut.plugins.tooltip = {
  enabled: true,
  callbacks: {
    label(context: any): string {
      if (context.parsed && context.dataset?.data !== null) {
        const total = context.dataset.data.reduce(
          (sum: number, val: number) => sum + val,
          0,
        );
        const percent = Math.round((context.parsed * 100) / total);

        return ` : ${percent}%`;
      }

      return "";
    },
    title(context: any[]): string {
      return context[0] !== null
        ? `${context[0].label || ""} (${context[0].parsed})`
        : "";
    },
  },
} as any;

const visibleVersions = computed<AsyncDeviceTwinsFirmwareCountByVersion>(() =>
  simplifyVisibleVersions(props.versions, localOthersLabel),
);

const versionsLabels = computed<string[]>(() =>
  Object.keys(visibleVersions.value).sort((v1: string, v2: string) => {
    let result = 0;
    if (v1 === localOthersLabel) {
      result = 1;
    } else if (v2 === localOthersLabel) {
      result = -1;
    } else {
      result = v1.localeCompare(v2);
    }
    return result;
  }),
);

const versionsValues = computed<number[]>(() =>
  versionsLabels.value.map((version) => visibleVersions.value[version]),
);
const otherVersionNames = computed<string[]>(() =>
  Object.keys(props.versions).filter(
    (versionName) =>
      versionName === "Other" || !versionsLabels.value.includes(versionName),
  ),
);

const chartData = computed<ChartData<"doughnut">>(() => ({
  labels: versionsLabels.value,
  datasets: [
    {
      backgroundColor: DONNUT_PART_COLORS,
      data: versionsValues.value,
      label: props.firmwareName ?? "",
    },
  ],
}));

const options = computed<ChartOptions<"doughnut">>(() => ({
  responsive: true,
  rotation: 180,
  cutout: 45,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fullSize: false,
      labels: {
        boxWidth: 60,
        boxHeight: 14,
        font: {
          family: DONNUT_FONT_FAMILY,
          size: 14,
        },
        padding: 10,
        pointStyle: "circle",
        textAlign: "left",
        usePointStyle: true,
        pointStyleWidth: 20,
      },
    },
    title: {
      align: "center",
      color: DONNUT_TITLE_COLOR.slice(0, visibleVersions.value.length),
      display: true,
      font: {
        weight: "bold",
        size: 14,
        family: DONNUT_FONT_FAMILY,
      },
      padding: {
        top: 0,
        bottom: 20,
      },
      position: "top",
      text: props.firmwareName,
    },
  },
  onClick(_evt, item) {
    let urlParams: LocationQueryRaw | undefined;

    if (versionsLabels.value.length !== 0 && item.length !== 0) {
      const itemName = versionsLabels.value[item[0]?.index ?? 0];
      urlParams = {};
      urlParams[`${Filters.MODEL_TYPE}[]`] = [props.firmwareName];

      if (itemName !== localOthersLabel) {
        urlParams[Filters.DEVICE_FIRMWARE_VERSIONS] =
          `[${versionsLabels.value[item[0].index]}]`;
      } else {
        urlParams[Filters.DEVICE_FIRMWARE_VERSION_INCLUDED_OR_NULL] =
          `[${otherVersionNames.value.join(",")}]`;
      }

      router.push({
        path: props.urlRedirect,
        query: urlParams,
      });
    } else {
      router.push(props.urlRedirect);
    }
  },
}));

const { doughnutChartProps } = useDoughnutChart({
  chartData,
  options,
});
</script>

<template>
  <div class="w-64">
    <doughnut-chart v-bind="doughnutChartProps" />
  </div>
</template>
