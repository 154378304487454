<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import type { KeystoreSelfsignedCertificateRequestI } from "@lxc/app-device-types";
import type { Rules } from "async-validator";
import { HttpStatusCode } from "axios";
import type { Ref, WritableComputedRef } from "vue";
import { ref } from "vue";
import { VALIDATION_REGEXP } from "~/core/constants/constants";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { KeystoreForm } from "~/modules/certificate/models/KeystoreForm.interface";
import keystoreService from "~/modules/certificate/services/keystore.service";
import { getValuesFromUniqueLabels } from "~/utils/unique-label-tools";

const { t } = useI18n();
const serialize = useSerialize();

const props = defineProps<{
  disabled?: false;
  isTagsLoading: boolean;
  sideCanvasShown: boolean;
  tags?: string[] | null;
  tagsError?: LxcError | null;
}>();

const emit = defineEmits(["save", "update:sideCanvasShown"]);

const isLoading: Ref<boolean> = ref(false);
const defaultCertificateForm: KeystoreForm = {
  alias: "",
  subject: {
    cn: "",
    email: "",
    serialNumber: undefined,
    organizationUnit: undefined,
    organization: undefined,
    locality: undefined,
    region: undefined,
    country: undefined,
  },
  tags: [],
  subjectAlternativeNames: [],
  extendedKeyUsageIds: [],
};

const formSideCanvasShown: WritableComputedRef<boolean> = computed({
  get() {
    return props.sideCanvasShown;
  },
  set(sideCanvasShown: boolean) {
    emit("update:sideCanvasShown", sideCanvasShown);
  },
});
const genericFormRef = ref();
// deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
const certificateForm: Ref<KeystoreForm> = ref<KeystoreForm>({
  ...defaultCertificateForm,
  subject: { ...defaultCertificateForm.subject },
  tags: [...defaultCertificateForm.tags],
  subjectAlternativeNames: [...defaultCertificateForm.subjectAlternativeNames],
  extendedKeyUsageIds: [...defaultCertificateForm.extendedKeyUsageIds],
});
const defaultCertificateFormStringified = serialize(defaultCertificateForm);
const certificateFormRef: Ref = ref();
const edited = computed(() => {
  const strigifiedForm = serialize(certificateForm.value);
  return strigifiedForm !== defaultCertificateFormStringified;
});

// Certificate rules
const rules = computed(() => {
  const rulesConfig: Rules = {
    alias: [
      {
        required: true,
        message: t("certificates.validation.alias.required"),
        type: "string",
        whitespace: false,
      },
      {
        type: "string",
        message: t("certificates.validation.alias.invalid"),
        pattern: VALIDATION_REGEXP.CERTIFICATE_ALIAS,
      },
    ],
    "subject.cn": [
      {
        required: true,
        message: t("certificates.validation.cn"),
        type: "string",
        whitespace: false,
      },
    ],
    "subject.email": [
      {
        required: true,
        message: t("certificates.validation.email"),
        whitespace: false,
      },
      {
        type: "email",
        message: t("input.error.invalidFormat"),
      },
    ],
  };

  return rulesConfig;
});

function setKeystoreForm() {
  // deep copy of certificate form including the subject to avoid pointer references to the defaultCertificateForm
  certificateForm.value = Object.assign(
    certificateForm.value,
    defaultCertificateForm,
    {
      subject: { ...defaultCertificateForm.subject },
      tags: [...defaultCertificateForm.tags],
      subjectAlternativeNames: [
        ...defaultCertificateForm.subjectAlternativeNames,
      ],
      extendedKeyUsageIds: [...defaultCertificateForm.extendedKeyUsageIds],
    },
  );
  // set timeout to be sure that the clearValidate is processed after updating the form inputs
  setTimeout(certificateFormRef.value?.clearValidate, 0);
}

async function saveForm(): Promise<void> {
  isLoading.value = true;
  certificateForm.value.alias = certificateForm.value.alias.trim();

  const certificate: KeystoreSelfsignedCertificateRequestI = {
    ...certificateForm.value,
    tags: getValuesFromUniqueLabels(certificateForm.value.tags),
  };

  const response =
    await keystoreService.generateSelfSignedCertificate(certificate);

  if (LxcError.check(response)) {
    if (response.status === HttpStatusCode.Conflict) {
      showNotificationError(
        t("notification.error.keystore.aliasAlreadyUsed.title"),
        t("notification.error.keystore.aliasAlreadyUsed.message"),
        false,
      );
    } else {
      response.notify(NotificationKey.saveError);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    close();
    setKeystoreForm();
    emit("save");
  }

  isLoading.value = false;
}

async function validate(): Promise<boolean> {
  const [certificateResult, genericResult] = await Promise.all([
    certificateFormRef.value?.validate().catch(() => false),
    genericFormRef.value?.validate().catch(() => false),
  ]);

  return certificateResult && genericResult;
}

const onSubmit = async (): Promise<boolean> => {
  const dataValid = await validate();

  if (dataValid) {
    await saveForm();
  }

  return dataValid;
};

function close() {
  formSideCanvasShown.value = false;
}

const onCancel = () => {
  close();
  setKeystoreForm();
};
</script>

<template>
  <lxc-side-canvas
    v-model:show="formSideCanvasShown"
    size="1/3"
    :header="t('certificates.keystore.generateSelfSignedCertificate')"
    :close-tooltip="t('button.close')"
    @discard="onCancel"
  >
    <lxc-form
      ref="certificateFormRef"
      :model="certificateForm"
      :rules="rules"
      @submit.prevent="onSubmit"
    >
      <keystore-generic-generation-form
        ref="genericFormRef"
        v-model="certificateForm"
        :disabled="false"
        :edition="false"
        :is-tags-loading="isTagsLoading"
        :tags="tags"
        :tags-error="tagsError"
      />
    </lxc-form>

    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.close')"
          @click="onCancel"
        >
          {{ t("button.close") }}
        </lxc-button>
        <lxc-button
          html-type="submit"
          :disabled="!edited || isLoading"
          :title="t('certificates.keystore.generateSelfSignedCertificate')"
          @click="onSubmit"
        >
          {{ t("certificates.keystore.generateSelfSignedCertificate") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
