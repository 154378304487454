<script setup lang="ts">
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { useConfigStore } from "~/core/stores/useConfigStore";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { UserCertificate } from "~/modules/user/models/UserCertificate.interface";
import type { UserData } from "~/modules/user/models/UserData.interface";
import userCertificateService from "~/modules/user/services/userCertificate.service";

const { featureToggle } = useConfigStore();
const acl = useAcl();
const { t } = useI18n();
const formRef = ref();
const isLoading = ref(false);
const refreshUserCertificateStatus = ref(false);
const userCertificateForm: UserCertificate = reactive({
  id: "",
});
const props = defineProps<{
  user: UserData | null;
}>();

const canViewCertificate = computed(
  () =>
    featureToggle.isVisible("user-certificate") &&
    acl.can(ACLRoles.USR_CERTIFICATES),
);

onMounted(() => {
  Object.assign(userCertificateForm, {
    id: props.user?.id,
  });
});

async function onRequestUserCertificate() {
  if (userCertificateForm.id) {
    isLoading.value = true;
    const response = await userCertificateService.requestUserCertificate(
      userCertificateForm.id,
    );

    isLoading.value = false;

    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
    } else {
      refreshUserCertificateStatus.value = !refreshUserCertificateStatus.value;
      showNotificationSuccess(t(NotificationKey.certificateGenerationSuccess));
    }
  }
}
</script>
<template>
  <container-component v-if="canViewCertificate" :px="0" :py="0">
    <h2>{{ t("user.userAccount.certificate.title") }}</h2>

    <user-account-certificate-status
      :refresh-user-certificate-status="refreshUserCertificateStatus"
      :user="user"
    />
    <el-form ref="formRef" :model="userCertificateForm">
      <el-form-item>
        <lxc-button
          type="secondary"
          html-type="button"
          :title="t('user.userAccount.forms.requestNewCertificate')"
          class="h-10 my-4"
          :disabled="isLoading"
          @click="onRequestUserCertificate"
        >
          {{ t("user.userAccount.forms.requestNewCertificate") }}
        </lxc-button>
      </el-form-item>
    </el-form>
  </container-component>
</template>
<style lang="scss" scoped>
.loader {
  margin-left: 0.6em;
}
</style>
