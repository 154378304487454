<script setup lang="ts">
import { DtwinOperationStatus } from "@lxc/app-device-types";
import { Color } from "~/core/models/Color.enum";

const props = defineProps<{
  status: DtwinOperationStatus;
}>();

const { t } = useI18n();

const badgeType = computed(() => {
  switch (props.status) {
    case DtwinOperationStatus.RUNNING:
    case DtwinOperationStatus.DELIVERED:
    case DtwinOperationStatus.RETRY:
      return Color.PRIMARY;
    case DtwinOperationStatus.CANCELLING:
      return Color.WARNING;
    case DtwinOperationStatus.DONE:
      return Color.SUCCESS;
    case DtwinOperationStatus.FAILED:
    case DtwinOperationStatus.EXPIRED:
    case DtwinOperationStatus.CANCELLED:
      return Color.DANGER;
    case DtwinOperationStatus.SCHEDULED:
    case DtwinOperationStatus.PENDING:
    case DtwinOperationStatus.QUEUED:
    default:
      return Color.SECONDARY;
  }
});

const statusTranslationFromEnum = computed(() => {
  let translation;
  switch (props.status) {
    case DtwinOperationStatus.RUNNING:
      translation = t("operation.running");
      break;
    case DtwinOperationStatus.SCHEDULED:
      translation = t("operation.scheduled");
      break;
    case DtwinOperationStatus.QUEUED:
      translation = t("operation.queued");
      break;
    case DtwinOperationStatus.DELIVERED:
      translation = t("operation.delivered");
      break;
    case DtwinOperationStatus.RETRY:
      translation = t("operation.retry");
      break;
    case DtwinOperationStatus.PENDING:
      translation = t("operation.pending");
      break;
    case DtwinOperationStatus.CANCELLING:
      translation = t("operation.cancelling");
      break;
    case DtwinOperationStatus.CANCELLED:
      translation = t("operation.cancelled");
      break;
    case DtwinOperationStatus.FAILED:
      translation = t("operation.failed");
      break;
    case DtwinOperationStatus.EXPIRED:
      translation = t("operation.expired");
      break;
    case DtwinOperationStatus.DONE:
      translation = t("operation.done");
      break;
  }
  if (translation === undefined) {
    translation = t("operation.unknown");
  }
  return translation;
});
</script>
<template>
  <lxc-badge :type="badgeType" :text-white="badgeType === 'primary'">
    {{ statusTranslationFromEnum }}
  </lxc-badge>
</template>
