<script lang="ts" setup>
import type { SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { isFeatureEnabled } from "~/composables/useFeature";
import type { TabNavigation } from "~/core/components/TabsWithNavigation.vue";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { AppFeatures } from "~/core/models/AppFeatures.enum";
import LxcError from "~/core/utils/LxcError";
import SectorsService from "~/modules/sector/services/sectors.service";

const acl = useAcl();
const route = useRoute();
const router = useRouter();
const sectorCode: Ref<string> = ref(route.params.code.toString());

const isLoading = ref(false);
const sector: Ref<SectorI | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);

async function fetchSector() {
  isLoading.value = true;
  const response = await SectorsService.getSectorByCode(sectorCode.value);

  if (LxcError.check(response)) {
    error.value = response;
  } else {
    sector.value = response;
    error.value = null;
  }

  isLoading.value = false;
}

async function navigateToSectorList() {
  await router.push(PATHS.SECTORS);
}

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const devicesTabIndex = 0;
const dtwinsTabIndex = 1;
const applicationsTabIndex = 2;
const usersTabIndex = 3;
const groupsTabIndex = 4;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [];
  if (acl.can(ACLRoles.DISPLAY_DEVICES)) {
    tabNavigations.push({
      index: devicesTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.DEVICES_DVTM_ESOFT_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.device.label",
      forceRefresh: true,
    });
  }
  if (isFeatureEnabled(AppFeatures.DTWINS)) {
    tabNavigations.push({
      index: dtwinsTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.DTWINS_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.dtwins.label",
      forceRefresh: true,
    });
  }
  if (acl.can(ACLRoles.DISPLAY_APPLICATIONS)) {
    tabNavigations.push({
      index: applicationsTabIndex,
      redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.APPLICATIONS_SUBPATH}`,
      labelTranslationKey: "sectors.details.tab.application.label",
      forceRefresh: true,
    });
  }
  if (acl.can(ACLRoles.DISPLAY_USERS)) {
    tabNavigations.push(
      {
        index: usersTabIndex,
        redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.USERS_SUBPATH}`,
        labelTranslationKey: "sectors.details.tab.user.label",
        forceRefresh: true,
      },
      {
        index: groupsTabIndex,
        redirectPath: `${PATHS.SECTORS}/${sectorCode.value}/${PATHS.GROUPS_SUBPATH}`,
        labelTranslationKey: "sectors.details.tab.userGroup.label",
        forceRefresh: true,
      },
    );
  }
  return tabNavigations;
});

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(fetchSector);
</script>

<template>
  <page-component
    :name="sector?.label ?? ''"
    back
    :back-callback="navigateToSectorList"
    should-not-translate
  >
    <template v-if="sector" #default>
      <p v-if="sector?.description" class="mb-6">{{ sector?.description }}</p>

      <tabs-with-navigation :tab-navigations="tabNavigations">
        <template #[devicesTabIndex]>
          <lxc-sector-detail-devices :sector="sector" />
        </template>
        <template #[dtwinsTabIndex]>
          <lxc-sector-detail-dtwins :sector="sector" />
        </template>
        <template #[applicationsTabIndex]>
          <lxc-sector-detail-applications :sector="sector" />
        </template>
        <template #[usersTabIndex]>
          <lxc-sector-detail-users :sector="sector" />
        </template>
        <template #[groupsTabIndex]>
          <lxc-sector-detail-user-groups :sector="sector" />
        </template>
      </tabs-with-navigation>
    </template>
  </page-component>
</template>
