<script setup lang="ts">
import dayjs from "dayjs";
import { useCampaignObjectOperations } from "~/core/composables/useCampaignObjectOperations";
import { OperationType } from "~/core/models/OperationType.enum";
import { CampaignType } from "~/modules/campaign/models/CampaignType.enum";

const props = defineProps<{
  applicationId: string;
  operationType?: OperationType;
}>();

const { t } = useI18n();

const campaignType = computed(() => {
  switch (props.operationType) {
    case OperationType.CRTCLT_RENEWAL_APP:
      return CampaignType.CRTCLT_RENEWAL_APP;
  }
});
const { fetchData, isLoading, operations, error } = useCampaignObjectOperations(
  props.applicationId,
  campaignType.value,
);

const dateFormat = t("operation.dateFormat");

const operationType = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.CRTCLT_RENEWAL_APP:
      return OperationType.CRTCLT_RENEWAL_APP;
  }
};

onMounted(fetchData);
</script>

<template>
  <lxc-table
    min-width="86rem"
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="operations?.data"
    :empty-text="t('operation.empty')"
    :context="operations?.context"
    data-cy="product-details-operations-table"
    @change-page-and-page-size="fetchData"
  >
    <lxc-table-column
      prop="type"
      :label="t('operation.type')"
      min-width="20rem"
    >
      <template #default="scope">
        {{ t(`operation.operationType.${operationType(scope.row.type)}`) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="createdAt"
      :label="t('operation.createdAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.createdAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="plannedStartAt"
      :label="t('operation.launchedAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.plannedStartAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="modifiedAt"
      :label="t('operation.updatedAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.modifiedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column prop="state" :label="t('operation.state')" width="21rem">
      <template #default="scope">
        <operation-state :state="scope.row.state" :with-label="false" />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
