<script setup lang="ts">
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import LxcError from "~/core/utils/LxcError";
import { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";
import type { DashboardServices } from "~/modules/dashboard/models/DashboardServices.interface";
import DashboardService from "~/modules/dashboard/services/dashboard.service";
import { verifyCardStatus } from "~/modules/dashboard/utils/dashboard";

const { t } = useI18n();
const { can } = useAcl();
const dashboardLoadingStatus = ref<DashboardCardStatus>(
  DashboardCardStatus.LOADING,
);
const dashboardServices = ref<DashboardServices>();

const displayPkiService = ACLRoles.CONFIGURE_PKI;

async function fetchDashboardServices() {
  dashboardLoadingStatus.value = DashboardCardStatus.LOADING;

  const response = await DashboardService.getDashboardServices();

  if (LxcError.check(response)) {
    dashboardLoadingStatus.value = DashboardCardStatus.FAILED;
  } else {
    dashboardServices.value = response;
    dashboardLoadingStatus.value = DashboardCardStatus.LOADED;
  }
}

onMounted(async () => {
  if (can(displayPkiService)) {
    await fetchDashboardServices();
  } else {
    dashboardLoadingStatus.value = DashboardCardStatus.UNAUTHORIZED;
  }
});

const isAuthorized = computed(
  () => dashboardLoadingStatus.value !== DashboardCardStatus.UNAUTHORIZED,
);
</script>

<template>
  <dashboard-card
    :title="t('dashboard.services.title')"
    :display-header-margin="true"
    :status="verifyCardStatus(dashboardLoadingStatus, dashboardServices)"
  >
    <div v-if="isAuthorized">
      <dashboard-tile-services-status-item
        :title="t('dashboard.services.activeDirectory')"
        :service-status="dashboardServices?.activeDirectory"
      />

      <dashboard-tile-services-status-item
        v-if="isAuthorized"
        :title="t('dashboard.services.pkiConnector')"
        :service-status="dashboardServices?.pkiConnector"
      />

      <dashboard-tile-services-status-item
        v-if="isAuthorized"
        :title="t('dashboard.services.smtpServer')"
        :service-status="dashboardServices?.smtpServer"
      />
    </div>
    <div v-else class="description-container">
      <div class="description-content">
        {{ t("dashboard.services.unauthorized") }}
      </div>
    </div>
  </dashboard-card>
</template>

<style lang="scss" scoped>
.description-container {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.description-title {
  color: $light-blue-color;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 30px;
}

.description-content {
  margin-left: 25px;
  font-size: 12px;
}
</style>
