<script setup lang="ts">
import type { AsyncDeviceTwinsFirmwareStatisticsMap } from "@lxc/app-device-types";
import { PATHS } from "~/core/constants/paths";
import type { Option } from "~/core/models/filters";
import { APP_MODEL_TYPE_OPTIONS } from "~/modules/application/constants";
import type { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";
import {
  isFirmwareTileDisplayable,
  verifyCardStatus,
} from "~/modules/dashboard/utils/dashboard";
import { useLicenseStatusStore } from "~/modules/license/stores/useLicenseStatusStore";

const props = defineProps<{
  status: DashboardCardStatus;
  firmwares?: AsyncDeviceTwinsFirmwareStatisticsMap;
}>();

const { t } = useI18n();
const licenseStatusStore = useLicenseStatusStore();

function getUriRedirect(firmwareName: string): string {
  let uri: string;

  if (
    APP_MODEL_TYPE_OPTIONS.options.some(
      (model: Option): boolean => model.value === firmwareName,
    )
  ) {
    uri = PATHS.APPLICATIONS;
  } else {
    uri = PATHS.DEVICES_DVTM_ESOFT;
  }

  return uri;
}
</script>

<template>
  <dashboard-card
    v-if="licenseStatusStore.isLicenseFeatureAdvancedFleetManagementActive"
    :display-header-margin="false"
    :status="verifyCardStatus(props.status, props.firmwares)"
  >
    <div
      v-if="isFirmwareTileDisplayable(firmwares)"
      class="flex flex-wrap justify-evenly relative box-border gap-2.5"
    >
      <div
        v-for="(firmware, firmwarName) in firmwares"
        :key="firmwarName"
        class="grow shrink basis-0 flex justify-center"
      >
        <dashboard-tile-firmware-pie
          :firmware-name="firmwarName"
          :versions="firmware"
          :url-redirect="getUriRedirect(firmwarName)"
        />
      </div>
    </div>

    <div v-else class="h-[7.5rem] flex justify-center p-2.5 text-center">
      <p>{{ t("dashboard.firmwares.noData") }}</p>
    </div>
  </dashboard-card>
</template>
