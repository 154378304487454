<script setup lang="ts">
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import { DtwinConfigurationType } from "~/modules/dtwin/models/DtwinConfigurationType.enum";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import ILxcEye from "~icons/lxc/eye";
import ILxcShare from "~icons/lxc/share";

const props = defineProps<{
  dtwin?: Dtwin | null;
  roles?: string[];
}>();

const { t } = useI18n();
const route = useRoute();
const userSessionStore = useUserSession();

const reloadConfigurationList = ref(false);
const canUploadConfiguration: ComputedRef<boolean | undefined> = computed(
  () => {
    return props.roles?.every((role) =>
      userSessionStore.userSession?.roles.includes(role),
    );
  },
);

const dtwinUid: ComputedRef<string> = computed(() => {
  return props.dtwin?.uid ?? route.params.id.toString();
});
const showImportPanel = ref(false);
const showConfigurationListPanel = ref(false);

const onShowImportPanel = () => {
  showImportPanel.value = true;
};

const onShowConfigurationListPanel = () => {
  showConfigurationListPanel.value = true;
};
const onReloadConfigurationList = () => {
  reloadConfigurationList.value = true;
};

const browseButtonTranslation = computed(() => {
  if (
    props.dtwin &&
    props.dtwin.configurationType &&
    props.dtwin.configurationType === DtwinConfigurationType.SELF_SUPPORTING
  ) {
    return t("dtwins.form.configuration.browse.selfSupporting");
  } else {
    return t("dtwins.form.configuration.browse.specific");
  }
});
</script>

<template>
  <div class="flex justify-between items-start">
    <div class="flex-1">
      <h3 class="!mb-4">{{ t("dtwins.form.configuration.title") }}</h3>
      <lxc-dtwins-core-configuration-information :dtwin="dtwin" />
    </div>
    <!-- Do not display the management of self-supporting configuration file if the configuration type is not defined -->
    <div
      v-if="dtwin?.configurationType && canUploadConfiguration"
      class="mt-8 flex gap-4"
    >
      <lxc-button
        type="tertiary"
        html-type="button"
        :title="browseButtonTranslation"
        class="!px-3 !py-3"
        @click="onShowConfigurationListPanel"
      >
        <i-lxc-eye class="text-gray-700 w-5 h-5" />
      </lxc-button>
      <lxc-button
        type="tertiary"
        html-type="button"
        :title="t('dtwins.form.configuration.upload.buttonTooltip')"
        class="!px-3 !py-3"
        @click="onShowImportPanel"
      >
        <i-lxc-share class="text-gray-700 w-5 h-5" />
      </lxc-button>
      <lxc-dtwins-configuration-upload
        v-model:show-import-panel="showImportPanel"
        :dtwin-uid="dtwinUid"
        :configuration-type="dtwin?.configurationType"
        @uploaded="onReloadConfigurationList"
      />
      <lxc-dtwins-configuration-list
        v-model:show-list-panel="showConfigurationListPanel"
        v-model:reload="reloadConfigurationList"
        :dtwin-uid="dtwinUid"
        :configuration-type="dtwin.configurationType"
      />
    </div>
  </div>
  <lxc-dtwins-core-configuration-operations-list :dtwin="dtwin" />
</template>
