import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/core/composables/useFetchPage";
import { SearchMode, useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import {
  Filters,
  type FiltersSelection,
  FiltersType,
} from "~/core/models/filters";
import { useConfigStore } from "~/core/stores/useConfigStore";
import { sum, zeroIfNaN } from "~/core/utils/number";
import type { Application } from "~/modules/application/models/Application.interface";
import type { Campaign } from "~/modules/campaign/models/Campaign.interface";
import CampaignService from "~/modules/campaign/services/campaign.service";
import { computeCampaignStatPercentage } from "~/modules/campaign/utils/campaignStatisticsComputation";
import type { Sector } from "~/modules/sector/models/Sector.interface";
import { useUserSession } from "~/modules/user/stores/useUserSession";

export function useCampaign() {
  const service = CampaignService.getCampaigns.bind(CampaignService);

  const { can } = useAcl();
  const { userSession } = useUserSession();
  const { defaultSectorCode } = useConfigStore();

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RAW,
    appliedFilters,
    SearchMode.URL_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: true,
  });

  function getDescription(item: Application) {
    return item?.name ?? "";
  }

  function canManageItems(sector: Sector) {
    return (
      !sector.isRootSector &&
      can(ACLRoles.NO_CONTROL) &&
      userSession?.sectors
        .map((sector) => sector.code)
        .includes(`${defaultSectorCode}`)
    );
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map((item) => item.id);
  }

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  };
}

export function useGetCampaignStat(
  campaign?: Campaign | null,
): Map<string, any> {
  const stats: Map<string, any> = new Map();
  const countTotal = zeroIfNaN(campaign?.statistics?.total);

  const countPending = sum([
    campaign?.statistics?.pending,
    campaign?.statistics?.opPending,
    campaign?.statistics?.paused,
  ]);

  const countInProgress = sum([
    campaign?.statistics?.delivered,
    campaign?.statistics?.opRunning,
    campaign?.statistics?.retry,
    campaign?.statistics?.opRetry,
  ]);

  const countSuccess = sum([campaign?.statistics?.done]);

  const countFailed = sum([
    campaign?.statistics?.opCancelling,
    campaign?.statistics?.opCancelled,
    campaign?.statistics?.opError,
    campaign?.statistics?.cancelling,
    campaign?.statistics?.cancelled,
    campaign?.statistics?.error,
    campaign?.statistics?.expired,
  ]);

  stats.set("countPending", countPending);
  stats.set("countInProgress", countInProgress);
  stats.set("countSuccess", countSuccess);
  stats.set("countFailed", countFailed);

  stats.set(
    "percentPending",
    computeCampaignStatPercentage(countTotal, countPending),
  );
  stats.set(
    "percentInProgress",
    computeCampaignStatPercentage(countTotal, countInProgress),
  );
  stats.set(
    "percentSuccess",
    computeCampaignStatPercentage(countTotal, countSuccess),
  );
  stats.set(
    "percentFailed",
    computeCampaignStatPercentage(countTotal, countFailed),
  );

  return stats;
}
