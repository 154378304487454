export enum ComponentType {
  ALERT_MANAGER = "alertManager",
  DEVICE_TWIN_MANAGER_ESOFT = "deviceTwinManagerEsoft",
  CAMPAIGN_MANAGER = "campaignManager",
  PKI_CONNECTOR = "pkiConnector",
  USER_MANAGER = "userManager",
  APP_MANAGER = "applicationManager",
  CONTENT_MANAGER = "contentManager",
  CA_CERT_MANAGER = "caCertificateManager",
  CERT_MANAGER = "certificateManager",
  API_GATEWAY = "apiGateway",
  TRANSLATION_MANAGER = "translationManager",
  OPERATION_MANAGER = "operationManager",
  DEVICE_TWIN_MANAGER = "deviceTwinManager",
  LICENSE_MANAGER = "licenseManager",
  DATA_MANAGER = "dataManager",
  AS = "as",
  FI = "fi",
  LWM2M_CONNECTOR = "lwm2mConnector",
  DATA_ENRICHMENT = "dataEnrichment",
}
