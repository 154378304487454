<script setup lang="ts">
import type { DtwinI } from "@lxc/app-device-types";

defineProps<{
  dtwin: DtwinI;
  modelLabel: string;
}>();

const emit = defineEmits(["close"]);
</script>

<template>
  <div class="w-96 rounded-lg bg-white">
    <div class="flex justify-between p-4">
      <map-devices-tile-header :dtwin="dtwin" @close="emit('close')" />
    </div>
    <hr class="py-1 border-gray-300" />
    <map-devices-tile-body :dtwin="dtwin" :model-label="modelLabel" />
  </div>
</template>
