<script lang="ts" setup>
import type LxcError from "~/core/utils/LxcError";

const props = defineProps<{
  error?: LxcError | null;
  isLoading?: boolean;
  loaderSize?: number;
  px?: number;
  py?: number;
  loaderContainerClass?: string | string[];
}>();

const DEFAULT_PADDING_X = 8;
const DEFAULT_PADDING_Y = 6;

const paddingYInPx: ComputedRef<number> = computed(
  () => (props.py ?? DEFAULT_PADDING_Y) * 4,
);
const paddingTop: ComputedRef<number> = computed(() => paddingYInPx.value);
const paddingBottom: ComputedRef<number> = computed(() => paddingYInPx.value);

const lxcContainerClass = computed(() => [
  `px-${props.px ?? DEFAULT_PADDING_X}`,
  `py-${props.py ?? DEFAULT_PADDING_Y}`,
  "flex",
  "flex-col",
  "grow",
]);

const loaderWrapperClass = computed(() => {
  const loaderWrapperClass = ["mt-20", "flex", "justify-center"];
  const propClass: string[] | undefined = Array.isArray(
    props.loaderContainerClass,
  )
    ? props.loaderContainerClass
    : props.loaderContainerClass?.split(" ");

  return loaderWrapperClass.concat(propClass ?? []);
});

defineExpose({
  paddingTop,
  paddingBottom,
});
</script>

<template>
  <div :class="lxcContainerClass">
    <div v-if="isLoading" :class="loaderWrapperClass">
      <lxc-loader :size="loaderSize || 28" />
    </div>
    <error-component v-else-if="error" :error="error" />
    <slot v-else />
  </div>
</template>
