<script lang="ts" setup>
import { CertificateConfigurationParameters } from "~/modules/certificateConfiguration/models/CertificateConfigurationParameters.enum";
import type { DeviceModelConfiguration } from "~/modules/certificateConfiguration/models/DeviceModelConfiguration.interface";
import { S4Types } from "~/modules/certificateConfiguration/models/S4Types.enum";

defineProps<{
  s4Device: DeviceModelConfiguration;
}>();
defineEmits(["change"]);

const { t } = useI18n();
</script>

<template>
  <h3>{{ t("table.header.equipments") }}</h3>
  <section class="config-row">
    <certificate-configuration-renewal
      :delay="s4Device.delay"
      :polling="s4Device.polling"
      :is-autoactivated="s4Device.autoActivation"
      :title="t('parameters.certificates.s4Device')"
      @select-change="
        $emit(
          'change',
          $event,
          S4Types.DVC,
          CertificateConfigurationParameters.DELAY,
        )
      "
      @polling-change="
        $emit(
          'change',
          $event,
          S4Types.DVC,
          CertificateConfigurationParameters.POLLING,
        )
      "
      @auto-activation-change="
        $emit(
          'change',
          $event,
          S4Types.DVC,
          CertificateConfigurationParameters.AUTO_ACTIVATION,
        )
      "
    />
  </section>
</template>
