<script setup lang="ts">
import { APP_MODEL_TYPE_OPTIONS } from "~/modules/application/constants";

const { t } = useI18n();

const props = defineProps<{
  modelValue: string[];
}>();
const emit = defineEmits(["select", "update:modelValue"]);

const isLoading = ref(false);

const selectedItems = computed({
  get() {
    return props.modelValue ?? [];
  },
  set(selected?: string[]) {
    emit("update:modelValue", selected);
    emit("select", selected);
  },
});
</script>

<template>
  <filter-selectable-list
    v-model="selectedItems"
    :data="APP_MODEL_TYPE_OPTIONS.options"
    :is-is-loading="isLoading"
    :empty-text="t('logs.filters.applications.type.empty')"
    :header="t('application.model.type')"
    prop="label"
    item-prop="value"
  />
</template>
