<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { PATHS } from "~/core/constants/paths";
import { useConfigStore } from "~/core/stores/useConfigStore";
import LxcError from "~/core/utils/LxcError";
import type { UserData } from "~/modules/user/models/UserData.interface";
import type { UserDataNew } from "~/modules/user/models/UserDataNew.type";
import type { UserIdentity } from "~/modules/user/models/UserIdentity.interface";
import userService from "~/modules/user/services/user.service";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { isModeAD } = storeToRefs(useConfigStore());

const error: Ref<LxcError | null> = ref(null);
const isLoading = ref(false);
const routeUserId = route?.params?.id as string;
const isCreate = routeUserId === "new";
const userId = ref(isCreate ? null : routeUserId);

const user: Ref<UserData | UserDataNew | null> = ref(null);
const step = ref(0);
const name = computed(() =>
  user?.value?.firstName
    ? `${user?.value?.firstName} ${user?.value?.lastName}`
    : "user.button.create",
);

onMounted(async () => {
  user.value = null;
  error.value = null;

  if (userId.value) {
    isLoading.value = true;
    const response = await userService.getUserById(userId.value);

    if (LxcError.check(response)) {
      error.value = response;
    } else {
      user.value = response;
    }

    isLoading.value = false;
  }
});

function onSaveUserIdentity(user: UserIdentity) {
  userId.value = user.id!;
  step.value = 1;
}

function onSaveUserGroups() {
  router.push(PATHS.USER_MANAGEMENT_USERS);
}
</script>

<template>
  <el-container
    data-cy="page-user-component"
    direction="vertical"
    class="container"
  >
    <breadcrumb-component
      :name="isLoading ? '' : name"
      class="px-8 mt-8"
      back
      :should-not-translate="!isCreate"
    />
    <container-component
      class="half-container mb-4"
      :is-loading="isLoading"
      :error="error"
    >
      <lxc-mandatory />

      <el-steps direction="vertical" :active="step" finish-status="success">
        <el-container direction="horizontal">
          <el-step :title="t('user.form.userIdentity.title')" />
          <user-form-identity
            :disabled="step !== 0 || isModeAD"
            :user="user"
            @submit="onSaveUserIdentity"
          />
        </el-container>

        <el-container direction="horizontal">
          <el-step :title="t('user.form.userProfile.title')" />

          <user-form-profile
            :disabled="step !== 1 || isModeAD"
            :user-id="userId"
            :user="user"
            @previous="step = 0"
            @submit="step = 2"
          />
        </el-container>

        <el-container direction="horizontal">
          <el-step :title="t('user.form.userGroups.title')" />
          <user-form-groups
            :disabled="step !== 2 || isModeAD"
            :user-id="userId"
            :user="user"
            @previous="step = 1"
            @submit="onSaveUserGroups"
          />
        </el-container>
      </el-steps>

      <el-row justify="center">
        <el-button
          plain
          class="lxc-plain"
          data-cy="back-button"
          @click="router.back()"
        >
          {{ t("button.cancel") }}
        </el-button>
      </el-row>
    </container-component>
  </el-container>
</template>

<style lang="scss" scoped>
:deep(.el-steps) {
  .el-container .el-form {
    flex: 1;
    margin: 40px 0 0;
  }

  .el-step {
    flex-basis: auto !important;
    width: 50px;
  }

  .el-step:last-of-type .el-step__line {
    display: block;
  }

  .el-container:last-of-type .el-step .el-step__line {
    display: none;
  }
}
</style>
