/**
 * MVP : only registered, activated and deactivated are needed
 */
export enum AppDeviceState {
  PROVISIONED = "provisioned",
  REGISTERED = "registered",
  LOCALIZED = "localized",
  ACTIVATED = "activated",
  DEACTIVATED = "deactivated",
  ARCHIVED = "archived",
  BLACKLISTED = "blacklisted",
}
