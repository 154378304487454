import RestService from "./rest.service";
import type { AsyncTaskId } from "~/core/models/AsyncTaskId.interface";
import type { AsyncTaskStatus } from "~/core/models/AsyncTaskStatus.interface";

class AlertService extends RestService {
  private readonly BASE_URL = "/alert";

  getExportLogsTaskId(): Promise<AsyncTaskId> {
    return this.$api.get(`${this.BASE_URL}/logs/export`);
  }

  getExportLogsTaskStatus(taskId: string): Promise<AsyncTaskStatus> {
    return this.$api.get(`${this.BASE_URL}/logs/tasks/${taskId}/status`);
  }

  downloadExportLogs(taskId: string): Promise<any> {
    return this.$api.get(`/logs/tasks/${taskId}/download`, {
      headers: { Accept: "application/octet-stream" },
      responseType: "arraybuffer",
      timeout: Infinity,
    });
  }
}

export default new AlertService();
