import type {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import axios from "axios";
import queryString from "query-string";
import { BASE_URL_MAP, SERVICE_URL_MAP } from "~/core/constants/constants";
import { ErrorCode } from "~/core/models/ErrorCode.enum";
import { ErrorMessage } from "~/core/models/ErrorMessage.enum";
import LxcError from "~/core/utils/LxcError";

export default class RestService {
  protected $api: AxiosInstance;
  protected $apiV2: AxiosInstance;
  protected $apiV3: AxiosInstance;

  constructor(serverUrl: string = import.meta.env.LXC_AS_DEVICE_URL) {
    const axiosConfig = {
      timeout: 10000,
      withCredentials: true,
    };

    this.$api = axios.create({
      ...axiosConfig,
      baseURL: `${serverUrl}/api/v1`,
    });

    this.$apiV2 = axios.create({
      ...axiosConfig,
      baseURL: `${serverUrl}/api/v2`,
    });

    this.$apiV3 = axios.create({
      ...axiosConfig,
      baseURL: `${serverUrl}/api/v3`,
    });

    this.$api.interceptors.request.use(this.serializeParameters);
    this.$apiV2.interceptors.request.use(this.serializeParameters);
    this.$apiV3.interceptors.request.use(this.serializeParameters);

    this.$api.interceptors.response.use(
      RestService.onFullFilled,
      RestService.onRejected,
    );
    this.$apiV2.interceptors.response.use(
      RestService.onFullFilled,
      RestService.onRejected,
    );
    this.$apiV3.interceptors.response.use(
      RestService.onFullFilled,
      RestService.onRejected,
    );
  }

  private serializeParameters(config: InternalAxiosRequestConfig<any>) {
    // Serialize the parameters with library "query-string"
    // It enables to encode brackets in parameters value in order to be compliant with RFC3986, otherwise the backend (AS Spring Boot) will reject the request
    config.paramsSerializer = (params: any) => {
      return (
        queryString
          .stringify(params, { arrayFormat: "bracket" })
          // Also encode the brackets in parameters name
          .replace(/\[/g, "%5B")
          .replace(/]/g, "%5D")
      );
    };
    return config;
  }

  /**
   * Return data from response on success
   * @private
   * @param response
   */
  private static onFullFilled(response: AxiosResponse) {
    if (response.status === 204) {
      return null;
    }
    return response.data;
  }

  /**
   * Logout if 401 is received
   * Prepare Error otherwise
   * @param error
   * @private
   */
  private static async onRejected(error: any): Promise<LxcError> {
    const status = error.response?.status;
    // If the page is /users/me, the redirection is done in the useUserSession store
    // otherwise it would loop to the login page
    if (
      status === 401 &&
      error.response?.config?.url !==
        `${BASE_URL_MAP.USERS}${SERVICE_URL_MAP.CURRENT_USER}`
    ) {
      const { logout } = await import("~/utils/auth.utils");
      await logout();
    }

    const code = error?.response?.data?.code;

    const lxcError = code
      ? { ...error.response.data, ...{ status } }
      : {
          code: ErrorCode.UNEXPECTED,
          message: ErrorMessage.UNKNOWN,
          timestamp: new Date().getTime(),
          status,
        };

    return new LxcError(lxcError);
  }
}
