<script setup lang="ts">
import type { DeviceI } from "@lxc/app-device-types";
import { AppDeviceState, OperationType } from "@lxc/app-device-types";
import { Roles } from "~/modules/user/models/Roles.enum";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import { ObjectType } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  device: DeviceI;
}>();

const { t } = useI18n();
const store = useUserSession();
const [confirmUpdateFirmwareVisible, toggleConfirmUpdateFirmware] = useToggle();

const activeName = ref("unitary");
const operationsListRef = ref();
const firmwareFormRef = ref();
const firmwareForm = reactive({
  version: "",
  description: "",
  origin: "",
  lastUpdate: "",
});

const disabledUpdateFirmwareButton = computed(
  () =>
    props.device.state !== AppDeviceState.ACTIVATED ||
    !store.userSession?.roles.includes(Roles.DVTM_DVT_FIRM_UPDATE),
);

onMounted(() => {
  Object.assign(firmwareForm, {
    version: props.device?.firmwareVersion
      ? props.device?.firmwareVersion
      : "-",
    description: "n/a",
    origin: "n/a",
    lastUpdate: "n/a",
  });
});

function updateOperationList() {
  operationsListRef.value.updateTable();
}
</script>
<template>
  <div class="flex flex-col">
    <h3>{{ t("device.firmwareForm.title") }}</h3>
    <lxc-form ref="firmwareFormRef" :model="firmwareForm">
      <div class="flex">
        <div class="w-1/3">
          <lxc-form-item :label="t('device.firmwareForm.version')">
            <lxc-input v-model="firmwareForm.version" type="text" read-only />
          </lxc-form-item>
          <lxc-form-item :label="t('device.firmwareForm.description')">
            <lxc-input
              v-model="firmwareForm.description"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
        <div class="w-1/3">
          <lxc-form-item :label="t('device.firmwareForm.origin')">
            <lxc-input v-model="firmwareForm.origin" type="text" read-only />
          </lxc-form-item>
          <lxc-form-item :label="t('device.firmwareForm.lastUpdate')">
            <lxc-input
              v-model="firmwareForm.lastUpdate"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </div>
    </lxc-form>
    <lxc-button
      class="w-fit"
      :icon="ILxcPlus"
      :title="t('device.updateFirmware')"
      :disabled="disabledUpdateFirmwareButton"
      @click="toggleConfirmUpdateFirmware(true)"
    >
      {{ t("device.updateFirmware") }}
    </lxc-button>
  </div>
  <div class="mt-12">
    <h3>
      {{ t("section.operations.title") }}
    </h3>
    <lxc-tabs v-model="activeName" class="tabs">
      <lxc-tab-pane :label="t('device.operation.unitary')" name="unitary">
        <lxc-device-operations-list
          v-if="device?.id"
          ref="operationsListRef"
          :device-id="device.id"
          :device="device"
          :operation-type="OperationType.FIRMWARE_UPGRADE_DVC"
          :has-callback-url="false"
        />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t('device.operation.campaign')" name="campaign">
        <lxc-device-campaign-operations-list
          v-if="device?.id"
          :device-id="device.id"
          :operation-type="OperationType.FIRMWARE_UPGRADE_DVC"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </div>
  <lxc-update-firmware-modal
    :is-dialog-visible="confirmUpdateFirmwareVisible"
    :object="device"
    :object-type="ObjectType.DEVICE"
    @update:toggle-dialog="toggleConfirmUpdateFirmware"
    @change="updateOperationList"
  />
</template>
