import type { ApiListResult, UserProfileI } from "@lxc/app-device-types";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import LxcError from "~/core/utils/LxcError";
import userProfileService from "~/modules/user/services/userProfile.service";

export function useProfile() {
  const profiles = ref<ApiListResult<UserProfileI> | null>(null);
  const error: Ref<LxcError | null | undefined> = ref(null);
  const isLoading = ref(true);

  async function fetchUserProfiles(
    page: number = DEFAULT_FIRST_PAGE,
    pageSize?: number,
    profileLabel?: string,
    query?: string,
  ) {
    isLoading.value = true;
    const response = await userProfileService.getUserProfiles(
      page,
      pageSize ?? DEFAULT_PAGE_SIZE,
      profileLabel,
      query,
    );

    if (LxcError.check(response)) {
      profiles.value = null;
      error.value = response;
    } else {
      profiles.value = response;
      error.value = null;
    }

    isLoading.value = false;
  }

  return {
    isLoading,
    profiles,
    error,
    fetchUserProfiles,
  };
}
