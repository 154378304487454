import { LogEntityClass } from "~/modules/log/models/LogEntityClass.enum";

const initDisplayableEntities: Record<string, boolean> = {};
initDisplayableEntities[LogEntityClass.DEVICE as string] = true;
initDisplayableEntities[LogEntityClass.DEVICE_DVTM_ESOFT as string] = true;
initDisplayableEntities[LogEntityClass.GATEWAY as string] = false;
initDisplayableEntities[LogEntityClass.GROUP as string] = true;
initDisplayableEntities[LogEntityClass.PLATFORM as string] = false;
initDisplayableEntities[LogEntityClass.PROFILE as string] = true;
initDisplayableEntities[LogEntityClass.ROLE as string] = false;
initDisplayableEntities[LogEntityClass.USER as string] = true;
export const displayableEntities: Record<string, boolean> = Object.freeze(
  initDisplayableEntities,
);

// Currently the entities "device" are not filtreable. See LXC-4474
const initFilterableEntities: Record<string, boolean> = {};
initFilterableEntities[LogEntityClass.DEVICE_DVTM_ESOFT as string] = true;
initFilterableEntities[LogEntityClass.GATEWAY as string] = false;
initFilterableEntities[LogEntityClass.GROUP as string] = true;
initFilterableEntities[LogEntityClass.PLATFORM as string] = false;
initFilterableEntities[LogEntityClass.PROFILE as string] = true;
initFilterableEntities[LogEntityClass.ROLE as string] = false;
initFilterableEntities[LogEntityClass.SECTOR as string] = true;
initFilterableEntities[LogEntityClass.USER as string] = true;
export const filterableEntities: Record<string, boolean> = Object.freeze(
  initFilterableEntities,
);
