import LxcError from "~/core/utils/LxcError";
import AppsService from "~/modules/application/services/application.service";
import type { ApplicationModelConfiguration } from "~/modules/certificateConfiguration/models/ApplicationModelConfiguration.interface";
import type { DeviceModelConfiguration } from "~/modules/certificateConfiguration/models/DeviceModelConfiguration.interface";
import DeviceService from "~/services/device.service";

export interface ICertificateConfiguration {
  isLoading: Ref<boolean>;
  error: Ref<LxcError | null | undefined>;
  appsConfig: Ref<ApplicationModelConfiguration[] | null | undefined>;
  devicesConfig: Ref<DeviceModelConfiguration[] | null | undefined>;
  fetchAppsConfig: () => void;
  fetchDeviceConfig: () => void;
  resetAppsConfig: () => void;
  resetDevicesConfig: () => void;
  onAppsConfigSaved: () => void;
  onDeviceConfigSaved: () => void;
}

export function useCertificateConfiguration(): ICertificateConfiguration {
  const isLoading = ref(false);
  const appsConfig = ref<ApplicationModelConfiguration[] | undefined | null>();
  const devicesConfig = ref<DeviceModelConfiguration[] | undefined | null>();
  let appsConfigBackup: ApplicationModelConfiguration[] | null = null;
  let deviceConfigBackup: DeviceModelConfiguration[] | null = null;

  const error = ref<LxcError | null | undefined>(null);

  function cloneDevicesConfig(
    paramDevicesConfig?: DeviceModelConfiguration[] | null,
  ): DeviceModelConfiguration[] | null {
    return (
      paramDevicesConfig?.map((deviceConfig: DeviceModelConfiguration) => ({
        ...deviceConfig,
      })) ?? null
    );
  }

  function cloneAppsConfig(
    paramAppsConfig?: ApplicationModelConfiguration[] | null,
  ): ApplicationModelConfiguration[] | null {
    return (
      paramAppsConfig?.map((appConfig: ApplicationModelConfiguration) => ({
        ...appConfig,
      })) ?? null
    );
  }

  async function fetchAppsConfig() {
    isLoading.value = true;
    const response = await AppsService.getAppsConfiguration();

    if (LxcError.check(response)) {
      appsConfig.value = null;
      appsConfigBackup = null;
      error.value = response;
    } else {
      appsConfig.value = response?.configs;
      appsConfigBackup = cloneAppsConfig(response?.configs);
    }
    isLoading.value = false;
  }

  async function fetchDeviceConfig() {
    isLoading.value = true;
    const response = await DeviceService.getDeviceConfiguration();

    if (LxcError.check(response)) {
      devicesConfig.value = null;
      deviceConfigBackup = null;
      error.value = response;
    } else {
      devicesConfig.value = response?.configs;
      deviceConfigBackup = cloneDevicesConfig(response?.configs);
    }
    isLoading.value = false;
  }

  function resetAppsConfig() {
    appsConfig.value = cloneAppsConfig(appsConfigBackup);
  }

  function resetDevicesConfig() {
    devicesConfig.value = cloneDevicesConfig(deviceConfigBackup);
  }

  function onAppsConfigSaved() {
    appsConfigBackup = cloneAppsConfig(appsConfig.value);
  }

  function onDeviceConfigSaved() {
    deviceConfigBackup = cloneAppsConfig(devicesConfig.value);
  }

  return {
    isLoading,
    error,
    appsConfig,
    devicesConfig,
    fetchAppsConfig,
    fetchDeviceConfig,
    resetAppsConfig,
    resetDevicesConfig,
    onAppsConfigSaved,
    onDeviceConfigSaved,
  };
}
