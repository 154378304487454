<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { useAcl } from "vue-simple-acl";
import type { TabNavigation } from "~/core/components/TabsWithNavigation.vue";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { useConfigStore } from "~/core/stores/useConfigStore";
import LxcError from "~/core/utils/LxcError";
import { NotificationKey } from "~/core/utils/notifications";
import type { UserData } from "~/modules/user/models/UserData.interface";
import type { UserLanguage } from "~/modules/user/models/UserLanguage.interface";
import userService from "~/modules/user/services/user.service";
import { useUserSession } from "~/modules/user/stores/useUserSession";

const acl = useAcl();
const { featureToggle } = useConfigStore();
const { userSession } = useUserSession();
const { isModeAD } = storeToRefs(useConfigStore());

const error: Ref<LxcError | null> = ref(null);
const user: Ref<UserData | null> = ref(null);
const canViewCertificate = computed(
  () =>
    featureToggle.isVisible("user-certificate") &&
    acl.can(ACLRoles.USR_CERTIFICATES),
);

const userAccountProfile = ref();

function saveLanguage(language: UserLanguage) {
  userAccountProfile.value.saveLanguage(language);
}

const isLoading = ref(false);

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const profileTabIndex = 0;
const languageTabIndex = 1;
const certificateTabIndex = 2;

const tabNavigations = computed(() => {
  const tabNavigations: TabNavigation[] = [
    {
      index: profileTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_PROFILE}`,
      labelTranslationKey: "user.userAccount.userInformations.title",
    },
    {
      index: languageTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_LANGUAGE}`,
      labelTranslationKey: "user.userAccount.language.title",
    },
  ];
  if (canViewCertificate.value) {
    tabNavigations.push({
      index: certificateTabIndex,
      redirectPath: `${PATHS.USER_ACCOUNT_CERTIFICATE}`,
      labelTranslationKey: "user.userAccount.certificate.title",
    });
  }
  return tabNavigations;
});

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(async () => {
  isLoading.value = true;
  error.value = null;

  const userId = userSession?.userId;
  if (userId) {
    const response = await userService.getUserById(userId);
    if (LxcError.check(response)) {
      response.notify(NotificationKey.error);
      error.value = response;
    } else {
      user.value = response;
    }
  }

  isLoading.value = false;
});
</script>

<template>
  <page-component :is-loading="isLoading" :error="error">
    <tabs-with-navigation :tab-navigations="tabNavigations">
      <template #[profileTabIndex]>
        <user-account-information
          ref="userAccountProfile"
          v-model:user="user"
          :is-mode-a-d="isModeAD"
        />
      </template>
      <template #[languageTabIndex]>
        <user-account-language
          v-model:user="user"
          :is-mode-a-d="isModeAD"
          @save-language="saveLanguage"
        />
      </template>
      <template #[certificateTabIndex]>
        <user-account-certificate v-model:user="user" />
      </template>
    </tabs-with-navigation>
  </page-component>
</template>
