<script setup lang="ts">
import { ErrorCode } from "~/core/models/ErrorCode.enum";
import { ErrorMessage } from "~/core/models/ErrorMessage.enum";
import LxcError from "~/core/utils/LxcError";
import { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";

const props = defineProps<{
  status: DashboardCardStatus;
  title?: string;
  subtitle?: string;
  displayHeaderMargin: boolean;
  shouldExpandBody?: boolean;
}>();

const error = computed<LxcError | undefined>(() =>
  props.status === DashboardCardStatus.FAILED
    ? new LxcError({
        code: ErrorCode.UNEXPECTED,
        message: ErrorMessage.UNKNOWN,
        timestamp: new Date().getTime(),
      })
    : undefined,
);
</script>

<template>
  <lxc-card class="card-body" shadow>
    <h4 v-if="props.title" class="card-title">
      {{ title }}
    </h4>

    <container-component
      :error="error"
      :is-loading="status === DashboardCardStatus.LOADING"
      :loader-size="16"
      :px="0"
      :py="0"
    >
      <p v-if="props.subtitle" class="card-sub-title">
        {{ props.subtitle }}
      </p>

      <div v-if="props.displayHeaderMargin" class="card-header-separator" />

      <slot />
    </container-component>
  </lxc-card>
</template>

<style lang="scss" scoped>
.card-title {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: $primary-color;
}

.card-sub-title {
  font-size: 14px;
  margin: 25px 0 0;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.card-header-separator {
  margin: 0 0 35px;
}

.card-body {
  min-height: $dashboard-min-card-height;
  height: 100%;
}
</style>
