import LxcDeviceDetail from "../components/devices/deviceDetail/LxcDeviceDetail.vue";
import LxcDevices from "../components/devices/deviceList/LxcDevices.vue";
import LxcDtwinsForm from "../components/dtwins/dtwinsForm/LxcDtwinsForm.vue";
import LxcDtwins from "../components/dtwins/dtwinsList/LxcDtwins.vue";
import LxcFirmwares from "../components/parameters/firmwares/LxcFirmwares.vue";
import LxcSectorDetail from "../components/sectors/sectorDetail/LxcSectorDetail.vue";
import LxcSectors from "../components/sectors/sectorList/LxcSectors.vue";
import ErrorUnexpected from "../core/views/ErrorUnexpected.vue";
import ApplicationDetailPage from "../modules/application/views/ApplicationDetailPage.vue";
import ApplicationsPage from "../modules/application/views/ApplicationsPage.vue";
import ApplicationMgtPage from "../modules/applicationManagement/views/ApplicationMgtPage.vue";
import CampaignDetailPage from "../modules/campaign/views/CampaignDetailPage.vue";
import CampaignReportPage from "../modules/campaign/views/CampaignReportPage.vue";
import CampaignsPage from "../modules/campaign/views/CampaignsPage.vue";
import CertificatesPage from "../modules/certificate/views/CertificatesPage.vue";
import CertificateConfigurationPage from "../modules/certificateConfiguration/views/CertificateConfigurationPage.vue";
import DashboardPage from "../modules/dashboard/views/DashboardPage.vue";
import ReportingPage from "../modules/reporting/views/ReportingPage.vue";
import UserFormPage from "../modules/user/views/UserFormPage.vue";
import UserGroupFormPage from "../modules/user/views/UserGroupFormPage.vue";
import { setupLayouts } from "virtual:generated-layouts";
import type {
  RouteLocation,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import type { AbilitiesEvaluation } from "vue-simple-acl/types/acl";
import { isFeatureEnabled } from "~/composables/useFeature";
import { BREADCRUMBS } from "~/core/constants/breadcrumbs";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { AppFeatures } from "~/core/models/AppFeatures.enum";
import ErrorNotFoundVue from "~/core/views/ErrorNotFound.vue";
import ErrorUnauthorized from "~/core/views/ErrorUnauthorized.vue";
import AboutPage from "~/modules/about/views/AboutPage.vue";
import { useAppInterface } from "~/modules/applicationManagement/composables/useAppInterface";
import CampaignOperationMgrDetailPage from "~/modules/campaign/views/CampaignOperationMgrDetailPage.vue";
import MapDevicesPage from "~/modules/cartography/views/MapDevicesPage.vue";
import ConnectMobileAppPage from "~/modules/connectMobileApp/views/ConnectMobileAppPage.vue";
import LxcFleetDetails from "~/modules/fleet/components/FleetDetails.vue";
import FleetsPage from "~/modules/fleet/views/FleetsPage.vue";
import LicensePage from "~/modules/license/views/LicensePage.vue";
import LogsPage from "~/modules/log/views/LogsPage.vue";
import PkiConnectorPage from "~/modules/pkiConnector/views/PkiConnectorPage.vue";
import { useSectorStore } from "~/modules/sector/stores/useSectorStore";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import UserAccountPage from "~/modules/user/views/UserAccountPage.vue";
import UserManagementPage from "~/modules/user/views/UserManagementPage.vue";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";

const { canViewThirdPartyApps } = useAppInterface();

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: () => {
      return PATHS.DASHBOARD;
    },
  },
  {
    path: PATHS.DASHBOARD,
    component: DashboardPage,
    meta: {
      auth: true,
      breadcrumb: BREADCRUMBS.DASHBOARD,
    },
  },
  {
    path: PATHS.ERROR,
    component: ErrorUnexpected,
    meta: {
      layout: "preLogin",
    },
  },
  {
    path: PATHS.APPLICATIONS,
    component: ApplicationsPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BREADCRUMBS.APPLICATION,
    },
  },
  {
    path: `${PATHS.APPLICATIONS}/:appId`,
    component: ApplicationDetailPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BREADCRUMBS.APPLICATION,
    },
  },
  {
    path: PATHS.DEVICES_DVTM_ESOFT,
    component: LxcDevices,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DEVICE,
    },
  },
  {
    path: `${PATHS.DEVICES_DVTM_ESOFT}/:id`,
    component: LxcDeviceDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DEVICE,
    },
  },
  {
    path: PATHS.DTWINS,
    component: LxcDtwins,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS}/:id`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
    // The dtwin page does not exist by itself, it is a wrapper for the dtwin tabs. The navigation to this page (via the
    // left panel or the URL) must redirect to the description tab.
    redirect: (to) => {
      return `${PATHS.DTWINS}/${to.params.id}/${PATHS.DTWINS_DESCRIPTION_SUBPATH}`;
    },
    children: [],
  },
  {
    path: `${PATHS.DTWINS_DESCRIPTION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_FIRMWARE}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_CONFIGURATION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_DATA_VISUALISATION}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: `${PATHS.DTWINS_LOGS}`,
    component: LxcDtwinsForm,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.DTWIN,
    },
  },
  {
    path: PATHS.CAMPAIGNS,
    component: CampaignsPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
    redirect: () => {
      if (isFeatureEnabled(AppFeatures.CAMPAIGN_DTWINS)) {
        return PATHS.CAMPAIGNS_DEVICES;
      } else {
        return PATHS.CAMPAIGNS_S4_LOCAL_STATION;
      }
    },
    children: [],
  },
  {
    path: PATHS.CAMPAIGNS_DEVICES,
    component: CampaignsPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
  },
  {
    path: PATHS.CAMPAIGNS_S4_LOCAL_STATION,
    component: CampaignsPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.CAMPAIGN_DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:id/${PATHS.DETAILS_SUBPATH}`,
    component: CampaignDetailPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS_REPORTS}/:id`,
    component: CampaignReportPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
  },
  {
    path: `${PATHS.CAMPAIGNS}/:uid`,
    component: CampaignOperationMgrDetailPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_CAMPAIGNS),
      auth: true,
      breadcrumb: BREADCRUMBS.CAMPAIGN,
    },
  },
  {
    path: PATHS.LOGS,
    component: LogsPage,
    meta: {
      anyCan: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        anyCan: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.LOGS) &&
        canWithRedirection(anyCan, [
          ACLRoles.CYBER_LOGS_VIEW,
          ACLRoles.DEVICE_LOGS_VIEW,
          ACLRoles.DEVICE_FLEET_LOGS_VIEW,
          ACLRoles.SYSTEM_LOGS_VIEW,
        ]),
      auth: true,
      breadcrumb: BREADCRUMBS.LOGS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_GROUPS,
    component: UserManagementPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.GROUP,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_GROUPS}/:code`,
    component: UserGroupFormPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.GROUP,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATE_ALERT_REPORTING,
    component: ReportingPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.CONFIGURE_NOTIFICATION),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_DEVICE_CONFIGURATION,
    component: CertificateConfigurationPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.MANAGE_CERTIFICATES),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_PKI_CONNECTOR,
    component: PkiConnectorPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.CONFIGURE_PKI),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES,
    component: CertificatesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
    // The certificate page does not exist by itself, it is a wrapper for the keystore and the truststore pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the keystore tab.
    redirect: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    children: [],
  },
  {
    path: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    component: CertificatesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_KEYSTORE}/:uuid`,
    component: CertificatesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}`,
    component: CertificatesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE}/:uuid`,
    component: CertificatesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVT_CACRT_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_FIRMWARES,
    component: LxcFirmwares,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.FOTA) &&
        canWithRedirection(can, ACLRoles.DISPLAY_FIRMWARES),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_FIRMWARES}/:uuid`,
    component: LxcFirmwares,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.FOTA) &&
        canWithRedirection(can, ACLRoles.MANAGE_FIRMWARES),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT_PROFILES,
    component: UserManagementPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.PROFILE,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_PROFILES}/:profileCode`,
    component: UserManagementPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.PROFILE,
    },
  },
  {
    path: PATHS.USER_MANAGEMENT,
    component: UserManagementPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_MANAGEMENT,
    },
    // The user management page does not exist by itself, it is a wrapper for the user, group and profile pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the user tab.
    redirect: PATHS.USER_MANAGEMENT_USERS,
    children: [],
  },
  {
    path: PATHS.USER_MANAGEMENT_USERS,
    component: UserManagementPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_MANAGEMENT,
    },
  },
  {
    path: `${PATHS.USER_MANAGEMENT_USERS}/:id`,
    component: UserFormPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_MANAGEMENT,
    },
  },
  {
    path: PATHS.SECTORS,
    component: LxcSectors,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: `${PATHS.SECTORS}/:code`,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.MANAGE_SECTORS),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
    redirect: (to: RouteLocation) => {
      return `${PATHS.SECTORS}/${to.params.code}/${PATHS.DEVICES_DVTM_ESOFT_SUBPATH}`;
    },
    children: [],
  },
  {
    path: PATHS.SECTORS_DEVICES,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_DTWINS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_APPLICATIONS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_APPLICATIONS),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_USERS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: PATHS.SECTORS_GROUPS,
    component: LxcSectorDetail,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DISPLAY_USERS),
      auth: true,
      breadcrumb: BREADCRUMBS.SECTOR,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, [
          ACLRoles.DVTM_APPM_MOBILEAPP_VIEW,
          ACLRoles.DVTM_APPM_THIRDPARTY_VIEW,
        ]),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
    // The application page does not exist by itself, it is a wrapper for the mobile application and the third-party
    // application pages accessible via the usage of tabs. The navigation to this page (via the left panel or the URL)
    // must redirect to the keystore tab. On this page, one tab can be disabled. If the two are disabled, the router
    // will not allow the redirection to the page and the following code will not be executed.
    redirect: canViewThirdPartyApps()
      ? PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP
      : PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
    children: [],
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_CREATE,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACLRoles.DVTM_APPM_MOBILEAPP_ADM),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACLRoles.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP}/:clientId`,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACLRoles.DVTM_APPM_THIRDPARTY_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACLRoles.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: `${PATHS.PARAMETERS_APPLICATIONS_MOBILE_APP}/:clientId`,
    component: ApplicationMgtPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.APPLICATION_MANAGEMENT) &&
        canWithRedirection(can, ACLRoles.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.ABOUT,
    component: AboutPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.ABOUT,
    },
  },
  {
    path: PATHS.PARAMETERS_LICENSE,
    component: LicensePage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.PARAMETERS,
    },
  },
  {
    path: PATHS.USER_ACCOUNT,
    component: UserAccountPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_ACCOUNT,
    },
    // The user management page does not exist by itself, it is a wrapper for the profile, language and certificate pages
    // accessible via the usage of tabs. The navigation to this page (via the left panel or the URL) must redirect
    // to the user tab.
    redirect: PATHS.USER_ACCOUNT_PROFILE,
    children: [],
  },
  {
    path: PATHS.USER_ACCOUNT_PROFILE,
    component: UserAccountPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.USER_ACCOUNT_LANGUAGE,
    component: UserAccountPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.NO_CONTROL),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.USER_ACCOUNT_CERTIFICATE,
    component: UserAccountPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.USR_CERTIFICATES),
      auth: true,
      breadcrumb: BREADCRUMBS.USER_ACCOUNT,
    },
  },
  {
    path: PATHS.NOT_FOUND,
    component: ErrorNotFoundVue,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.UNAUTHORIZED,
    component: ErrorUnauthorized,
    meta: {
      auth: true,
    },
  },
  {
    path: PATHS.CONNECT_MY_MOBILE_APP,
    component: ConnectMobileAppPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) => canWithRedirection(can, ACLRoles.DVTM_APPM_MOBILEAPP_VIEW),
      auth: true,
    },
  },
  {
    path: PATHS.FLEETS,
    component: FleetsPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.FLEETS,
    },
  },
  {
    path: `${PATHS.FLEETS}/:uid`,
    component: LxcFleetDetails,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
      breadcrumb: BREADCRUMBS.FLEETS,
    },
  },
  {
    path: PATHS.MAP,
    component: MapDevicesPage,
    meta: {
      can: (
        _to: RouteLocationNormalized,
        _from: RouteLocationNormalized,
        can: AbilitiesEvaluation,
      ) =>
        isFeatureEnabled(AppFeatures.DTWINS) &&
        isFeatureEnabled(AppFeatures.CARTOGRAPHY) &&
        canWithRedirection(can, ACLRoles.DISPLAY_DEVICES),
      auth: true,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.LXC_FI_DEVICE_BASENAME),
  routes: setupLayouts(routes),
});

async function canWithRedirection(
  can: AbilitiesEvaluation,
  permission: ACLRoles | ACLRoles[],
) {
  const perm = can(permission);
  if (!perm) {
    await router.push({ path: PATHS.UNAUTHORIZED });
  }
  return perm;
}

/**
 * Manage route checks
 * Set current user if needed and clear code/session_state query params
 * Return false if not auth after current user should have been set to avoid showing part of application before auto redirecting to keycloak login
 */
router.beforeEach(
  async (
    to: RouteLocationNormalized,
  ): Promise<RouteLocationNormalized | boolean | void> => {
    const { setCurrentUser, isAuthenticated } = useUserSession();

    if (to.meta.auth && !isAuthenticated) {
      await setCurrentUser(to);

      delete to.query.code;
      delete to.query.session_state;
      // set isAuthenticated again to fix infinite loading time
      const { isAuthenticated } = useUserSession();

      if (isAuthenticated) {
        const sectorsStore = useSectorStore();
        await sectorsStore.init(to.query);

        return to;
      } else {
        return false;
      }
    }

    // case checking display confirm
    const confirmLeavePageStore = useConfirmLeavePageStore();

    // need confirm before leaving the page
    if (confirmLeavePageStore.isActivated()) {
      // let redirection pass if it is done after confirm
      if (confirmLeavePageStore.confirmed()) {
        confirmLeavePageStore.reset();
      }
      // otherwise display the leaving page confirm message
      else {
        // provide the destination route in order to redirect after confirm
        confirmLeavePageStore.show(to.fullPath);
        return false; // stop redirection for confirm
      }
    }
  },
);
