import RestService from "~/core/services/rest.service";
import type { License } from "~/modules/license/models/License.interface";
import type { LicenseStatus } from "~/modules/license/models/LicenseStatus.interface";
import type { PlatformIdCard } from "~/modules/license/models/PlatformIdCard.interface";

class LicenseService extends RestService {
  private readonly BASE_URL = "/license";

  /**
   * Get the license properties
   * @returns
   */
  getLicenseProperties(): Promise<License> {
    return this.$api.get(this.BASE_URL);
  }

  /**
   * Get the license status
   * @returns
   */
  getLicenseStatus(): Promise<LicenseStatus> {
    return this.$api.get(`${this.BASE_URL}/status`);
  }

  /**
   * Upload a license file
   * @param licenseFile
   */
  uploadLicenseFile(licenseFile: File): Promise<any> {
    const formData = new FormData();
    formData.append("licenseFile", licenseFile);
    return this.$api.post(`${this.BASE_URL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: Infinity,
    });
  }

  /**
   * Return the platform ID card
   * @returns
   */
  getPlatformIdCard(): Promise<PlatformIdCard> {
    return this.$api.get(`${this.BASE_URL}/platformIdCard`);
  }
}

export default new LicenseService();
