<script setup lang="ts">
import type {
  DtwinI,
  FleetI,
  FleetUpdatePayloadI,
} from "@lxc/app-device-types";
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import {
  DisplayColumns,
  DtwinsListDisplayContext,
} from "~/components/dtwins/dtwinsList/dtwinsList.type";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import { FleetUpdateActionType } from "~/modules/fleet/models/FleetUpdateActionType.enum";
import FleetService from "~/modules/fleet/services/fleet.service";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";
import ILxcPlus from "~icons/lxc/plus";

const acl = useAcl();
const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const isAllowedToManageFleet = computed(() => acl.can(ACLRoles.DVTM_DVT_ADM));
const fleet: Ref<FleetI | undefined> = ref();
const fleetError: Ref<LxcError | null> = ref(null);
const reloadDtwins: Ref<boolean> = ref(false);
const reloadAttachDtwinsList: Ref<boolean> = ref(false);
const attachDevicesPanelShown: Ref<boolean> = ref(false);
const dtwinsListRef = ref();
const isUpdatingFleet: Ref<boolean> = ref(false);
const showConfirmRemoveDevices: Ref<boolean> = ref(false);
const lxcFleetAttachDevicesRef = ref();
const selectedDtwins: Ref<DtwinI[]> = ref([]);

async function fetchFleet() {
  const response = await FleetService.getFleetByUid(
    route.params.uid.toString(),
  );

  if (LxcError.check(response)) {
    fleetError.value = response;
  } else {
    fleet.value = response;
  }
}

const onAttachDevicesSidePanelShow = () => {
  attachDevicesPanelShown.value = true;
};

const countDevicesToRemove = ref();
const removeDtwins: Ref<DtwinI[]> = ref([]);

const onConfirmRemoveDevices = async () => {
  if (removeDtwins.value) {
    await onUpdateFleet(FleetUpdateActionType.REMOVE, removeDtwins.value);
  }
  showConfirmRemoveDevices.value = false;
};
const onRemoveDevices = (dtwinList: DtwinI[]) => {
  removeDtwins.value = dtwinList;
  countDevicesToRemove.value = dtwinList.length;
  showConfirmRemoveDevices.value = true;
};

async function onUpdateFleet(
  fleetAction: FleetUpdateActionType,
  dtwins: DtwinI[],
) {
  isUpdatingFleet.value = true;
  const selectedDtwinsUids: string[] = dtwins.map((dtwin: DtwinI) => dtwin.uid);
  let fleetUpdatePayload: FleetUpdatePayloadI;
  switch (fleetAction) {
    case FleetUpdateActionType.REMOVE:
      fleetUpdatePayload = {
        deviceOperations: {
          remove: selectedDtwinsUids,
        },
      };
      break;
    case FleetUpdateActionType.ADD:
    default:
      fleetUpdatePayload = {
        deviceOperations: {
          add: selectedDtwinsUids,
        },
      };
      break;
  }
  if (fleet.value) {
    const response = await FleetService.updateFleet(
      fleet.value.uid,
      fleetUpdatePayload,
    );

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      if (fleetAction === FleetUpdateActionType.ADD) {
        showNotificationSuccess(
          t("fleet.attachDevices.updateSuccess", {
            count: selectedDtwinsUids.length,
            fleetName: fleet.value.friendlyName,
          }),
        );
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
      }
      lxcFleetAttachDevicesRef.value.onClose();
      dtwinsListRef.value.onCancelSelection();
      selectedDtwins.value = [];
      reloadDtwins.value = true;
      reloadAttachDtwinsList.value = true;
    }
  }
  isUpdatingFleet.value = false;
}

async function navigateFleets() {
  await router.push(PATHS.FLEETS);
}

onMounted(fetchFleet);
</script>
<template>
  <!-- The loading state of the view is handled by the dtwin list instead of
  this component as two async calls with two different scope are done at the
  same times. To avoid the display of multiple spinners one after all, this
  component will only be responsible of displaying its error if needed. -->
  <container-component :error="fleetError">
    <div class="flex justify-between mb-[1rem]">
      <breadcrumb-component
        :name="fleet?.friendlyName ?? ''"
        back
        :back-callback="navigateFleets"
        should-not-translate
      />
      <lxc-button
        v-if="fleet"
        :icon="ILxcPlus"
        :title="
          isAllowedToManageFleet
            ? t('fleet.addDevices')
            : t('fleet.actionForbidden')
        "
        :disabled="!isAllowedToManageFleet"
        @click="onAttachDevicesSidePanelShow"
      >
        {{ t("fleet.addDevices") }}
      </lxc-button>
    </div>
    <lxc-dtwins-list
      ref="dtwinsListRef"
      v-model:reload="reloadDtwins"
      v-model:selected-dtwins="selectedDtwins"
      clickable
      :fleet-uid="route.params.uid.toString()"
      :context="DtwinsListDisplayContext.FLEET"
      :columns="[
        DisplayColumns.FRIENDLY_NAME,
        DisplayColumns.TYPE,
        DisplayColumns.SERIAL_NUMBER,
        DisplayColumns.SECTOR,
        DisplayColumns.CONNECTIVITY,
        DisplayColumns.METASTATUS,
        DisplayColumns.STATE,
        DisplayColumns.CREATED_AT,
        DisplayColumns.ACTION,
      ]"
      :is-removing-from-fleet="isUpdatingFleet"
      @remove-from-fleet="onRemoveDevices"
    />
    <fleet-details-attach-devices-side-panel
      v-if="fleet"
      ref="lxcFleetAttachDevicesRef"
      v-model:attach-devices-panel-shown="attachDevicesPanelShown"
      v-model:reload-dtwin-list="reloadAttachDtwinsList"
      :fleet="fleet"
      :is-updating-fleet="isUpdatingFleet"
      @add-to-fleet="
        (dtwins: DtwinI[]) => onUpdateFleet(FleetUpdateActionType.ADD, dtwins)
      "
    />
    <lxc-confirm-modal
      :is-dialog-visible="showConfirmRemoveDevices"
      :title="
        t('fleet.removeDevices.confirmTitle', { count: countDevicesToRemove })
      "
      :description="
        t('fleet.removeDevices.confirmMessage', {
          count: countDevicesToRemove,
        })
      "
      :ok-label="t('button.confirm')"
      :cancel-label="t('button.cancel')"
      :disabled-confirm="isUpdatingFleet"
      :icon="ILxcLightTrash2"
      icon-color-theme="error"
      color-theme="danger"
      @confirm="onConfirmRemoveDevices"
      @cancel="showConfirmRemoveDevices = false"
    />
  </container-component>
</template>
