<script setup lang="ts">
import type { DeviceI } from "@lxc/app-device-types";
import { formatIsoDate } from "~/core/utils/date";

const props = defineProps<{
  device: DeviceI | null;
}>();
const { t } = useI18n();

const deviceIdCardFormRef = ref();
const deviceIdCardForm: DeviceI = reactive({
  name: "",
  model: {
    type: "",
    declination: "",
  },
  serialNumber: "",
  hardwareVersion: "",
});

const deviceStateForm = reactive({
  state: undefined,
  connectivity: {
    lastCommunicationDate: undefined,
  },
  certificate: {
    notValidAfter: undefined,
  },
  // provisional fields: to be modify
  lastChange: "",
  configurationApplicationDate: "",
  lastRebootDate: "",
  firstCommissioningDate: "",
  lastFirmwareUpdate: "",
});

const deviceConnectivityFormRef = ref();
const deviceConnectivityForm = reactive({
  // provisional fields: to be modify
  mobileNetwork: "",
  gsmFrequencyBand: "",
  communicationProtocol: "",
});

const dateFormat: string = t("dateFormat.date");
const dateFormatDatetime: string = t("dateFormat.datetime");

onMounted(() => {
  Object.assign(deviceIdCardForm, {
    name: props.device?.name,
    model: {
      type: props.device?.model?.type,
      declination: props.device?.model?.declination ?? "-",
    },
    serialNumber: props.device?.serialNumber,
    hardwareVersion: props.device?.hardwareVersion ?? "-",
  });
  Object.assign(deviceStateForm, {
    state: t(`device.states.${props.device?.state}`),
    connectivity: {
      lastCommunicationDate: props.device?.connectivity?.lastCommunicationDate
        ? formatIsoDate(
            props.device?.connectivity?.lastCommunicationDate,
            dateFormatDatetime,
          )
        : "-",
    },
    certificate: {
      notValidAfter: props.device?.certificate?.notValidAfter
        ? formatIsoDate(props.device?.certificate?.notValidAfter, dateFormat)
        : "-",
    },
    lastChange: props.device?.metadata?.configuration?.modifiedBy ?? "-",
    configurationApplicationDate: props.device?.metadata?.configuration
      ?.updatedAt
      ? formatIsoDate(
          props.device?.metadata?.configuration?.updatedAt,
          dateFormat,
        )
      : "-",
    lastRebootDate: "-",
    firstCommissioningDate: "-",
    lastFirmwareUpdate: "-",
  });
  Object.assign(deviceConnectivityForm, {
    mobileNetwork:
      props.device?.metadata?.ipCommunications
        ?.map((ipCommunication) => ipCommunication.type)
        .join(", ") || "-",
    gsmFrequencyBand: "-",
    communicationProtocol: "-",
  });
});
</script>
<template>
  <!-- idCard -->
  <DescriptionContainer :title="t(`device.description.idCard`)">
    <template #left>
      <lxc-form ref="deviceIdCardFormRef" :model="deviceIdCardForm">
        <lxc-form-item :label="t('device.name')">
          <lxc-input v-model="deviceIdCardForm.name" type="text" read-only />
        </lxc-form-item>
        <lxc-form-item :label="t('device.type')">
          <lxc-input
            v-model="deviceIdCardForm.model.type"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item :label="t('device.model')">
          <lxc-input
            v-model="deviceIdCardForm.model.declination"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item :label="t('device.serialNumber')">
          <lxc-input
            v-model="deviceIdCardForm.serialNumber"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item :label="t('device.hardwareVersion')">
          <lxc-input
            v-model="deviceIdCardForm.hardwareVersion"
            type="text"
            read-only
          />
        </lxc-form-item>
      </lxc-form>
    </template>
  </DescriptionContainer>
  <!-- end idCard -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- State -->
  <DescriptionContainer :title="t(`device.state`)">
    <template #left>
      <lxc-form-item :label="t('device.state')">
        <lxc-input v-model="deviceStateForm.state" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item :label="t('device.description.lastConnection')">
        <lxc-input
          v-model="deviceStateForm.connectivity.lastCommunicationDate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item :label="t('device.description.lastChange')">
        <lxc-input v-model="deviceStateForm.lastChange" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('device.description.configurationApplicationDate')"
      >
        <lxc-input
          v-model="deviceStateForm.configurationApplicationDate"
          type="text"
          read-only
        />
      </lxc-form-item>
    </template>
    <template #right>
      <lxc-form-item :label="t('device.description.lastRebootDate')">
        <lxc-input
          v-model="deviceStateForm.lastRebootDate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item :label="t('device.description.firstCommissioningDate')">
        <lxc-input
          v-model="deviceStateForm.firstCommissioningDate"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item :label="t('device.description.certificateValidityDate')">
        <lxc-input
          v-model="deviceStateForm.certificate.notValidAfter"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item :label="t('device.description.lastFirmwareUpdate')">
        <lxc-input
          v-model="deviceStateForm.lastFirmwareUpdate"
          type="text"
          read-only
        />
      </lxc-form-item>
    </template>
  </DescriptionContainer>
  <!-- end State -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- Connectivity -->
  <DescriptionContainer :title="t(`device.connectivity`)" with-margin-bottom>
    <template #left>
      <lxc-form ref="deviceConnectivityFormRef" :model="deviceConnectivityForm">
        <lxc-form-item :label="t('device.description.mobileNetwork')">
          <lxc-input
            v-model="deviceConnectivityForm.mobileNetwork"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item :label="t('device.description.gsmFrequencyBand')">
          <lxc-input
            v-model="deviceConnectivityForm.gsmFrequencyBand"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item :label="t('device.description.communicationProtocol')">
          <lxc-input
            v-model="deviceConnectivityForm.communicationProtocol"
            type="text"
            read-only
          />
        </lxc-form-item>
      </lxc-form>
    </template>
  </DescriptionContainer>
  <!-- end Connectivity -->
</template>
