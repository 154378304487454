<script lang="ts" setup>
import { CheckDatePrecision, checkPastDate } from "@lxc/app-device-common";
import type {
  ActionToUpdateI,
  DeviceI,
  FirmwareI,
  OperationI,
  OperationToUpdateI,
} from "@lxc/app-device-types";
import { CampaignType, FirmwareStatus } from "@lxc/app-device-types";
import type { InternalRuleItem } from "async-validator/dist-types/interface";
import dayjs from "dayjs";
import type { Ref } from "vue";
import { DisplayableColumns } from "~/components/parameters/firmwares/LxcFirmwaresList.type";
import LxcInformationRow from "~/components/shared/LxcInformationRow.vue";
import { hasActionScheduled } from "~/composables/useDevices";
import { useFirmware } from "~/core/composables/useFirmware";
import { SearchMode } from "~/core/composables/useSearch";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import deviceService from "~/services/device.service";
import { Filters, ObjectType } from "~/types";
import ILxcInfoFilled from "~icons/lxc-custom/info-filled";

const props = defineProps<{
  isDialogVisible: boolean;
  object: DeviceI;
  objectType: ObjectType;
  operation?: OperationI;
}>();
const emit = defineEmits(["update:toggleDialog", "change"]);

const { t } = useI18n();
const { isLoading, error } = useFirmware();

const selectedFirmware: Ref<FirmwareI | undefined> = ref();

const formRef = ref();

interface Form {
  startTime: string;
  uuid: string;
}

const form = reactive<Form>({
  startTime: props.operation?.process?.startedAt || "",
  uuid: "",
});

watch(
  () => selectedFirmware.value,
  (value) => (form.uuid = value?.uuid || ""),
);

function isSelectedFirmwareValid(): boolean {
  if (!selectedFirmware.value && !props.operation) {
    showNotificationError(
      t("campaign.firmware.update.FirmwareSelectionRequired"),
    );
    return false;
  } else {
    return true;
  }
}

async function onConfirmUpdate() {
  const isFormValid = await formRef.value.validate().catch(() => false);
  const isSelectedFirmware = isSelectedFirmwareValid();

  if (isFormValid && isSelectedFirmware) {
    const formattedDate = dayjs(form.startTime).utc(false).toISOString();
    const operationsToUpdate: Ref<OperationToUpdateI[]> = ref([]);
    const actionsToUpdate: Ref<ActionToUpdateI[]> = ref([]);

    switch (props.objectType) {
      case ObjectType.DEVICE: {
        const createOrUpdateOperationResponse: Ref<OperationI | any> = ref();
        if (props.operation && props.operation?.definition.id) {
          props.operation.definition.actions.forEach((action) => {
            actionsToUpdate.value.push({
              id: action.id,
              startTime: `${formattedDate.split(".")[0]}Z`, // Remove milliseconds
            });
          });

          operationsToUpdate.value.push({
            id: props.operation.definition.id,
            actions: actionsToUpdate.value,
          });

          createOrUpdateOperationResponse.value =
            await deviceService.updateOperations(
              props.object as DeviceI,
              operationsToUpdate.value,
            );
        } else {
          createOrUpdateOperationResponse.value =
            await deviceService.createOperationFirmwareUpgrade(
              props.object as DeviceI,
              form.uuid,
              formattedDate,
            );
        }

        if (LxcError.check(createOrUpdateOperationResponse.value)) {
          showNotificationError(t(NotificationKey.error));
        } else {
          showNotificationSuccess(t(NotificationKey.success));
          emit("update:toggleDialog");
          emit("change");
          form.startTime = "";
          form.uuid = "";
          selectedFirmware.value = undefined;
        }
        break;
      }
    }
  }
}

const description = computed(() =>
  props.objectType === ObjectType.DEVICE
    ? t("operation.firmware.update.description", { name: t("device.label") })
    : t("operation.firmware.update.description", {
        name: t("application.label"),
      }),
);

const notaBene = computed(() =>
  props.objectType === ObjectType.DEVICE
    ? t("operation.firmware.update.notaBene", { name: t("device.label") })
    : t("operation.firmware.update.notaBene", { name: t("application.label") }),
);

// Check if the date has been validated
const isDateValid = (
  _: InternalRuleItem,
  value: string,
  callback: (error?: Error) => void,
) => {
  if (checkPastDate(value, CheckDatePrecision.MINUTE)) {
    callback(new Error(t("campaign.dateInferiorToToday")));
  } else {
    callback();
  }
};

const rules = reactive({
  uuid: [
    {
      required: true,
      message: t("operation.firmware.update.uuidRequired"),
      trigger: "change",
    },
  ],
  startTime: [
    {
      required: true,
      message: t("operation.firmware.update.dateTimeError"),
      trigger: "change",
    },
    { validator: isDateValid, trigger: "change" },
    { validator: isDateValid, trigger: "blur" }, // To check date validity also on blur event if the blur happens too late
  ],
});

function disabledDate(time: Date) {
  return time.getTime() < dayjs().subtract(1, "day").valueOf();
}
const isScheduledOrRunningOperations = ref(false);
async function computeScheduledOrRunningOperations() {
  isScheduledOrRunningOperations.value = false;
  const deviceId = props.object.id;
  if (deviceId) {
    const response = await deviceService.getStatsOperation(
      deviceId.toString(),
      CampaignType.FIRMWARE_UPGRADE_DVC,
    );
    isScheduledOrRunningOperations.value =
      hasActionScheduled(response, CampaignType.FIRMWARE_UPGRADE_DVC) || false;
  }
}
</script>

<template>
  <lxc-modal
    :dialog-visible="isDialogVisible"
    :title="t('operation.firmware.update.label')"
    width="50%"
    @confirm="onConfirmUpdate"
    @cancel="$emit('update:toggleDialog', false)"
    @update:dialog-visible="$emit('update:toggleDialog', $event)"
    @open="computeScheduledOrRunningOperations"
  >
    <container-component :is-loading="isLoading" :error="error">
      <lxc-alert type="warning">
        <span class="break-normal">
          {{ t("operation.firmware.update.warningMessage") }}
        </span>
      </lxc-alert>
      <lxc-alert v-if="isScheduledOrRunningOperations" type="warning">
        <span class="break-normal">
          {{ t("device.warningMessage") }}
        </span>
      </lxc-alert>
      <div>
        <p class="update-description">
          {{ description }}
        </p>

        <lxc-information-row :title="notaBene" />

        <el-form
          ref="formRef"
          :model="form"
          :rules="rules"
          label-position="top"
        >
          <el-form-item
            :label="t('operation.firmware.update.plannedStartAt')"
            prop="startTime"
          >
            <!-- checking also the date on blur event if user blur field after a minute -->
            <el-date-picker
              v-model="form.startTime"
              value-format="YYYY-MM-DDTHH:mm:ssZ"
              type="datetime"
              size="large"
              :format="t('device.dateFormat')"
              :disabled-date="disabledDate"
              placeholder="YYYY/MM/DD HH:mm"
              data-cy="datepicker-input"
            />
          </el-form-item>
        </el-form>
        <el-container v-if="!operation" direction="vertical">
          <p class="firmware-label">
            <span class="requiredStar">*</span>&nbsp;{{
              t("campaign.firmware.update.firmwareLabel")
            }}
          </p>
          <p class="firmware-selection-instruction">
            <ILxcInfoFilled class="icon" />
            {{ t("campaign.firmware.update.firmwareSelection") }}
          </p>
          <lxc-firmwares-list
            v-model:selected-firmware="selectedFirmware"
            no-action
            selectable
            :columns="[
              DisplayableColumns.NAME,
              DisplayableColumns.VERSION,
              DisplayableColumns.RANGE,
              DisplayableColumns.DECLINATION,
            ]"
            :default-filters="
              new Map<Filters, any>([
                [Filters.STATUS, FirmwareStatus.ACTIVATED],
                [Filters.RANGE, object.model?.type],
                [Filters.MODEL_DECLINATION, object.model?.declination],
                [Filters.FIRMWARE_VERSIONS, `*${object.firmwareVersion}*`],
                [
                  Filters.HARDWARE_VERSIONS,
                  object.hardwareVersion
                    ? `*${object.hardwareVersion}*`
                    : undefined,
                ],
              ])
            "
            :search-mode="SearchMode.FILTER_SEARCH"
          />
        </el-container>
      </div>
    </container-component>
  </lxc-modal>
</template>

<style lang="scss" scoped>
.update-description {
  font-size: 16px;
}

//Overridden nota-bene-container to set custom margin
.nota-bene-container {
  margin: 10px;
}
.icon {
  margin-left: 5px;
  width: 15px;
}

.firmware-selection-instruction,
.firmware-label {
  margin: 0.5em 0;
}
</style>
