<script lang="ts" setup>
import { ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { useCertificateTags } from "~/composables/useCertificateTags";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import type { TabNavigation } from "~/core/components/TabsWithNavigation.vue";
import { BREADCRUMBS } from "~/core/constants/breadcrumbs";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const { can } = useAcl();
const { canManageCertificates } = useCertificatesACLRoles();
const {
  error: tagsError,
  getTags,
  isLoading: isTagsLoading,
  tags,
} = useCertificateTags();

const canCreateKeystoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !isTagsLoading.value,
);
const triggerSearch = ref<boolean>(false);
const generateSelfSignedCertificateFormShown: Ref<boolean> = ref(false);
const generateCSRFormShown: Ref<boolean> = ref(false);

const canCreateTruststoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !isTagsLoading.value,
);
const importTruststoreCertificate: Ref<boolean> = ref(false);

const refreshLoad = () => {
  triggerSearch.value = !triggerSearch.value;
};
const onGenerateSelfSignedCertificate = () => {
  generateSelfSignedCertificateFormShown.value = true;
};

const onGenerateCSR = () => {
  generateCSRFormShown.value = true;
};

const onImportTrustStoreCertificate = () => {
  importTruststoreCertificate.value = true;
};

const isTabVisible = computed((): boolean => can(ACLRoles.DVT_CACRT_VIEW));

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const keystoreTabIndex = 0;
const truststoreTabIndex = 1;

const tabNavigations: TabNavigation[] = [
  {
    index: keystoreTabIndex,
    redirectPath: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    labelTranslationKey: "certificates.tab.keystore",
    forceRefresh: true,
  },
  {
    index: truststoreTabIndex,
    redirectPath: PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE,
    labelTranslationKey: "certificates.tab.truststore",
    forceRefresh: true,
  },
];

// The current tab index is stored here as it is needed to display buttons above the tab components.
const selectedTabIndex: Ref<number | undefined> = ref();
const isKeystoreTabSelected = computed(
  (): boolean => selectedTabIndex.value === keystoreTabIndex,
);

const isTruststoreTabSelected = computed(
  (): boolean => selectedTabIndex.value === truststoreTabIndex,
);

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(async () => {
  if (isTabVisible.value) {
    await getTags();
  }
});
</script>

<template>
  <page-component :name="BREADCRUMBS.CERTIFICATES.title">
    <template v-if="isTabVisible" #top-right>
      <div v-if="isKeystoreTabSelected" class="flex justify-end gap-4">
        <lxc-button
          :disabled="!canCreateKeystoreCertificate"
          :icon="ILxcPlus"
          :title="t('certificates.keystore.generateSelfSignedCertificate')"
          class="self-center"
          html-type="button"
          type="primary"
          @click="onGenerateSelfSignedCertificate"
        >
          {{ t("certificates.keystore.generateSelfSignedCertificate") }}
        </lxc-button>
        <lxc-button
          :disabled="!canCreateKeystoreCertificate"
          :icon="ILxcPlus"
          :title="t('certificates.keystore.generateCSR')"
          class="self-center"
          html-type="button"
          type="primary"
          @click="onGenerateCSR"
        >
          {{ t("certificates.keystore.generateCSR") }}
        </lxc-button>
      </div>
      <template v-if="isTruststoreTabSelected">
        <lxc-button
          :disabled="!canCreateTruststoreCertificate"
          :icon="ILxcPlus"
          :title="t('certificates.truststore.uploadCertificate')"
          class="self-center"
          html-type="button"
          type="primary"
          @click="onImportTrustStoreCertificate"
        >
          {{ t("certificates.truststore.uploadCertificate") }}
        </lxc-button>
      </template>
    </template>

    <tabs-with-navigation
      v-if="isTabVisible"
      :tab-navigations="tabNavigations"
      @update:selected-tab-index="
        (newSelectedTabIndex) => (selectedTabIndex = newSelectedTabIndex)
      "
    >
      <template #[keystoreTabIndex]>
        <keystore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <keystore-self-signed-certificate-generation-form
          v-model:side-canvas-shown="generateSelfSignedCertificateFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
        <keystore-csr-generation-form
          v-model:side-canvas-shown="generateCSRFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </template>
      <template #[truststoreTabIndex]>
        <truststore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <truststore-upload-certificate
          v-model:side-canvas-shown="importTruststoreCertificate"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </template>
    </tabs-with-navigation>
  </page-component>
</template>
