<script setup lang="ts">
import type { DateShortcutCallback } from "@lxc/app-device-common/src/interfaces/dateshortcut.interface";
import { CONNECTIVITY_OPTIONS } from "~/core/constants/connectivity";
import type {
  FilterFormSection,
  FilterOptions,
  FilterSelectionValue,
  FiltersSelection,
} from "~/core/models/filters";
import { FilterInputType, Filters } from "~/core/models/filters";
import {
  APP_MODEL_DECLINATION_OPTIONS as defaultAppModelOptions,
  APP_MODEL_TYPE_OPTIONS as defaultTypeOptions,
  APP_STATE_OPTIONS as defaultStateOptions,
} from "~/modules/application/constants";

const { t } = useI18n();

/**
 * It is possible to override the default options (for example to disable some options) by setting the corresponding options params to the LxcApplicationList component.
 */
const props = defineProps<{
  filters: FiltersSelection;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
}>();

const emit = defineEmits(["change", "enter"]);

const stateLabel = t(defaultStateOptions.label as string);
const typeLabel = t(defaultTypeOptions.label as string);
const appModelLabel = t(defaultAppModelOptions.label as string);
const connectivityLabel = t(CONNECTIVITY_OPTIONS.label as string);
const certificateLabel = t("filters.certificate");

const filterStateOptions = ref(
  props.stateOptions?.options ?? defaultStateOptions.options,
);
const filterModelTypeOptions = ref(
  props.typeOptions?.options ?? defaultTypeOptions.options,
);
const filterAppModelOptions = ref(
  props.modelOptions?.options ?? defaultAppModelOptions.options,
);
const filterConnectivityOptions = ref(CONNECTIVITY_OPTIONS.options);

/* Filter form sections settings */
const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: Filters.STATE,
    footerEnabled: true,
    footerId: "state-footer",
    header: stateLabel,
    id: "state",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: t("filters.state"),
    options: filterStateOptions,
    tagPrefix: stateLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.MODEL_TYPE,
    footerEnabled: true,
    footerId: "type-footer",
    header: typeLabel,
    id: "type",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: typeLabel,
    options: filterModelTypeOptions,
    tagPrefix: typeLabel,
  },
  {
    disabled: false,
    filter: Filters.MODEL_DECLINATION,
    footerId: "model-footer",
    footerEnabled: true,
    header: appModelLabel,
    id: "model",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: appModelLabel,
    options: filterAppModelOptions,
    tagPrefix: appModelLabel,
  },
  {
    disabled: false,
    filter: Filters.CONNECTIVITY,
    footerId: "connectivity-footer",
    footerEnabled: true,
    header: connectivityLabel,
    id: "connectivity",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: connectivityLabel,
    options: filterConnectivityOptions,
    translate: true,
    tagPrefix: connectivityLabel,
  },
  {
    additionalFilter: Filters.CERTIFICATE_EXPIRE_BEFORE,
    disabled: false,
    header: certificateLabel,
    filter: Filters.CERTIFICATE_EXPIRE_AFTER,
    footerId: "certificate-footer",
    footerEnabled: true,
    id: "certificate",
    inputType: FilterInputType.PERIOD_PICKER,
    menuLabel: certificateLabel,
    tagPrefix: t("filters.certificateValidity.label"),
  },
];

const dateShortcuts: Ref<DateShortcutCallback> = ref(() => [
  {
    label: t("filters.certificateValidity.expired"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(date.setFullYear(date.getFullYear() - 10)), new Date()];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder1"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 1))];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder3"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 3))];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder6"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 6))];
    },
  },
  {
    label: t("filters.certificateValidity.validityAfter6"),
    atClick: (): Date[] => {
      const date = new Date();
      const dateTmp = new Date();
      const dateWithSiwMonth = new Date(
        dateTmp.setMonth(dateTmp.getMonth() + 6),
      );
      return [
        new Date(date.setMonth(date.getMonth() + 6)),
        new Date(
          dateWithSiwMonth.setFullYear(dateWithSiwMonth.getFullYear() + 10),
        ),
      ];
    },
  },
]);

function setFilter(filter: Filters, value: FilterSelectionValue) {
  emit("change", filter, value);
}
</script>

<template>
  <div class="relative mb-4">
    <filters-component
      :date-shortcuts="dateShortcuts"
      :filter-sections="filterFormSections"
      :filters="filters"
      is-button-right
      @change="setFilter"
      @enter="emit('enter', $event)"
    />
  </div>
</template>
