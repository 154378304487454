<script setup lang="ts">
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";

defineProps<{
  campaign?: CampaignOperationMgr;
}>();

const { t } = useI18n();
</script>

<template>
  <lxc-card>
    <h5 class="!mt-0 !mb-4">
      {{ t("campaign.details.characteristics.title") }}
    </h5>
    <div class="flex gap-4">
      <div class="flex-1">
        <p class="font-medium">
          {{ t("campaign.details.characteristics.createdBy") }}
          {{ campaign?.createdBy }}
        </p>
        <div class="pt-4">
          <campaign-timeline :campaign-operation-mgr="campaign" />
        </div>
      </div>
      <div class="flex-1">
        <campaign-inclusion-periods :campaign-operation-mgr="campaign" />
      </div>
    </div>
  </lxc-card>
</template>
