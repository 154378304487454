<script setup lang="ts">
import type { DtwinModelI } from "@lxc/app-device-types";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import { isAWPDevice } from "~/utils/lxc-dtwins-type";

export interface LxcDtwinsDescriptionIdCardI {
  friendlyName: string;
  type: string;
  serialNumber: string;
  hardwareVersion: string;
  doesUpdateSucceed: boolean | undefined;
  manufacturer: string | undefined;
  sectorName: string | undefined;
}

interface Props {
  modelValue: LxcDtwinsDescriptionIdCardI;
  isAllowedToEdit: boolean;
  dtwin: Dtwin | null | undefined;
  models: DtwinModelI[];
}
const props = defineProps<Props>();
const emit = defineEmits(["update:name"]);

const { t } = useI18n();

const nameFieldRef = ref();
const name: Ref<string> = ref(props.modelValue.friendlyName);
const isEditing: Ref<boolean> = ref(false);
const isSending: Ref<boolean> = ref(false);
const isEdited: ComputedRef<boolean> = computed(
  () => nameFieldRef.value?.hasValueChanged ?? false,
);

// When the request to the backend succeed, reset the editing flag to true
watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue.doesUpdateSucceed) {
      isEditing.value = false;
      name.value = modelValue.friendlyName;
    }
    isSending.value = false;
  },
);

function onSave(newValue: string): void {
  isSending.value = true;
  emit("update:name", newValue);
}

// activate the leave page confirm message if edited
watch(
  () => isEdited.value,
  (isEdited) => useConfirmLeavePageStore().activate(isEdited),
);
</script>

<template>
  <description-container :title="t(`dtwins.form.description.idCard.title`)">
    <template #left>
      <editable-field
        ref="nameFieldRef"
        v-model:is-editing="isEditing"
        :field-value="name"
        :label="t('dtwins.form.description.idCard.attributes.name')"
        :is-saving="isSending"
        class="mb-4"
        @validate="onSave"
      />
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.type')"
      >
        <lxc-input :value="modelValue.type" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        v-if="isAWPDevice(models, dtwin) && modelValue.manufacturer"
        :label="t('dtwins.form.description.idCard.attributes.manufacturer')"
      >
        <lxc-input :value="modelValue.manufacturer" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.serialNumber')"
      >
        <lxc-input :value="modelValue.serialNumber" type="text" read-only />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.hardwareVersion')"
      >
        <lxc-input :value="modelValue.hardwareVersion" type="text" read-only />
      </lxc-form-item>
    </template>
    <template #right>
      <lxc-form-item
        :label="t('dtwins.form.description.idCard.attributes.sector')"
      >
        <lxc-input :value="modelValue.sectorName" type="text" read-only />
      </lxc-form-item>
    </template>
  </description-container>
</template>
