<script lang="ts" setup>
import { useAcl } from "vue-simple-acl";
import { useToggle } from "~/core/composables/useToggle";
import { useUpdateState } from "~/core/composables/useUpdateState";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { AppDeviceState } from "~/core/models/AppDeviceState.enum";
import { ErrorCode } from "~/core/models/ErrorCode.enum";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { Application } from "~/modules/application/models/Application.interface";
import ApplicationService from "~/modules/application/services/application.service";
import { useLicenseStatus } from "~/modules/license/composables/useLicenseStatus";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  isActivated: AppDeviceState;
  application: Application;
}>();
const emit = defineEmits(["change"]);

const { getLicenseStatus } = useLicenseStatus();

const { t } = useI18n();
const { can } = useAcl();
const { createDataForStateUpdate, getState } = useUpdateState();

const [confirmDialogVisible, toggleConfirmVisible] = useToggle();
const [confirmDeleteDialogVisible, toggleConfirmDeleteVisible] = useToggle();

const options = computed(() =>
  createDataForStateUpdate(
    props.isActivated,
    "application",
    props.application.name!,
  ),
);

async function updateState(state: AppDeviceState, id: string) {
  const response = await ApplicationService.updateApplicationState(
    id,
    getState(state),
  );

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DEACTIVATION_CONFLICT) {
      showNotificationError(t("application.updateState.deactivate.error"));
    } else {
      response.notify(NotificationKey.saveError);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    await getLicenseStatus();
  }

  toggleConfirmVisible();
  emit("change");
}

async function deleteApplication(id: string) {
  const response = await ApplicationService.deleteApplication(id);

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DELETION_CONFLICT) {
      showNotificationError(t("application.delete.error"));
    } else {
      response.notify(NotificationKey.removeError);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    await getLicenseStatus();
  }

  toggleConfirmDeleteVisible();
  emit("change");
}

enum Action {
  ACTIVATE = "ACTIVATE",
  DELETE = "DELETE",
}

function onClick(event: Event, action: Action) {
  event.preventDefault();
  event.stopPropagation();

  switch (action) {
    case Action.ACTIVATE:
      toggleConfirmVisible(true);
      break;
    case Action.DELETE:
      toggleConfirmDeleteVisible(true);
      break;
  }
}

const roleUpdate: ACLRoles = ACLRoles.UPDATE_APPLICATIONS;
</script>

<template>
  <!-- activate/deactivate button -->
  <div class="flex">
    <lxc-button
      v-if="options && can(roleUpdate)"
      html-type="button"
      type="borderless"
      :icon="options.icon"
      :title="options.activatedButton"
      class="invisible"
      @click.prevent="(event: Event) => onClick(event, Action.ACTIVATE)"
    />
    <!-- delete button -->
    <lxc-button
      v-if="application.deletable"
      html-type="button"
      type="borderless"
      :icon="ILxcLightTrash2"
      :title="t('device.delete.execute')"
      class="invisible"
      @click.prevent="(event: Event) => onClick(event, Action.DELETE)"
    />
  </div>

  <lxc-confirm-modal
    v-if="options && application.id"
    :is-dialog-visible="confirmDialogVisible"
    :title="options.title"
    :description="options.description"
    :info="options.warning"
    @cancel="toggleConfirmVisible"
    @confirm="updateState(isActivated, application.id)"
  />
  <lxc-confirm-modal
    v-if="application.id"
    :is-dialog-visible="confirmDeleteDialogVisible"
    :title="t('application.delete.execute')"
    :description="
      t('application.delete.description', { name: application.name })
    "
    :info="t('application.delete.warning')"
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @cancel="toggleConfirmDeleteVisible"
    @confirm="deleteApplication(application.id)"
  />
</template>
<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
