import LxcError from "~/core/utils/LxcError";
import type { RolesStructure } from "~/modules/user/models/RolesStructure.interface";
import RoleService from "~/modules/user/services/role.service";

export function useRole() {
  const roles: Ref<RolesStructure | null | undefined> = ref(undefined);
  const error: Ref<LxcError | null | undefined> = ref(undefined);
  const isLoading = ref(true);

  async function fetchRoles() {
    isLoading.value = true;
    const response = await RoleService.getAllGrantableRoles();

    if (LxcError.check(response)) {
      roles.value = null;
      error.value = response;
    } else {
      roles.value = response;
      error.value = null;
    }

    isLoading.value = false;
  }

  return {
    roles,
    error,
    fetchRoles,
    isLoading,
  };
}
