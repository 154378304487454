<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import type { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";
import type { DashboardSector } from "~/modules/dashboard/models/DashboardSector.interface";
import { verifyCardStatus } from "~/modules/dashboard/utils/dashboard";
import type { Sector } from "~/modules/sector/models/Sector.interface";
import { useSectorStore } from "~/modules/sector/stores/useSectorStore";

const props = defineProps<{
  status: DashboardCardStatus;
  sector?: DashboardSector;
}>();

const { t } = useI18n();
const { selectedSectors, availableSectors } = storeToRefs(useSectorStore());

const subtitle = computed(() => {
  const sectors =
    selectedSectors.value.length !== 0
      ? selectedSectors.value
      : availableSectors.value;
  return (sectors ?? [])
    .filter((sector: Sector) => props.sector?.sectors.includes(sector.code))
    .map((sector) => sector.label)
    .join(", ");
});

const deviceDisplay: Ref<string> = computed(
  () => `${props.sector?.devices ?? "-"}`,
);
const applicationsDisplay: Ref<string> = computed(
  () => `${props.sector?.applications ?? "-"}`,
);
</script>

<template>
  <dashboard-card
    :title="t('dashboard.sector.title')"
    :subtitle="subtitle"
    :display-header-margin="false"
    :status="verifyCardStatus(props.status, props.sector)"
  >
    <div>
      <dl class="description-container">
        <dt class="content-value">
          {{ deviceDisplay }}
        </dt>
        <dd class="content-label">
          {{ t("dashboard.sector.devices") }}
        </dd>
      </dl>
      <dl class="description-container">
        <dt class="content-value">
          {{ applicationsDisplay }}
        </dt>
        <dd class="content-label">
          {{ t("dashboard.sector.applications") }}
        </dd>
      </dl>
    </div>
  </dashboard-card>
</template>

<style lang="scss" scoped>
.content-container {
  margin-top: 0;
}

.description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
}

.content-value {
  color: $light-blue-color;
  font-size: 20px;
  font-weight: bold;
}

.content-label {
  color: $primary-color;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
</style>
