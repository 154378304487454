import RestService from "../../../core/services/rest.service";
import type { About } from "~/modules/about/models/About.interface";

class AboutService extends RestService {
  private BASE_URL = "/about";

  /**
   * Retrieve about with version
   */
  getAbout(): Promise<About> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new AboutService();
