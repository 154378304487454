import { useFetchPage } from "~/core/composables/useFetchPage";
import type { SearchMode } from "~/core/composables/useSearch";
import { useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import filtersUtils from "~/core/utils/filters.utils";
import truststoreService from "~/modules/certificate/services/truststore.service";
import type { FiltersSelection } from "~/types";
import {
  Filters,
  FiltersType,
  OPERATOR_RSQL_IN,
  OPERATOR_RSQL_LIKE,
} from "~/types";

export function useTruststore(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.TRUSTSTORE_ALIAS,
      {
        key: "alias",
        operator: OPERATOR_RSQL_LIKE,
        value: "",
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
    [
      Filters.TRUSTSTORE_TAGS,
      { key: "tags", operator: OPERATOR_RSQL_IN, value: [] },
    ],
  ]);

  const service =
    truststoreService.getListOfCertificates.bind(truststoreService);
  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  return {
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    onSortChange,
    results,
    search,
    setFilter,
  };
}
