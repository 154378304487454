<script setup lang="ts">
import dayjs from "dayjs";
import type { OperationType } from "~/core/models/OperationType.enum";
import { useApplicationOperations } from "~/modules/application/composables/useApplicationOperations";
import type { Application } from "~/modules/application/models/Application.interface";

const props = defineProps<{
  applicationId: string;
  application: Application;
  operationType?: OperationType;
  hasCallbackUrl?: boolean;
}>();
defineExpose({ updateTable });

const { t } = useI18n();
const { fetchData, isLoading, operations, error } = useApplicationOperations(
  props.applicationId,
  props.operationType,
  props.hasCallbackUrl,
);

const dateFormat = t("operation.dateFormat");

function updateTable() {
  fetchData();
}

onMounted(fetchData);
</script>

<template>
  <lxc-table
    min-width="86rem"
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="operations?.data"
    :empty-text="t('operation.empty')"
    :context="operations?.context"
    data-cy="product-details-operations-table"
    @change-page-and-page-size="fetchData"
  >
    <lxc-table-column
      prop="type"
      :label="t('operation.type')"
      min-width="20rem"
    >
      <template #default="scope">
        {{ t(`operation.operationType.${scope.row.definition.type}`) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="modifiedAt"
      :label="t('operation.createdAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.createdAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="startedAt"
      :label="t('operation.launchedAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.startedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="startedAt"
      :label="t('operation.updatedAt')"
      width="15rem"
    >
      <template #default="scope">
        {{ dayjs(scope.row.process.modifiedAt).format(dateFormat) }}
      </template>
    </lxc-table-column>

    <lxc-table-column
      prop="state"
      :label="t('operation.state')"
      :width="`${hasCallbackUrl ? 9 : 15}rem`"
    >
      <template #default="scope">
        <operation-state
          :state="scope.row.definition.state"
          :with-label="false"
        />
      </template>
    </lxc-table-column>

    <lxc-table-column
      v-if="!hasCallbackUrl"
      prop="actions"
      :label="t('operation.actions')"
      class="!py-0"
      width="6rem"
    >
      <template #default="scope">
        <application-operation-actions
          :key="scope.row.id + scope.row.definition.state"
          :application="application"
          :operation="scope.row"
          @change="fetchData"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
