export enum CampaignState {
  SCHEDULED = "SCHEDULED",
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
  COMPLETE = "COMPLETE",
  COMPLETE_WITH_ERRORS = "COMPLETE_WITH_ERRORS",
  INCOMPLETE = "INCOMPLETE",
  PLATFORM_ERROR = "PLATFORM_ERROR",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
}
