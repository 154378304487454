<script setup lang="ts">
import type { SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { useElTable } from "~/composables/useElTable";
import { useToggle } from "~/core/composables/useToggle";
import { PATHS } from "~/core/constants/paths";
import LxcError from "~/core/utils/LxcError";
import SectorsService from "~/modules/sector/services/sectors.service";

const { t } = useI18n();
const router = useRouter();
const [formDialogVisible, toggleFormDialogVisible] = useToggle();
const [removeDialogVisible, toggleRemoveDialogVisible] = useToggle();
const [removeDeniedDialogVisible, toggleRemoveDeniedDialogVisible] =
  useToggle();
const { getCellStyle } = useElTable();

const error = ref();
const isLoading = ref(false);
const sectors = ref<SectorI[]>([]);
const searchQuery = ref<string>("");
const selectedSector = ref<SectorI | null>(null);
const selectedSectorHasAsyncTask = ref(false);
const isCreate = ref(false);
const grantedSectors: Ref<string[]> = computed(() =>
  getGrantedSectors(sectors.value),
);

function getGrantedSectors(
  sectors: SectorI[],
  initialValue: string[] = [],
): string[] {
  return sectors.reduce((previousValue, currentValue) => {
    const grantedSectors = [
      ...previousValue,
      ...getGrantedSectors(currentValue.children ?? [], []),
    ];
    return currentValue.grantedStatus.granted
      ? [...grantedSectors, currentValue.code]
      : grantedSectors;
  }, initialValue);
}

onMounted(getSectors);

/**
 * Retrieve sectors
 */
async function getSectors() {
  isLoading.value = true;
  const response = await SectorsService.getSectors(searchQuery.value);

  if (LxcError.check(response)) {
    error.value = response;
  } else {
    sectors.value = response === null ? [] : [response as SectorI];
    error.value = null;
  }

  isLoading.value = false;
}

/**
 * On select a specific sector
 * @param sector
 */
function onSelectSector(sector: SectorI) {
  if (canUpdateSector(sector.code)) {
    router.push(`${PATHS.SECTORS}/${sector.code}`);
  }
}

/**
 * Show create sector modal
 */
function onCreateSector(sector?: SectorI) {
  setSelectedSector(sector);
  isCreate.value = true;
  toggleFormDialogVisible();
}

function onUpdateSector(sector?: SectorI) {
  setSelectedSector(sector);
  isCreate.value = false;
  toggleFormDialogVisible();
}

/**
 * Show remove sector modal or denied modal based on sector devices
 */
async function onRemoveSector(sector: SectorI) {
  await setSelectedSector(sector);
  selectedSectorHasAsyncTask.value = true;
  const isAllowed = await SectorsService.isAllowedToRemoveSector(sector.id);
  selectedSectorHasAsyncTask.value = false;
  if (!isAllowed) {
    toggleRemoveDeniedDialogVisible();
  } else {
    toggleRemoveDialogVisible();
  }
}

/**
 * Close create sector modal
 * @param refresh
 */
function onCloseRemoveSector(refresh = false) {
  toggleRemoveDialogVisible(false);
  onClose(refresh);
}

function onCloseDeniedRemoveSector() {
  toggleRemoveDeniedDialogVisible(false);
}

/**
 * Close remove sector modal and reset parentSector
 * Refresh list if needed
 * @param refresh
 */
function onCloseCreateSector(refresh = false) {
  toggleFormDialogVisible(false);
  onClose(refresh);
}

async function setSelectedSector(sector?: SectorI) {
  if (sector) {
    selectedSector.value = await SectorsService.getSectorByCode(sector.code);
  }
}

function onClose(refresh: boolean) {
  selectedSector.value = null;

  if (refresh) {
    getSectors();
  }
}

function canUpdateSector(sectorCode: string): boolean {
  return grantedSectors.value.includes(`${sectorCode}`);
}

function getClass(row: any): string {
  let classes = "sector-row";
  if (!row?.row?.grantedStatus.granted) {
    classes = `${classes} not-granted-sector`;
  }
  return classes;
}
</script>

<template>
  <page-component>
    <search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('sectors.search')"
      @clear="getSectors"
      @search="getSectors"
    />

    <container-component
      :px="0"
      :py="0"
      :is-loading="isLoading && !!sectors"
      :error="error"
    >
      <el-table
        v-loading="isLoading"
        class="tree-table my-8"
        default-expand-all
        :data="sectors"
        :fit="true"
        :empty-text="t('sectors.empty')"
        row-key="code"
        :row-class-name="getClass"
        :cell-style="getCellStyle"
      >
        <el-table-column :label="t('sectors.header.name')">
          <template #default="scope">
            <el-button
              class="p-6"
              :disabled="!canUpdateSector(scope.row?.code)"
              text
              @click="onSelectSector(scope.row)"
            >
              <span
                class="text-gray-700 font-medium text-[1.14286em] truncate w-full"
              >
                {{ scope.row.label }}
              </span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template #default="scope">
            <lxc-loader
              v-if="
                scope.row?.code == selectedSector?.code &&
                selectedSectorHasAsyncTask
              "
              class="pl-3"
            />
            <lxc-sector-actions
              v-else
              :is-affected="canUpdateSector(scope.row?.code)"
              :is-top-level="scope.row.isRootSector"
              :is-last-level="scope.row.isLastLevel"
              @create="onCreateSector(scope.row)"
              @update="onUpdateSector(scope.row)"
              @remove="onRemoveSector(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </container-component>

    <lxc-sector-form
      v-if="selectedSector"
      :is-visible="formDialogVisible"
      :sector="selectedSector"
      :is-create="isCreate"
      @close="onCloseCreateSector"
    />

    <lxc-sector-remove
      :is-visible="removeDialogVisible"
      :sector="selectedSector"
      @close="onCloseRemoveSector(false)"
      @change="onCloseRemoveSector(true)"
    />
    <lxc-sector-remove-denied
      :is-visible="removeDeniedDialogVisible"
      @close="onCloseDeniedRemoveSector()"
    />
  </page-component>
</template>

<style lang="scss" scoped>
:deep(.sector-row) .el-table__cell {
  padding: 0;
}
:deep(.sector-row) .cell {
  display: flex;
  align-items: center;

  .el-table__expand-icon {
    width: $row-expand-icon-size;
    line-height: $row-expand-icon-size;
    height: $row-expand-icon-size;
  }

  .el-button {
    font-family: "Montserrat", sans-serif;
    text-transform: none;
    line-height: $row-expand-icon-size;
    flex: 1 1 100%;
    justify-content: start;
  }
}
</style>
