export const BASE_URL_MAP = {
  ACTIONS: "/actions",
  USERS: "/users",
};
export const SERVICE_URL_MAP = {
  CURRENT_USER: "/me",
};
export const DONNUT_FONT_FAMILY = "Montserrat";
export const DEFAULT_SORT_OPERATION = "-modifiedAt";
export const DONNUT_TITLE_COLOR = "#002f5d";
export const FILTER_DATE_FORMAT = "YYYY-MM-DD";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_FIRST_PAGE = 1;
export const FILTER_SEARCH_TXT_MIN_LENGTH = 3;
export const VALIDATION_REGEXP: Record<string, RegExp> = {
  // Match characters, number, underscores hyphens or spaces, but not only spaces
  CERTIFICATE_ALIAS: /^(?!\s*$)([a-z0-9\-_\s]*$)/g,

  // Email
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,

  // Forbid uppercase
  NO_UPPERCASE: /^[^A-Z]*$/,

  // Must contain lowercase + uppercase letters, number and special characters, minimum size should be 12 characters
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{12,}$/,
};

export const DEFAULT_SORT_CONTENT_RESOURCE = "-modifiedAt";
