import { defineStore } from "pinia";
import type LxcError from "~/core/utils/LxcError";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";

interface AllActionActionTypes {
  allActions: TranslatedLogAttribute[] | LxcError | undefined;
  allActionTypes: TranslatedLogAttribute[] | LxcError | undefined;
  allActionsLanguage: string | undefined;
  allActionTypesLanguage: string | undefined;
}

export const useLogActionActionTypeStore = defineStore(
  "logActionActionTypeStore",
  () => {
    const allActionsActionTypes = reactive<AllActionActionTypes>({
      allActions: undefined,
      allActionTypes: undefined,
      allActionsLanguage: undefined,
      allActionTypesLanguage: undefined,
    });

    return {
      ...toRefs(allActionsActionTypes),
    };
  },
);
