<script setup lang="ts">
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { Fleet } from "~/modules/fleet/models/Fleet.interface";
import { FleetColumns } from "~/modules/fleet/models/FleetColumns.enum";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const acl = useAcl();

const isAllowedToManageFleet = computed(() => acl.can(ACLRoles.DVTM_DVT_ADM));

const selectedFleets: Ref<Fleet[]> = ref([]);

const fleetFormShown: Ref<boolean> = ref(false);
const onUpdateFleetFormShown = (show: boolean) => {
  fleetFormShown.value = show;
};

const reload: Ref<boolean> = ref(false);
const onReloadFleetList = (reloadList: boolean) => {
  reload.value = reloadList;
};
</script>

<template>
  <page-component>
    <template #top-right>
      <lxc-button
        :icon="ILxcPlus"
        :title="
          isAllowedToManageFleet
            ? t('fleet.addFleet')
            : t('fleet.actionForbidden')
        "
        :disabled="!isAllowedToManageFleet"
        @click="() => (fleetFormShown = true)"
      >
        {{ t("fleet.addFleet") }}
      </lxc-button>
    </template>

    <fleet-list
      v-model:reload="reload"
      v-model:selected-fleets="selectedFleets"
      :use-query-parameters-for-pagination="true"
      display-quick-actions-toolbar
    />
    <fleet-form
      :fleet-form-shown="fleetFormShown"
      :columns="[
        FleetColumns.FRIENDLY_NAME,
        FleetColumns.DEVICE_TWIN_COUNT,
        FleetColumns.CREATED_AT,
        FleetColumns.MODIFIED_AT,
        FleetColumns.ACTION,
      ]"
      @update:fleet-form-shown="onUpdateFleetFormShown"
      @reload-fleet-list="onReloadFleetList"
    />
  </page-component>
</template>

<style lang="scss" scoped>
button {
  :deep(svg) {
    height: 20px;
    width: 20px;
  }
}
</style>
