<script setup lang="ts">
import type { ChartData, ChartOptions } from "chart.js";
import { Chart, registerables } from "chart.js";
import { DoughnutChart, useDoughnutChart } from "vue-chart-3";
import { DONNUT_FONT_FAMILY } from "~/core/constants/constants";
import { sum } from "~/core/utils/number";
import { useGetCampaignStat } from "~/modules/campaign/composables/useCampaign";
import type { Campaign } from "~/modules/campaign/models/Campaign.interface";
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";
import { CampaignStatsColorHexaCodes } from "~/modules/campaign/models/CampaignStatsColorHexaCodes.enum";
import { DisplayedStatistic } from "~/modules/campaign/models/DisplayedStatistic.enum";
import {
  computeCampaignStatPercentage,
  computeCampaignStats,
} from "~/modules/campaign/utils/campaignStatisticsComputation";

const { t } = useI18n();

const props = defineProps<{
  campaign?: Campaign | null;
  campaignOperationMgr?: CampaignOperationMgr | null;
}>();

const emits = defineEmits(["totalCountComputation"]);

Chart.register(...registerables);
// Ovveride here the tooltip to display % after the value
Chart.overrides.doughnut.plugins.tooltip = {
  enabled: true,
  callbacks: {
    label(context) {
      if (context.parsed && context.dataset?.data !== null) {
        const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
        const percent = Math.round((context.parsed * 100) / total);

        return ` : ${percent}%`;
      }

      return "";
    },
    title(context) {
      return context[0] !== null
        ? `${context[0].label || ""} (${context[0].parsed})`
        : "";
    },
  },
};

const statCampaign = computed(() => {
  return useGetCampaignStat(props.campaign);
});

const campaignMgrStat = (statisticToDisplay: DisplayedStatistic) => {
  if (
    props.campaignOperationMgr &&
    props.campaignOperationMgr.operationStatistics
  ) {
    return computeCampaignStats(
      props.campaignOperationMgr.operationStatistics,
      statisticToDisplay,
    );
  } else {
    return 0;
  }
};

const countCampaignOperationMgrOperationPending = computed(() => {
  return campaignMgrStat(DisplayedStatistic.PENDING);
});
const countCampaignOperationMgrOperationInProgress = computed(() => {
  return campaignMgrStat(DisplayedStatistic.IN_PROGRESS);
});
const countCampaignOperationMgrOperationSuccess = computed(() => {
  return campaignMgrStat(DisplayedStatistic.SUCCESS);
});
const countCampaignOperationMgrOperationFailed = computed(() => {
  return campaignMgrStat(DisplayedStatistic.FAILED);
});

const campaignOperationMgrOperationsTotalCount = computed(() => {
  const totalCount = sum([
    countCampaignOperationMgrOperationPending.value,
    countCampaignOperationMgrOperationInProgress.value,
    countCampaignOperationMgrOperationSuccess.value,
    countCampaignOperationMgrOperationFailed.value,
  ]);
  emits("totalCountComputation", totalCount);
  return totalCount;
});

const chartDataLabels = computed(() => {
  if (props.campaign) {
    return [
      t("campaign.details.characteristics.equipments.status.pending", {
        percent: statCampaign.value.get("percentPending"),
        count: statCampaign.value.get("countPending"),
      }),
      t("campaign.details.characteristics.equipments.status.running", {
        percent: statCampaign.value.get("percentInProgress"),
        count: statCampaign.value.get("countInProgress"),
      }),
      t("campaign.details.characteristics.equipments.status.complete", {
        percent: statCampaign.value.get("percentSuccess"),
        count: statCampaign.value.get("countSuccess"),
      }),
      t("campaign.details.characteristics.equipments.status.failed", {
        percent: statCampaign.value.get("percentFailed"),
        count: statCampaign.value.get("countFailed"),
      }),
    ];
  } else {
    return [
      t("campaign.details.characteristics.devices.status.scheduled", {
        percent: computeCampaignStatPercentage(
          campaignOperationMgrOperationsTotalCount.value,
          countCampaignOperationMgrOperationPending.value,
        ),
        count: countCampaignOperationMgrOperationPending.value,
      }),
      t("campaign.details.characteristics.devices.status.running", {
        percent: computeCampaignStatPercentage(
          campaignOperationMgrOperationsTotalCount.value,
          countCampaignOperationMgrOperationInProgress.value,
        ),
        count: countCampaignOperationMgrOperationInProgress.value,
      }),
      t("campaign.details.characteristics.devices.status.completed", {
        percent: computeCampaignStatPercentage(
          campaignOperationMgrOperationsTotalCount.value,
          countCampaignOperationMgrOperationSuccess.value,
        ),
        count: countCampaignOperationMgrOperationSuccess.value,
      }),
      t("campaign.details.characteristics.devices.status.error", {
        percent: computeCampaignStatPercentage(
          campaignOperationMgrOperationsTotalCount.value,
          countCampaignOperationMgrOperationFailed.value,
        ),
        count: countCampaignOperationMgrOperationFailed.value,
      }),
    ];
  }
});

const chartData = computed<ChartData<"doughnut">>(() => ({
  labels: chartDataLabels.value,
  datasets: [
    {
      backgroundColor: [
        CampaignStatsColorHexaCodes.SCHEDULED,
        CampaignStatsColorHexaCodes.RUNNING,
        CampaignStatsColorHexaCodes.COMPLETED,
        CampaignStatsColorHexaCodes.ERROR,
      ],
      data: [
        props.campaign
          ? statCampaign.value.get("countPending")
          : countCampaignOperationMgrOperationPending.value,
        props.campaign
          ? statCampaign.value.get("countInProgress")
          : countCampaignOperationMgrOperationInProgress.value,
        props.campaign
          ? statCampaign.value.get("countSuccess")
          : countCampaignOperationMgrOperationSuccess.value,
        props.campaign
          ? statCampaign.value.get("countFailed")
          : countCampaignOperationMgrOperationFailed.value,
      ],
    },
  ],
}));

const options = computed<ChartOptions<"doughnut">>(() => ({
  responsive: true,
  rotation: 180,
  plugins: {
    legend: {
      display: true,
      position: "right",
      align: "center",
      fullSize: false,
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        font: {
          family: DONNUT_FONT_FAMILY,
          size: 14,
          weight: 500,
        },
        padding: 15,
        pointStyle: "circle",
        textAlign: "left",
        usePointStyle: true,
      },
    },
  },
}));

const { doughnutChartProps } = useDoughnutChart({
  chartData,
  options,
});
</script>

<template>
  <doughnut-chart v-bind="doughnutChartProps" />
</template>
