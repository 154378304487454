<script setup lang="ts">
import { getConnectivityStatusParameters } from "~/components/dtwins/dtwinsList/connectivity/LxcDtwinsListConnectivityStatus.helper";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwin, useDtwins } from "~/composables/useDtwins";
import { useOperationManager } from "~/core/composables/useOperationManager";
import { SearchMode } from "~/core/composables/useSearch";
import { useSector } from "~/core/composables/useSector";
import { Filters } from "~/core/models/filters";
import { formatIsoDate } from "~/core/utils/date";
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";

const props = defineProps<{
  campaign?: CampaignOperationMgr;
}>();

const { t } = useI18n();

// Activate or deactivate the use of page and pageSize parameters in URL
// Limitation when activated: the fetch of dtwins is done twice
const useQueryParametersForPagination = true;

const { error, fetchData, results, setFilter, onSearch } = useOperationManager(
  SearchMode.FILTER_SEARCH,
  useQueryParametersForPagination,
);

const {
  error: errorDtwins,
  setFilter: setDtwinFilter,
  fetchData: fetchDtwins,
  results: dtwins,
} = useDtwins(SearchMode.FILTER_SEARCH, useQueryParametersForPagination);
const { getDtwinTypeLabel } = useDtwin();

const { getSectorLabel } = useSector();

const {
  error: errorDtwinModels,
  results: dtwinModels,
  fetchAllModels,
} = useDtwinModels();

const isLoading: Ref<boolean> = ref(false);

async function loadData(page?: number, pageSize?: number) {
  if (props.campaign) {
    isLoading.value = true;

    setFilter(Filters.OPERATION_CAMPAIGN_UID, props.campaign.uid);

    await fetchData(page, pageSize);
    const dtwinsUid = results.value?.results.map(
      (operation) => operation.deviceTwinUid,
    );
    if (dtwinsUid) {
      setDtwinFilter(Filters.DTWIN_UID, dtwinsUid);
    }
    await Promise.all([fetchAllModels(), fetchDtwins(page, pageSize)]);
    isLoading.value = false;
  }
}

const getDtwin = (uid: string) => {
  return dtwins.value?.data.find((dtwin) => dtwin.uid === uid);
};

const getSectorName = (dtwinUid: string) => {
  const sectorUid = dtwins.value?.data.find((dtwin) => dtwin.uid === dtwinUid)
    ?.attributes.sectorId;
  return getSectorLabel(sectorUid);
};

onSearch(loadData);
</script>
<template>
  <h4 class="mt-0 mb-4 leading-7 py-2">
    {{ t("campaign.details.equipments.title") }}
  </h4>
  <lxc-table
    min-width="82rem"
    :is-loading="isLoading"
    :error="
      error?.toError() || errorDtwinModels?.toError() || errorDtwins?.toError()
    "
    :data="results?.results"
    :context="results?.pagination"
    :empty-text="t('campaign.details.equipments.empty')"
    @change-page-and-page-size="loadData"
  >
    <lxc-table-column
      :label="t('campaign.details.equipments.column.name.label')"
      min-width="10rem"
    >
      <template #default="scope">
        {{ getDtwin(scope.row.deviceTwinUid)?.attributes.friendlyName }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.deviceType.label')"
      width="8rem"
    >
      <template v-if="dtwinModels" #default="scope">
        {{
          getDtwinTypeLabel(
            dtwinModels.results,
            getDtwin(scope.row.deviceTwinUid),
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.serialNumber.label')"
      width="12rem"
    >
      <template #default="scope">
        {{ getDtwin(scope.row.deviceTwinUid)?.attributes.serialNumber }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.firmware.label')"
      width="8rem"
    >
      <template #default="scope">
        {{
          getDtwin(scope.row.deviceTwinUid)?.features?.firmware?.reported
            ?.firmwareVersion
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.connectivity.label')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <LxcDtwinsListConnectivityStatus
          v-if="getDtwin(scope.row.deviceTwinUid)?.connectivityStatus"
          :connectivity-status="
            getDtwin(scope.row.deviceTwinUid)?.connectivityStatus
          "
          :connectivity-status-parameters="
            getConnectivityStatusParameters(
              getDtwin(scope.row.deviceTwinUid)?.deviceModelUid,
              dtwinModels?.results,
            )
          "
          :center="true"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.metastatus.label')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <LxcDtwinsListMetadataStatus
          v-if="getDtwin(scope.row.deviceTwinUid)?.operationStatuses"
          :operation-statuses="
            getDtwin(scope.row.deviceTwinUid)?.operationStatuses
          "
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.sector.label')"
      width="10rem"
    >
      <template #default="scope">
        {{ getSectorName(scope.row.deviceTwinUid) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.lastUpdate.label')"
      width="10rem"
    >
      <template #default="scope">
        {{
          formatIsoDate(
            scope.row.updatedAt,
            t("campaign.list.column.dates.format"),
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('campaign.details.equipments.column.operationStatus.label')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <campaign-operation-mgr-operation-status-badge
          :status="scope.row.status"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
