<script lang="ts" setup>
import type { UniqueLabel } from "@lxc/app-device-common";
import type { Ref } from "vue";
import { computed, ref } from "vue";
import { VALIDATION_NUMBER } from "~/constants/constants";
import LxcError from "~/core/utils/LxcError";
import type SubjectAlternativeNames from "~/modules/certificate/components/KeystoreSubjectAlternativeNames.vue";
import type { KeystoreCertificateDetailUpdateForm } from "~/modules/certificate/models/KeystoreCertificateDetailUpdateForm.interface";
import type { KeystoreForm } from "~/modules/certificate/models/KeystoreForm.interface";
import type { Option } from "~/types";
import ILxcAlertCircle from "~icons/lxc/alert-circle";

const props = defineProps<{
  disabled?: boolean;
  edition?: boolean;
  isTagsLoading: boolean;
  modelValue: KeystoreForm | KeystoreCertificateDetailUpdateForm;
  tags?: string[] | null | undefined;
  tagsError?: LxcError | null;
}>();

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const subjectAlternativeNamesFormRef: Ref<
  typeof SubjectAlternativeNames | undefined
> = ref();
const isSubjectDisplayed = ref(true);
const areSubjectAlternativeNamesDisplayed = ref(false);
const areExtendedKeyUsagesDisplayed = ref(false);

const selectedTags = computed({
  get(): UniqueLabel[] {
    return props.modelValue.tags ?? [];
  },
  set(paramTags: UniqueLabel[]) {
    const tmpCertificate = props.modelValue;
    tmpCertificate.tags = paramTags;
    emit("update:modelValue", tmpCertificate);
  },
});

// deep copy of modelValue including subject property
const form = reactive<KeystoreForm | KeystoreForm>(props.modelValue);

let initTagOptionsPending = props.tags === undefined || !props.tags?.length;
const tagOptions: Ref<Option[]> = ref([]);

async function initTagOptions() {
  if (props.isTagsLoading) {
    initTagOptionsPending = true;
  } else if (!LxcError.check(props.tagsError)) {
    initTagOptionsPending = props.tags === undefined;

    tagOptions.value =
      props.tags?.map((tag: string) => {
        return {
          disabled:
            props.disabled ||
            !!selectedTags.value.find((selectedTag) => selectedTag.uid === tag),
          label: tag,
          value: tag,
        };
      }) ?? [];
  }
}

watch(
  () => form,
  (newForm: KeystoreForm | KeystoreForm) => {
    emit("update:modelValue", newForm);
  },
  {
    deep: true,
  },
);

watch(
  () => props.isTagsLoading,
  (isLoading) => {
    if (!isLoading && initTagOptionsPending) {
      initTagOptions();
    }
  },
);

const isTagError: ComputedRef<boolean> = computed(() => {
  return LxcError.check(props.tagsError);
});

onMounted(async () => {
  await initTagOptions();
});

defineExpose({
  validate: async () => {
    if (form.subjectAlternativeNames.length > 0) {
      // Display the SAN form toggle to be able to get the form reference.
      if (!areSubjectAlternativeNamesDisplayed.value) {
        areSubjectAlternativeNamesDisplayed.value = true;
      }
      return new Promise((resolve) => {
        // The setTimeout is required in order to wait the SAN form to be fully displayed,
        // otherwise the `subjectAlternativeNamesFormRef` is undefined
        setTimeout(
          async () =>
            resolve(
              await subjectAlternativeNamesFormRef.value
                ?.validate()
                .catch(() => false),
            ),
          0,
        );
      });
    }
    return Promise.resolve(true);
  },
});
</script>

<template>
  <lxc-alert v-if="isTagError" :icon="ILxcAlertCircle" type="error">
    <template #title>
      {{ t("certificates.tags.label") }}
      {{ t("certificates.tags.label") }}
    </template>
    <p>{{ tagsError?.toError()?.message ?? "" }}</p>
  </lxc-alert>

  <lxc-form-item v-if="modelValue.chain">
    <lxc-label>{{ t("certificates.chain.label") }}</lxc-label>
    <lxc-certificate-chain
      :chain-links="modelValue.chain"
      :leaf-subject-alternative-names="modelValue.subjectAlternativeNames"
    />
  </lxc-form-item>

  <lxc-form-item
    :label="t('certificates.tags.label')"
    prop="tags"
    class="!pb-0"
  >
    <lxc-tag-select
      v-model="selectedTags"
      :options="tagOptions"
      :placeholer="t('certificates.form.tags.placeholder')"
      :delete-tooltip="t('certificates.form.tags.remove.tooltip')"
      :disabled="disabled || isTagError"
    />
  </lxc-form-item>

  <lxc-form-item :label="t('certificates.alias')" prop="alias">
    <lxc-input
      v-model="form.alias"
      type="text"
      :max-length="VALIDATION_NUMBER.ALIAS_MAX_LENGTH"
      :disabled="disabled || edition"
      required
    />
  </lxc-form-item>

  <lxc-form-item :label="t('certificates.form.cn')" prop="subject.cn">
    <lxc-input
      v-model="form.subject.cn"
      type="text"
      :disabled="disabled || edition"
      :required="!(disabled || edition)"
    />
  </lxc-form-item>

  <lxc-form-item :label="t('certificates.form.email')" prop="subject.email">
    <lxc-input
      v-model="form.subject.email"
      type="email"
      :disabled="disabled || edition"
      :max-length="VALIDATION_NUMBER.MAIL_MAX_LENGTH"
      :required="!(disabled || edition)"
    />
  </lxc-form-item>

  <LxcTextToggle
    v-model="isSubjectDisplayed"
    :title="t('certificates.subject.label')"
  >
    <lxc-form-item
      :label="t('certificates.form.serialNumber')"
      prop="subject.serialNumber"
    >
      <lxc-input
        v-model="form.subject.serialNumber"
        type="text"
        :disabled="disabled || edition"
      />
    </lxc-form-item>

    <lxc-form-item
      :label="t('certificates.form.organizationUnit')"
      prop="subject.organizationUnit"
    >
      <lxc-input
        v-model="form.subject.organizationUnit"
        type="text"
        :disabled="disabled || edition"
      />
    </lxc-form-item>

    <lxc-form-item
      :label="t('certificates.form.organization')"
      prop="subject.organization"
    >
      <lxc-input
        v-model="form.subject.organization"
        type="text"
        :disabled="disabled || edition"
      />
    </lxc-form-item>

    <lxc-form-item
      :label="t('certificates.form.locality')"
      prop="subject.locality"
    >
      <lxc-input
        v-model="form.subject.locality"
        type="text"
        :disabled="disabled || edition"
      />
    </lxc-form-item>

    <div class="flex gap-6">
      <div class="grow shrink basis-0">
        <lxc-form-item
          :label="t('certificates.form.region')"
          prop="subject.region"
        >
          <lxc-input
            v-model="form.subject.region"
            type="text"
            :disabled="disabled || edition"
          />
        </lxc-form-item>
      </div>

      <div class="grow shrink basis-0">
        <lxc-form-item
          :label="t('certificates.form.country')"
          prop="subject.country"
        >
          <lxc-input
            v-model="form.subject.country"
            type="text"
            :disabled="disabled || edition"
          />
        </lxc-form-item>
      </div>
    </div>
  </LxcTextToggle>

  <div class="mt-2">
    <LxcTextToggle
      v-model="areSubjectAlternativeNamesDisplayed"
      :title="t('certificates.subjectAlternativeName.label')"
    >
      <keystore-subject-alternative-names
        ref="subjectAlternativeNamesFormRef"
        :model-value="{
          subjectAlternativeNames: form.subjectAlternativeNames,
        }"
        :is-editable="!disabled && !edition"
      />
    </LxcTextToggle>
  </div>

  <div class="mt-2">
    <LxcTextToggle
      v-model="areExtendedKeyUsagesDisplayed"
      :title="t('certificates.extendedKeyUsage.label')"
    >
      <keystore-extended-key-usage
        v-model="form.extendedKeyUsageIds"
        :is-editable="!disabled && !edition"
      />
    </LxcTextToggle>
  </div>
</template>
