<script setup lang="ts">
import { NO_CONTENT } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import { DtwinConfigurationType } from "~/modules/dtwin/models/DtwinConfigurationType.enum";

interface LxcDtwinsConfigurationInformationI {
  configurationType: DtwinConfigurationType | string;
  configurationOrigin: string;
  lastConfigurationUpdate: string;
  selfSupportingVersion?: string;
  globalVersion?: string;
  specificVersion?: string;
}

const props = defineProps<{
  dtwin?: Dtwin | null;
}>();

const { t } = useI18n();

const configurationInformation: ComputedRef<LxcDtwinsConfigurationInformationI> =
  computed(() => {
    // TODO: To be modified when backend will return the information
    return {
      configurationType: props.dtwin?.configurationType ?? NO_CONTENT,
      configurationOrigin:
        props.dtwin?.features?.configuration?.reported?.configurationOrigin ??
        NO_CONTENT,
      lastConfigurationUpdate:
        props.dtwin?.features?.configuration?.reported
          ?.lastConfigurationUpdate ?? NO_CONTENT,
      selfSupportingVersion:
        props.dtwin?.features?.configuration?.reported?.selfSupportingVersion ??
        NO_CONTENT,
      globalVersion:
        props.dtwin?.features?.configuration?.reported?.globalVersion ??
        NO_CONTENT,
      specificVersion:
        props.dtwin?.features?.configuration?.reported?.specificVersion ??
        NO_CONTENT,
    };
  });
</script>

<template>
  <div class="flex">
    <div class="w-1/3">
      <lxc-form-item
        :label="
          t('dtwins.form.configuration.information.configurationType.label')
        "
      >
        <lxc-input
          :value="
            t(
              `dtwins.form.configuration.information.configurationType.${configurationInformation.configurationType}`,
            )
          "
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        v-if="
          configurationInformation.configurationType ===
          DtwinConfigurationType.SELF_SUPPORTING
        "
        :label="
          t('dtwins.form.configuration.information.selfSupportingVersion')
        "
      >
        <lxc-input
          :value="configurationInformation?.selfSupportingVersion"
          type="text"
          read-only
        />
      </lxc-form-item>
      <div v-else>
        <lxc-form-item
          :label="t('dtwins.form.configuration.information.globalVersion')"
        >
          <lxc-input
            :value="configurationInformation?.globalVersion"
            type="text"
            read-only
          />
        </lxc-form-item>
        <lxc-form-item
          :label="t('dtwins.form.configuration.information.specificVersion')"
        >
          <lxc-input
            :value="configurationInformation?.specificVersion"
            type="text"
            read-only
          />
        </lxc-form-item>
      </div>
    </div>
    <div class="w-1/3">
      <lxc-form-item
        :label="t('dtwins.form.configuration.information.configurationOrigin')"
      >
        <lxc-input
          :value="configurationInformation?.configurationOrigin"
          type="text"
          read-only
        />
      </lxc-form-item>
      <lxc-form-item
        :label="t('dtwins.form.configuration.information.lastUpdate')"
      >
        <lxc-input
          :value="configurationInformation?.lastConfigurationUpdate"
          type="text"
          read-only
        />
      </lxc-form-item>
    </div>
  </div>
</template>
