import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { FiltersSelection } from "~/core/models/filters";
import RestService from "~/core/services/rest.service";
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";
import type { CampaignOperationMgrCreate } from "~/modules/campaign/models/CampaignOperationMgrCreate.interface";
import type { CampaignOperationMgrUpdate } from "~/modules/campaign/models/CampaignOperationMgrUpdate.interface";

export class CampaignOperationMgrService extends RestService {
  protected BASE_URL = "/campaignOperationMgr";

  async findWithPagination(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<ApiListResult<CampaignOperationMgr>> {
    const params = { page, pageSize, filter, sort };
    return this.$api.get(this.BASE_URL, { params });
  }

  async find(uid: string): Promise<CampaignOperationMgr> {
    return this.$api.get(`${this.BASE_URL}/${uid}`);
  }

  async create(
    campaign: CampaignOperationMgrCreate,
  ): Promise<CampaignOperationMgr> {
    return this.$api.post(this.BASE_URL, { campaign });
  }

  async update(uid: string, campaign: CampaignOperationMgrUpdate) {
    return this.$api.patch(`${this.BASE_URL}/${uid}`, {
      updatePayload: campaign,
    });
  }
}
export default new CampaignOperationMgrService();
