<script setup lang="ts">
import ILxcImgNotFound from "~icons/lxc-business/not-found";

const { t } = useI18n();
</script>
<template>
  <container-component>
    <div class="m-10 flex flex-col justify-center items-center flex-1">
      <ILxcImgNotFound />
      <h2 class="mt-10">
        {{ t("errors.UNAUTHORIZED_ROUTE.title") }}
      </h2>
      <h3>{{ t("errors.UNAUTHORIZED_ROUTE.message") }}</h3>
    </div>
  </container-component>
</template>
