export class TreeNodes {
  /**
   * Identifier of the current node.
   * Must be unique.
   */
  value: string;
  /**
   * Display label of the current node.
   * This is this value that will be display in the Tree.
   */
  label: string;
  /**
   * Whether the current node is disabled.
   * If disabled, it will be displayed but not selectable.
   * Default: false.
   */
  disabled: boolean;
  /**
   * Children nodes of current node.
   */
  children: TreeNodes[] | null;

  constructor(value: string, label: string, disabled: boolean = false) {
    this.value = value;
    this.label = label;
    this.disabled = disabled;
  }
}
