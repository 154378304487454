<script setup lang="ts">
import type { SelectionModeEnum } from "@lxc/app-device-common";
import type { ElTable } from "element-plus";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import TagForList from "~/core/components/TagForList.vue";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import { PATHS } from "~/core/constants/paths";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import { useConfigStore } from "~/core/stores/useConfigStore";
import LxcError from "~/core/utils/LxcError";
import type { UserData } from "~/modules/user/models/UserData.interface";
import userService from "~/modules/user/services/user.service";
import { useUserSession } from "~/modules/user/stores/useUserSession";

const props = defineProps<{
  actions?: boolean;
  rowClickSelectionMode?: SelectionModeEnum;
  selectedUsers?: UserData[];
  userIdsNotSelectable?: string[];
}>();
const emit = defineEmits(["update:selectedUsers"]);

const { t } = useI18n();
const router = useRouter();
const { userSession } = useUserSession();
const { isModeAD } = storeToRefs(useConfigStore());

const tableRef = ref<InstanceType<typeof ElTable>>();
const users: Ref<ApiListResult<UserData> | null> = ref(null);
const isLoading = ref(false);
const error: Ref<LxcError | null> = ref(null);
const searchQuery = ref<string>("");

const rowSelection: Ref<UserData[]> = ref([]);

/**
 * Select users
 */
const setSelection = () => {
  if (props.selectedUsers !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedUsers?.some((selectedUser) => selectedUser.id === row.id),
      );
    }

    rowSelection.value = props.selectedUsers || [];
  }
};

watch(() => props.selectedUsers, setSelection);

function onChangeSelectInput() {
  emit("update:selectedUsers", rowSelection.value);
}

/**
 * Retrieve users
 * @param page
 * @param pageSize
 */
async function getUsers(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  isLoading.value = true;
  const response = await userService.getUsers(
    page,
    pageSize,
    searchQuery.value,
  );

  if (LxcError.check(response)) {
    error.value = response;
  } else {
    users.value = response;
  }

  isLoading.value = false;

  // Select users (must be asynchronous)
  setTimeout(setSelection, 0);
}

async function performSearch() {
  await getUsers(1, users.value?.context?.pageSize);
}

/**
 * Calcul and emit the selected users
 */
const handleSelect = (handledSelected: UserData[]) => {
  // Keep the selected users which were selected on another page
  emit(
    "update:selectedUsers",
    props.selectedUsers
      ?.filter(
        (selectedUser) =>
          !tableRef.value ||
          !tableRef.value.data.find((row) => row.id === selectedUser.id),
      )
      .concat(handledSelected)
      //Remove duplicated elements of the array
      ?.filter((value, index, array) => array.indexOf(value) === index),
  );
};

/**
 * On row click
 * @param user
 */
function onRowClick(user: UserData) {
  router.push(`${PATHS.USER_MANAGEMENT_USERS}/${user.id}`);
}

/**
 * Return true if the user can be selected, false otherwise
 * @param user
 */
function isRowDisabled(user: UserData) {
  return !(
    !props.userIdsNotSelectable ||
    !props.userIdsNotSelectable?.includes(user.id)
  );
}

async function reloadWithContext() {
  await getUsers(users.value?.context?.page, users.value?.context?.pageSize);
}

/**
 * Checks whether the authenticated user is authorized to perform actions on the user in the list.
 * In the current version, only deletion is supported.
 * @param userId User ID on which to perform the action
 */
function isAllowedToPerformAction(userId: string): boolean {
  return userId !== userSession?.userId;
}

onMounted(getUsers);
</script>

<template>
  <search-bar
    v-model:search-query="searchQuery"
    class="grow shrink basis-0"
    :search-placeholder="t('user.search')"
    @clear="performSearch"
    @search="performSearch"
  />

  <container-component
    :is-loading="isLoading && !users"
    :error="error"
    :px="0"
    :py="4"
  >
    <lxc-table
      ref="tableRef"
      min-width="73rem"
      :is-loading="isLoading"
      :data="users?.data"
      :empty-text="t('user.empty')"
      :context="users?.context"
      clickable
      class="mt-2"
      :row-click-selection-mode="rowClickSelectionMode"
      @row-click="onRowClick"
      @select="handleSelect"
      @select-all="handleSelect"
      @change-page-and-page-size="getUsers"
    >
      <lxc-table-column
        v-if="selectedUsers !== undefined"
        type="selection"
        :disabled="isRowDisabled"
      />
      <lxc-table-column :label="t('table.header.lastname')" min-width="10rem">
        <template #default="scope">
          {{ scope.row.lastName.toUpperCase() }}
        </template>
      </lxc-table-column>
      <lxc-table-column :label="t('table.header.firstname')" min-width="10rem">
        <template #default="scope">
          {{ scope.row.firstName }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        class="!py-0"
        :label="t('table.header.profile')"
        width="15rem"
      >
        <template #default="scope">
          <tag-for-list :tags="scope.row.userProfiles" :max="1" />
        </template>
      </lxc-table-column>
      <lxc-table-column
        prop="email"
        :label="t('table.header.email')"
        width="20rem"
      />
      <lxc-table-column
        class="!py-0"
        :label="t('table.header.groups')"
        width="12rem"
      >
        <template #default="scope">
          <tag-for-list :tags="scope.row.userGroups" :max="1" />
        </template>
      </lxc-table-column>
      <lxc-table-column v-if="actions" class="!py-0" width="6rem">
        <template #default="scope">
          <div class="flex gap-2">
            <user-list-actions
              v-if="
                !isModeAD &&
                isAllowedToPerformAction(scope.row.id) &&
                !scope.row.isProtected
              "
              :user="scope.row"
              @change="reloadWithContext"
            />
          </div>
        </template>
      </lxc-table-column>
    </lxc-table>

    <div class="footer-wrapper">
      <el-select
        v-if="rowSelection?.length !== 0"
        v-model="rowSelection"
        class="footer-selection"
        value-key="id"
        multiple
        collapse-tags
        collapse-tags-tooltip
        reserve-keyword
        :teleported="false"
        @change="onChangeSelectInput"
      >
        <el-option
          v-for="user in rowSelection"
          :key="user.id"
          :label="`${user.firstName} ${user.lastName}`"
          :value="user"
        />
      </el-select>
    </div>
  </container-component>
</template>

<style lang="scss" scoped>
// Hide disabled selection checkbox
:deep(.lxc-table-column--selection) {
  .cell {
    .el-checkbox.is-disabled {
      display: none;
    }
  }
}

:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .footer-pagination {
    flex: 1;
  }
}
</style>
