export enum ErrorCode {
  CERTIFICATE_CONFLICT = "CERTIFICATE_CONFLICT",
  FIRMWARE_CONFLICT = "FIRMWARE_CONFLICT",
  NOT_FOUND = "NOT_FOUND",
  REQUIREMENT_NOT_MET = "REQUIREMENT_NOT_MET",
  BAD_PARAMETER = "BAD_PARAMETER",
  FORBIDDEN = "FORBIDDEN",
  SECTOR_CONFLICT = "SECTOR_CONFLICT",
  UNAUTHORIZED = "UNAUTHORIZED",
  UNEXPECTED = "UNEXPECTED",
  DELETION_CONFLICT = "DELETION_CONFLICT",
  DEACTIVATION_CONFLICT = "DEACTIVATION_CONFLICT",
}
