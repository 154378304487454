import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/core/composables/useFetchPage";
import { SearchMode, useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { FiltersType } from "~/core/models/filters";
import type LxcError from "~/core/utils/LxcError";
import { fetchAllApiListResult } from "~/core/utils/fetch";
import type { MobileInterface } from "~/modules/applicationManagement/models/Mobile.interface";
import mobileInterfaceService from "~/modules/applicationManagement/services/mobileInterface.service";

export function useMobileInterface() {
  const service = mobileInterfaceService.findWithPagination.bind(
    mobileInterfaceService,
  );
  const { can } = useAcl();

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.PIPE_SEPARATOR,
    new Map(),
    SearchMode.FILTER_SEARCH,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination: false,
  });

  function canViewMobileApplication() {
    return can(ACLRoles.DVTM_APPM_MOBILEAPP_VIEW);
  }

  function canGenerateQRCode() {
    return canViewMobileApplication() && can(ACLRoles.DISPLAY_SECTORS);
  }

  async function fetchAllMobileInterfaces(): Promise<
    MobileInterface[] | LxcError
  > {
    return await fetchAllApiListResult((page: number, pageSize: number) =>
      mobileInterfaceService.findWithPagination(page, pageSize),
    );
  }

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
    canViewMobileApplication,
    canGenerateQRCode,
    fetchAllMobileInterfaces,
  };
}
