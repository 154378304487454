<script setup lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { VALIDATION_REGEXP } from "~/core/constants/constants";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { UserData } from "~/modules/user/models/UserData.interface";
import type { UserEmail } from "~/modules/user/models/UserEmail.interface";
import type { UserInformation } from "~/modules/user/models/UserInformation.interface";
import type { UserLanguage } from "~/modules/user/models/UserLanguage.interface";
import type { UserPhone } from "~/modules/user/models/UserPhone.interface";
import userService from "~/modules/user/services/user.service";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import { fallbackLocaleTag, setLocaleTag } from "~/plugins/i18n";
import ILxcMail from "~icons/lxc/mail";
import ILxcPhone from "~icons/lxc/phone";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  user: UserData | null;
  isModeAD: boolean;
}>();

const emits = defineEmits(["update:user"]);

const formInformationRef = ref();
const formEmailRef = ref();
const formPhoneRef = ref();
const { t } = useI18n();
const { userSession } = useUserSession();
const isDisabledInformation = ref(true);
const isDisabledEmail = ref(true);
const isPhoneAdding = ref(false);
const isPhoneEditing = ref(false);
const isAllowedToEdit = computed(() => !props.user?.isProtected);

const newLanguage: Ref<UserLanguage | null> = ref(null);

defineExpose({ saveLanguage });

function setUserForm(user: UserData | null) {
  setUserInformationForm(user);
  setUserEmailForm(user);
  setUserPhoneForm(user);
}

watch(() => props.user, setUserForm);

onMounted(() => setUserForm(props.user));

/**
 * User forms rules
 */
const userInformationFormValidationRules = reactive({
  login: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
    {
      pattern: VALIDATION_REGEXP.NO_UPPERCASE,
      message: t("input.error.lowercaseOnly"),
      trigger: "blur",
    },
  ],
  firstName: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
  ],
  lastName: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
  ],
});

const userEmailFormValidationRules = reactive({
  email: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 255, message: t("input.error.maxLength", { maxLength: 255 }) },
    {
      pattern: VALIDATION_REGEXP.EMAIL,
      message: t("input.error.invalidFormat"),
      trigger: "blur",
    },
  ],
});

/**
 * User information properties
 */
const userInformationForm: UserInformation = reactive({
  login: "",
  organizationName: "",
  firstName: "",
  lastName: "",
  groups: "",
  sectors: "",
  profiles: "",
});

function setUserInformationForm(user: UserData | null) {
  Object.assign(userInformationForm, {
    login: user?.login,
    organisationName: user?.organization.name,
    firstName: user?.firstName,
    lastName: user?.lastName,
    groups: user?.userProfiles?.map((profile) => profile.label).join(", "),
    sectors: userSession?.sectors?.map((sector) => sector.label).join(", "),
    profiles: user?.userProfiles?.map((profile) => profile.label).join(", "),
  });
}

const isSavingUserInformation: Ref<boolean> = ref(false);

async function saveUserInformation() {
  isSavingUserInformation.value = true;

  if (await saveUser()) {
    isDisabledInformation.value = !isDisabledInformation.value;
  }

  isSavingUserInformation.value = false;
}

async function cancelUserInformation() {
  setUserInformationForm(props.user);
  isDisabledInformation.value = !isDisabledInformation.value;
}

/**
 * User email properties
 */
const userEmailForm: UserEmail = reactive({
  email: "",
});

function setUserEmailForm(user: UserData | null) {
  Object.assign(userEmailForm, {
    email: user?.email,
  });
}

const isSavingUserEmail: Ref<boolean> = ref(false);

async function saveUserEmail() {
  isSavingUserEmail.value = true;

  if (await saveUser()) {
    isDisabledEmail.value = !isDisabledEmail.value;
  }

  isSavingUserEmail.value = false;
}

async function cancelUserEmail() {
  setUserEmailForm(props.user);
  isDisabledEmail.value = !isDisabledEmail.value;
}

/**
 * User phone properties
 */
const userPhoneForm: UserPhone = reactive({
  phone: "",
});

function setUserPhoneForm(user: UserData | null) {
  Object.assign(userPhoneForm, {
    phone: user?.phone,
  });
}

const isSavingUserPhone: Ref<boolean> = ref(false);

async function saveUserPhone() {
  isSavingUserPhone.value = true;

  if (await saveUser()) {
    isPhoneEditing.value = !isPhoneEditing.value;
  }

  isSavingUserPhone.value = false;
}

async function cancelUserPhone() {
  setUserPhoneForm(props.user);
  isPhoneEditing.value = !isPhoneEditing.value;
}

/**
 * User language properties
 */
function saveLanguage(language: UserLanguage) {
  newLanguage.value = language;
  saveUser();
}

function getUserLocale(): string {
  const userLocale = newLanguage.value
    ? newLanguage.value.language
    : props.user?.language;
  return userLocale ?? fallbackLocaleTag;
}

/**
 * Save user
 */
async function saveUser(): Promise<boolean> {
  let succeed = false;

  const isFormInformationValid = await formInformationRef.value
    .validate()
    .catch(() => false);
  const isFormEmailValid = await formEmailRef.value
    .validate()
    .catch(() => false);

  if (props.user && isFormInformationValid && isFormEmailValid) {
    const response = await userService.updateUser({
      id: props.user?.id,
      login: userInformationForm.login,
      firstName: userInformationForm.firstName,
      lastName: userInformationForm.lastName,
      email: userEmailForm.email,
      organization: props.user.organization,
      language: getUserLocale(),
      status: props.user.status,
      phone: userPhoneForm.phone,
    });
    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      // build a user with the updated information
      const user = {
        ...props.user,
        ...response,
      };

      // update i18n locale
      setLocaleTag(user.language);

      // update the user session information on front side only
      if (userSession) {
        userSession.login = user.login;
        userSession.firstName = user.firstName;
        userSession.lastName = user.lastName;
        userSession.email = user.email;
        userSession.language = user.language;
      }

      emits("update:user", user);
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      succeed = true;
    }
  }

  return succeed;
}

const classFormWrapper = "flex flex-wrap -mx-4";
const classFormItem = "px-4 sm:w-full lg:w-1/2 xl:w-1/4";
</script>

<template>
  <container-component :px="0" :py="0">
    <h2>{{ t("user.userAccount.userInformations.title") }}</h2>
    <lxc-mandatory class="!mt-0" />
    <p class="text-gray-600">
      {{ t("user.userAccount.instructions.informations") }}
    </p>

    <!-- User information form -->
    <lxc-form
      ref="formInformationRef"
      :rules="userInformationFormValidationRules"
      :model="userInformationForm"
    >
      <div :class="classFormWrapper">
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.organisationName')"
        >
          <lxc-input
            v-model="userInformationForm.organizationName"
            type="text"
            disabled
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.form.userGroups.title')"
        >
          <lxc-input
            v-model="userInformationForm.groups"
            type="text"
            disabled
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.sectors')"
        >
          <lxc-input
            v-model="userInformationForm.sectors"
            type="text"
            disabled
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.profils')"
        >
          <lxc-input
            v-model="userInformationForm.profiles"
            type="text"
            disabled
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.login')"
          prop="login"
        >
          <lxc-input
            v-model="userInformationForm.login"
            type="text"
            :disabled="isDisabledInformation"
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.firstName')"
          prop="firstName"
        >
          <lxc-input
            v-model="userInformationForm.firstName"
            type="text"
            :disabled="isDisabledInformation"
          />
        </lxc-form-item>
        <lxc-form-item
          :class="classFormItem"
          :label="t('user.userAccount.forms.lastName')"
          prop="lastName"
        >
          <lxc-input
            v-model="userInformationForm.lastName"
            type="text"
            :disabled="isDisabledInformation"
          />
        </lxc-form-item>
      </div>
      <lxc-form-item v-if="!isModeAD">
        <lxc-button
          v-if="isDisabledInformation"
          type="secondary"
          :title="t('user.userAccount.forms.edit')"
          :disabled="!isAllowedToEdit"
          @click="isDisabledInformation = !isDisabledInformation"
        >
          {{ t("user.userAccount.forms.edit") }}
        </lxc-button>
        <div v-if="!isDisabledInformation" class="flex gap-2.5">
          <lxc-button
            :title="t('user.userAccount.forms.validate')"
            @click.prevent="saveUserInformation"
          >
            <div class="flex relative">
              {{ t("user.userAccount.forms.validate") }}
              <lxc-loader
                v-if="isSavingUserInformation"
                class="ml-2"
                :size="5"
              />
            </div>
          </lxc-button>
          <lxc-button
            :title="t('user.userAccount.forms.cancel')"
            type="secondary"
            @click="cancelUserInformation"
          >
            {{ t("user.userAccount.forms.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
    </lxc-form>

    <hr class="my-4" />

    <!-- User email form -->
    <lxc-form
      ref="formEmailRef"
      :rules="userEmailFormValidationRules"
      :model="userEmailForm"
    >
      <div :class="classFormWrapper">
        <lxc-form-item :class="classFormItem" prop="email">
          <lxc-label required :disabled="isDisabledEmail">
            {{ t("user.userAccount.forms.email") }}
          </lxc-label>
          <p class="text-gray-600">
            {{ t("user.userAccount.instructions.email") }}
          </p>
          <lxc-input
            v-model="userEmailForm.email"
            type="email"
            :disabled="isDisabledEmail"
          >
            <template #prefix>
              <i-lxc-mail />
            </template>
          </lxc-input>
        </lxc-form-item>
      </div>
      <lxc-form-item v-if="!isModeAD">
        <lxc-button
          v-if="isDisabledEmail"
          type="secondary"
          :title="t('user.userAccount.forms.edit')"
          :disabled="!isAllowedToEdit"
          @click="isDisabledEmail = !isDisabledEmail"
        >
          {{ t("user.userAccount.forms.edit") }}
        </lxc-button>
        <div v-if="!isDisabledEmail" class="flex gap-2.5">
          <lxc-button
            :title="t('user.userAccount.forms.validate')"
            @click.prevent="saveUserEmail"
          >
            <div class="flex relative">
              {{ t("user.userAccount.forms.validate") }}
              <lxc-loader v-if="isSavingUserEmail" class="ml-2" :size="5" />
            </div>
          </lxc-button>
          <lxc-button
            :title="t('user.userAccount.forms.cancel')"
            type="secondary"
            @click="cancelUserEmail"
          >
            {{ t("user.userAccount.forms.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
    </lxc-form>

    <hr class="my-4" />

    <!-- User phone form -->
    <lxc-form ref="formPhoneRef" :model="userPhoneForm">
      <div :class="classFormWrapper">
        <lxc-form-item :class="classFormItem">
          <lxc-label :disabled="!isPhoneEditing">
            {{ t("user.userAccount.forms.phoneNumber") }}
          </lxc-label>
          <div v-if="!isModeAD">
            <p v-if="!user?.phone" class="text-gray-600">
              {{ t("user.userAccount.instructions.phone") }}
            </p>
            <lxc-button
              v-if="!user?.phone && !isPhoneAdding"
              :title="t('button.add')"
              type="borderless"
              :icon="ILxcPlus"
              :disabled="!isAllowedToEdit"
              @click="
                isPhoneAdding = !isPhoneAdding;
                isPhoneEditing = !isPhoneEditing;
              "
            >
              {{ t("button.add") }}
            </lxc-button>
          </div>
          <lxc-input
            v-if="user?.phone || isPhoneAdding"
            v-model="userPhoneForm.phone"
            type="tel"
            :disabled="!isPhoneEditing"
          >
            <template #prefix>
              <i-lxc-phone />
            </template>
          </lxc-input>
        </lxc-form-item>
      </div>
      <lxc-form-item v-if="(user?.phone || isPhoneAdding) && !isModeAD">
        <lxc-button
          v-if="!isPhoneEditing"
          :title="t('user.userAccount.forms.edit')"
          type="secondary"
          @click="isPhoneEditing = !isPhoneEditing"
        >
          {{ t("user.userAccount.forms.edit") }}
        </lxc-button>
        <div v-if="isPhoneEditing" class="flex gap-2.5">
          <lxc-button
            :title="t('user.userAccount.forms.validate')"
            @click.prevent="saveUserPhone"
          >
            <div class="flex relative">
              {{ t("user.userAccount.forms.validate") }}
              <lxc-loader v-if="isSavingUserPhone" class="ml-2" :size="5" />
            </div>
          </lxc-button>
          <lxc-button
            type="secondary"
            :title="t('user.userAccount.forms.cancel')"
            @click="cancelUserPhone"
          >
            {{ t("user.userAccount.forms.cancel") }}
          </lxc-button>
        </div>
      </lxc-form-item>
    </lxc-form>
  </container-component>
</template>
