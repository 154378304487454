<script setup lang="ts">
import type { PeriodI, UniqueLabel } from "@lxc/app-device-common";
import { filterEmptyValues, IntlDateTools } from "@lxc/app-device-common";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import type { Ref } from "vue";
import { watch } from "vue";
import { useAcl } from "vue-simple-acl";
import { typeOptions } from "~/constants/deviceFilters.config";
import { FILTER_DATE_FORMAT } from "~/core/constants/constants";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { Device } from "~/core/models/Device.interface";
import type { ReactiveArrayObject } from "~/core/models/ReactiveArrayObject.interface";
import type {
  FilterFormSection,
  FilterSelectionDefinition,
  FilterSelectionValue,
  FiltersSelection,
  Option,
} from "~/core/models/filters";
import { FilterInputType, Filters } from "~/core/models/filters";
import LxcError from "~/core/utils/LxcError";
import { getTodayPeriod } from "~/core/utils/date";
import filtersUtils from "~/core/utils/filters.utils";
import { NotificationKey } from "~/core/utils/notifications";
import { APP_MODEL_TYPE_OPTIONS } from "~/modules/application/constants";
import type { Application } from "~/modules/application/models/Application.interface";
import applicationService from "~/modules/application/services/application.service";
import { useActionsActionTypes } from "~/modules/log/composables/useActionsActionTypes";
import { ActionsOrActionTypesContext } from "~/modules/log/models/ActionsOrActionTypesContext.enum";
import type { AsyncLog } from "~/modules/log/models/AsyncLog.interface";
import type { LogComponentId } from "~/modules/log/models/LogComponentId.enum";
import { LogEntityClass } from "~/modules/log/models/LogEntityClass.enum";
import { LogEntitySubClass } from "~/modules/log/models/LogEntitySubClass.enum";
import { LogEventType } from "~/modules/log/models/LogEventType.enum";
import { LogLevel } from "~/modules/log/models/LogLevel.enum";
import type { LogSortBy } from "~/modules/log/models/LogSortBy.enum";
import type { LogSortDirection } from "~/modules/log/models/LogSortDirection.enum";
import { Origin } from "~/modules/log/models/Origin.enum";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";
import SectorsService from "~/modules/sector/services/sectors.service";
import type { Role } from "~/modules/user/models/Role.interface";
import type { UserData } from "~/modules/user/models/UserData.interface";
import type { UserGroup } from "~/modules/user/models/UserGroup.interface";
import type { UserProfile } from "~/modules/user/models/UserProfile.interface";
import type { UserSessionSector } from "~/modules/user/models/UserSessionSector.interface";
import userService from "~/modules/user/services/user.service";
import userGroupService from "~/modules/user/services/userGroup.service";
import userProfileService from "~/modules/user/services/userProfile.service";
import DeviceService from "~/services/device.service";

const props = defineProps<{
  filters: FiltersSelection;
  rowsSelected: AsyncLog[];
}>();
const emit = defineEmits(["change", "enter"]);

const { can } = useAcl();
const { locale, t } = useI18n();
const { fetchAllActions, fetchAllActionTypes } = useActionsActionTypes();

const isMounted = ref(false);
const canClearPeriodSelection = ref(false);
const updateInProgress = ref(false);
const applyPending = ref(false);
let onPropsEntityChangedPending = false;

const componentId: Ref<LogComponentId | undefined> = ref(
  props.filters.get(Filters.LOG_COMPONENT_ID)?.value as
    | LogComponentId
    | undefined,
);
const entityClass: Ref<LogEntityClass | undefined> = ref(
  props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as
    | LogEntityClass
    | undefined,
);
const entitySubClass: Ref<LogEntitySubClass | undefined> = ref();
const entityId: Ref<string[] | undefined> = ref();
const actions: Ref<TranslatedLogAttribute[] | undefined> = ref();
const actionTypes: Ref<TranslatedLogAttribute[] | undefined> = ref();
const devicesDvtmEsoft = reactive<ReactiveArrayObject<Device>>({ value: [] });
const applications = reactive<ReactiveArrayObject<Application>>({ value: [] });
const users = reactive<ReactiveArrayObject<UserData>>({ value: [] });
const appliedActions = ref<TranslatedLogAttribute[] | undefined>();
const appliedActionTypes = ref<TranslatedLogAttribute[] | undefined>();
const appliedEntitySubClass = ref<LogEntitySubClass | undefined>();
const cacheActionAttr = ref<TranslatedLogAttribute[] | LxcError>();
const cacheActionTypesAttr = ref<TranslatedLogAttribute[] | LxcError>();
const appliedDevicesDvtmEsoft = reactive<ReactiveArrayObject<Device>>({
  value: [],
});
const appliedApplications = reactive<ReactiveArrayObject<Application>>({
  value: [],
});
const appliedUserGroups = reactive<ReactiveArrayObject<UserGroup>>({
  value: [],
});
const appliedProfiles = reactive<ReactiveArrayObject<UserProfile>>({
  value: [],
});
const appliedRoles = reactive<ReactiveArrayObject<Role>>({ value: [] });
const appliedSectors = reactive<ReactiveArrayObject<UserSessionSector>>({
  value: [],
});
const appliedUsers = reactive<ReactiveArrayObject<UserData>>({ value: [] });
const sortBy: Ref<LogSortBy | undefined> = ref(
  props.filters.get(Filters.SORT_BY)?.value as LogSortBy | undefined,
);
const sortDirection: Ref<LogSortDirection | undefined> = ref(
  props.filters.get(Filters.SORT_DIRECTION)?.value as
    | LogSortDirection
    | undefined,
);
const isDevicesLoading = ref(false);
const isApplicationsLoading = ref(false);
const isUsersLoading = ref(false);
const isUserGroupsLoading = ref(false);
const isProfileLoading = ref(false);
const isRolesLoading = ref(false);
const isSectorLoading = ref(false);
const tagsetId = `tagset-${uuidv4()}`;
const tagsetSelector = `#${tagsetId}`;

const deviceDvtmEsoftLabel = t(
  "logs.filters.entityClass.value.device-dvtm-esoft",
);
const applicationLabel = t("logs.filters.applications.label");
const sectorLabel = t("logs.filters.entityClass.value.sector");
const profileLabel = t("logs.filters.entityClass.value.profile");
const roleLabel = t("logs.filters.entityClass.value.role");
const userGroupLabel = t("logs.filters.entityClass.value.group");
const userLabel = t("logs.filters.entityClass.value.user");
const actionLabel = t("logs.action.label");
const actionTypeLabel = t("logs.actionType.label");
const periodSeparator = ref(` ${t("logs.filters.timestamp.periodSeparator")} `);
const eventTypeLabel = t("logs.eventType.label");
const levelLabel = t("logs.level.label");
const originLabel = t("logs.origin.label");

const editableEntityClasses = [
  LogEntityClass.DEVICE_DVTM_ESOFT,
  LogEntityClass.USER,
];
const editableEntitySubClasses: Record<string, LogEntitySubClass[]> = {
  "device-dvtm-esoft": [
    LogEntitySubClass.APPLICATION,
    LogEntitySubClass.DEVICE_DVTM_ESOFT,
  ],
};

const isEntityLoading = computed((): boolean => {
  return (
    isDevicesLoading.value ||
    isApplicationsLoading.value ||
    isUsersLoading.value ||
    isUserGroupsLoading.value ||
    isProfileLoading.value ||
    isSectorLoading.value ||
    isRolesLoading.value
  );
});

const selectedFiltersByTypes = computed<
  (
    | string
    | LogComponentId
    | LogEntityClass
    | (string | LogLevel | undefined | null)[]
    | undefined
    | null
  )[]
>(() => [
  componentId.value,
  entityId.value,
  actions.value?.map((translated) => translated.rawValue),
  actionTypes.value?.map((translated) => translated.rawValue),
]);

const formPeriod = computed({
  get(): PeriodI {
    const appliedStartStringDate = props.filters.get(Filters.LOG_START_DATE)
      ?.value as string | undefined;
    const appliedEndStringDate = props.filters.get(Filters.LOG_END_DATE)
      ?.value as string | undefined;

    if (appliedStartStringDate && appliedEndStringDate) {
      return {
        startDate: IntlDateTools.parse(
          appliedStartStringDate,
          FILTER_DATE_FORMAT,
          locale.value,
        ),
        endDate: IntlDateTools.parse(
          appliedEndStringDate,
          FILTER_DATE_FORMAT,
          locale.value,
        ),
      };
    } else {
      return getTodayPeriod();
    }
  },
  set(newValue: PeriodI) {
    const todayPeriod = getTodayPeriod();
    const newStartDateFormatted = dayjs(newValue?.startDate).format(
      FILTER_DATE_FORMAT,
    );
    const newEndDateFormatted = dayjs(newValue?.endDate).format(
      FILTER_DATE_FORMAT,
    );
    const todayStartDateFormatted = dayjs(todayPeriod?.startDate).format(
      FILTER_DATE_FORMAT,
    );
    const todayEndDateFormatted = dayjs(todayPeriod?.endDate).format(
      FILTER_DATE_FORMAT,
    );

    canClearPeriodSelection.value =
      newStartDateFormatted !== todayStartDateFormatted ||
      newEndDateFormatted !== todayEndDateFormatted;

    if (newValue.startDate == null || newValue.endDate == null) {
      if (!updateInProgress.value) {
        emit("change", Filters.LOG_START_DATE, todayStartDateFormatted);
        emit("change", Filters.LOG_END_DATE, todayEndDateFormatted);
        emit("enter");
      }
    } else {
      if (!updateInProgress.value) {
        emit("change", Filters.LOG_START_DATE, newStartDateFormatted);
        emit("change", Filters.LOG_END_DATE, newEndDateFormatted);
        emit("enter");
      }
    }
  },
});

function checkDeviceType(device?: Device | Application): boolean {
  return (
    typeOptions.options.find(
      (deviceType: Option) => deviceType.value === device?.model?.type,
    ) != null
  );
}

async function fetchAppliedEntities<T>(
  appliedList: ReactiveArrayObject<T>,
  service: any,
  detailsMethod: string,
  primaryKey: string,
  loading: Ref<boolean>,
  entityIds?: string[],
  list?: ReactiveArrayObject<T>,
): Promise<T[]> {
  let entitiesResult: T[] = [];

  if (entityIds && !loading.value) {
    loading.value = true;
    let entityIdsToFetch: string[] = [];

    if (list && list.value.length > 0) {
      entityIdsToFetch = entityIds.filter(
        (paramId) =>
          list.value.every(
            (currentEntity: T) =>
              (currentEntity as any)[primaryKey] !== paramId,
          ) &&
          appliedList.value.every(
            (currentEntity) => (currentEntity as any)[primaryKey] !== paramId,
          ),
      );

      entitiesResult = list.value
        .filter((currentEntity: T) =>
          entityIds.includes((currentEntity as any)[primaryKey] ?? ""),
        )
        .concat(
          appliedList.value.filter((currentEntity) => {
            return (
              entityIds.includes((currentEntity as any)[primaryKey] ?? "") &&
              !list.value.some(
                (pEntity: T) =>
                  (pEntity as any)[primaryKey] ===
                  (currentEntity as any)[primaryKey],
              )
            );
          }),
        );
    } else {
      entityIdsToFetch = entityIds.filter((paramId) =>
        appliedList.value.every(
          (currentEntity) => (currentEntity as any)[primaryKey] !== paramId,
        ),
      );
    }

    if (entityIdsToFetch.length > 0) {
      // Fetch the devices name if the device entityId is in the URL when the page is loaded.
      const response = await service[detailsMethod](entityIdsToFetch);

      if (LxcError.check(response)) {
        if (response.status === 404 || response.status === 410) {
          // If the resource does not exist or is deleted, then display the filter with the id
          entitiesResult = entitiesResult.concat(
            entityIdsToFetch.map((entityId) => {
              const fakeEntity: any = {};
              fakeEntity[primaryKey] = entityId;
              fakeEntity.label = entityId;
              return fakeEntity as T;
            }),
          );
        } else {
          response.notify(NotificationKey.error);
        }
      } else {
        entitiesResult = entitiesResult.concat(response);
      }
    }

    loading.value = false;
  }

  return entitiesResult;
}

async function fetchAppliedDevicesDvtmEsoft(deviceIds?: string[]) {
  const devicesResult = await fetchAppliedEntities<Device>(
    appliedDevicesDvtmEsoft,
    DeviceService,
    "getDevicesDetails",
    "id",
    isDevicesLoading,
    deviceIds,
    devicesDvtmEsoft,
  );

  /**
   * Seem to be useful for application tag filter display, nor for device from
   * dtwin. If a device do not have model, it means that the device came from
   * dtwin and the object is an encapsulation.
   */
  if (devicesDvtmEsoft.value.every((device) => device.model === undefined)) {
    appliedDevicesDvtmEsoft.value = devicesResult;
  } else {
    appliedDevicesDvtmEsoft.value = devicesResult.filter(
      (currentDevice: Device) => checkDeviceType(currentDevice),
    );
  }
}

function checkApplicationType(application?: Device | Application): boolean {
  return (
    APP_MODEL_TYPE_OPTIONS.options.find(
      (appType: Option) => appType.value === application?.model?.type,
    ) != null
  );
}

async function fetchAppliedApplications(applicationIds?: string[]) {
  const applicationResult = await fetchAppliedEntities<Application>(
    appliedApplications,
    applicationService,
    "getApplicationsDetails",
    "id",
    isApplicationsLoading,
    applicationIds,
    applications,
  );
  appliedApplications.value = applicationResult.filter(
    (currentApplication: Application) =>
      checkApplicationType(currentApplication),
  );
}

function getDeviceIds(devices: Device[]): string[] {
  return devices
    .filter((device) => device.id !== undefined)
    .map((device) => device.id as string);
}

function getApplicationIds(applications: Application[]): string[] {
  return applications
    .filter((app) => app.id !== undefined)
    .map((app) => app.id as string);
}

function getUserIds(users: UserData[]): string[] {
  return users.filter((user) => user.id !== undefined).map((user) => user.id);
}

function initializeActionsAndActionTypesFilters() {
  if (LxcError.check(cacheActionAttr.value)) {
    actions.value = (
      props.filters.get(Filters.LOG_ACTION)?.value as string[]
    ).map((rawValue) => {
      return {
        rawValue,
        translation: rawValue,
      };
    });
  } else {
    actions.value = cacheActionAttr.value?.filter((action) =>
      props.filters.get(Filters.LOG_ACTION)?.value.includes(action.rawValue),
    );
  }
  if (LxcError.check(cacheActionTypesAttr.value)) {
    actionTypes.value = (
      props.filters.get(Filters.LOG_ACTION_TYPE)?.value as string[]
    ).map((rawValue) => {
      return {
        rawValue,
        translation: rawValue,
      };
    });
  } else {
    actionTypes.value = cacheActionTypesAttr.value?.filter((actionType) =>
      props.filters
        .get(Filters.LOG_ACTION_TYPE)
        ?.value.includes(actionType.rawValue),
    );
  }
}

async function initializeActionsAndActionTypesValues() {
  const [allActions, allActionTypes] = await Promise.all([
    fetchAllActions(),
    fetchAllActionTypes(),
  ]);
  cacheActionAttr.value = allActions;
  cacheActionTypesAttr.value = allActionTypes;
  initializeActionsAndActionTypesFilters();
}

const onPropsEntityChanged = async (
  paramEntityClass?: string,
  paramEntityIds?: string[],
) => {
  if (isEntityLoading.value) {
    onPropsEntityChangedPending = true;
    return;
  }

  switch (paramEntityClass) {
    case LogEntityClass.DEVICE:
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both DVTM devices and applications have the same entity class "device-dvtm-esoft"
      await fetchAppliedDevicesDvtmEsoft(paramEntityIds);
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      devicesDvtmEsoft.value = appliedDevicesDvtmEsoft.value?.slice(0) ?? [];

      if (appliedDevicesDvtmEsoft.value.length !== 0) {
        applications.value = [];
        entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT;
        appliedEntitySubClass.value = entitySubClass.value;
      } else {
        await fetchAppliedApplications(paramEntityIds);
        applications.value = appliedApplications.value?.slice(0) ?? [];

        if (appliedApplications.value.length !== 0) {
          entitySubClass.value = LogEntitySubClass.APPLICATION;
          appliedEntitySubClass.value = entitySubClass.value;
          devicesDvtmEsoft.value = [];
        } else {
          entitySubClass.value = undefined;
          devicesDvtmEsoft.value = [];
          applications.value = [];
        }
      }
      break;
    case LogEntityClass.USER:
      appliedUsers.value = await fetchAppliedEntities<UserData>(
        appliedUsers,
        userService,
        "getUsersById",
        "id",
        isUsersLoading,
        paramEntityIds,
        users,
      );
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      users.value = appliedUsers.value?.slice(0) ?? [];
      break;
    case LogEntityClass.GROUP:
      appliedUserGroups.value = await fetchAppliedEntities<UserGroup>(
        appliedUserGroups,
        userGroupService,
        "getUserGroupsByCode",
        "code",
        isUserGroupsLoading,
        paramEntityIds,
      );
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      break;
    case LogEntityClass.PROFILE:
      appliedProfiles.value = await fetchAppliedEntities<UserProfile>(
        appliedProfiles,
        userProfileService,
        "getUserProfilesByCodes",
        "code",
        isProfileLoading,
        paramEntityIds,
      );
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      break;
    case LogEntityClass.ROLE:
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      break;
    case LogEntityClass.SECTOR:
      appliedSectors.value = await fetchAppliedEntities<UserSessionSector>(
        appliedSectors,
        SectorsService,
        "getSectorsByCodes",
        "code",
        isSectorLoading,
        paramEntityIds,
      );
      entityClass.value = paramEntityClass;
      entityId.value = paramEntityIds;
      break;
    default:
      entityClass.value = undefined;
      entityId.value = undefined;
      devicesDvtmEsoft.value = [];
      applications.value = [];
      users.value = [];
      break;
  }
};

const onPropsEntityClassChanged = (entityClass?: string) => {
  onPropsEntityChanged(
    entityClass,
    props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined,
  );
};
const onPropsEntitySubClassChanged = () => {
  onPropsEntityChanged(
    props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as
      | LogEntityClass
      | undefined,
    props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined,
  );
};

const onDevicesDvtmEsoftSelected = (
  devicesDvtmEsoft: ReactiveArrayObject<Device>,
) => {
  if (!devicesDvtmEsoft.value.length) {
    entityClass.value = undefined;
    entitySubClass.value = undefined;
    entityId.value = undefined;
  } else {
    /*
     * For an obscure reason, the device from the dtwin or eSoft do not share
     * the same entity class (but there are still devices...). If a device do
     * not have model, it means that the device came from dtwin and the object
     * is an encapsulation.
     */
    if (devicesDvtmEsoft.value.every((device) => device.model === undefined)) {
      entityClass.value = LogEntityClass.DEVICE;
    } else {
      entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT;
    }
    entitySubClass.value = LogEntitySubClass.DEVICE_DVTM_ESOFT;
    entityId.value = getDeviceIds(devicesDvtmEsoft.value);
  }
};

const onEntityLoadingUpdated = (loading: boolean) => {
  if (!loading && onPropsEntityChangedPending) {
    onPropsEntityChangedPending = false;
    onPropsEntityChanged(
      props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as
        | LogEntityClass
        | undefined,
      props.filters.get(Filters.LOG_ENTITY_ID)?.value as string[] | undefined,
    );
  }
};

const onApplicationsSelected = (
  applications: ReactiveArrayObject<Application>,
) => {
  if (!applications.value.length) {
    entityClass.value = undefined;
    entitySubClass.value = undefined;
    entityId.value = undefined;
  } else {
    entityClass.value = LogEntityClass.DEVICE_DVTM_ESOFT;
    entitySubClass.value = LogEntitySubClass.APPLICATION;
    entityId.value = getApplicationIds(applications.value);
  }
};

const onUsersSelected = (users: ReactiveArrayObject<UserData>) => {
  updateInProgress.value = true;

  if (!users.value.length) {
    entityClass.value = undefined;
    entityId.value = undefined;
  } else {
    entityClass.value = LogEntityClass.USER;
    entitySubClass.value = undefined;
    entityId.value = getUserIds(users.value);
  }

  updateInProgress.value = false;
};

const onChange = (filter: Filters, value: FilterSelectionValue) => {
  emit("change", filter, value);
};

const onEnter = (event: Event) => {
  emit("enter", event);
};

const logLevelOptions: Ref<(Option | Record<string, any>)[]> = ref(
  Object.values(LogLevel).map((value) => {
    return {
      value,
      label: value ? `logs.level.value.${value}` : "",
    };
  }),
);

const eventTypeOptions = computed<Option[]>((): Option[] => {
  return Object.values(LogEventType)
    .filter((value): boolean => {
      let permission: boolean;

      switch (value) {
        case LogEventType.CYBER:
          permission = can(ACLRoles.CYBER_LOGS_VIEW);
          break;
        case LogEventType.DEVICE:
          permission = can(ACLRoles.DEVICE_LOGS_VIEW);
          break;
        case LogEventType.DEVICE_FLEET:
          permission = can(ACLRoles.DEVICE_FLEET_LOGS_VIEW);
          break;
        case LogEventType.SYSTEM:
          permission = can(ACLRoles.SYSTEM_LOGS_VIEW);
          break;
        default:
          permission = false;
      }
      return permission;
    })
    .map((value: LogEventType): Option => {
      const label = value ? t(`logs.eventType.value.${value}`) : "";
      return {
        value,
        label,
      };
    });
});

const originOptions: Ref<Option[]> = ref([
  {
    label: "logs.origin.values.device",
    value: Origin.DEVICE,
  },
  {
    label: "logs.origin.values.mobileApplication",
    value: Origin.MOBILE_APPLICATION,
  },
  {
    label: "logs.origin.values.thirdPartyApplication",
    value: Origin.THIRD_PARTY_APPLICATION,
  },
  {
    label: "logs.origin.values.lxConnect",
    value: Origin.LX_CONNECT,
  },
]);

const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: Filters.LOG_LEVEL,
    footerEnabled: true,
    footerId: "level-footer",
    header: levelLabel,
    id: "level",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: levelLabel,
    options: logLevelOptions,
    tagPrefix: levelLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.LOG_ORIGINS,
    footerEnabled: true,
    footerId: "origin-footer",
    header: originLabel,
    id: "origin",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: originLabel,
    options: originOptions,
    tagPrefix: originLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.LOG_EVENT_TYPE,
    footerId: "eventType-footer",
    footerEnabled: true,
    header: eventTypeLabel,
    inputType: FilterInputType.CHECKBOX,
    id: "eventType",
    menuLabel: eventTypeLabel,
    options: eventTypeOptions,
    tagPrefix: eventTypeLabel,
    translate: false,
  },
  {
    disabled: false,
    filter: Filters.LOG_ACTION,
    footerEnabled: true,
    footerId: "action-footer",
    header: actionLabel,
    id: "action",
    inputType: FilterInputType.CUSTOM,
    menuLabel: actionLabel,
    tagPrefix: actionLabel,
    translate: false,
  },
  {
    disabled: false,
    filter: Filters.LOG_ACTION_TYPE,
    footerEnabled: true,
    footerId: "action-type-footer",
    header: actionTypeLabel,
    id: "action-type",
    inputType: FilterInputType.CUSTOM,
    menuLabel: actionTypeLabel,
    tagPrefix: actionTypeLabel,
    translate: false,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerEnabled: true,
    footerId: "user-footer",
    header: userLabel,
    id: "user",
    inputType: FilterInputType.CUSTOM,
    menuLabel: userLabel,
    tagPrefix: userLabel,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerEnabled: true,
    footerId: "device-dvtm-esoft-device-footer",
    header: deviceDvtmEsoftLabel,
    id: "device-dvtm-esoft-device",
    inputType: FilterInputType.CUSTOM,
    menuLabel: deviceDvtmEsoftLabel,
    tagPrefix: deviceDvtmEsoftLabel,
  },
  {
    additionalFilter: Filters.LOG_ENTITY_CLASS,
    disabled: false,
    filter: Filters.LOG_ENTITY_ID,
    footerId: "device-dvtm-esoft-application-footer",
    footerEnabled: true,
    header: applicationLabel,
    inputType: FilterInputType.CUSTOM,
    id: "device-dvtm-esoft-application",
    menuLabel: applicationLabel,
    tagPrefix: applicationLabel,
  },
];

function getFilterFormSectionById(
  id: string,
  subclassId?: string,
): FilterFormSection | undefined {
  return filterFormSections.find((formSection) => {
    const testId = !subclassId ? id : `${id}-${subclassId}`;
    return formSection.id === testId;
  });
}

function onEntityClassAndSubChanged(
  newEntityClass?: LogEntityClass,
  newEntitySubClass?: LogEntitySubClass,
) {
  for (const currentClass of editableEntityClasses) {
    let filterFormSection: FilterFormSection | undefined;

    if (currentClass !== LogEntityClass.DEVICE_DVTM_ESOFT) {
      filterFormSection = getFilterFormSectionById(currentClass as string);

      if (filterFormSection) {
        filterFormSection.disabled =
          !!newEntityClass && currentClass !== newEntityClass;
      }
    } else {
      for (const currentSubClass of editableEntitySubClasses[
        currentClass as string
      ]) {
        filterFormSection = getFilterFormSectionById(
          currentClass as string,
          currentSubClass,
        );

        if (filterFormSection) {
          /**
           * Again a tricky stuff to make filters enables / disables in the side
           * panel for dtwin devices. It must not be possible to fetch
           * application when dtwin are selected. It must still be possible to
           * check device from dtwin with the same type as the one selected(s).
           */
          if (newEntityClass === LogEntityClass.DEVICE) {
            if (filterFormSection.id === "device-dvtm-esoft-application") {
              filterFormSection.disabled = true;
            }
            if (filterFormSection.id === "device-dvtm-esoft-device") {
              filterFormSection.disabled = false;
            }
          } else {
            filterFormSection.disabled =
              !!newEntityClass &&
              (currentClass !== newEntityClass ||
                currentSubClass !== newEntitySubClass);
          }
        }
      }
    }
  }
}

function onEntityClassChanged(newEntityClass?: LogEntityClass) {
  onEntityClassAndSubChanged(newEntityClass, entitySubClass.value);
}

function onEntitySubClassChanged(newEntitySubClass?: LogEntitySubClass) {
  onEntityClassAndSubChanged(entityClass.value, newEntitySubClass);
}

function onAppliedFilterChange() {
  appliedActions.value = actions.value;
  appliedActionTypes.value = actionTypes.value;
  const appliedEntityClass = props.filters.get(Filters.LOG_ENTITY_CLASS)
    ?.value as string | undefined;
  const appliedEntityId = props.filters.get(Filters.LOG_ENTITY_ID)?.value as
    | string[]
    | undefined;

  onPropsEntityClassChanged(appliedEntityClass);
  onPropsEntityChanged(appliedEntityClass, appliedEntityId);
  onPropsEntitySubClassChanged();
}

function applyFilter() {
  if (!updateInProgress.value) {
    emit(
      "change",
      Filters.LOG_ACTION,
      actions.value?.map((translated) => translated.rawValue).slice(0) ?? [],
    );
    emit(
      "change",
      Filters.LOG_ACTION_TYPE,
      actionTypes.value?.map((translated) => translated.rawValue).slice(0) ??
        [],
    );
    emit("change", Filters.LOG_ENTITY_CLASS, entityClass.value ?? "");
    emit("change", Filters.LOG_ENTITY_ID, entityId.value?.slice(0) ?? []);
    appliedEntitySubClass.value = entitySubClass.value;
    emit("enter");
  } else {
    applyPending.value = true;
  }
}

const onUpdateInProgressChanged = (inProgress: boolean) => {
  if (!inProgress && applyPending.value) {
    applyFilter();
    applyPending.value = false;
  }
};

function clearFilter() {
  updateInProgress.value = true;
  componentId.value = undefined;
  entityClass.value = undefined;
  entitySubClass.value = undefined;
  entityId.value = [];
  actions.value = [];
  actionTypes.value = [];
  sortBy.value = undefined;
  sortDirection.value = undefined;
  devicesDvtmEsoft.value = [];
  applications.value = [];
  users.value = [];
  appliedDevicesDvtmEsoft.value = [];
  updateInProgress.value = false;
  applyFilter();
}

function discardFilter() {
  updateInProgress.value = true;
  componentId.value = props.filters.get(Filters.LOG_COMPONENT_ID)?.value as
    | LogComponentId
    | undefined;
  entityClass.value = props.filters.get(Filters.LOG_ENTITY_CLASS)?.value as
    | LogEntityClass
    | undefined;
  entitySubClass.value = appliedEntitySubClass.value;
  entityId.value = props.filters.get(Filters.LOG_ENTITY_ID)?.value as
    | string[]
    | undefined;
  sortBy.value = props.filters.get(Filters.SORT_BY)?.value as
    | LogSortBy
    | undefined;
  sortDirection.value = props.filters.get(Filters.SORT_DIRECTION)?.value as
    | LogSortDirection
    | undefined;

  switch (entityClass.value) {
    case LogEntityClass.DEVICE:
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      devicesDvtmEsoft.value = appliedDevicesDvtmEsoft.value?.slice(0) ?? [];
      applications.value = appliedApplications.value?.slice(0) ?? [];
      break;
    case LogEntityClass.USER:
      users.value = appliedUsers.value?.slice(0) ?? [];
      break;
    default:
      break;
  }

  updateInProgress.value = false;
}

function onFilterShowing() {
  initializeActionsAndActionTypesFilters();
  onAppliedFilterChange();
}

function updateEntityFilterSection(
  paramFilterMap: FiltersSelection,
  entityClass: LogEntityClass,
  entitySubclass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: Device[],
  paramApplications?: Application[],
  paramUsers?: UserData[],
  paramUserGroups?: UserGroup[],
  paramProfiles?: UserProfile[],
  paramRoles?: Role[],
  paramSectors?: UserSessionSector[],
) {
  let entityIds: string[] = [];

  switch (entityClass) {
    case LogEntityClass.DEVICE:
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (
        entitySubclass === LogEntitySubClass.DEVICE_DVTM_ESOFT ||
        entityClass === LogEntityClass.DEVICE
      ) {
        if (paramDevicesDvtmEsoft) {
          entityIds = filterEmptyValues<string>(
            paramDevicesDvtmEsoft.map((deviceDvtmEsoft) => deviceDvtmEsoft.id),
          );
        }
      } else if (entitySubclass === LogEntitySubClass.APPLICATION) {
        if (paramApplications) {
          entityIds = filterEmptyValues<string>(
            paramApplications.map((application) => application.id),
          );
        }
      }
      break;
    case LogEntityClass.GROUP:
      if (paramUserGroups) {
        entityIds = filterEmptyValues<string>(
          paramUserGroups.map((userGroup) => userGroup.code),
        );
      }
      break;
    case LogEntityClass.PROFILE:
      if (paramProfiles) {
        entityIds = filterEmptyValues<string>(
          paramProfiles.map((profile) => profile.code),
        );
      }
      break;
    case LogEntityClass.ROLE:
      if (paramRoles) {
        entityIds = filterEmptyValues<string>(
          paramRoles.map((role) => (role.id ? String(role.id) : undefined)),
        );
      }
      break;
    case LogEntityClass.SECTOR:
      if (paramSectors) {
        entityIds = filterEmptyValues<string>(
          paramSectors.map((sector) => sector.code),
        );
      }
      break;
    case LogEntityClass.USER:
      if (paramUsers) {
        entityIds = filterEmptyValues<string>(
          paramUsers.map((user) => user.id),
        );
      }
      break;
    default:
      break;
  }

  paramFilterMap.set(Filters.LOG_ENTITY_CLASS, {
    key: "entityClass",
    operator: "=",
    value: entityClass,
  });

  paramFilterMap.set(Filters.LOG_ENTITY_ID, {
    key: "entityId",
    operator: "=",
    value: entityIds,
  });
}

function updateFilterSelection(
  filters: FiltersSelection,
  actions?: TranslatedLogAttribute[],
  actionTypes?: TranslatedLogAttribute[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: Device[],
  paramApplications?: Application[],
  paramUsers?: UserData[],
  paramGroups?: UserGroup[],
  paramProfiles?: UserProfile[],
  paramRoles?: Role[],
  paramSectors?: UserSessionSector[],
): FiltersSelection {
  filters.set(Filters.LOG_ACTION, {
    key: "action",
    operator: "=",
    value:
      actions?.map(
        (translatedLogAttribute) => translatedLogAttribute.rawValue,
      ) ?? [],
  });
  filters.set(Filters.LOG_ACTION_TYPE, {
    key: "actionType",
    operator: "=",
    value:
      actionTypes?.map(
        (translatedLogAttribute) => translatedLogAttribute.rawValue,
      ) ?? [],
  });

  if (entityClass) {
    updateEntityFilterSection(
      filters,
      entityClass,
      entitySubClass,
      paramDevicesDvtmEsoft,
      paramApplications,
      paramUsers,
      paramGroups,
      paramProfiles,
      paramRoles,
      paramSectors,
    );
  }

  return filters;
}

function deleteDeviceDvtmEsoftFilter(id: string) {
  const index = devicesDvtmEsoft.value.findIndex((device) => device.id === id);

  if (index >= 0) {
    devicesDvtmEsoft.value.splice(index, 1);
  }
}

function deleteApplicationFilter(id: string) {
  const index = applications.value.findIndex(
    (application) => application.id === id,
  );

  if (index >= 0) {
    applications.value.splice(index, 1);
  }
}

function deleteUserFilter(id: string) {
  const index = users.value.findIndex((user) => user.id === id);

  if (index >= 0) {
    users.value.splice(index, 1);
  }
}

function deleteEntityTag(id: string) {
  switch (entityClass.value) {
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both DVTM E-Soft devices and applications have the same entity class "device-dvtm-esoft"
      if (entitySubClass.value === LogEntitySubClass.DEVICE_DVTM_ESOFT) {
        deleteDeviceDvtmEsoftFilter(id);
      } else if (entitySubClass.value === LogEntitySubClass.APPLICATION) {
        deleteApplicationFilter(id);
      }
      break;
    case LogEntityClass.DEVICE:
      deleteDeviceDvtmEsoftFilter(id);
      break;
    case LogEntityClass.USER:
      deleteUserFilter(id);
      break;
    default:
      break;
  }
}

function deleteTagFromList(valueToDelete: string, list?: string[] | null) {
  if (list) {
    const index = list.findIndex((value) => value === valueToDelete);

    if (index != null && index >= 0) {
      list.splice(index, 1);
    }
  }
}

function buildEntityTagFilter(
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: Device[],
  paramApplications?: Application[],
  paramUsers?: UserData[],
  paramGroups?: UserGroup[],
  paramProfiles?: UserProfile[],
  paramRoles?: Role[],
  paramSectors?: UserSessionSector[],
): UniqueLabel[] {
  let tagFilters: UniqueLabel[] = [];
  switch (entityClass) {
    case LogEntityClass.DEVICE:
    case LogEntityClass.DEVICE_DVTM_ESOFT:
      // Both devices and applications have the same entity class "device-dvtm-esoft"
      if (
        entityClass === LogEntityClass.DEVICE ||
        entitySubClass === LogEntitySubClass.DEVICE_DVTM_ESOFT
      ) {
        if (
          paramDevicesDvtmEsoft != null &&
          Array.isArray(paramDevicesDvtmEsoft)
        ) {
          tagFilters = filterEmptyValues<UniqueLabel>(
            paramDevicesDvtmEsoft.map((deviceDvtmEsoft) =>
              filtersUtils.getDeviceDvtmEsoftTag(
                deviceDvtmEsoft,
                deviceDvtmEsoftLabel,
              ),
            ),
          );
        }
      } else if (entitySubClass === LogEntitySubClass.APPLICATION) {
        if (paramApplications != null && Array.isArray(paramApplications)) {
          tagFilters = filterEmptyValues<UniqueLabel>(
            paramApplications.map((application) =>
              filtersUtils.getApplicationTag(application, applicationLabel),
            ),
          );
        }
      }
      break;
    case LogEntityClass.GROUP:
      if (paramGroups != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(
          paramGroups.map((userGroup) =>
            filtersUtils.getUserGroupTag(userGroup, userGroupLabel),
          ),
        );
      }
      break;
    case LogEntityClass.PROFILE:
      if (paramProfiles != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(
          paramProfiles.map((profile) =>
            filtersUtils.getProfileTag(profile, profileLabel),
          ),
        );
      }
      break;
    case LogEntityClass.ROLE:
      if (paramRoles != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(
          paramRoles.map((role) => filtersUtils.getRoleTag(role, roleLabel)),
        );
      }
      break;
    case LogEntityClass.SECTOR:
      if (paramSectors != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(
          paramSectors.map((sector) =>
            filtersUtils.getSectorTag(sector, sectorLabel),
          ),
        );
      }
      break;
    case LogEntityClass.USER:
      if (paramUsers != null) {
        tagFilters = filterEmptyValues<UniqueLabel>(
          paramUsers.map((user) => filtersUtils.getUserTag(user, userLabel)),
        );
      }
      break;
    default:
  }

  return tagFilters;
}

function buildFilterTags(
  actions?: TranslatedLogAttribute[],
  actionTypes?: TranslatedLogAttribute[],
  entityClass?: LogEntityClass,
  entitySubClass?: LogEntitySubClass,
  paramDevicesDvtmEsoft?: Device[],
  paramApplications?: Application[],
  paramUsers?: UserData[],
  paramGroups?: UserGroup[],
  paramProfiles?: UserProfile[],
  paramRoles?: Role[],
  paramSectors?: UserSessionSector[],
): UniqueLabel[] {
  const tagFilters: UniqueLabel[] = [];

  if (actions && actions.length !== 0) {
    for (const action of actions) {
      const filterTag = filtersUtils.getTag(
        actionLabel,
        action.rawValue,
        action.translation,
      );
      if (filterTag) {
        tagFilters.push(filterTag);
      }
    }
  }
  if (actionTypes && actionTypes.length !== 0) {
    for (const actionType of actionTypes) {
      const filterTag = filtersUtils.getTag(
        actionTypeLabel,
        actionType.rawValue,
        actionType.translation,
      );
      if (filterTag) {
        tagFilters.push(filterTag);
      }
    }
  }

  if (entityClass) {
    tagFilters.push(
      ...buildEntityTagFilter(
        entityClass,
        entitySubClass,
        paramDevicesDvtmEsoft,
        paramApplications,
        paramUsers,
        paramGroups,
        paramProfiles,
        paramRoles,
        paramSectors,
      ),
    );
  }

  return tagFilters;
}

const selectedFilterTags = computed((): UniqueLabel[] => {
  return buildFilterTags(
    actions.value,
    actionTypes.value,
    entityClass.value,
    entitySubClass.value,
    devicesDvtmEsoft.value,
    applications.value,
    users.value,
  );
});

const selectedFilterMap = computed((): FiltersSelection => {
  const filterSelection: FiltersSelection = new Map<
    Filters,
    FilterSelectionDefinition
  >();
  return updateFilterSelection(
    filterSelection,
    actions.value,
    actionTypes.value,
    entityClass.value,
    entitySubClass.value,
    devicesDvtmEsoft.value,
    applications.value,
    users.value,
  );
});

const appliedFilterTags = computed((): UniqueLabel[] => {
  const appliedEntityClass = props.filters.get(Filters.LOG_ENTITY_CLASS)
    ?.value as LogEntityClass | undefined;
  return buildFilterTags(
    appliedActions.value,
    appliedActionTypes.value,
    appliedEntityClass,
    appliedEntitySubClass.value,
    appliedDevicesDvtmEsoft.value,
    appliedApplications.value,
    appliedUsers.value,
    appliedUserGroups.value,
    appliedProfiles.value,
    appliedRoles.value,
    appliedSectors.value,
  );
});

function onDeleteSelectedTag(tag: string, uid?: string) {
  updateInProgress.value = true;
  actions.value = actions.value?.filter(
    (action) => action.rawValue !== (uid ?? tag),
  );
  actionTypes.value = actionTypes.value?.filter(
    (actionTypes) => actionTypes.rawValue !== (uid ?? tag),
  );
  deleteEntityTag(uid ?? tag);
  updateInProgress.value = false;
}

function onAppliedTagDeleteClick(tag: string, uid?: string) {
  updateInProgress.value = true;
  actions.value = actions.value?.filter(
    (action) => action.rawValue !== (uid ?? tag),
  );
  actionTypes.value = actionTypes.value?.filter(
    (actionTypes) => actionTypes.rawValue !== (uid ?? tag),
  );
  deleteTagFromList(uid ?? tag, entityId.value);

  if (!entityId.value?.length && entityClass.value !== undefined) {
    entityClass.value = undefined;
    entitySubClass.value = undefined;
  }

  updateInProgress.value = false;
  applyFilter();
}

watch(() => applications, onApplicationsSelected, { deep: true });
watch(() => devicesDvtmEsoft, onDevicesDvtmEsoftSelected, { deep: true });
watch(() => entityClass.value, onEntityClassChanged);
watch(() => entitySubClass.value, onEntitySubClassChanged);
watch(() => isEntityLoading.value, onEntityLoadingUpdated);
watch(() => users, onUsersSelected, { deep: true });
watch(() => updateInProgress.value, onUpdateInProgressChanged);

onMounted(async () => {
  await initializeActionsAndActionTypesValues();
  onAppliedFilterChange();
  isMounted.value = true;
});
</script>
<template>
  <div class="relative flex justify-between">
    <log-download :data="props.rowsSelected" />
    <div class="relative flex justify-end">
      <lxc-period-picker
        v-model="formPeriod"
        type="primary"
        :can-clear-selection="canClearPeriodSelection"
        button-size
        :formatter="t('logs.filters.timestamp.formatter')"
        :i18n="locale"
        :separator="periodSeparator"
        :placeholder="t('logs.filters.timestamp.placeholder')"
        class="mr-3"
      />

      <filters-component
        v-if="isMounted"
        :applied-tags="appliedFilterTags"
        :filter-sections="filterFormSections"
        :filters="props.filters"
        :filters-by-type="selectedFiltersByTypes"
        :selected-filters="selectedFilterMap"
        :selected-tags="selectedFilterTags"
        :teleported-tags="tagsetSelector"
        @apply="applyFilter"
        @applied-filter-change="onAppliedFilterChange"
        @change="onChange"
        @delete="onAppliedTagDeleteClick"
        @delete-selected="onDeleteSelectedTag"
        @enter="onEnter"
        @discard="discardFilter"
        @reset="clearFilter"
        @showing="onFilterShowing"
      >
        <template #action>
          <log-action-action-type-filter
            v-model="actions"
            :context="ActionsOrActionTypesContext.ACTION"
            :label="actionLabel"
          />
        </template>

        <template #action-type>
          <log-action-action-type-filter
            v-model="actionTypes"
            :context="ActionsOrActionTypesContext.ACTION_TYPES"
            :label="actionTypeLabel"
          />
        </template>

        <template #device-dvtm-esoft-device>
          <log-device-filter
            v-model="devicesDvtmEsoft.value"
            :label="deviceDvtmEsoftLabel"
          />
        </template>

        <template #device-dvtm-esoft-application>
          <log-application-filter
            v-model="applications.value"
            :label="applicationLabel"
          />
        </template>

        <template #user>
          <log-user-filter v-model="users.value" :label="userLabel" />
        </template>
      </filters-component>
    </div>
  </div>
  <div :id="tagsetId" />
</template>
