<script setup lang="ts">
import type { CertificateI } from "@lxc/app-device-types";
import { timeLimitInMonthOrDays } from "~/core/utils/date";
import ILxCertificateExpired from "~icons/lxc-business/certificate-expired";
import ILxCertificateValidityGreaterThanSixMonths from "~icons/lxc-business/certificate-validity-greater-than-six-months";
import ILxCertificateValidityLessThanOneMonths from "~icons/lxc-business/certificate-validity-less-than-one-months";
import ILxCertificateValidityLessThanSixMonths from "~icons/lxc-business/certificate-validity-less-than-six-months";
import ILxCertificateValidityLessThanThreeMonths from "~icons/lxc-business/certificate-validity-less-than-three-months";

const props = defineProps<{
  certificate: CertificateI;
}>();

const { t } = useI18n();

const certificateStatusData = computed(() => {
  const timeLimitProvided = timeLimitInMonthOrDays(
    props.certificate?.notValidAfter,
  );
  const timeInDays: number = timeLimitInMonthOrDays(
    props.certificate?.notValidAfter,
    true,
  );
  let icon = "";
  let title = "";

  if (timeLimitProvided >= 6) {
    icon = "certificate-validity-greater-than-six-months";
    title = `${t("certificates.valid")} (> ${t("certificates.month", 6)}) - ${t("certificates.days", timeInDays)}`;
  } else if (timeLimitProvided < 6 && timeLimitProvided >= 3) {
    icon = "certificate-validity-less-than-six-months";
    title = `${t("certificates.valid")} (< ${t("certificates.month", 6)}) - ${t("certificates.days", timeInDays)}`;
  } else if (timeLimitProvided < 3 && timeLimitProvided >= 1) {
    icon = "certificate-validity-less-than-three-months";
    title = `${t("certificates.valid")} (< ${t("certificates.month", 3)}) - ${t("certificates.days", timeInDays)}`;
  } else if (timeLimitProvided < 1 && timeLimitProvided >= 0) {
    icon = "certificate-validity-less-than-one-months";
    title = `${t("certificates.valid")} (< ${t("certificates.month", 1)}) - ${t("certificates.days", timeInDays)}`;
  } else {
    icon = "certificate-expired";
    title = t(`certificates.expired`);
  }
  return { icon, title };
});
</script>

<template>
  <span :title="certificateStatusData.title" class="block mx-auto w-fit">
    <ILxCertificateValidityGreaterThanSixMonths
      v-if="
        certificateStatusData.icon ===
        'certificate-validity-greater-than-six-months'
      "
    />
    <ILxCertificateValidityLessThanSixMonths
      v-if="
        certificateStatusData.icon ===
        'certificate-validity-less-than-six-months'
      "
    />
    <ILxCertificateValidityLessThanThreeMonths
      v-if="
        certificateStatusData.icon ===
        'certificate-validity-less-than-three-months'
      "
    />
    <ILxCertificateValidityLessThanOneMonths
      v-if="
        certificateStatusData.icon ===
        'certificate-validity-less-than-one-months'
      "
    />
    <ILxCertificateExpired
      v-if="certificateStatusData.icon === 'certificate-expired'"
    />
  </span>
</template>
