export enum CampaignOMOperationStatisticsStatus {
  RUNNING = "RUNNING",
  SCHEDULED = "SCHEDULED",
  QUEUED = "QUEUED",
  DELIVERED = "DELIVERED",
  RETRY = "RETRY",
  PENDING = "PENDING",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
  DONE = "DONE",
}
