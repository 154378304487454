<script setup lang="ts">
import { saveAs } from "file-saver";
import LxcError from "~/core/utils/LxcError";
import { NotificationKey } from "~/core/utils/notifications";
import type { UserData } from "~/modules/user/models/UserData.interface";
import userCertificateService from "~/modules/user/services/userCertificate.service";
import ILxcDownload from "~icons/lxc/download";

const { t } = useI18n();
const isLoading = ref(false);
const props = defineProps<{
  user: UserData | null;
}>();
async function downloadCertificate() {
  isLoading.value = true;
  if (props.user && props.user?.id) {
    const userCertificateResponse: BlobPart | Error =
      await userCertificateService.downloadUserCertificate(props.user?.id);

    if (LxcError.check(userCertificateResponse)) {
      userCertificateResponse.notify(NotificationKey.error);
    } else {
      const blob = new Blob([userCertificateResponse as BlobPart], {
        type: "application/x-pkcs12",
      });
      const fileName = `certificate-${props.user?.login}.p12`;
      saveAs(blob, fileName);
    }

    isLoading.value = false;
  }
}
</script>

<template>
  <lxc-button
    type="borderless"
    html-type="button"
    :title="t('button.download')"
    :disabled="isLoading"
    @click="downloadCertificate"
  >
    <lxc-loader v-if="isLoading" :width="25" />
    <i-lxc-download v-else />
  </lxc-button>
</template>

<style lang="scss" scoped>
button {
  :deep(i) {
    &[class*="el-icon"] {
      + span {
        margin-left: 0;
      }
    }
  }
}
</style>
