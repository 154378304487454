<script setup lang="ts">
import type { Rules } from "async-validator";
import type { RuleItem } from "async-validator/dist-types/interface";

const props = defineProps<{
  fieldValue?: string;
  label: string;
  isEditing: boolean;
  formItemProp?: string;
  buttonsOnSide?: boolean;
  rules?: RuleItem | RuleItem[];
  isSaving?: boolean;
  isReadOnly?: boolean;
  placeholder?: string;
}>();

const emit = defineEmits(["validate", "update:isEditing"]);

const { t } = useI18n();

const placeholderComputed = computed(() =>
  props.placeholder || props.isReadOnly ? props.placeholder : " ",
);

interface FormInterface {
  fieldValue?: string;
}
const formRef: Ref<HTMLFormElement | undefined> = ref();
const defaultFormValue: ComputedRef<FormInterface> = computed(() => {
  return {
    fieldValue: props.fieldValue,
  };
});
const formModel = reactive<FormInterface>({
  ...defaultFormValue.value,
});

const formRules: ComputedRef<Rules> = computed(() => {
  return {
    fieldValue: props.rules ?? [],
  };
});

const isValid: ComputedRef<boolean | undefined> = computed(
  () => formRef.value?.isValid,
);

const onEditing = () => {
  emit("update:isEditing", true);
};

const hasValueChanged = computed(
  () => defaultFormValue.value.fieldValue !== formModel.fieldValue,
);
const isSavingComputed = computed(() =>
  hasValueChanged.value ? props.isSaving : false,
);

const wrapperClass = computed(() => {
  return props.buttonsOnSide ? ["flex", "gap-8"] : [];
});

const editButtonsWrapperClass = computed(() => {
  const wrapperClass = ["flex", "gap-2.5"];
  if ((props.isEditing || isSavingComputed.value) && props.buttonsOnSide) {
    if (props.fieldValue && !isValid.value) {
      wrapperClass.push("self-center");
    } else {
      wrapperClass.push("self-end");
    }
  } else {
    wrapperClass.push("self-start");
  }
  if (!props.buttonsOnSide) {
    wrapperClass.push("mt-2.5");
  }
  return wrapperClass;
});

const onValidate = () => {
  emit("validate", formModel.fieldValue);
};
const onCancel = () => {
  formModel.fieldValue = defaultFormValue.value.fieldValue;
  emit("update:isEditing", false);
};
defineExpose({ hasValueChanged });
</script>

<template>
  <lxc-form ref="formRef" :model="formModel" :rules="formRules">
    <div :class="wrapperClass">
      <lxc-form-item :label="label" prop="fieldValue" class="!pb-0">
        <lxc-input
          v-model="formModel.fieldValue"
          type="text"
          :placeholder="placeholderComputed"
          :disabled="!isEditing || isSavingComputed"
          :read-only="isReadOnly && !isEditing && !isSavingComputed"
        />
      </lxc-form-item>
      <div :class="editButtonsWrapperClass">
        <lxc-button
          v-if="!isEditing && !isSavingComputed"
          html-type="button"
          :title="t('button.edit')"
          type="tertiary"
          @click="onEditing"
        >
          {{ t("button.edit") }}
        </lxc-button>
        <lxc-button
          v-if="isEditing || isSavingComputed"
          html-type="button"
          :title="t('button.cancel')"
          type="tertiary"
          @click="onCancel"
        >
          {{ t("button.cancel") }}
        </lxc-button>
        <lxc-button
          v-if="isEditing || isSavingComputed"
          html-type="button"
          :title="t('button.validate')"
          :disabled="!hasValueChanged || !isValid || isSavingComputed"
          :is-loading="isSavingComputed"
          @click="onValidate"
        >
          {{ t("button.validate") }}
        </lxc-button>
      </div>
    </div>
  </lxc-form>
</template>
