<script lang="ts" setup>
import { BREADCRUMBS } from "~/core/constants/breadcrumbs";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import ApplicationService from "~/modules/application/services/application.service";
import { useCertificateConfiguration } from "~/modules/certificateConfiguration/composables/useCertificateConfiguration";
import type { ApplicationModelConfiguration } from "~/modules/certificateConfiguration/models/ApplicationModelConfiguration.interface";
import { CertificateConfigurationParameters } from "~/modules/certificateConfiguration/models/CertificateConfigurationParameters.enum";
import type { DeviceModelConfiguration } from "~/modules/certificateConfiguration/models/DeviceModelConfiguration.interface";
import { S4Declination } from "~/modules/certificateConfiguration/models/S4Declination.enum";
import { S4Types } from "~/modules/certificateConfiguration/models/S4Types.enum";
import DeviceService from "~/services/device.service";

const { t } = useI18n();

const {
  isLoading,
  error,
  appsConfig,
  devicesConfig,
  fetchAppsConfig,
  fetchDeviceConfig,
  resetAppsConfig,
  resetDevicesConfig,
  onAppsConfigSaved,
  onDeviceConfigSaved,
} = useCertificateConfiguration();

function setSameValuesForAllDevices(
  devices: DeviceModelConfiguration[],
  configParam: CertificateConfigurationParameters,
  event: number,
) {
  return devices.map((device: DeviceModelConfiguration) => ({
    type: device.type,
    declination: device.declination,
    polling:
      configParam === CertificateConfigurationParameters.POLLING
        ? event
        : device.polling,
    delay:
      configParam === CertificateConfigurationParameters.DELAY
        ? event
        : device.delay,
    autoActivation:
      configParam === CertificateConfigurationParameters.AUTO_ACTIVATION
        ? Boolean(event)
        : device.autoActivation,
  }));
}

function setValuesForApp(
  applications: ApplicationModelConfiguration[],
  configParam: CertificateConfigurationParameters,
  event: number,
  type: S4Types,
) {
  return applications?.map((app: ApplicationModelConfiguration) =>
    app.type === type
      ? {
          type: app.type,
          declination: app.declination,
          polling:
            configParam === CertificateConfigurationParameters.POLLING
              ? event
              : app.polling,
          delay:
            configParam === CertificateConfigurationParameters.DELAY
              ? event
              : app.delay,
          autoActivation:
            configParam === CertificateConfigurationParameters.AUTO_ACTIVATION
              ? Boolean(event)
              : app.autoActivation,
        }
      : app,
  );
}

const device = computed<DeviceModelConfiguration | null | undefined>(() =>
  devicesConfig?.value?.find(
    (device) => device.declination === S4Declination.S4W,
  ),
);
const sg4000 = computed<ApplicationModelConfiguration | null | undefined>(() =>
  appsConfig?.value?.find((app) => app.type === S4Types.SG4000),
);
const s4Tools = computed<ApplicationModelConfiguration | null | undefined>(() =>
  appsConfig?.value?.find((app) => app.declination === S4Declination.S4W),
);
const s4Manager = computed<ApplicationModelConfiguration | null | undefined>(
  () => appsConfig?.value?.find((app) => app.type === S4Types.MANAGER),
);
const s4View = computed<ApplicationModelConfiguration | null | undefined>(() =>
  appsConfig?.value?.find((app) => app.type === S4Types.VIEW),
);

/**
 * Update delay, polling or auto activation values
 */
function onValueChange(
  event: string | number | boolean,
  type: S4Types,
  configParam: CertificateConfigurationParameters,
) {
  const numberedEvent = Number(event);

  if (!appsConfig.value || !devicesConfig.value) {
    return;
  }

  switch (type) {
    case S4Types.DVC:
      devicesConfig.value = setSameValuesForAllDevices(
        devicesConfig?.value,
        configParam,
        numberedEvent,
      );
      break;
    case S4Types.TOOLS:
    case S4Types.MANAGER:
    case S4Types.VIEW:
    case S4Types.SG4000:
      appsConfig.value = setValuesForApp(
        appsConfig?.value,
        configParam,
        numberedEvent,
        type,
      );
      break;
  }
}

/**
 * Update configurations
 */
async function updateConfig() {
  if (!devicesConfig.value || !appsConfig.value) {
    showNotificationError(t(NotificationKey.error));
    return;
  }
  const result = await Promise.all([
    DeviceService.updateDeviceConfiguration(devicesConfig.value),
    ApplicationService.updateAppsConfiguration(appsConfig.value),
  ]);

  if (result.some((updateAction) => LxcError.check(updateAction))) {
    showNotificationError(t(NotificationKey.saveError));
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    onAppsConfigSaved();
    onDeviceConfigSaved();
  }
}

function cancel() {
  resetAppsConfig();
  resetDevicesConfig();
  showNotificationSuccess(t(NotificationKey.cancel));
}

onMounted(() => {
  Promise.all([fetchDeviceConfig(), fetchAppsConfig()]);
});
</script>

<template>
  <page-component
    :name="BREADCRUMBS.DEVICE_CONFIGURATION.title"
    :is-loading="isLoading"
    :error="error"
  >
    <lxc-mandatory />

    <certificate-configuration-devices
      v-if="devicesConfig"
      :s4-device="device"
      @change="onValueChange"
    />

    <el-divider />

    <certificate-configuration-applications
      v-if="appsConfig && devicesConfig"
      :s4-tool="s4Tools"
      :s4-manager="s4Manager"
      :s4-view="s4View"
      :sg4000="sg4000"
      @change="onValueChange"
    />

    <lxc-cancel-or-submit-buttons @cancel="cancel" @submit="updateConfig" />
  </page-component>
</template>
