import { AppDeviceState } from "~/core/models/AppDeviceState.enum";
import ILxcLightRotateCw from "~icons/lxc-light/rotate-cw";
import ILxcLightSlash from "~icons/lxc-light/slash";

export function useUpdateState() {
  const { t } = useI18n();

  function getState(state: AppDeviceState): AppDeviceState {
    switch (state) {
      case AppDeviceState.DEACTIVATED:
      case AppDeviceState.REGISTERED:
        return AppDeviceState.ACTIVATED;
      case AppDeviceState.ACTIVATED:
        return AppDeviceState.DEACTIVATED;
      default:
        return AppDeviceState.ACTIVATED;
    }
  }

  function createDataForStateUpdate(
    isActivated: AppDeviceState,
    type: string,
    name: string,
  ) {
    switch (isActivated) {
      case AppDeviceState.ACTIVATED:
        return {
          activatedButton: t(`${type}.updateState.deactivate.execute`),
          title: t(`${type}.updateState.deactivate.execute`),
          description: t(`${type}.updateState.deactivate.description`, {
            name,
          }),
          warning: t(`${type}.updateState.deactivate.warning`),
          icon: ILxcLightSlash,
        };
      case AppDeviceState.DEACTIVATED:
      case AppDeviceState.REGISTERED:
        return {
          activatedButton: t(`${type}.updateState.activate.execute`),
          title: t(`${type}.updateState.activate.execute`),
          description: t(`${type}.updateState.activate.description`, { name }),
          warning: "",
          icon: ILxcLightRotateCw,
        };
    }
  }

  return {
    getState,
    createDataForStateUpdate,
  };
}
