import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { useFetchPage } from "~/core/composables/useFetchPage";
import type { SearchMode } from "~/core/composables/useSearch";
import { useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { FiltersSelection } from "~/core/models/filters";
import { Filters, FiltersType } from "~/core/models/filters";
import type { Sector } from "~/modules/sector/models/Sector.interface";
import type { UserData } from "~/modules/user/models/UserData.interface";
import UsersService from "~/modules/user/services/user.service";

export function useUser(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = UsersService.getUsers.bind(UsersService);
  const itemType = "user";

  const { can } = useAcl();

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RAW,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  function getDescription(item: UserData) {
    return item ? `${item?.firstName} ${item?.lastName}` : "";
  }

  function canManageItems(_sector: Sector) {
    return can(ACLRoles.DISPLAY_USERS);
  }

  function getItemIds(items: Ref<any[]>): string[] {
    return items.value.map((item) => item.id);
  }

  return {
    itemType,
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    getDescription,
    canManageItems,
    getItemIds,
    search,
    onSearch,
  };
}
