import { BASE_URL_MAP } from "~/core/constants/constants";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { FiltersSelection } from "~/core/models/filters";
import RestService from "~/core/services/rest.service";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";

export class ActionsService extends RestService {
  protected BASE_URL = BASE_URL_MAP.ACTIONS;

  /**
   * Retrieve actions with pagination
   * @param page
   * @param pageSize
   * @param searchParams
   */
  public getActions(
    page: number,
    pageSize: number,
    searchParams?: string | FiltersSelection,
  ): Promise<ApiListResult<TranslatedLogAttribute>> {
    const params = {
      page,
      pageSize,
      search: searchParams,
    };

    return this.$api.get(this.BASE_URL, { params });
  }
}

export default new ActionsService();
