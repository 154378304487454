import { createAcl, defineAclRules } from "vue-simple-acl";
import "~/assets/styles/main/main.scss";
import "~/assets/styles/vendors/element-plus.scss";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { DisplayPermission } from "~/core/models/DisplayPermission.enum";
import { useUserSession } from "~/modules/user/stores/useUserSession";
import { router } from "~/plugins/router";

const rolesFromPermission: {
  role: ACLRoles;
  permissions: DisplayPermission[];
}[] = [
  { role: ACLRoles.NO_CONTROL, permissions: [] },
  {
    role: ACLRoles.DISPLAY_DEVICES,
    permissions: [DisplayPermission.DVM_VIEW_DEV],
  },
  {
    role: ACLRoles.UPDATE_DEVICES,
    permissions: [DisplayPermission.DVM_UPDATE_DEV],
  },
  {
    role: ACLRoles.CONFIGURE_PKI,
    permissions: [DisplayPermission.DVM_PKI_MANAGEMENT],
  },
  {
    role: ACLRoles.DISPLAY_USERS,
    permissions: [DisplayPermission.DVM_USER_MANAGEMENT],
  },
  {
    role: ACLRoles.DISPLAY_APPLICATIONS,
    permissions: [DisplayPermission.DVM_VIEW_APP],
  },
  {
    role: ACLRoles.UPDATE_APPLICATIONS,
    permissions: [DisplayPermission.DVM_UPDATE_APP],
  },
  {
    role: ACLRoles.DISPLAY_SECTORS,
    permissions: [DisplayPermission.DVM_SECT_VIEW],
  },
  {
    role: ACLRoles.MANAGE_SECTORS,
    permissions: [DisplayPermission.DVM_SECT_MANAGEMENT],
  },
  {
    role: ACLRoles.MANAGE_TRUSTSTORE,
    permissions: [DisplayPermission.DVM_TRST_MANAGEMENT],
  },
  {
    role: ACLRoles.MANAGE_CERTIFICATES,
    permissions: [DisplayPermission.DVM_CERT_MANAGEMENT],
  },
  {
    role: ACLRoles.DVT_CACRT_VIEW,
    permissions: [DisplayPermission.CACRT_READ, DisplayPermission.CACRT_NOTIFS],
  },
  {
    role: ACLRoles.DVT_CACRT_ADM,
    permissions: [DisplayPermission.CACRT_ADM, DisplayPermission.CACRT_NOTIFS],
  },
  {
    role: ACLRoles.DISPLAY_FIRMWARES,
    permissions: [DisplayPermission.DVM_VIEW_FIRM],
  },
  {
    role: ACLRoles.MANAGE_FIRMWARES,
    permissions: [DisplayPermission.DVM_FIRM_MANAGEMENT],
  },
  {
    role: ACLRoles.CONFIGURE_NOTIFICATION,
    permissions: [DisplayPermission.DVM_ALER_MANAGEMENT],
  },
  {
    role: ACLRoles.ASK_MANUAL_CERTIFICATE_RENEWAL_APP,
    permissions: [DisplayPermission.DVM_CRTRENEW_APP_MANAGEMENT],
  },
  {
    role: ACLRoles.ASK_MANUAL_CERTIFICATE_RENEWAL_DEVICE,
    permissions: [DisplayPermission.DVM_CRTRENEW_DEV_MANAGEMENT],
  },
  {
    role: ACLRoles.ASK_MANUAL_FIRMWARE_UPDATE_DEVICE,
    permissions: [DisplayPermission.DVM_UPDATE_FIRM_DEV_MANAGEMENT],
  },
  {
    role: ACLRoles.DISPLAY_CAMPAIGNS,
    permissions: [DisplayPermission.CAMP_VIEW],
  },
  {
    role: ACLRoles.MANAGE_CAMPAIGNS,
    permissions: [DisplayPermission.CAMP_MANAGEMENT],
  },
  {
    role: ACLRoles.USR_CERTIFICATES,
    permissions: [DisplayPermission.USR_CERTIFICATES],
  },
  {
    role: ACLRoles.CYBER_LOGS_VIEW,
    permissions: [DisplayPermission.CYBER_LOGS_VIEW],
  },
  {
    role: ACLRoles.DEVICE_LOGS_VIEW,
    permissions: [DisplayPermission.DEVICE_LOGS_VIEW],
  },
  {
    role: ACLRoles.DEVICE_FLEET_LOGS_VIEW,
    permissions: [DisplayPermission.DEVICE_FLEET_LOGS_VIEW],
  },
  {
    role: ACLRoles.DVTM_DVT_ADM,
    permissions: [
      DisplayPermission.DVM_VIEW_DEV,
      DisplayPermission.DVM_ADM_DEV,
    ],
  },
  {
    role: ACLRoles.SYSTEM_LOGS_VIEW,
    permissions: [DisplayPermission.SYSTEM_LOGS_VIEW],
  },
  {
    role: ACLRoles.BSCOMMON_ERRDESC_LIST,
    permissions: [DisplayPermission.BSCOMMON_ERRDESC_LIST],
  },
  {
    role: ACLRoles.LICENSEM_UPLOAD,
    permissions: [DisplayPermission.LICENSEM_UPLOAD],
  },
  {
    role: ACLRoles.DVTM_APPM_THIRDPARTY_VIEW,
    permissions: [DisplayPermission.APPMGR_READ_THIRDPARTYAPP],
  },
  {
    role: ACLRoles.DVTM_APPM_THIRDPARTY_ADM,
    permissions: [DisplayPermission.APPMGR_ADM_THIRDPARTYAPP],
  },
  {
    role: ACLRoles.DVTM_APPM_MOBILEAPP_VIEW,
    permissions: [DisplayPermission.APPMGR_READ_MOBILEAPP],
  },
  {
    role: ACLRoles.DVTM_APPM_MOBILEAPP_ADM,
    permissions: [DisplayPermission.APPMGR_ADM_MOBILEAPP],
  },
];

export default {
  install: (app: any) => {
    const user = computed(() => useUserSession().$state.userSession);
    const rules = () =>
      defineAclRules<typeof user.value>((setRule) =>
        rolesFromPermission.forEach((t) => {
          setRule(t.role, (user) =>
            t.permissions.every((permission) =>
              (user?.permissions ?? []).includes(permission),
            ),
          );
        }),
      );

    app.use(
      createAcl({
        user,
        rules,
        router,
      }),
    );
  },
};
