import RestService from "../../../core/services/rest.service";
import type { AboutComponent } from "~/modules/about/models/AboutComponent.interface";
import { ComponentType } from "~/modules/about/models/ComponentType.enum";
import { ComponentVersion } from "~/modules/about/models/ComponentVersion.class";

const REQUEST_TIMEOUT_IN_MILLISECONDS = 20_000;

class PlatformService extends RestService {
  private BASE_URL = "/platform";

  async fetch(): Promise<AboutComponent[]> {
    let data = (await this.$api.get(`${this.BASE_URL}/system/versions`, {
      timeout: REQUEST_TIMEOUT_IN_MILLISECONDS,
    })) as AboutComponent[];

    // If the `data?.length` return undefined, it means that we do not receive a valid JSON or the request trigger a timeout.
    if (data?.length === undefined) {
      data = [];
    }

    // Add this module version.
    data.push(
      new ComponentVersion({
        name: ComponentType.FI,
        version: import.meta.env.LXC_FI_DEVICE_VERSION ?? null,
      }),
    );

    return data;
  }
}

export default new PlatformService();
