import { defineStore } from "pinia";
import { useLicenseStatus } from "~/modules/license/composables/useLicenseStatus";
import type { LicenseStatus } from "~/modules/license/models/LicenseStatus.interface";

interface LicenseFeaturesState {
  licenseStatus: LicenseStatus | undefined | null;
  isLicenseFeatureAdvancedFleetManagementPresent: boolean | undefined;
  isLicenseFeatureAdvancedFleetManagementActive: boolean | undefined;
  isLicenseFeatureDataSharingPresent: boolean | undefined;
  isLicenseFeatureDataSharingActive: boolean | undefined;
}

export const useLicenseStatusStore = defineStore("licenseStatusStore", () => {
  const state = reactive<LicenseFeaturesState>({
    licenseStatus: undefined,
    isLicenseFeatureAdvancedFleetManagementPresent: undefined,
    isLicenseFeatureAdvancedFleetManagementActive: undefined,
    isLicenseFeatureDataSharingPresent: undefined,
    isLicenseFeatureDataSharingActive: undefined,
  });

  const { getLicenseStatus: useLicenseGetLicenseStatus } = useLicenseStatus();

  async function getLicenseStatus() {
    return useLicenseGetLicenseStatus();
  }

  return {
    ...toRefs(state),
    getLicenseStatus,
  };
});
