<script setup lang="ts">
import type { SlideMenuItem } from "@lxc/app-device-common";
import type {
  DtwinI,
  FleetI,
  FleetUpdatePayloadI,
} from "@lxc/app-device-types";
import type { Ref } from "vue";
import { SearchMode } from "~/core/composables/useSearch";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import { useFleet } from "~/modules/fleet/composables/useFleet";
import fleetService from "~/modules/fleet/services/fleet.service";
import { Filters } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

const props = defineProps<{
  dtwinList?: DtwinI[];
  fleetFormShown: boolean;
}>();
const emit = defineEmits([
  "update:fleetFormShown",
  "attachedToFleet",
  "reloadFleetList",
]);

const { t } = useI18n();
const {
  isLoading,
  error,
  results,
  fetchData,
  filters,
  setFilter,
  search,
  onSearch,
} = useFleet(SearchMode.FILTER_SEARCH);

const searchQuery = computed({
  get() {
    return (filters.get(Filters.FLEET_NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.FLEET_NAME, searchQuery);
  },
});

async function loadData(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  const params = new Map();
  params.set("sort", "-modifiedAt");
  await fetchData(page, pageSize, params);
}

const selectedFleet: Ref<FleetI | undefined> = ref();
const isValidateDisabled = ref(false);
const disabledValidate = (newValue: boolean) => {
  isValidateDisabled.value = newValue;
};
const isAddDevicesDisabled = computed(() => !selectedFleet.value);
const isSaving = ref(false);

const slideMenuRef = ref();
const currentPanel: Ref<string | null | undefined> = ref();
const displayForm = ref(0);
const onDiscardSideCanvas = () => {
  displayForm.value++;
};
const fleetCreateFormRef = ref();

const sidePanels: SlideMenuItem[] = [
  {
    id: "fleetCreateFormId",
    header: t("fleet.form.createFleet.header"),
    footerId: "fleetCreateFormFooterId",
    footerEnabled: true,
  },
];
const [createFleetFormPanel] = sidePanels;

const slideToFleetCreationForm = (targetId: string | undefined) => {
  if (slideMenuRef.value && targetId) {
    slideMenuRef.value.slideToPanel(targetId);
  }
};

const dtwinsUid = computed(() => {
  return props.dtwinList?.map((dtwin) => dtwin.uid);
});

const onSelectFleet = (fleet: FleetI) => {
  if (selectedFleet.value && selectedFleet.value === fleet) {
    selectedFleet.value = undefined;
  } else {
    selectedFleet.value = fleet;
  }
};

const getSelectFleetButtonClass = (fleetUid: string) => {
  const buttonClass = ["text-base", "!justify-start", "mb-2", "fleet-button"];
  if (selectedFleet.value && selectedFleet.value.uid === fleetUid) {
    buttonClass.push(
      "!text-primary",
      "!border-primary",
      "!border",
      "!bg-primary-50",
      "!rounded",
      "!box-border",
    );
  }
  return buttonClass;
};

const showFleetForm = computed({
  get: () => {
    return props.fleetFormShown;
  },
  set: (shown: boolean) => {
    emit("update:fleetFormShown", shown);
  },
});

async function createFleet() {
  await fleetCreateFormRef.value.onSubmit();
}

async function attachToFleet() {
  isSaving.value = true;

  if (selectedFleet.value && props.dtwinList) {
    const selectedDtwinsUids: string[] = props.dtwinList.map(
      (dtwins: DtwinI) => dtwins.uid,
    );
    const fleetUpdatePayload: FleetUpdatePayloadI = {
      deviceOperations: {
        add: selectedDtwinsUids,
      },
    };
    const response = await fleetService.updateFleet(
      selectedFleet.value.uid,
      fleetUpdatePayload,
    );
    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(
        t("fleet.attachDevices.updateSuccess", {
          count: selectedDtwinsUids.length,
        }),
      );
      onClose();
      emit("attachedToFleet");
    }
  }

  isSaving.value = false;
}

function onClose() {
  showFleetForm.value = false;
  selectedFleet.value = undefined;
  fleetCreateFormRef.value.onClose();
}

function onFleetCreated() {
  if (props.dtwinList) {
    slideMenuRef.value.slideBackToMenu(createFleetFormPanel.htmlId);
    loadData();
  } else {
    onClose();
    emit("reloadFleetList", true);
  }
}

function onUpdateDisplayedPanel(panel: string | null) {
  currentPanel.value = panel;
}

onMounted(() => {
  // Do not execute loadData callback in no device list given.
  // Indeed, if `props.dtwinList` is undefined, it means the fleet creation form is displayed. In that case, no need
  // to load fleet.
  onSearch(loadData, props.dtwinList !== undefined);
  disabledValidate(true);
});
</script>
<template>
  <lxc-side-canvas
    v-model:show="showFleetForm"
    :header="
      dtwinList
        ? t('fleet.form.header.addDevices')
        : t('fleet.form.header.createFleet')
    "
    body-class="!py-0 !px-0"
    @hidden="onClose"
    @discard="onDiscardSideCanvas"
  >
    <lxc-slide-menu
      ref="slideMenuRef"
      v-model:display-menu="displayForm"
      :menu-items="sidePanels"
      no-default-slide-button
      @update:displayed-panel="onUpdateDisplayedPanel"
    >
      <template #customContent>
        <container-component v-if="dtwinList" :px="0" :py="0" :error="error">
          <div class="mb-[1.188rem]">
            <search-bar
              v-model:search-query="searchQuery"
              :search-placeholder="t('filters.searchByName')"
              @clear="search"
              @search="search"
            />
          </div>
          <lxc-button
            :title="t('fleet.form.newFleet')"
            type="borderless"
            :icon="ILxcPlus"
            class="text-base mb-4 !justify-start text-primary"
            @click="slideToFleetCreationForm(createFleetFormPanel.htmlId)"
          >
            {{ t("fleet.form.newFleet") }}
          </lxc-button>

          <container-component :px="0" :py="0" :is-loading="isLoading">
            <lxc-button
              v-for="fleet in results?.results"
              :key="fleet.uid"
              :title="fleet.friendlyName"
              type="borderless"
              :class="getSelectFleetButtonClass(fleet.uid)"
              @click.prevent="onSelectFleet(fleet)"
            >
              {{ fleet.friendlyName }}
            </lxc-button>
          </container-component>
        </container-component>

        <fleet-form-create
          v-else
          ref="fleetCreateFormRef"
          @saved="onFleetCreated"
          @is-saving="(value: boolean) => (isSaving = value)"
          @is-validate-disabled="disabledValidate"
        />
      </template>

      <template #[createFleetFormPanel.id]>
        <fleet-form-create
          v-if="dtwinList"
          ref="fleetCreateFormRef"
          :dtwins-uids="dtwinsUid"
          @is-saving="(value: boolean) => (isSaving = value)"
          @saved="onFleetCreated"
          @is-validate-disabled="disabledValidate"
        />
      </template>
    </lxc-slide-menu>

    <template #footer>
      <fleet-form-footer
        v-if="currentPanel"
        :close-button-label="t('button.close')"
        :validate-button-label="t(`fleet.form.createFleet.validate`)"
        :disabled-validate-button="isValidateDisabled"
        :is-loading="isSaving"
        @close="onClose"
        @validate="createFleet"
      />
      <fleet-form-footer
        v-if="!currentPanel"
        :close-button-label="t('button.close')"
        :validate-button-label="
          dtwinList ? t('button.add') : t('fleet.form.createFleet.validate')
        "
        :disabled-validate-button="
          dtwinList ? isAddDevicesDisabled || isSaving : isValidateDisabled
        "
        :is-loading="isSaving"
        :context="results?.pagination"
        @close="onClose"
        @validate="dtwinList ? attachToFleet() : createFleet()"
        @change-page-and-page-size="loadData"
      />
    </template>
  </lxc-side-canvas>
</template>
<style scoped lang="scss">
:deep(button) {
  svg {
    width: 20px;
    height: 20px;
  }
}
:deep(.fleet-button) {
  span {
    text-align: start;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }
}
:deep(.slide-menu) {
  padding-top: 1.5rem;
  :nth-child(1) {
    overflow-y: visible;
  }
}
:deep(.menu-item) {
  padding-top: 1.5rem;
  :nth-child(1) {
    :nth-child(2) {
      overflow-y: visible;
    }
  }
}
</style>
