import { useFetchPage } from "~/core/composables/useFetchPage";
import { type SearchMode, useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import {
  Filters,
  type FiltersSelection,
  FiltersType,
  OPERATOR_RSQL_ILIKE,
  OPERATOR_RSQL_IN,
} from "~/core/models/filters";
import OperationManagerService from "~/core/services/operationManager.service";
import filtersUtils from "~/core/utils/filters.utils";

export function useOperationManager(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = OperationManagerService.getOperations.bind(
    OperationManagerService,
  );

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.OPERATION_CAMPAIGN_UID,
      {
        key: "campaignUid",
        operator: OPERATOR_RSQL_IN,
        value: [],
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
    [
      Filters.DTWIN_UID,
      {
        key: "deviceTwinUid",
        operator: OPERATOR_RSQL_IN,
        value: "",
      },
    ],
    [
      Filters.DTWIN_OPERATION_MODEL_TYPE,
      {
        key: "type",
        operator: OPERATOR_RSQL_IN,
        value: "",
      },
    ],
    [
      Filters.DTWIN_OPERATION_SERVICE_ORIGIN,
      {
        key: "serviceOrigin",
        operator: OPERATOR_RSQL_IN,
        value: "",
      },
    ],
    [
      Filters.DTWINS_CONFIGURATION_VERSIONS,
      {
        key: [
          "customInputParameters.selfSupportingVersion",
          "customInputParameters.globalVersion",
          "specificVersion",
        ],
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
        valueFormatter: filtersUtils.formatRSQLValue,
      },
    ],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
  });

  return {
    isLoading,
    error,
    results,
    filters,
    setFilter,
    fetchData,
    onSortChange,
    search,
    onSearch,
    sort,
  };
}
