export enum OperationState {
  PENDING = "pending",
  RUNNING = "running",
  RETRY = "retry",
  DONE = "done",
  FAILED = "failed",
  CANCELLING = "cancelling",
  CANCELLED = "cancelled",
  EXPIRED = "expired",
  VOID = "void",
}
