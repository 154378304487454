<script setup lang="ts">
import type { Ref } from "vue";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import { PATHS } from "~/core/constants/paths";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import LxcError from "~/core/utils/LxcError";
import type { Sector } from "~/modules/sector/models/Sector.interface";
import type { UserGroup } from "~/modules/user/models/UserGroup.interface";
import userGroupService from "~/modules/user/services/userGroup.service";

const { t } = useI18n();
const router = useRouter();

const userGroups: Ref<null | ApiListResult<UserGroup>> = ref(null);
const searchQuery = ref<string>("");
const error: Ref<LxcError | null> = ref(null);

const isLoading = ref(true);

/**
 * Retrieve userGroups
 * @param page
 * @param pageSize
 */
async function getUserGroups(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  isLoading.value = true;
  error.value = null;

  const userGroupsResponse = await userGroupService.getUserGroups(
    page,
    pageSize,
    searchQuery.value,
  );

  if (LxcError.check(userGroupsResponse)) {
    error.value = userGroupsResponse;
  } else {
    userGroups.value = userGroupsResponse;
  }

  isLoading.value = false;
}

/**
 * Redirect when selecting à specific userGroup
 * @param userGroup
 */
function onSelectUserGroup(userGroup: UserGroup) {
  router.push(`${PATHS.USER_MANAGEMENT_GROUPS}/${userGroup.code}`);
}

async function reloadWithContext() {
  await getUserGroups(
    userGroups.value?.context?.page,
    userGroups.value?.context?.pageSize,
  );
}

async function performSearch() {
  await getUserGroups(DEFAULT_FIRST_PAGE, userGroups.value?.context?.pageSize);
}

function getSectorLabels(sectors: Sector[]) {
  return sectors.map((sector) => sector.label);
}

onMounted(getUserGroups);
</script>

<template>
  <search-bar
    v-model:search-query="searchQuery"
    :search-placeholder="t('userGroup.search')"
    class="grow shrink basis-0"
    @clear="performSearch"
    @search="performSearch"
  />

  <container-component :px="0" :py="4">
    <lxc-table
      min-width="45rem"
      :is-loading="isLoading"
      :data="userGroups?.data"
      :empty-text="t('userGroup.empty')"
      :context="userGroups?.context"
      :error="error?.toError()"
      clickable
      class="mt-2"
      @row-click="onSelectUserGroup"
      @change-page-and-page-size="getUserGroups"
    >
      <lxc-table-column
        prop="label"
        :label="t('table.header.label')"
        min-width="15rem"
      />
      <lxc-table-column
        class="!py-0"
        :label="t('table.header.sectors')"
        min-width="12rem"
      >
        <template #default="scope">
          <tag-for-list :tags="getSectorLabels(scope.row.sectors)" :max="2" />
        </template>
      </lxc-table-column>
      <lxc-table-column
        align="right"
        prop="nbUsers"
        :label="t('table.header.nbUsers')"
        width="12rem"
      />
      <lxc-table-column class="!py-0" width="6rem">
        <template #default="scope">
          <div class="flex gap-2">
            <user-group-list-actions
              :user-group="scope.row"
              @change="reloadWithContext"
            />
          </div>
        </template>
      </lxc-table-column>
    </lxc-table>
  </container-component>
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
