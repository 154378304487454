<script setup lang="ts">
import { FeaturesName } from "~/core/models/FeaturesName.enum";
import { useLicenseStatus } from "~/modules/license/composables/useLicenseStatus";
import { useLicenseStatusStore } from "~/modules/license/stores/useLicenseStatusStore";

const props = defineProps<{
  context?: FeaturesName;
}>();
const emit = defineEmits([
  "isBeyondExpirationDate",
  "isBeyondLimitOfAllDeviceType",
]);

const { t } = useI18n();
const {
  isBeyondExpirationDate,
  isBeyondLimitOfAllDeviceType,
  licenseExpirationDateWarning,
  featureBeyondLimitWarnings,
  getLicenseStatus,
  checkWarnings,
} = useLicenseStatus();
const licenseStatusStore = useLicenseStatusStore();

const featureBeyondLimitWarningsToDisplay = computed(() => {
  switch (props.context) {
    case FeaturesName.ADVANCED_FLEET_MGT:
      return featureBeyondLimitWarnings.value.filter(
        (feature) => feature.featureName === FeaturesName.ADVANCED_FLEET_MGT,
      );
    case FeaturesName.DATA_SHARING:
      return featureBeyondLimitWarnings.value.filter(
        (feature) => feature.featureName === FeaturesName.DATA_SHARING,
      );
    default:
      return featureBeyondLimitWarnings.value;
  }
});

const licenseExpirationDateWarningClass = computed(() => {
  return featureBeyondLimitWarnings.value.length > 0
    ? ["mt-3", "mx-3"]
    : ["mt-3"];
});

const featureBeyondLimitWarningsClass = computed(() => {
  return licenseExpirationDateWarning.value ? ["mt-3", "mx-3"] : ["mb-6"];
});

onMounted(getLicenseStatus);
watch(
  () => licenseStatusStore.licenseStatus,
  async () => {
    await checkWarnings();
    emit("isBeyondExpirationDate", isBeyondExpirationDate.value);
    emit("isBeyondLimitOfAllDeviceType", isBeyondLimitOfAllDeviceType.value);
  },
);
</script>

<template>
  <div
    v-if="licenseExpirationDateWarning"
    :class="licenseExpirationDateWarningClass"
  >
    <lxc-alert :type="licenseExpirationDateWarning.alertType">
      <template #title>
        {{ t("license.caracteristics.date.end") }}
      </template>
      <p>{{ licenseExpirationDateWarning.message }}</p>
      <p>{{ t("license.warningMessages.contactCutomerService") }}</p>
    </lxc-alert>
  </div>
  <div
    v-if="featureBeyondLimitWarningsToDisplay?.length > 0"
    :class="featureBeyondLimitWarningsClass"
  >
    <lxc-alert
      v-for="featureBeyondLimitWarning in featureBeyondLimitWarningsToDisplay"
      :key="
        featureBeyondLimitWarningsToDisplay.indexOf(featureBeyondLimitWarning)
      "
      :type="featureBeyondLimitWarning.alertType"
    >
      <template #title>
        {{
          t(`license.content.feature.${featureBeyondLimitWarning.featureName}`)
        }}
      </template>
      <p>{{ featureBeyondLimitWarning.message }}</p>
      <p>{{ t("license.warningMessages.contactCutomerService") }}</p>
    </lxc-alert>
  </div>
</template>
