import RestService from "../core/services/rest.service";
import type {
  ApiListResult,
  DeviceModelConfigI,
  DeviceModelUiConfigI,
  DtwinI,
  DtwinLifeCycleState,
  DtwinModelI,
  ImportMode,
  ImportResultsI,
  PaginatedListI,
} from "@lxc/app-device-types";
import type { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { ContentTags } from "~/core/models/ContentTags";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import type { DtwinAddConfigurationPayload } from "~/modules/dtwin/models/DtwinAddConfigurationPayload.interface";
import type { DtwinConfigurationCreateContentResourceResponse } from "~/modules/dtwin/models/DtwinConfigurationCreateContentResourceResponse.interface";
import type { DtwinConfigurationUploadPayloadCreateContentResource } from "~/modules/dtwin/models/DtwinConfigurationUploadPayloadCreateContentResource.interface";
import type { FiltersSelection } from "~/types";

export class DtwinsService extends RestService {
  protected BASE_URL = "/dtwins";
  protected PROXY_URL_DTWINS = "/proxy?serviceId=DTWINS";
  protected PROXY_URL_DATAM = "/proxy?serviceId=DATAM";

  /**
   * Retrieve a device model by its ID
   * @param id
   * @returns
   */
  getModelById(id: string): Promise<DtwinModelI> {
    const target = `deviceModels/${id}`;
    return this.$api.get(this.PROXY_URL_DTWINS, { params: { target } });
  }

  /**
   * Fetch LPP4/LogUp devices.
   * @param page
   * @param pageSize
   * @param filter
   * @param sort
   * @param additionalParams
   */
  async getDtwins(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
    additionalParams?: Map<string, unknown>,
  ): Promise<ApiListResult<DtwinI>> {
    let filterWithCompatibilityCriteria = filter as string;
    const compatibilityCriteria = additionalParams?.get(
      "compatibilityCriteria",
    );
    if (compatibilityCriteria) {
      filterWithCompatibilityCriteria += `${filterWithCompatibilityCriteria ? ";" : ""}${compatibilityCriteria}`;
    }
    const params: Record<string, string> = {
      page: page.toString(),
      pageSize: pageSize.toString(),
      filter: filterWithCompatibilityCriteria,
    };

    if (sort) {
      params["sort"] = sort;
    }

    const config: AxiosRequestConfig = {
      params,
    };

    return await this.$api
      .get(`${this.BASE_URL}`, config)
      .then((response) => {
        if (
          !(response as unknown as PaginatedListI<DtwinI>).pagination &&
          !(response as unknown as PaginatedListI<DtwinI>).results
        ) {
          return response;
        }
        return {
          context: (response as unknown as PaginatedListI<DtwinI>).pagination,
          data: (response as unknown as PaginatedListI<DtwinI>).results,
        };
      })
      .catch((error) => error);
  }

  /**
   * Fetch device types
   * @returns
   */
  async getAllDtwinModels(): Promise<DtwinModelI[]> {
    return this.$api.get(`${this.BASE_URL}/models`);
  }

  /**
   * Retrieve device
   * @param id
   * @param showMetadata If set to true, ask the dtwin to add the device metadata. Default: false.
   * @param addOperationStatuses If set true, AS will add last operations statuses to the Dtwin object.
   */
  getDtwin(
    id: string,
    showMetadata?: boolean,
    addOperationStatuses?: boolean,
  ): Promise<Dtwin> {
    return this.$api.get(`${this.BASE_URL}/${id}`, {
      params: {
        showMetadata,
        addOperationStatuses,
      },
    });
  }

  /**
   * Update device properties
   * @param uid
   * @param data
   */
  patchDtwin(uid: string, data: Record<string, any>): Promise<void> {
    return this.$api.patch(`${this.PROXY_URL_DTWINS}`, data, {
      params: {
        target: `deviceTwins/${uid}/attributes`,
      },
    });
  }

  /**
   * Import devices
   */
  import(
    file: File,
    progress: Ref<number | undefined | null>,
    analyze: ImportMode,
  ): Promise<ImportResultsI> {
    const formData = new FormData();
    formData.append("file", file);
    return this.$api.post(`${this.BASE_URL}/import?mode=${analyze}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total
          ? (progressEvent.loaded / progressEvent.total) * 100
          : 0;
      },
      timeout: Infinity,
    });
  }

  /**
   * Retrieve device configuration
   * @param id
   * @returns
   */
  getDtwinTelemetries(id: string): Promise<any> {
    return this.$api.get(`${this.PROXY_URL_DTWINS}`, {
      params: { target: `deviceTwins/${id}/dataviz/configuration` },
    });
  }

  getDtwinTelemetryValues(
    id: string,
    name: string,
    startTimestampNs: number,
    endTimestampNs: number,
    calculatedTelemetry: boolean,
  ): Promise<any> {
    return this.$api.get(this.PROXY_URL_DATAM, {
      params: {
        target: `devices/${id}/telemetry/${name}?startDateTime=${startTimestampNs}&endDateTime=${endTimestampNs}&calculatedTelemetry=${calculatedTelemetry}`,
      },
    });
  }

  getAllDeviceModelConfigs(): Promise<DeviceModelConfigI[]> {
    return this.$api.get(`${this.BASE_URL}/deviceModelConfigs`);
  }

  async updateDeviceLifeCycleState(
    uid: string,
    lifeCycleState: DtwinLifeCycleState,
  ): Promise<void> {
    return this.$api.put(
      `${this.BASE_URL}/${uid}/lifeCycleState/${lifeCycleState}`,
    );
  }

  async getDeviceModelUiConfigByDeviceModelUid(
    deviceModelUid: string,
  ): Promise<DeviceModelUiConfigI> {
    return this.$api.get(
      `${this.BASE_URL}/deviceModels/${deviceModelUid}/uiConfig`,
    );
  }

  async getAllDeviceModelUiConfigs(): Promise<DeviceModelUiConfigI[]> {
    return this.$api.get(`${this.BASE_URL}/deviceModelUIConfigs`);
  }

  /**
   * Upload a configuration file
   * @param file
   * @param progress
   * @param dtwinUid
   */
  async uploadConfigurationFile(
    file: File,
    progress: Ref<number | undefined | null>,
    dtwinUid: string,
  ): Promise<DtwinConfigurationCreateContentResourceResponse> {
    const formData = new FormData();
    formData.append("file0", file);
    const contentResource: DtwinConfigurationUploadPayloadCreateContentResource =
      {
        name: file.name,
        deviceTwinUid: dtwinUid,
        temporary: true,
        files: [
          {
            name: file.name,
          },
        ],
        tags: [ContentTags.CONFIGURATION],
      };
    const contentResourceBlob = new Blob([JSON.stringify(contentResource)], {
      type: "application/json",
    });
    formData.append("contentResource", contentResourceBlob);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total
          ? (progressEvent.loaded / progressEvent.total) * 100
          : 0;
      },
      timeout: Infinity,
    };

    return this.$apiV3.post(`/contents`, formData, config);
  }

  async addConfigurationFile(
    contentResourceUid: string,
    configurationForm: Record<string, string>,
  ): Promise<void> {
    const patchConfigurationPayload: DtwinAddConfigurationPayload = {
      descriptor: configurationForm,
      temporary: false,
    };

    return this.$apiV3.patch(
      `/contents/${contentResourceUid}`,
      patchConfigurationPayload,
    );
  }
}

export default new DtwinsService();
