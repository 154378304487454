<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useDashboard } from "~/modules/dashboard/composables/useDashboard";
import { useSectorStore } from "~/modules/sector/stores/useSectorStore";

const {
  canManageFirmwares,
  dashboardLoadingStatus,
  dashboard,
  fetchDashboard,
  fetchFirmwareStatistics,
  firmwareStatistics,
  firmwareStatisticsStatus,
} = useDashboard();

const { selectedSectorCodes } = storeToRefs(useSectorStore());

const percentageTotal = computed<number | undefined>(() => {
  const certificates = dashboard?.value?.certificates;
  const { countCertificatesValid, countCertificatesExpired } = [
    certificates?.s4Device,
    certificates?.s4Manager,
    certificates?.s4Tools,
    certificates?.s4View,
  ].reduce(
    (totals, cert) => {
      if (cert) {
        totals.countCertificatesValid += cert.valid + cert.expiredSoon;
        totals.countCertificatesExpired += cert.expired;
      }

      return totals;
    },
    { countCertificatesValid: 0, countCertificatesExpired: 0 },
  );

  if (countCertificatesValid > 0 || countCertificatesExpired > 0) {
    return Math.round(
      (countCertificatesValid * 100) /
        (countCertificatesValid + countCertificatesExpired),
    );
  }
});

async function fetchDashboardAndFirmwareStatistics() {
  return await Promise.all([
    fetchDashboard(),
    canManageFirmwares.value ? fetchFirmwareStatistics() : null,
  ]);
}

onMounted(async () => {
  await fetchDashboardAndFirmwareStatistics();
});

watch(
  () => selectedSectorCodes.value,
  async () => {
    await fetchDashboardAndFirmwareStatistics();
  },
);
</script>

<template>
  <page-component>
    <lxc-license-warning-messages />

    <el-row class="row-separator-gap" :gutter="10" justify="space-evenly">
      <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
        <el-row :gutter="10" class="row-separator-gap">
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <dashboard-tile-certificates-valid
              :status="dashboardLoadingStatus"
              :percentage="percentageTotal"
            />
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <dashboard-tile-my-actions :actions="dashboard?.actions" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
            <dashboard-tile-services-activation />
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <dashboard-tile-certificates-details
              :status="dashboardLoadingStatus"
              :campaign="dashboard?.certificates"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
        <dashboard-tile-sector
          class="sector-tile"
          :status="dashboardLoadingStatus"
          :sector="dashboard?.sector"
        />
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col>
        <dashboard-tile-firmware
          v-if="canManageFirmwares"
          :status="firmwareStatisticsStatus"
          :firmwares="firmwareStatistics"
        />
      </el-col>
    </el-row>
  </page-component>
</template>

<style lang="scss" scoped>
.row-separator-gap {
  gap: 10px 0;
}

.el-row {
  margin-bottom: 10px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.sector-tile {
  height: 100%;
}
</style>
