<script setup lang="ts">
import type { UploadUserInformationI } from "~/core/models/UploadFile.interface";
import uploadUtils from "~/core/utils/upload.utils";
import ILxcInfo from "~icons/lxc/info";

const props = defineProps<{
  files?: FileList | null;
  userInformation?: UploadUserInformationI;
  fileFormats?: string[];
  filesUploadError?: Error | null;
  filesProgress?: number | null;
  disabled?: boolean;
}>();

const { locale, t } = useI18n();

const emits = defineEmits([
  "clearUploadStatus",
  "update:files",
  "update:filesUploadError",
  "update:filesProgress",
]);

const accept = computed(() => {
  return props?.fileFormats?.join(",");
});
const fileFormatsLabel = computed(() => {
  return props.fileFormats?.map((format) => format.toUpperCase()).join(", ");
});

const filesComputed = computed({
  get: () => {
    return props.files;
  },
  set: (newFiles: FileList | undefined | null) => {
    emits("update:files", newFiles);
  },
});
const filesUploadErrorComputed = computed({
  get: () => {
    return props.filesUploadError;
  },
  set: (newValue: Error | undefined | null) => {
    emits("update:filesUploadError", newValue);
  },
});
const filesProgressComputed = computed({
  get: () => {
    return props.filesProgress;
  },
  set: (newValue: number | undefined | null) => {
    emits("update:filesProgress", newValue);
  },
});

function clearUploadStatus() {
  filesUploadErrorComputed.value =
    filesUploadErrorComputed.value !== undefined ? null : undefined;
  filesProgressComputed.value = null;

  if (filesComputed.value?.length) {
    emits("clearUploadStatus");
  }
}

/**
 * Clear the upload form and reset the reference of the file to upload
 */
function clearUpload() {
  filesComputed.value = null;
}

watch(() => filesComputed.value, clearUploadStatus);

defineExpose({ clearUpload });
</script>

<template>
  <div>
    <lxc-information v-if="userInformation" class="mt-0 mb-4 !p-0">
      <template #component>
        <lxc-info-sign>
          <i-lxc-info
            width="1.25rem"
            height="1.25rem"
            view-box="0 0 1.25rem 1.25rem"
          />
        </lxc-info-sign>
      </template>
      <template #body>
        <h6 v-if="userInformation.title">{{ userInformation.title }}</h6>
        <span>{{ userInformation.description }}</span>
      </template>
    </lxc-information>
    <lxc-input
      v-model="filesComputed"
      type="file"
      :accept="accept"
      :delete-tooltip="t('input.deleteFile')"
      :error="filesUploadErrorComputed"
      :locale="locale"
      :percentage="filesProgressComputed"
      :size-unit="uploadUtils.getUnitTranslations()"
      @blur="clearUploadStatus"
    >
      <template #placeholder>
        <div class="mx-8">
          <span class="text-primary-700 font-bold">
            {{ t("input.clickToUpload") }}</span
          >&nbsp;
          <span>
            {{ t("input.orDragAndDrop", { format: fileFormatsLabel }) }}
          </span>
        </div>
      </template>
    </lxc-input>
  </div>
</template>
