<script setup lang="ts">
import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import fleetService from "~/modules/fleet/services/fleet.service";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";

const props = defineProps<{
  fleetUid: string;
}>();
const emits = defineEmits(["deleted"]);

const { t } = useI18n();
const acl = useAcl();

const isAllowedToManageFleet = computed(() => acl.can(ACLRoles.DVTM_DVT_ADM));
const isDeletingFleet: Ref<boolean> = ref(false);
const showConfirm: Ref<boolean> = ref(false);

async function onDeleteFleet() {
  isDeletingFleet.value = true;
  showConfirm.value = false;

  const response = await fleetService.deleteFleet(props.fleetUid);

  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError);
  } else {
    showNotificationSuccess(t(NotificationKey.removeSuccess));
    emits("deleted");
  }

  isDeletingFleet.value = false;
}

const onDeleteFleetClick = () => {
  showConfirm.value = true;
};
</script>

<template>
  <lxc-button
    html-type="button"
    type="borderless"
    :disabled="!isAllowedToManageFleet"
    :icon="!isDeletingFleet ? ILxcLightTrash2 : undefined"
    :title="
      isAllowedToManageFleet
        ? t('fleet.delete.title')
        : t('fleet.actionForbidden')
    "
    class="invisible"
    @click.stop.prevent="onDeleteFleetClick"
  >
    <lxc-loader v-if="isDeletingFleet" />
  </lxc-button>
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('fleet.delete.title')"
    :description="t('fleet.delete.confirm.message')"
    :ok-label="t('button.confirm')"
    :cancel-label="t('button.cancel')"
    :icon="ILxcLightTrash2"
    icon-color-theme="error"
    color-theme="danger"
    @confirm="onDeleteFleet"
    @cancel="showConfirm = false"
  />
</template>
