import { useSerialize } from "@lxc/app-device-common/src/composables/useSerialize";
import type { SectorI } from "@lxc/app-device-types";
import { computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import type {
  FilterSelectionDefinition,
  FilterSelectionValue,
  FiltersSelection,
} from "~/types";
import { Filters, FiltersType } from "~/types";

export enum SearchMode {
  /**
   * Use query parameters to set/store the filters and execute the search
   * Default search mode
   */
  URL_SEARCH,

  /**
   * Use the local filters only to store and execute the search
   */
  FILTER_SEARCH,
}

export function useSearch(
  filterType: FiltersType,
  appliedFilters?: FiltersSelection,
  searchMode: SearchMode = SearchMode.URL_SEARCH,
) {
  const route = useRoute() ?? {};
  const router = useRouter() ?? {};
  const serialize = useSerialize();

  const filters = reactive<FiltersSelection>(appliedFilters ?? new Map());

  let onSearchCallback: () => void;

  const searchParams = computed(() => getSearchParams());

  // watch the route changes and set the filters, then execute the search
  if (searchMode === SearchMode.URL_SEARCH) {
    watch(route, () => {
      if (setFiltersFromQueryParams() && onSearchCallback) {
        onSearchCallback();
      }
    });
  }

  // Store the route path at first use
  const routePath = route.path;

  // Store the timestamp of the last search from query parameters
  let lastSearchQueryParamsTimestamp: string;

  /**
   * Set the filters from query parameter values
   * @return true if the filters have been updated from query parameter values, false otherwise
   */
  function setFiltersFromQueryParams(): boolean {
    // Check if the current route path is the same as the initial one, otherwise this is another page so no need to apply search
    if (route.path === routePath) {
      let filtersChanged = false;

      // Set all the filters from query parameters
      for (const [filter, filterSelectionDefinition] of filters) {
        const filterValue = toRaw(filterSelectionDefinition.value);
        const isFilterValueArray = Array.isArray(filterValue);
        const param: string = isFilterValueArray ? `${filter}[]` : filter;

        let paramValue;

        if (route.query[param] !== undefined) {
          paramValue = route.query[param];
          paramValue =
            isFilterValueArray && !Array.isArray(paramValue)
              ? [paramValue]
              : paramValue;
        } else {
          paramValue = isFilterValueArray ? [] : "";
        }

        if (serialize(paramValue) !== serialize(filterValue)) {
          setFilter(filter, paramValue as FilterSelectionValue);
          filtersChanged = true;
        }
      }

      if (
        filtersChanged ||
        (route.query.t && lastSearchQueryParamsTimestamp !== route.query.t)
      ) {
        lastSearchQueryParamsTimestamp = route.query.t as string;
        return true;
      }
    }

    return false;
  }

  /**
   * Set a filter
   * @param filter
   * @param value
   * @param fallbackValue
   */
  function setFilter(
    filter: Filters,
    value: FilterSelectionValue,
    fallbackValue?: FilterSelectionValue | undefined,
  ): void {
    const filtersSelection: FilterSelectionDefinition | undefined =
      filters.get(filter);

    if (filtersSelection) {
      filtersSelection.value = value;
      filtersSelection.fallbackValue = fallbackValue;
    }
  }

  /**
   * Execute a search with the filters set
   * @param replaceHistory if true, replace the current entry in the history stack
   *                       only available for searchMode = SearchMode.URL_SEARCH
   *                       default = false
   */
  async function search(replaceHistory = false) {
    // Default case => URL_SEARCH
    if (searchMode === SearchMode.URL_SEARCH) {
      // Update the URL parameters, the search will be launched by the route watcher
      const query = { ...route.query };
      let hasFiltersBeenApplied = false;
      filters.forEach(
        (
          filterSelectionDefinition: FilterSelectionDefinition,
          filter: Filters,
        ) => {
          if (Array.isArray(filterSelectionDefinition.value)) {
            if (filterSelectionDefinition.value.length === 0) {
              delete query[`${filter}[]`];
            } else {
              query[`${filter}[]`] = filterSelectionDefinition.value;
              hasFiltersBeenApplied = true;
            }
          } else if (filterSelectionDefinition.value === "") {
            delete query[filter];
          } else {
            query[filter] = filterSelectionDefinition.value;
            hasFiltersBeenApplied = true;
          }
        },
      );

      if (query.t || hasFiltersBeenApplied) {
        query.t = Date.now().toString();
      } else {
        delete query.t;
      }

      // The query parameters change => update the route
      if (serialize(route.query) !== serialize(query)) {
        // Reset the page to the first one otherwise the search result can be empty
        query.page = DEFAULT_FIRST_PAGE.toString();
        query.pageSize = query.pageSize ?? DEFAULT_PAGE_SIZE.toString();

        const newRoute = {
          path: route.path,
          query,
        };

        if (replaceHistory) {
          await router.replace(newRoute);
        } else {
          await router.push(newRoute);
        }
      }
      // The query parameters do not change => directly call the search callback to apply refresh
      else if (onSearchCallback) {
        onSearchCallback();
      }
    }
    // Case FILTER_SEARCH
    else if (onSearchCallback) {
      onSearchCallback();
    }
  }

  /**
   * Execute the search with filters set and the provided sectors
   * @param selectedSectors
   * @param replaceHistory
   */
  async function searchOnSectors(
    selectedSectors: SectorI[],
    replaceHistory?: boolean,
  ) {
    // set filter on selected sectors
    setFilter(
      Filters.SECTORS,
      selectedSectors.map((sector) => sector.code),
    );

    // call search in order to update the query parameters with filters
    await search(replaceHistory);
  }

  /**
   * Define the function to call on search execution
   * By default, execute the callback at the same time except if executeCallback = false
   * @param callback function to call on search
   * @param executeCallback true = execute the callback function
   */
  function onSearch(callback: () => void, executeCallback = true) {
    onSearchCallback = callback;

    // set the filters from query parameters, this is useful when accessing to a page with filters in query parameters
    if (searchMode === SearchMode.URL_SEARCH) {
      setFiltersFromQueryParams();
    }
    if (executeCallback) {
      onSearchCallback();
    }
  }

  /**
   * Build the query of filters depending on the filter type
   * @returns the formatted query
   */
  function getSearchParams(): string | FiltersSelection {
    if (filterType === FiltersType.RAW) {
      return filters.get(Filters.NAME)?.value as string;
    }

    // get the filters which have a defined value (not empty string or empty array)
    const filtersWithValue: FilterSelectionDefinition[] = [
      ...filters.values(),
    ].filter(
      (filterSelectionDefinition) =>
        filterSelectionDefinition.value.length ||
        filterSelectionDefinition.fallbackValue?.length,
    );

    if (filterType === FiltersType.FILTERS_SELECTION) {
      return initSearchFilterSelection();
    }

    let orFilterFormatter: (formattedFilters: string[]) => string;
    let arrayValueFormatter: (value: string[]) => string;
    let andSeparator: string;

    const mapFormatFilter = (filter: FilterSelectionDefinition) =>
      formatFilter(filter, orFilterFormatter, arrayValueFormatter);

    switch (filterType) {
      case FiltersType.TWO_AMPERSAND_SEPARATOR:
        orFilterFormatter = (formattedFilters: string[]) =>
          `(${formattedFilters.join("||")})`;
        arrayValueFormatter = (values: string[]) => `[${values.join(",")}]`;
        andSeparator = "&&";

        return filtersWithValue.map(mapFormatFilter).join(andSeparator);
      case FiltersType.PIPE_SEPARATOR:
        orFilterFormatter = (formattedFilters: string[]) =>
          `(${formattedFilters.join("|'")})`;
        arrayValueFormatter = (values: string[]) => `[${values.join(",")}]`;
        andSeparator = "|";

        return `(${filtersWithValue.map(mapFormatFilter).join(andSeparator)})`;
      case FiltersType.RSQL:
        orFilterFormatter = (formattedFilters: string[]) =>
          `(${formattedFilters.join(",")})`;
        arrayValueFormatter = (values: string[]) => `(${values.join(",")})`;
        andSeparator = ";";

        return filtersWithValue.map(mapFormatFilter).join(andSeparator);
    }

    return "";
  }

  /**
   * Transform a FilterSelectionDefinition to a formatted string query
   * @param filterSelectionDefinition
   * @param orFilterFormatter
   * @param arrayValueFormatter
   * @returns query as string
   */
  function formatFilter(
    filterSelectionDefinition: FilterSelectionDefinition,
    orFilterFormatter: (formattedFilters: string[]) => string,
    arrayValueFormatter: (value: string[]) => string,
  ): string {
    // case when the key is an array => it is considered to be an OR query => format the filter for each key recursively
    if (Array.isArray(filterSelectionDefinition.key)) {
      const formattedValues = filterSelectionDefinition.key.map((key) =>
        formatFilter(
          { ...filterSelectionDefinition, key },
          orFilterFormatter,
          arrayValueFormatter,
        ),
      );
      return orFilterFormatter(formattedValues);
    } else {
      let value =
        filterSelectionDefinition.value ??
        filterSelectionDefinition.fallbackValue ??
        "";

      if (filterSelectionDefinition.valueFormatter) {
        value = filterSelectionDefinition.valueFormatter(value);
      } else if (Array.isArray(value)) {
        value = arrayValueFormatter(value);
      }

      return `${filterSelectionDefinition.key}${filterSelectionDefinition.operator}${value}`;
    }
  }

  function initSearchFilterSelection(): FiltersSelection {
    const searchFilterSelection: FiltersSelection = new Map<
      Filters,
      FilterSelectionDefinition
    >();
    const searchKeys = Array.from(filters.keys()).filter(
      (filterName: Filters) => {
        const definition = filters.get(filterName);
        return !!definition?.value?.length;
      },
    );
    for (const filterName of searchKeys) {
      searchFilterSelection.set(
        filterName,
        filters.get(filterName) as FilterSelectionDefinition,
      );
    }
    return searchFilterSelection;
  }

  return {
    searchParams,
    filters,
    setFilter,
    onSearch,
    search,
    searchOnSectors,
  };
}
