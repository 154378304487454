import dayjs from "dayjs";
import { CampaignOMStatus } from "~/modules/campaign/models/CampaignOMStatus.enum";
import { CampaignState } from "~/modules/campaign/models/CampaignState.enum";

export enum Step {
  Creation,
  CreationToLaunch,
  Launch,
  LaunchToClosing,
  Closing,
}

export function computeStep(
  campaignPlannedStartAt?: string,
  campaignPlannedEndAt?: string,
  campaignState?: CampaignState | CampaignOMStatus,
) {
  let step = Step.Creation;
  const now = dayjs();
  const plannedStartAt = dayjs(campaignPlannedStartAt);
  const plannedEndAt = dayjs(campaignPlannedEndAt);

  if (campaignState) {
    if (
      now < plannedStartAt &&
      [
        CampaignState.SCHEDULED,
        CampaignState.PAUSED,
        CampaignOMStatus.SCHEDULED,
      ].includes(campaignState)
    ) {
      step = Step.Creation;
    } else if (
      [CampaignState.SCHEDULED, CampaignOMStatus.SCHEDULED].includes(
        campaignState,
      )
    ) {
      step = Step.CreationToLaunch;
    } else if (
      now < plannedEndAt &&
      [
        CampaignState.RUNNING,
        CampaignOMStatus.RUNNING,
        CampaignOMStatus.CANCELLING,
      ].includes(campaignState)
    ) {
      step = Step.Launch;
    } else if (
      [
        CampaignState.RUNNING,
        CampaignOMStatus.RUNNING,
        CampaignOMStatus.CANCELLING,
      ].includes(campaignState)
    ) {
      step = Step.LaunchToClosing;
    } else if (
      [
        CampaignState.COMPLETE,
        CampaignState.CANCELLED,
        CampaignState.COMPLETE_WITH_ERRORS,
        CampaignState.PLATFORM_ERROR,
        CampaignOMStatus.COMPLETE,
        CampaignOMStatus.CANCELLED,
        CampaignOMStatus.COMPLETE_WITH_ERRORS,
        CampaignOMStatus.INCOMPLETE,
      ].includes(campaignState)
    ) {
      step = Step.Closing;
    } else if (now > plannedStartAt && now < plannedEndAt) {
      step = Step.CreationToLaunch;
    } else if (now > plannedEndAt) {
      step = Step.LaunchToClosing;
    }
  } else {
    if (now < plannedStartAt) {
      step = Step.Creation;
    } else if (now > plannedStartAt && now < plannedEndAt) {
      step = Step.CreationToLaunch;
    } else if (now > plannedEndAt) {
      step = Step.LaunchToClosing;
    }
  }

  return step;
}
