<script setup lang="ts">
import dayjs from "dayjs";
import type { Campaign } from "~/modules/campaign/models/Campaign.interface";
import type { CampaignOperationMgr } from "~/modules/campaign/models/CampaignOperationMgr.interface";
import {
  computeStep,
  Step,
} from "~/modules/campaign/utils/campaignDetailTimelineComputation";
import ILxStepDone from "~icons/lxc-business/step-done";
import ILxStepInProgress from "~icons/lxc-business/step-in-progress";
import ILxStepToDo from "~icons/lxc-business/step-to-do";

const props = defineProps<{
  campaign?: Campaign | null;
  campaignOperationMgr?: CampaignOperationMgr;
}>();

const { t } = useI18n();

const formatCampaignDate: any = (date: string) =>
  date ? dayjs(date).format(t("dateFormat.date")) : "";

const step = computed(() => {
  return props.campaign
    ? computeStep(
        props.campaign?.config?.plannedStartAt,
        props.campaign?.config?.plannedEndAt,
        props.campaign?.state,
      )
    : computeStep(
        props.campaignOperationMgr?.launchPlannedAt,
        props.campaignOperationMgr?.expiredAt,
        props.campaignOperationMgr?.status,
      );
});
</script>

<template>
  <ol class="relative ml-2 border-l border-gray-200 dark:border-gray-700">
    <li class="mb-6 ml-6">
      <span
        class="absolute flex items-center justify-center w-5.5 h-5.5 rounded-full -left-3 ring-8 ring-white"
      >
        <ILxStepDone />
      </span>
      <div class="pt-0.5 font-medium">
        {{
          t("campaign.details.characteristics.lifeCyle.creation", {
            value: formatCampaignDate(
              campaign ? campaign?.createdAt : campaignOperationMgr?.createdAt,
            ),
          })
        }}
      </div>
    </li>
    <li class="mb-6 ml-6">
      <span
        class="absolute flex items-center justify-center rounded-full -left-3 ring-8 ring-white"
      >
        <ILxStepToDo v-if="step == Step.Creation" />
        <ILxStepInProgress
          v-if="step == Step.CreationToLaunch"
          class="w-6 h-6"
        />
        <ILxStepDone
          v-if="
            [Step.Launch, Step.LaunchToClosing, Step.Closing].includes(step)
          "
        />
      </span>
      <div class="pt-0.5 font-medium">
        {{
          t("campaign.details.characteristics.lifeCyle.launch", {
            value: formatCampaignDate(
              campaign
                ? campaign?.config?.plannedStartAt
                : campaignOperationMgr?.launchPlannedAt,
            ),
          })
        }}
      </div>
    </li>
    <li class="mb-6 ml-6">
      <span
        class="absolute flex items-center justify-center rounded-full -left-3 ring-8 ring-white"
      >
        <ILxStepToDo
          v-if="
            [Step.Creation, Step.CreationToLaunch, Step.Launch].includes(step)
          "
        />
        <ILxStepInProgress
          v-if="step == Step.LaunchToClosing"
          class="w-6 h-6"
        />
        <ILxStepDone v-if="step == Step.Closing" />
      </span>
      <div class="pt-0.5 font-medium">
        {{
          t("campaign.details.characteristics.lifeCyle.closing", {
            value: formatCampaignDate(
              campaign
                ? campaign?.config?.plannedEndAt
                : campaignOperationMgr?.expiredAt,
            ),
          })
        }}
      </div>
    </li>
  </ol>
</template>
