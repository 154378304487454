<script setup lang="ts">
import {
  OperationModelType,
  OperationServiceOrigin,
} from "@lxc/app-device-types";
import { useOperationManager } from "~/core/composables/useOperationManager";
import { SearchMode } from "~/core/composables/useSearch";
import { formatIsoDate } from "~/core/utils/date";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";
import { DtwinConfigurationType } from "~/modules/dtwin/models/DtwinConfigurationType.enum";
import { Filters } from "~/types";

const props = defineProps<{
  dtwin?: Dtwin | null;
}>();

const { t } = useI18n();

const {
  isLoading,
  error,
  results,
  filters,
  setFilter,
  fetchData,
  search,
  onSearch,
  sort,
} = useOperationManager(SearchMode.FILTER_SEARCH);

const dateFormat = t("operation.dateFormat");

const searchQuery = computed({
  get() {
    return (filters.get(Filters.DTWINS_CONFIGURATION_VERSIONS) ?? "") as string;
  },
  set(newQuery) {
    setFilter(Filters.DTWINS_CONFIGURATION_VERSIONS, newQuery);
  },
});

async function fetchOperations(page?: number, pageSize?: number) {
  if (props.dtwin?.uid) {
    setFilter(Filters.DTWIN_UID, props.dtwin.uid);
    setFilter(Filters.DTWIN_OPERATION_MODEL_TYPE, OperationModelType.CONFIG);
    setFilter(
      Filters.DTWIN_OPERATION_SERVICE_ORIGIN,
      OperationServiceOrigin.UNITARY,
    );
    sort.value = "-launchPlannedAt";
    await fetchData(page, pageSize);
  }
}

const isSelfSupportingConfiguration = computed(() => {
  return (
    props.dtwin?.configurationType === DtwinConfigurationType.SELF_SUPPORTING
  );
});

onMounted(() => {
  onSearch(fetchOperations);
});
</script>

<template>
  <h3 class="mt-10">{{ t("dtwins.form.configuration.operations.title") }}</h3>
  <div class="flex justify-between align-middle">
    <div class="self-center w-[25rem] mb-5">
      <search-bar
        v-model:search-query="searchQuery"
        :search-placeholder="t('filters.searchOperations')"
        @clear="search"
        @search="search"
      />
    </div>
  </div>
  <lxc-table
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="results?.results"
    :empty-text="t('operation.empty')"
    :context="results?.pagination"
    @change-page-and-page-size="fetchOperations"
  >
    <lxc-table-column
      v-if="isSelfSupportingConfiguration"
      prop="customInputParameters.selfSupportingVersion"
      :label="t('operation.configuration.selfSupportingVersion')"
      min-width="12rem"
    />
    <lxc-table-column
      v-if="!isSelfSupportingConfiguration"
      prop="customInputParameters.globalVersion"
      :label="t('operation.configuration.globalVersion')"
      min-width="12rem"
    />
    <lxc-table-column
      v-if="!isSelfSupportingConfiguration"
      prop="customInputParameters.specificVersion"
      :label="t('operation.configuration.specificVersion')"
      min-width="12rem"
    />
    <lxc-table-column
      prop="createdAt"
      :label="t('operation.createdAt')"
      width="15rem"
    >
      <template #default="scope">
        {{
          scope.row.createdAt
            ? formatIsoDate(scope.row.createdAt, dateFormat)
            : ""
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="launchPlannedAt"
      :label="t('operation.launchedAt')"
      width="15rem"
    >
      <template #default="scope">
        {{
          scope.row.launchPlannedAt
            ? formatIsoDate(scope.row.launchPlannedAt, dateFormat)
            : ""
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="updatedAt"
      :label="t('operation.configuration.updatedDate')"
      width="15rem"
    >
      <template #default="scope">
        {{
          scope.row.updatedAt
            ? formatIsoDate(scope.row.updatedAt, dateFormat)
            : ""
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column prop="status" :label="t('operation.state')" width="15rem">
      <template #default="scope">
        <operation-mgr-operation-status-badge :status="scope.row.status" />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>
