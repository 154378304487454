import type { Ref } from "vue";
import LxcError from "~/core/utils/LxcError";
import CampaignService from "~/modules/campaign/services/campaign.service";
import type { ReportingConfiguration } from "~/modules/reporting/models/ReportingConfiguration.interface";
import type { ReportingRecipient } from "~/modules/reporting/models/ReportingRecipient.interface";

export function useReporting() {
  const configuration = ref<ReportingConfiguration>();
  const configurationBackup = ref<ReportingConfiguration>();
  const isLoading = ref(false);
  const error: Ref<LxcError | null> = ref(null);

  function cloneConfiguration(
    response: ReportingConfiguration,
  ): ReportingConfiguration {
    return { ...response };
  }

  async function fetchConfiguration() {
    isLoading.value = true;
    const response = await CampaignService.getReportingConfiguration();

    if (LxcError.check(response)) {
      error.value = response;
    } else {
      configuration.value = response;
      configurationBackup.value = cloneConfiguration(response);
    }

    isLoading.value = false;

    return response;
  }

  async function updateConfiguration(config: ReportingConfiguration) {
    isLoading.value = true;
    const response = await CampaignService.postReportingConfiguration(config);

    if (!LxcError.check(response)) {
      configuration.value = response;
      configurationBackup.value = cloneConfiguration(response);
    }
    isLoading.value = false;

    return response;
  }

  async function addReportingRecipients(
    configurationId: string,
    recipients: ReportingRecipient[],
  ) {
    return await CampaignService.addReportingRecipients(
      configurationId,
      recipients,
    );
  }

  async function removeReportingRecipients(
    configurationId: string,
    recipients: ReportingRecipient[],
  ) {
    return await CampaignService.removeReportingRecipients(
      configurationId,
      recipients,
    );
  }

  return {
    configuration,
    configurationBackup,
    error,
    isLoading,
    fetchConfiguration,
    updateConfiguration,
    addReportingRecipients,
    removeReportingRecipients,
  };
}
