<script setup lang="ts">
import { SelectionModeEnum } from "@lxc/app-device-common";
import type { DtwinI } from "@lxc/app-device-types";
import { DtwinLifeCycleState } from "@lxc/app-device-types";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwins } from "~/composables/useDtwins";
import { SearchMode, useSearch } from "~/core/composables/useSearch";
import type { OperationModel } from "~/core/models/OperationModel.interface";
import { OperationModelType } from "~/core/models/OperationModelType.enum";
import {
  Filters,
  type FilterSelectionValue,
  FiltersType,
} from "~/core/models/filters";
import LxcError from "~/core/utils/LxcError";
import { showNotificationError } from "~/core/utils/notifications";
import type { Firmware } from "~/modules/firmware/models/Firmware.interface";
import type { Fleet } from "~/modules/fleet/models/Fleet.interface";
import { FleetColumns } from "~/modules/fleet/models/FleetColumns.enum";
import { FleetType } from "~/modules/fleet/models/FleetType.enum";
import FleetService from "~/modules/fleet/services/fleet.service";

export interface CampaignFunnelStep2 {
  dtwins: DtwinI[];
  fleets: Fleet[];
}

const props = defineProps<{
  modelValue: CampaignFunnelStep2;
  operationModels: OperationModel[];
  operationModelType?: OperationModelType;
  fleetUid?: string;
  firmware?: Firmware;
}>();

const { t } = useI18n();

const emit = defineEmits(["update:modelValue", "update:resetSelected"]);

const {
  fetchAllModels,
  isLoading: isLoadingDtwinModels,
  results: allDtwinModels,
  error: errorDtwinModels,
} = useDtwinModels();

const initialSelectedDtwinsUid: Ref<string[]> = ref([]);

const {
  setFilter: setFilterDtwins,
  fetchData: fetchDtwins,
  isLoading: isLoadingDtwins,
  results: resultDtwins,
  error: errorDtwins,
} = useDtwins(SearchMode.FILTER_SEARCH, false);

const fleetsDevicesShow: Ref<boolean> = ref(false);
const infoFleet: Ref<Fleet | undefined> = ref();

async function resetSelectedDtwins(fleetUid?: string) {
  if (fleetUid) {
    setFilterDtwins(Filters.DTWINS_IN_FLEET, fleetUid);

    const allSelectedDtwins = await fetchAllSelectedDtwins();

    if (!LxcError.check(allSelectedDtwins)) {
      initialSelectedDtwinsUid.value = allSelectedDtwins.map(
        (selectedDtwin) => selectedDtwin.uid,
      );
      selectedDtwins.value = allSelectedDtwins;
    }
  }
}

// Fetch all the selected dtwins recursively.
// This is required in order to set the selected dtwins in the list.
async function fetchAllSelectedDtwins(
  fromPage: number = 1,
  // only pageSizeMagicNumbers defined in useSearch are allowed
  pageSize: number = 50,
): Promise<DtwinI[] | LxcError> {
  await fetchDtwins(fromPage, pageSize);

  if (errorDtwins.value) {
    return errorDtwins.value;
  } else if (
    resultDtwins.value &&
    resultDtwins.value.context.count > 0 &&
    resultDtwins.value.context.page <
      Math.ceil(
        resultDtwins.value.context.totalCount /
          resultDtwins.value.context.pageSize,
      )
  ) {
    const dtwinsFetched = resultDtwins.value.data.slice();

    const dtwinsNextPageFetched: DtwinI[] | LxcError =
      await fetchAllSelectedDtwins(fromPage + 1, pageSize);
    if (LxcError.check(dtwinsNextPageFetched)) {
      return dtwinsNextPageFetched;
    } else {
      return dtwinsFetched.concat(dtwinsNextPageFetched);
    }
  } else {
    return resultDtwins.value?.data || [];
  }
}

const initialSelectedFleetsUid: Ref<string[]> = ref([]);
const isLoadingSelectedFleets = ref(false);
const errorSelectedFleets: Ref<LxcError | undefined> = ref(undefined);

async function resetSelectedFleets(fleetUid?: string) {
  if (fleetUid) {
    errorSelectedFleets.value = undefined;
    isLoadingSelectedFleets.value = true;

    const response = await FleetService.getFleetByUid(fleetUid);

    if (LxcError.check(response)) {
      errorSelectedFleets.value = response;
    } else if (response.subFleets) {
      initialSelectedFleetsUid.value = response.subFleets
        .map((subFleet) => subFleet.copyOf)
        .filter((fleetUid) => fleetUid !== undefined);
      selectedFleets.value = response.subFleets.map((subFleet) => {
        // reset the selected fleet
        // the only useful attributes are uid and hash, the others are not relevant
        return {
          uid: subFleet.copyOf,
          orgId: "",
          friendlyName: "",
          type: FleetType.STATIC,
          createdAt: "",
          hash: subFleet.hash,
        } as Fleet;
      });
    }

    isLoadingSelectedFleets.value = false;
  }
}

const form: ComputedRef<CampaignFunnelStep2> = computed({
  get() {
    return props.modelValue;
  },
  set(form: CampaignFunnelStep2) {
    emit("update:modelValue", form);
  },
});

const selectedDtwins = computed({
  get() {
    return form.value.dtwins;
  },
  set(selectedDtwins) {
    form.value.dtwins = selectedDtwins;
  },
});

const selectedFleets = computed({
  get() {
    return form.value.fleets;
  },
  set(selectedFleets) {
    form.value.fleets = selectedFleets;
  },
});

// compute model UID from firmware range
const modelUidFromFirmwareRange = computed(() => {
  let modelUidFromFirmwareRange: string | undefined = undefined;

  if (
    props.operationModelType === OperationModelType.FIRM_UPDATE &&
    props.firmware &&
    allDtwinModels.value
  ) {
    const model = allDtwinModels.value.results.find(
      (dtwinModel) => dtwinModel.name === props.firmware?.range,
    );
    modelUidFromFirmwareRange = model?.uid;
  }

  return modelUidFromFirmwareRange;
});

// compute filters to apply on dtwins list
const dtwinsListDefaultFilters = computed(() => {
  if (
    !props.operationModelType ||
    (props.operationModelType === OperationModelType.FIRM_UPDATE &&
      !props.firmware)
  ) {
    return undefined;
  } else {
    const dtwinsListDefaultFilters: Map<Filters, FilterSelectionValue> =
      new Map();

    // filter on dtwins state
    dtwinsListDefaultFilters.set(Filters.DTWINS_IN_LIFE_CYCLE_STATE, [
      DtwinLifeCycleState.PREPROVISIONED,
      DtwinLifeCycleState.REGISTERED,
    ]);

    // filter on dtwins type
    let modelUids: string[] = [];

    // for update firmware operation
    if (props.operationModelType === OperationModelType.FIRM_UPDATE) {
      // filter on firmware range
      if (modelUidFromFirmwareRange.value) {
        modelUids = [modelUidFromFirmwareRange.value];
      }

      // filter on compatible firmware software version, do not filter if compatible software versions contain "*"
      dtwinsListDefaultFilters.set(
        Filters.DTWIN_IN_SOFTWARE_VERSION,
        props.firmware?.firmwareVersions &&
          !props.firmware?.firmwareVersions.includes("*")
          ? toRaw(props.firmware?.firmwareVersions)
          : [],
      );

      // filter on compatible firmware hardware version, do not filter if compatible hardware versions contain "*"
      dtwinsListDefaultFilters.set(
        Filters.DTWIN_IN_HARDWARE_VERSION,
        props.firmware?.hardwareVersions &&
          !props.firmware?.hardwareVersions.includes("*")
          ? toRaw(props.firmware?.hardwareVersions)
          : [],
      );
    }
    // for other operations
    else if (allDtwinModels.value) {
      // filter on types defined in the selected operation models
      for (const operationModel of props.operationModels) {
        if (operationModel.type === props.operationModelType) {
          for (const deviceType of operationModel.deviceTypes) {
            const models = allDtwinModels.value.results.filter(
              (dtwinModel) => dtwinModel.name === deviceType,
            );
            models.forEach((model) => {
              if (!modelUids.includes(model.uid)) {
                modelUids.push(model.uid);
              }
            });
          }
        }
      }

      // reset filter on firmware compatible software version
      dtwinsListDefaultFilters.set(Filters.DTWIN_IN_SOFTWARE_VERSION, []);

      // reset filter on firmware compatible hardware version
      dtwinsListDefaultFilters.set(Filters.DTWIN_IN_HARDWARE_VERSION, []);
    }

    dtwinsListDefaultFilters.set(Filters.DTWIN_MODEL_TYPE, modelUids);

    return dtwinsListDefaultFilters;
  }
});

// build RSQL query for fleet compatibility criteria in order to get the count of compatible dtwins
const { appliedFilters } = useDtwins();
const { searchParams, setFilter } = useSearch(
  FiltersType.RSQL,
  appliedFilters,
  SearchMode.FILTER_SEARCH,
);
watch(
  () => dtwinsListDefaultFilters.value,
  () => {
    if (dtwinsListDefaultFilters.value) {
      for (let [filter, value] of dtwinsListDefaultFilters.value) {
        setFilter(filter, value);
      }
    }
  },
);

const onFleetRowClick = (fleet: Fleet) => {
  fleetsDevicesShow.value = true;
  infoFleet.value = fleet;
};

onMounted(async () => {
  await Promise.all([
    fetchAllModels(),
    resetSelectedDtwins(props.fleetUid),
    resetSelectedFleets(props.fleetUid),
  ]);
});

defineExpose({
  compatibilityCriteria: searchParams,
  initialSelectedDtwinsUid,
  initialSelectedFleetsUid,
  validate: () => {
    if (
      selectedDtwins.value.length === 0 &&
      selectedFleets.value.length === 0
    ) {
      const message = t("campaign.funnel.fleetSelection.validation.required");
      showNotificationError(message);
      return Promise.reject(message);
    } else {
      return Promise.resolve(true);
    }
  },
});
</script>

<template>
  <container-component
    class="!p-0"
    :is-loading="
      isLoadingDtwinModels || isLoadingDtwins || isLoadingSelectedFleets
    "
    :error="errorDtwinModels || errorDtwins || errorSelectedFleets"
  >
    <lxc-tabs :add-margin-bottom="false">
      <lxc-tab-pane :label="t('campaign.funnel.fleetSelection.devices')">
        <lxc-dtwins-list
          v-model:selected-dtwins="selectedDtwins"
          :row-click-selection-mode="SelectionModeEnum.FORCE"
          no-quick-action-menu
          no-quick-filters
          clickable
          :default-filters="dtwinsListDefaultFilters"
          :search-mode="SearchMode.FILTER_SEARCH"
          search-bar-class="mt-4 mb-5"
        />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t('campaign.funnel.fleetSelection.fleets')">
        <fleet-list
          v-model:selected-fleets="selectedFleets"
          :row-click-selection-mode="SelectionModeEnum.NO_SELECTION"
          :compatibility-criteria="searchParams as string"
          :columns="[
            FleetColumns.FRIENDLY_NAME,
            FleetColumns.DEVICE_TWIN_COUNT,
            FleetColumns.CREATED_AT,
            FleetColumns.MODIFIED_AT,
            FleetColumns.INCOMPATIBLE_DEVICE_TWIN_COUNT,
            FleetColumns.TOOLTIP,
          ]"
          search-bar-class="mt-4 mb-5"
          no-redirection
          :search-mode="SearchMode.FILTER_SEARCH"
          @row-click="onFleetRowClick"
        />
        <campaign-funnel-step2-fleet-device-list
          v-model:show="fleetsDevicesShow"
          :fleet="infoFleet"
          :compatibility-criteria="searchParams as string"
        />
      </lxc-tab-pane>
    </lxc-tabs>
  </container-component>
</template>
