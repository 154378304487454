<script setup lang="ts">
import LxcForm from "@lxc/app-device-common/src/components/form/LxcForm.vue";
import { useFirmware } from "~/core/composables/useFirmware";
import { SearchMode } from "~/core/composables/useSearch";
import type { OperationModel } from "~/core/models/OperationModel.interface";
import { OperationModelType } from "~/core/models/OperationModelType.enum";
import { Filters } from "~/core/models/filters";
import LxcError from "~/core/utils/LxcError";
import type { Firmware } from "~/modules/firmware/models/Firmware.interface";
import { FirmwareStatus } from "~/modules/firmware/models/FirmwareStatus.enum";

const props = defineProps<{
  /**
   * Firmware uuid
   */
  modelValue?: string;
  operationModels: OperationModel[];
  canEdit: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits(["update:modelValue", "selectFirmware"]);

const { results, error, fetchData, setFilter } = useFirmware(
  SearchMode.FILTER_SEARCH,
  false,
);

const isLoadingAllActiveFirmwares: Ref<boolean | undefined> = ref(false);
const errorAllActiveFirmwares: Ref<LxcError | undefined | null> = ref();
const allActiveFirmwares: Ref<Firmware[]> = ref([]);

async function setAllActiveFirmwares() {
  isLoadingAllActiveFirmwares.value = true;
  errorAllActiveFirmwares.value = undefined;

  setFilter(Filters.STATUS, FirmwareStatus.ACTIVATED);

  // filter on the firmwares with range in device types of firmware update operation models
  const ranges: string[] = [];
  for (const operationModel of props.operationModels) {
    if (operationModel.type === OperationModelType.FIRM_UPDATE) {
      for (const deviceType of operationModel.deviceTypes) {
        ranges.push(deviceType);
      }
    }
  }
  setFilter(Filters.RANGE, ranges);

  const allActiveFirmwaresFetched = await fetchAllActiveFirmwares();
  if (LxcError.check(allActiveFirmwaresFetched)) {
    errorAllActiveFirmwares.value = allActiveFirmwaresFetched;
  } else {
    allActiveFirmwares.value = allActiveFirmwaresFetched;
  }

  isLoadingAllActiveFirmwares.value = false;
}

// Fetch all the active firmwares recursively.
// This is required for now because the filter is done on client side by the component LxcSelect.
async function fetchAllActiveFirmwares(
  fromPage: number = 1,
  // only pageSizeMagicNumbers defined in useSearch are allowed
  pageSize: number = 50,
): Promise<Firmware[] | LxcError> {
  await fetchData(fromPage, pageSize);

  if (error.value) {
    return error.value;
  } else if (
    results.value &&
    results.value.context.count > 0 &&
    results.value.context.page <
      Math.ceil(
        results.value.context.totalCount / results.value.context.pageSize,
      )
  ) {
    const activeFirmwaresFetched = results.value.data.slice();

    const activeFirmwaresNextPageFetched = await fetchAllActiveFirmwares(
      fromPage + 1,
      pageSize,
    );
    if (LxcError.check(activeFirmwaresNextPageFetched)) {
      return activeFirmwaresNextPageFetched;
    } else {
      return activeFirmwaresFetched.concat(activeFirmwaresNextPageFetched);
    }
  } else {
    return results.value?.data || [];
  }
}

function setSelectedFirmware() {
  if (form.value.firmwareUuid) {
    selectedFirmware.value = allActiveFirmwares.value.find(
      (firmware) => firmware.uuid === form.value.firmwareUuid,
    );
  } else {
    selectedFirmware.value = undefined;
  }
}

const rules = {
  firmwareUuid: [
    {
      required: true,
      message: t("campaign.funnel.campaignSettings.validation.type"),
      trigger: "change",
    },
  ],
};

const formRef: Ref<typeof LxcForm | undefined> = ref();
const form: Ref<{ firmwareUuid: string | undefined }> = ref({
  firmwareUuid: props.modelValue,
});

const selectedFirmware: Ref<Firmware | undefined | null> = ref();

watch(
  () => form.value.firmwareUuid,
  async (firmwareUuid) => {
    setSelectedFirmware();
    emit("update:modelValue", firmwareUuid);
  },
);

onMounted(async () => {
  await setAllActiveFirmwares();
  setSelectedFirmware();
});

defineExpose({
  validate: () => formRef.value?.validate(),
});
</script>

<template>
  <hr class="mt-4" />

  <container-component
    :is-loading="isLoadingAllActiveFirmwares"
    :error="errorAllActiveFirmwares"
    class="!p-0"
  >
    <lxc-label class="my-6">
      {{
        t(
          "campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.title",
        )
      }}
    </lxc-label>

    <lxc-form ref="formRef" :model="form" :rules="rules">
      <lxc-form-item
        :label="
          t(
            'campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.firmwareName',
          )
        "
        class="w-80"
        prop="firmwareUuid"
      >
        <lxc-select
          v-model="form.firmwareUuid"
          :disabled="!canEdit"
          select-filter
          :select-search-placeholder="t('input.select.search.placeholder')"
          :select-no-result-text="t('input.select.search.noResults')"
        >
          <lxc-option
            v-for="firmware of allActiveFirmwares"
            :key="firmware.uuid"
            :label="firmware.name"
            :value="firmware.uuid"
          />
        </lxc-select>
      </lxc-form-item>
    </lxc-form>

    <lxc-firmware-info
      v-model:firmware="selectedFirmware"
      @update:firmware="$emit('selectFirmware', $event)"
    />
  </container-component>
</template>
