<script setup lang="ts">
import { SortMode } from "@lxc/app-device-common";
import type { ElTable } from "element-plus";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import type { SearchMode } from "~/core/composables/useSearch";
import { PATHS } from "~/core/constants/paths";
import { type FilterOptions, Filters } from "~/core/models/filters";
import { useApplication } from "~/modules/application/composables/useApplication";
import type { Application } from "~/modules/application/models/Application.interface";
import type { CampaignType } from "~/modules/campaign/models/CampaignType.enum";
import { useSectorStore } from "~/modules/sector/stores/useSectorStore";
import { router } from "~/plugins/router";

const props = defineProps<{
  noAction?: boolean;
  selectedApplications?: Application[];
  defaultFilters?: Map<Filters, any>;
  campaignType?: CampaignType;
  searchMode?: SearchMode;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
  useQueryParametersForPagination?: boolean;
}>();
const emit = defineEmits(["update:selectedApplications"]);

const { t } = useI18n();
const {
  isLoading,
  results: applications,
  filters,
  error,
  fetchData,
  setFilter,
  getHasActionStatusLabel,
  onSearch,
  search,
  searchOnSectors,
  onSortChange,
} = useApplication(props.searchMode, props.useQueryParametersForPagination);
const { selectedSectors } = storeToRefs(useSectorStore());

const tableRef = ref<InstanceType<typeof ElTable>>();

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => searchOnSectors(selectedSectors.value, true));

const rowSelection: Ref<Application[]> = ref([]);

/**
 * Select applications
 */
const setSelection = () => {
  if (props.selectedApplications !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedApplications.some(
          (selectedApplication) => selectedApplication.id === row.id,
        ),
      );
    }

    rowSelection.value = props.selectedApplications || [];
  }
};

watch(() => props.selectedApplications, setSelection);

/**
 * Retrieve applications and sectors
 * @param page
 * @param pageSize
 */
async function loadData(page?: number, pageSize?: number) {
  const params = new Map();
  params.set("campaignType", props.campaignType);
  await fetchData(page, pageSize, params);

  setSelection();
}

/**
 * Calcul and emit the selected applications
 */
const handleSelect = (handledSelected: Application[]) => {
  // Keep the selected applications which were selected on another page and not present in handled selected
  emit(
    "update:selectedApplications",
    props.selectedApplications
      ?.filter(
        (selectedApplication: Application) =>
          !tableRef.value ||
          !tableRef.value.data.find((row) => row.id === selectedApplication.id),
      )
      .filter(
        (selectedApplication: Application) =>
          !handledSelected.find((row) => row.id === selectedApplication.id),
      )
      .concat(handledSelected),
  );
};

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

const onSelectApplication = (app: Application) => {
  router.push(`${PATHS.APPLICATIONS}/${app.id}`);
};

const searchQuery = computed({
  get() {
    return (filters.get(Filters.NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.NAME, searchQuery);
  },
});

function reloadWithContext() {
  loadData(
    applications.value?.context?.page,
    applications.value?.context?.pageSize,
  );
}

onMounted(async () => {
  onSearch(loadData, false);
  await searchOnSectors(selectedSectors.value, true);
});
</script>

<template>
  <search-bar
    v-model:search-query="searchQuery"
    :search-placeholder="t('filters.searchByName')"
    @clear="search"
    @search="search"
  />
  <application-filters
    :filters="filters"
    :type-options="typeOptions"
    :model-options="modelOptions"
    :state-options="stateOptions"
    @change="setFilter"
    @enter="search"
  />

  <lxc-table
    ref="tableRef"
    min-width="90rem"
    :is-loading="isLoading"
    :data="applications?.data"
    :empty-text="t('application.empty')"
    :context="applications?.context"
    data-cy="application-table"
    :error="error?.toError()"
    :clickable="true"
    @change-page-and-page-size="loadData"
    @row-click="onSelectApplication"
    @select="handleSelect"
    @select-all="handleSelect"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      v-if="selectedApplications !== undefined"
      type="selection"
    />
    <lxc-table-column
      prop="name"
      :label="t('application.name')"
      min-width="16rem"
    />
    <lxc-table-column
      prop="model.type"
      :label="t('application.model.type')"
      width="8rem"
    />
    <lxc-table-column
      prop="model.declination"
      :label="t('application.model.declination')"
      width="8rem"
    />
    <lxc-table-column
      prop="serialNumber"
      :label="t('application.serialNumber')"
      width="12rem"
    />
    <lxc-table-column
      prop="connectivity.state"
      :label="t('application.connectivity')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <connectivity-status
          :connectivity-state="scope.row.connectivity.state"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('application.certificate')"
      prop="certificateExpireBefore"
      :sort-mode="SortMode.CUSTOM"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <lxc-certificate-status :certificate="scope.row.certificate" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="firmwareVersion"
      :label="t('application.firmwareVersion')"
      width="10rem"
    />
    <lxc-table-column prop="state" :label="t('application.state')" width="8rem">
      <template #default="scope">
        {{ scope.row.state ? t(`application.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="campaignType"
      :label="t('application.actions.scheduled.title')"
      width="3rem"
    >
      <template #default="scope">
        <span>{{ getHasActionStatusLabel(scope.row, campaignType) }}</span>
      </template>
    </lxc-table-column>
    <lxc-table-column v-if="!noAction" class="w-16 !py-0" width="12rem">
      <template #default="scoped">
        <application-actions
          :key="scoped.row.id"
          :is-activated="scoped.row.state"
          :application="scoped.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>

  <div class="mt-4">
    <el-select
      v-if="rowSelection?.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :teleported="false"
      @change="$emit('update:selectedApplications', rowSelection)"
    >
      <el-option
        v-for="device in rowSelection"
        :key="device.id"
        :label="device.name"
        :value="device"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
