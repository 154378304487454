import type { AboutComponent } from "~/modules/about/models/AboutComponent.interface";

export class ComponentVersion implements AboutComponent {
  readonly name: string;
  readonly version: string | null;

  constructor({ name, version }: { name: string; version: string | null }) {
    this.name = name;
    this.version = version;
  }
}
