export enum LogActionType {
  ALERT = "alert",
  CONFIGURATION = "configuration",
  DVTM = "dvtm",
  EXTERNAL_PLATFORM = "external_platform",
  GROUP = "group",
  IAM = "iam",
  LGT_TYPE_AUTHENT_FAILURE = "lgt_type_authent_failure",
  LGT_TYPE_AUTHENT_SUCCESS = "lgt_type_authent_success",
  LGT_TYPE_FIRMWARE_UPDATE = "lgt_type_firmware_update",
  LGT_TYPE_OPERATING_MODES = "lgt_type_operating_modes",
  LGT_TYPE_PERMISSION_DENIED = "lgt_type_permission_denied",
  LGT_TYPE_PHYSICAL_ACCESS = "lgt_type_physical_access",
  LGT_TYPE_STORAGE_CAPACITY = "lgt_type_storage_capacity",
  OPERATION = "operation",
  PLATFORM = "platform",
  PROFILE = "profile",
  SECTOR = "sector",
  TALQ_GATEWAY = "talq_gateway",
  USER = "user",
  KEYSTORE = "keystore",
  TRUSTSTORE = "truststore",
}
