import { DEFAULT_SORT_OPERATION } from "~/core/constants/constants";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { Operation } from "~/core/models/Operation.type";
import type { OperationType } from "~/core/models/OperationType.enum";
import LxcError from "~/core/utils/LxcError";
import ApplicationService from "~/modules/application/services/application.service";

export function useApplicationOperations(
  applicationId: string,
  operationType?: OperationType,
  hasCallbackUrl?: boolean,
) {
  const operations = ref<ApiListResult<Operation> | null>(null);
  const error = ref<LxcError | null>(null);
  const isLoading = ref<boolean>(false);

  async function fetchData(
    page?: number,
    pageSize?: number,
    sort: string = DEFAULT_SORT_OPERATION,
  ) {
    isLoading.value = true;
    error.value = null;

    const response = await ApplicationService.getApplicationOperations(
      applicationId,
      page,
      pageSize,
      sort,
      operationType,
      hasCallbackUrl,
    );

    if (LxcError.check(response)) {
      operations.value = null;
      error.value = response;
    } else {
      operations.value = response;
    }

    isLoading.value = false;
  }

  return {
    fetchData,
    isLoading,
    operations,
    error,
  };
}
