<script setup lang="ts">
import type { SelectionModeEnum } from "@lxc/app-device-common";
import { SortMode } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import { useAcl } from "vue-simple-acl";
import type { SearchMode } from "~/core/composables/useSearch";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { QuickActionButtonConfigI } from "~/core/models/QuickActionsButtonConfig.interface";
import LxcError from "~/core/utils/LxcError";
import { formatIsoDate } from "~/core/utils/date";
import { NotificationKey } from "~/core/utils/notifications";
import { useFleet } from "~/modules/fleet/composables/useFleet";
import type { Fleet } from "~/modules/fleet/models/Fleet.interface";
import { FleetColumns } from "~/modules/fleet/models/FleetColumns.enum";
import fleetService from "~/modules/fleet/services/fleet.service";
import { Filters } from "~/types";
import ILxcLightTrash2 from "~icons/lxc-light/trash-2";
import ILxcInfo from "~icons/lxc/info";
import ILxcTrash2 from "~icons/lxc/trash-2";

const props = defineProps<{
  reload?: boolean;
  columns?: FleetColumns[];
  searchMode?: SearchMode;
  searchBarClass?: string | string[];
  selectedFleets?: Fleet[];
  useQueryParametersForPagination?: boolean;
  compatibilityCriteria?: string;
  rowClickSelectionMode?: SelectionModeEnum;
  noRedirection?: boolean;
  displayQuickActionsToolbar?: boolean;
}>();

const emit = defineEmits([
  "update:reload",
  "update:selected-fleets",
  "rowClick",
]);

const { t } = useI18n();
const router = useRouter();
const acl = useAcl();
const {
  isLoading,
  error,
  results,
  setFilter,
  setCompatibilityCriteria,
  fetchData,
  search,
  filters,
  onSearch,
  onSortChange,
} = useFleet(
  props.searchMode,
  props.useQueryParametersForPagination,
  props.compatibilityCriteria,
);

const isAllowedToManageFleet = computed(() => acl.can(ACLRoles.DVTM_DVT_ADM));
const showConfirm: Ref<boolean> = ref(false);
const isDeleting: Ref<boolean> = ref(false);

const searchQuery = computed({
  get() {
    return (filters.get(Filters.FLEET_NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.FLEET_NAME, searchQuery);
  },
});

async function reloadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
  setSelection();
}

async function executeSearch(replaceHistory?: boolean) {
  // call search in order to update the query parameters with filters
  await search(replaceHistory);
}

const tableRef = ref<InstanceType<typeof LxcTable>>();

/**
 * Select fleet
 */
const setSelection = () => {
  if (props.selectedFleets && tableRef.value && tableRef.value.data) {
    tableRef.value.toggleTableSelectionMode(props.selectedFleets.length > 0);
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedFleets?.some(
          (selectedFleet) => selectedFleet.uid === (row as Fleet).uid,
        ),
      );
    }
  }
};

watch(() => props.selectedFleets, setSelection);

watch(
  () => props.compatibilityCriteria,
  (compatibilityCriteria) => {
    setCompatibilityCriteria(compatibilityCriteria);
    reloadData();
  },
);

const handleSelect = (selectedFleets: Fleet[]) => {
  const computeSelectedFleets = props.selectedFleets
    ?.filter(
      (selectedFleet: Fleet) =>
        !tableRef.value ||
        !(tableRef.value.data as Fleet[])?.find(
          (row: Fleet) => row.uid === selectedFleet.uid,
        ),
    )
    .filter(
      (selectedFleet: Fleet) =>
        !selectedFleets.find((row) => row.uid === selectedFleet.uid),
    )
    .concat(selectedFleets);

  emit("update:selected-fleets", computeSelectedFleets);
};

const quickActionButtonConfig: ComputedRef<QuickActionButtonConfigI> = computed(
  () => {
    return {
      icon: ILxcTrash2,
      title: t(
        "fleet.quickActions.delete.title",
        props.selectedFleets?.length ?? 0,
      ),
      event: () => (showConfirm.value = true),
    };
  },
);

async function onBulkDeletion() {
  isDeleting.value = true;
  const selectedFleetsUids =
    props.selectedFleets?.map((fleet: Fleet) => fleet.uid) ?? [];

  const response = await fleetService.bulkDeleteFleet(selectedFleetsUids);

  showConfirm.value = false;
  isDeleting.value = false;
  if (LxcError.check(response)) {
    response.notify(NotificationKey.removeError);
  } else {
    onClearSelection();
    await reloadData();
  }
}

interface TooltipInfoI {
  display: boolean;
  message?: string;
}

const initialSelectedFleet: Fleet[] = props.selectedFleets
  ? [...props.selectedFleets]
  : [];

const getTooltipMessage = (fleet: Fleet): TooltipInfoI => {
  const fleetInSelectedFleet = initialSelectedFleet.find(
    (sFleet) => sFleet.uid === fleet.uid,
  );

  if (fleet.compatibleDeviceTwinCount === 0) {
    return {
      display: true,
      message: t("fleet.table.column.information.noDeviceCompatible"),
    };
  } else if (fleetInSelectedFleet && fleetInSelectedFleet.hash !== fleet.hash) {
    return {
      display: true,
      message: t("fleet.table.column.information.fleetHasChanged"),
    };
  } else if (fleet.deviceTwinCount !== fleet.compatibleDeviceTwinCount) {
    return {
      display: true,
      message: t("fleet.table.column.information.incompatibleDevices"),
    };
  } else {
    return {
      display: false,
    };
  }
};

const disabledFleetSelection = (fleet: Fleet): boolean => {
  return fleet.compatibleDeviceTwinCount === 0;
};

watch(
  () => props.reload,
  (reload) => {
    if (reload) {
      reloadData();
      emit("update:reload", false);
    }
  },
);

const onRowClick = (fleet: Fleet) => {
  emit("rowClick", fleet);
  if (!props.noRedirection) {
    router.push(`${PATHS.FLEETS}/${fleet.uid}`);
  }
};

const onClearSelection = () => {
  emit("update:selected-fleets", []);
  if (tableRef.value) {
    tableRef.value.selectedRows = [];
  }
};

const onCloseConfirmModal = () => {
  showConfirm.value = false;
};

onMounted(async () => {
  onSearch(reloadData, false);
  await executeSearch(true);
});
</script>

<template>
  <div :class="`mb-6 ${searchBarClass}`">
    <search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('filters.searchByName')"
      @clear="search"
      @search="search"
    />
  </div>

  <div
    v-if="
      selectedFleets &&
      selectedFleets.length > 0 &&
      isAllowedToManageFleet &&
      displayQuickActionsToolbar
    "
  >
    <quick-actions-toolbar
      :quick-action-button-config="quickActionButtonConfig"
      :selection-message="
        t('fleet.quickActions.selectedFleets', selectedFleets.length)
      "
      @cancel-selection="onClearSelection"
    />
  </div>

  <lxc-table
    ref="tableRef"
    min-width="71rem"
    :is-loading="isLoading"
    :context="results?.pagination"
    :data="results?.results"
    :error="error?.toError()"
    data-cy="fleet-table"
    clickable
    :empty-text="t('fleet.table.empty')"
    :row-click-selection-mode="rowClickSelectionMode"
    :is-row-disabled="disabledFleetSelection"
    @change-page-and-page-size="reloadData"
    @row-click="onRowClick"
    @select="handleSelect"
    @select-all="handleSelect"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      v-if="selectedFleets && isAllowedToManageFleet"
      type="selection"
    />
    <lxc-table-column
      v-if="!columns || columns.includes(FleetColumns.FRIENDLY_NAME)"
      prop="friendlyName"
      :label="t('fleet.table.column.fleet')"
      :sort-mode="SortMode.CUSTOM"
      min-width="20rem"
    />
    <lxc-table-column
      v-if="!columns || columns.includes(FleetColumns.DEVICE_TWIN_COUNT)"
      prop="deviceTwinCount"
      :label="t('fleet.table.column.devices')"
      width="10rem"
    />
    <lxc-table-column
      v-if="!columns || columns.includes(FleetColumns.CREATED_AT)"
      prop="createdAt"
      :label="t('fleet.table.column.createdAt')"
      :sort-mode="SortMode.CUSTOM"
      width="15rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.createdAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns.includes(FleetColumns.MODIFIED_AT)"
      prop="modifiedAt"
      :label="t('fleet.table.column.updatedAt')"
      :sort-mode="SortMode.CUSTOM"
      min-width="20rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.modifiedAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="
        columns && columns.includes(FleetColumns.INCOMPATIBLE_DEVICE_TWIN_COUNT)
      "
      prop="incompatibleDeviceTwinCount"
      :label="t('fleet.table.column.incompatibleDevices')"
      width="18rem"
    >
      <template #default="scope">
        {{
          isNaN(scope.row.compatibleDeviceTwinCount)
            ? 0
            : scope.row.deviceTwinCount - scope.row.compatibleDeviceTwinCount
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="columns && columns?.includes(FleetColumns.TOOLTIP)"
      class="!py-0"
      width="6rem"
    >
      <template #default="scope">
        <span
          v-if="getTooltipMessage(scope.row).display"
          :title="getTooltipMessage(scope.row).message"
        >
          <ILxcInfo class="text-gray-700" width="1.25rem" height="1.25rem" />
        </span>
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="!columns || columns.includes(FleetColumns.ACTION)"
      class="!py-0"
      width="6rem"
    >
      <template #default="scope">
        <fleet-list-delete-action
          :fleet-uid="scope.row.uid"
          @deleted="reloadData"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-confirm-modal
    :is-dialog-visible="showConfirm"
    :title="t('fleet.bulkDelete.confirm.title', selectedFleets?.length ?? 0)"
    :description="
      t('fleet.bulkDelete.confirm.message', selectedFleets?.length ?? 0)
    "
    :ok-label="t('button.confirm')"
    :cancel-label="t('button.cancel')"
    :icon="ILxcLightTrash2"
    :disabled-confirm="isDeleting"
    icon-color-theme="error"
    color-theme="danger"
    @confirm="onBulkDeletion"
    @cancel="onCloseConfirmModal"
  />
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
