<script setup lang="ts">
import type { QuickActionButtonConfigI } from "~/core/models/QuickActionsButtonConfig.interface";
import ILxcClose from "~icons/lxc/x";

defineProps<{
  quickActionButtonConfig: QuickActionButtonConfigI;
  selectionMessage: string;
}>();

const { t } = useI18n();

const emits = defineEmits(["cancelSelection"]);
</script>

<template>
  <div class="mb-2 py-2 bg-gray-100 rounded text-gray-700 flex items-center">
    <lxc-button
      :icon="ILxcClose"
      class="!px-0 !py-0 ml-2 mr-4"
      type="borderless"
      :title="t('dtwins.fleet.cancelSelection')"
      @click="emits('cancelSelection')"
    />
    <span class="text-base">
      {{ selectionMessage }}
    </span>
    <lxc-button
      :icon="quickActionButtonConfig.icon"
      type="borderless"
      :title="quickActionButtonConfig.title"
      class="!px-0 !py-0 ml-8"
      @click="quickActionButtonConfig.event"
    />
  </div>
</template>
