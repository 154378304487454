import { SearchMode, useSearch } from "~/core/composables/useSearch";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import {
  Filters,
  type FiltersSelection,
  FiltersType,
} from "~/core/models/filters";
import LxcError from "~/core/utils/LxcError";
import type { CampaignOperationState } from "~/modules/campaign/models/CampaignOperationState.enum";
import type { ObjectCampaign } from "~/modules/campaign/models/ObjectCampaign.interface";
import campaignService from "~/modules/campaign/services/campaign.service";

export function useCampaignProcessedObjects(campaignId: string) {
  const campaignProcessedObjects = ref<ApiListResult<ObjectCampaign> | null>(
    null,
  );
  const error = ref<LxcError | null>(null);
  const isLoading = ref<boolean>(false);

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
    [Filters.MODEL_TYPE, { key: "lxcModelType", operator: "=", value: [] }],
    [Filters.STATE, { key: "state", operator: "=", value: [] }],
  ]);

  // use FILTER_SEARCH mode because the filters are not well applied after reloading the page
  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.TWO_AMPERSAND_SEPARATOR,
    appliedFilters,
    SearchMode.FILTER_SEARCH,
  );

  const label: Ref<string> = ref("");
  const objectTypes: Ref<string[] | undefined> = ref();
  const states: Ref<CampaignOperationState[] | undefined> = ref();
  async function fetchData(page?: number, pageSize?: number, sort?: string) {
    isLoading.value = true;
    error.value = null;
    filters.forEach((filter) => {
      switch (filter.key) {
        case Filters.NAME:
          label.value = filter.value as string;
          break;
        case Filters.MODEL_TYPE:
          objectTypes.value = filter.value as string[];
          break;
        case Filters.STATE:
          states.value = filter.value as CampaignOperationState[];
          break;
      }
    });

    const response = await campaignService.getCampaignProcessedObjects(
      campaignId,
      page,
      pageSize,
      sort,
      label.value,
      objectTypes.value,
      states.value,
    );

    if (LxcError.check(response)) {
      campaignProcessedObjects.value = null;
      error.value = response;
    } else {
      campaignProcessedObjects.value = response;
    }

    isLoading.value = false;
  }

  return {
    campaignProcessedObjects,
    error,
    isLoading,
    filters,
    searchParams,
    fetchData,
    onSearch,
    search,
    setFilter,
  };
}
