<script setup lang="ts">
import { formatIsoDate } from "~/core/utils/date";
import type { Application } from "~/modules/application/models/Application.interface";

const props = defineProps<{
  application: Application | null;
}>();

const { t } = useI18n();

const dateFormat: string = t("dateFormat.date");
const dateFormatDatetime: string = t("dateFormat.datetime");

const applicationIdCardFormRef = ref();
let idCardInfos = ref([
  {
    name: "name",
    label: t("application.description.name"),
    model: props.application?.name ?? "-",
  },
  {
    name: "type",
    label: t("application.description.type"),
    model: props.application?.model?.type,
  },
  {
    name: "model",
    label: t("application.description.model"),
    model: props.application?.model?.declination ?? "-",
  },
  {
    name: "serialNumber",
    label: t("application.description.serialNumber"),
    model: props.application?.serialNumber ?? "-",
  },
]);

const applicationStateFormRef = ref();
let stateColumnOneInfos = ref([
  {
    name: "state",
    label: t("application.description.state"),
    model: t(`application.states.${props.application?.state}`),
  },
  {
    name: "lastConnection",
    label: t("application.description.lastConnection"),
    model: props.application?.connectivity?.lastCommunicationDate
      ? formatIsoDate(
          props.application?.connectivity?.lastCommunicationDate,
          dateFormatDatetime,
        )
      : "-",
  },
  {
    name: "terminalName",
    label: t("application.description.terminalName"),
    model: props.application?.terminalName ?? "-",
  },
  {
    name: "windowsUsername",
    label: t("application.description.windowsUsername"),
    model: props.application?.windowsUserAccount ?? "-",
  },
]);
let stateColumnTwoInfos = ref([
  {
    name: "firstCommissioningDate",
    label: t("application.description.firstCommissioningDate"),
    model: "-",
  },
  {
    name: "certificateValidityDate",
    label: t("application.description.certificateValidityDate"),
    model: props.application?.certificate?.notValidAfter
      ? formatIsoDate(props.application?.certificate?.notValidAfter, dateFormat)
      : "-",
  },
  {
    name: "firmwareVersion",
    label: t("application.description.firmwareVersion"),
    model: props.application?.firmwareVersion ?? "-",
  },
]);

const applicationConnectivityFormRef = ref();
let connectivityInfos = ref([
  {
    name: "mobileNetwork",
    label: t("application.description.mobileNetwork"),
    model: "-",
  },
  {
    name: "gsmFrequencyBand",
    label: t("application.description.gsmFrequencyBand"),
    model: "-",
  },
  {
    name: "communicationProtocol",
    label: t("application.description.communicationProtocol"),
    model: "-",
  },
]);

if (props.application?.model?.type === "SG4000") {
  idCardInfos = ref([
    {
      name: "name",
      label: t("application.description.name"),
      model: props.application?.name ?? "-",
    },
    {
      name: "type",
      label: t("application.description.type"),
      model: props.application?.model?.type,
    },
    {
      name: "serialNumber",
      label: t("application.description.serialNumber"),
      model: props.application?.serialNumber ?? "-",
    },
  ]);
  stateColumnOneInfos = ref([
    {
      name: "state",
      label: t("application.description.state"),
      model: props.application?.state ?? "-",
    },
    {
      name: "lastConnection",
      label: t("application.description.lastConnection"),
      model: props.application?.connectivity?.lastCommunicationDate
        ? formatIsoDate(
            props.application?.connectivity?.lastCommunicationDate,
            dateFormatDatetime,
          )
        : "-",
    },
  ]);
  stateColumnTwoInfos = ref([
    {
      name: "lastRebootDate",
      label: t("application.description.lastRebootDate"),
      model: "-",
    },
    {
      name: "firstCommissioningDate",
      label: t("application.description.firstCommissioningDate"),
      model: "-",
    },
    {
      name: "certificateValidityDate",
      label: t("application.description.certificateValidityDate"),
      model: props.application?.certificate?.notValidAfter
        ? formatIsoDate(
            props.application?.certificate?.notValidAfter,
            dateFormat,
          )
        : "-",
    },
    {
      name: "firmwareVersion",
      label: t("application.description.firmwareVersion"),
      model: props.application?.firmwareVersion ?? "-",
    },
  ]);
  connectivityInfos = ref([
    {
      name: "communicationProtocol",
      label: t("application.description.communicationProtocol"),
      model: "-",
    },
  ]);
}
</script>

<template>
  <!-- idCard -->
  <description-container :title="t(`application.description.idCard`)">
    <template #left>
      <lxc-form ref="applicationIdCardFormRef" :model="idCardInfos">
        <div v-for="idCardInfo in idCardInfos" :key="idCardInfo.name">
          <lxc-form-item :label="idCardInfo.label">
            <lxc-input v-model="idCardInfo.model" type="text" read-only />
          </lxc-form-item>
        </div>
      </lxc-form>
    </template>
  </description-container>
  <!-- end idCard -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- State -->
  <lxc-form ref="applicationStateFormRef" :model="stateColumnOneInfos">
    <description-container :title="t(`application.description.state`)">
      <template #left>
        <div v-for="stateInfo in stateColumnOneInfos" :key="stateInfo.name">
          <lxc-form-item :label="stateInfo.label">
            <lxc-input v-model="stateInfo.model" type="text" read-only />
          </lxc-form-item>
        </div>
      </template>
      <template #right>
        <div
          v-for="stateColumnTwoInfo in stateColumnTwoInfos"
          :key="stateColumnTwoInfo.name"
        >
          <lxc-form-item :label="stateColumnTwoInfo.label">
            <lxc-input
              v-model="stateColumnTwoInfo.model"
              type="text"
              read-only
            />
          </lxc-form-item>
        </div>
      </template>
    </description-container>
  </lxc-form>
  <!-- end State -->
  <div class="w-full h-0.5 bg-gray-200 mb-5" />
  <!-- Connectivity -->
  <description-container
    :title="t(`application.description.connectivity`)"
    with-margin-bottom
  >
    <template #left>
      <lxc-form ref="applicationConnectivityFormRef" :model="connectivityInfos">
        <div
          v-for="connectivityInfo in connectivityInfos"
          :key="connectivityInfo.name"
        >
          <lxc-form-item :label="connectivityInfo.label">
            <lxc-input v-model="connectivityInfo.model" type="text" read-only />
          </lxc-form-item>
        </div>
      </lxc-form>
    </template>
  </description-container>
  <!-- end Connectivity -->
</template>
