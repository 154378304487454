import { DEFAULT_SORT_CONTENT_RESOURCE } from "~/core/constants/constants";
import type { ContentResource } from "~/core/models/ContentResource.interface";
import type { PaginatedList } from "~/core/models/PaginatedList.interface";
import type { FiltersSelection } from "~/core/models/filters";
import RestService from "~/core/services/rest.service";

export class ContentService extends RestService {
  protected BASE_URL = "/contents";

  /**
   * Find a resource
   * @param page
   * @param pageSize
   * @param filter
   * @param sort
   */
  async findContentResources(
    page: number,
    pageSize: number,
    filter?: string | FiltersSelection,
    sort?: string | null,
  ): Promise<PaginatedList<ContentResource>> {
    const params = {
      page,
      pageSize,
      filter,
      sort: sort ?? DEFAULT_SORT_CONTENT_RESOURCE,
    };

    return this.$apiV3.get(this.BASE_URL, { params });
  }

  /**
   * Download a file from content manager
   * @param contentResourceUid
   * @param fileResourceUid
   */
  async getFile(
    contentResourceUid: string,
    fileResourceUid: string,
  ): Promise<BlobPart> {
    return this.$apiV3.get(
      `${this.BASE_URL}/${contentResourceUid}/files/${fileResourceUid}`,
      {
        headers: { Accept: "application/octet-stream" },
        responseType: "arraybuffer",
        timeout: Infinity,
      },
    );
  }
}

export default new ContentService();
