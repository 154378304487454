export enum DisplayPermission {
  DVM_UPDATE_DEV = "DVM_UPDATE_DEV",
  DVM_UPDATE_APP = "DVM_UPDATE_APP",
  DVM_ADM_DEV = "DVM_ADM_DEV",
  DVM_VIEW_DEV = "DVM_VIEW_DEV",
  DVM_VIEW_APP = "DVM_VIEW_APP",
  DVM_VIEW_FIRM = "DVM_VIEW_FIRM",
  DVM_USER_MANAGEMENT = "DVM_USER_MANAGEMENT",
  DVM_SECT_VIEW = "DVM_SECT_VIEW",
  DVM_SECT_MANAGEMENT = "DVM_SECT_MANAGEMENT",
  DVM_CERT_MANAGEMENT = "DVM_CERT_MANAGEMENT",
  DVM_FIRM_MANAGEMENT = "DVM_FIRM_MANAGEMENT",
  DVM_ALER_MANAGEMENT = "DVM_ALER_MANAGEMENT",
  DVM_TRST_MANAGEMENT = "DVM_TRST_MANAGEMENT",
  DVM_PKI_MANAGEMENT = "DVM_PKI_MANAGEMENT",
  DVM_CRTRENEW_DEV_MANAGEMENT = "DVM_CRTRENEW_DEV_MANAGEMENT",
  DVM_CRTRENEW_APP_MANAGEMENT = "DVM_CRTRENEW_APP_MANAGEMENT",
  DVM_UPDATE_FIRM_DEV_MANAGEMENT = "DVM_UPDATE_FIRM_DEV_MANAGEMENT",
  CACRT_ADM = "CACRT_ADM",
  CACRT_READ = "CACRT_READ",
  CACRT_NOTIFS = "CACRT_NOTIFS",
  CAMP_VIEW = "CAMP_VIEW",
  CAMP_MANAGEMENT = "CAMP_MANAGEMENT",
  CYBER_LOGS_VIEW = "CYBER_LOGS_VIEW",
  DEVICE_LOGS_VIEW = "DEVICE_LOGS_VIEW",
  DEVICE_FLEET_LOGS_VIEW = "DEVICE_FLEET_LOGS_VIEW",
  SYSTEM_LOGS_VIEW = "SYSTEM_LOGS_VIEW",
  BSCOMMON_ERRDESC_LIST = "BSCOMMON_ERRDESC_LIST",
  USR_CERTIFICATES = "USR_CERTIFICATES",
  LICENSEM_UPLOAD = "LICENSEM_UPLOAD",
  APPMGR_ADM_THIRDPARTYAPP = "APPMGR_ADM_THIRDPARTYAPP",
  APPMGR_READ_THIRDPARTYAPP = "APPMGR_READ_THIRDPARTYAPP",
  APPMGR_ADM_MOBILEAPP = "APPMGR_ADM_MOBILEAPP",
  APPMGR_READ_MOBILEAPP = "APPMGR_READ_MOBILEAPP",
}
