<script setup lang="ts">
import type { TabNavigation } from "~/core/components/TabsWithNavigation.vue";

interface Props {
  tabNavigation: TabNavigation;
}
const { tabNavigation } = defineProps<Props>();

const route = useRoute();

const displayable = computed(() =>
  route.path.startsWith(tabNavigation.redirectPath),
);
</script>

<template>
  <slot v-if="displayable" :name="tabNavigation.index" />
</template>
