<script setup lang="ts">
import type { Rules } from "async-validator";
import { HttpStatusCode } from "axios";
import type { ComputedRef } from "vue";
import type { UploadUserInformationI } from "~/core/models/UploadFile.interface";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { FilesUploadErrorMessagesI } from "~/core/utils/upload.utils";
import uploadUtils from "~/core/utils/upload.utils";
import type { DtwinConfigurationForm } from "~/modules/dtwin/models/DtwinConfigurationForm.interface";
import dtwinsService from "~/services/dtwins.service";
import ILxcPauseCircle from "~icons/lxc/pause-circle";

const props = defineProps<{
  showImportPanel: boolean;
  dtwinUid: string;
  configurationType: string;
}>();

const emit = defineEmits(["update:showImportPanel", "uploaded"]);

const { t } = useI18n();

const sideCanvasShown = computed({
  get: () => {
    return props.showImportPanel;
  },
  set: (shown: boolean) => {
    emit("update:showImportPanel", shown);
  },
});

const configurationTypeLabel = computed(() =>
  t(
    `dtwins.form.configuration.information.configurationType.${props.configurationType}`,
  ),
);

const defaultConfigurationForm = {
  versionName: "",
  version: "",
};

const configurationForm = ref<DtwinConfigurationForm>({
  ...defaultConfigurationForm,
});
const configurationFormRef: Ref<HTMLFormElement | undefined | null> = ref();
const rules: Rules = {
  versionName: [
    {
      required: true,
      message: t("input.error.required"),
      whitespace: true,
    },
    {
      max: 50,
      message: t("input.error.maxLength", { maxLength: 50 }),
    },
  ],
  version: [
    { required: true, message: t("input.error.required"), whitespace: true },
  ],
};

const isFormValid: ComputedRef<boolean | undefined> = computed(
  () => configurationFormRef.value?.isValid,
);

const files: Ref<FileList | undefined | null> = ref();
const fileProgress: Ref<number | undefined | null> = ref();
const filesUploadError: Ref<Error | undefined | null> = ref();
const fileSelected: ComputedRef<boolean> = computed(
  () => !!files?.value?.length,
);
const isConfigurationUploaded: Ref<boolean> = ref(false);

watch(
  () => fileSelected.value,
  () => {
    if (!fileSelected.value) {
      resetConfigurationForm();
      isConfigurationUploaded.value = false;
    }
  },
);

const userInformation: UploadUserInformationI = {
  title: t("dtwins.form.configuration.upload.userInformation.title"),
  description: t(
    "dtwins.form.configuration.upload.userInformation.description",
  ),
};
const fileFormats = [".json", ".jwt"];
const MAX_FILE_SIZE_BYTES_ALLOWED = 750_000_000;
const errorMessages: FilesUploadErrorMessagesI = {
  invalidType: t("dtwins.form.configuration.upload.error.invalidFileType"),
  size: t("dtwins.form.configuration.upload.error.fileSize"),
  empty: t("dtwins.form.configuration.upload.error.emptyFile"),
};

function resetSelectedFile() {
  files.value = undefined;
  fileProgress.value = undefined;
  filesUploadError.value = undefined;
  isConfigurationUploaded.value = false;
  resetConfigurationForm();
}

function resetConfigurationForm() {
  configurationForm.value = { ...defaultConfigurationForm };
  setTimeout(configurationFormRef.value?.clearValidate, 0);
}

function onCancel() {
  sideCanvasShown.value = false;
}

const contentResourceUid: Ref<string | undefined> = ref();
const uploadConfiguration = async () => {
  filesUploadError.value =
    filesUploadError.value !== undefined ? null : undefined;
  if (
    files.value &&
    files.value.length > 0 &&
    uploadUtils.canUploadFile(
      files.value[0],
      MAX_FILE_SIZE_BYTES_ALLOWED,
      fileFormats,
      errorMessages,
      filesUploadError,
    )
  ) {
    const response = await dtwinsService.uploadConfigurationFile(
      files.value[0],
      fileProgress,
      props.dtwinUid,
    );

    if (LxcError.check(response)) {
      if (response.status === HttpStatusCode.Conflict) {
        filesUploadError.value = new Error(
          t("dtwins.form.configuration.upload.error.fileAlreadyExist"),
        );
      } else {
        filesUploadError.value = response.toError(NotificationKey.uploadError);
      }
    } else {
      contentResourceUid.value = response.uid;
      isConfigurationUploaded.value = true;
    }
  }
};

const addConfiguration = async () => {
  if (isFormValid.value && contentResourceUid.value) {
    const response = await dtwinsService.addConfigurationFile(
      contentResourceUid.value,
      {
        ...configurationForm.value,
        type: props.configurationType,
      },
    );
    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(t("dtwins.form.configuration.upload.success"));
      sideCanvasShown.value = false;
      resetSelectedFile();
      resetConfigurationForm();
      emit("uploaded");
    }
  }
};
</script>

<template>
  <lxc-side-canvas
    v-model:show="sideCanvasShown"
    :header="t('dtwins.form.configuration.upload.title')"
    :close-tooltip="t('filters.close')"
    :confirm-enabled="fileSelected"
    :confirm-title="t('dtwins.form.configuration.upload.confirm.title')"
    :confirm-message="t('dtwins.form.configuration.upload.confirm.message')"
    :confirm-ok-label="t('button.confirm')"
    :confirm-cancel-label="t('button.cancel')"
    :confirm-icon="ILxcPauseCircle"
    confirm-icon-color-theme="error"
    confirm-color-theme="danger"
    @discard="onCancel"
    @hidden="resetSelectedFile"
  >
    <upload-file
      v-model:files="files"
      v-model:files-progress="fileProgress"
      v-model:files-upload-error="filesUploadError"
      :user-information="userInformation"
      :file-formats="fileFormats"
    />
    <div v-if="isConfigurationUploaded" class="mt-8">
      <h6 class="mb-4">
        {{ t("dtwins.form.configuration.upload.form.title") }}
      </h6>
      <lxc-form
        ref="configurationFormRef"
        :model="configurationForm"
        :rules="rules"
        class="flex flex-col gap-2"
      >
        <lxc-form-item
          :label="t('dtwins.form.configuration.upload.form.versionName')"
          prop="versionName"
        >
          <lxc-input v-model="configurationForm.versionName" type="text" />
        </lxc-form-item>
        <lxc-form-item
          :label="t('dtwins.form.configuration.upload.form.version')"
          prop="version"
        >
          <lxc-input v-model="configurationForm.version" type="text" />
        </lxc-form-item>
        <lxc-form-item
          :label="t('dtwins.form.configuration.upload.form.type')"
          prop="type"
        >
          <lxc-input v-model="configurationTypeLabel" type="text" read-only />
        </lxc-form-item>
      </lxc-form>
    </div>
    <template #footer>
      <div class="grid grid-cols-[max-content_auto] gap-4">
        <lxc-button
          html-type="button"
          type="secondary"
          :title="t('button.cancel')"
          @click="onCancel"
        >
          {{ t("button.cancel") }}
        </lxc-button>
        <lxc-button
          v-if="fileSelected && !isConfigurationUploaded"
          html-type="button"
          :disabled="!!filesUploadError"
          :title="t('button.upload')"
          @click="uploadConfiguration"
        >
          {{ t("button.upload") }}
        </lxc-button>
        <lxc-button
          v-if="isConfigurationUploaded"
          html-type="button"
          :disabled="!!filesUploadError || !isFormValid"
          :title="t('button.validate')"
          @click="addConfiguration"
        >
          {{ t("button.validate") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
