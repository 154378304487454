import RestService from "../core/services/rest.service";
import type {
  ApiListResult,
  FirmwareI,
  FirmwareStatsI,
  LxcBsFirmwareDistinct,
} from "@lxc/app-device-types";
import type { AxiosProgressEvent } from "axios";
import type { PaginatedList } from "~/core/models/PaginatedList.interface";
import LxcError from "~/core/utils/LxcError";
import type { FirmwareUpdatePayload } from "~/modules/firmware/models/FirmwareUpdatePayload.interface";
import type { LxcBsFirmwareV2 } from "~/modules/firmware/models/LxcBsFirmwareV2.type";
import type { FilterOptions, FiltersSelection, Option } from "~/types";

export class FirmwareService extends RestService {
  protected BASE_URL = "/firmwares";
  protected BASE_URL_V2 = "/contents/firmwares";

  /**
   * Retrieve firmware
   * @param page
   * @param pageSize
   * @param search
   * @param sort
   * @param additionalParams
   */
  async getFirmwares(
    page?: number,
    pageSize?: number,
    search?: string | FiltersSelection,
    sort?: string | null,
    additionalParams?: Map<string, any>,
  ): Promise<ApiListResult<FirmwareI, FilterOptions | undefined> | LxcError> {
    const additionalParamsObj = additionalParams
      ? Object.fromEntries(additionalParams.entries())
      : {};
    const params = {
      page,
      pageSize,
      filter: search,
      sort,
      ...additionalParamsObj,
    };

    const result: PaginatedList<LxcBsFirmwareV2, LxcBsFirmwareDistinct> =
      await this.$apiV2.get(this.BASE_URL_V2, { params });
    if (LxcError.check(result)) {
      return result;
    } else {
      const rangeOptions: Option[] | undefined = result.distinctValues?.[
        "data.deviceTypeFriendlyName"
      ]?.reduce((acc: Option[], option: string) => {
        if (option != null) {
          acc.push({ label: option, value: option });
        }
        return acc;
      }, []);
      return {
        data: result.results?.map(FirmwareService.extractFirmwareDataV2),
        context: result.pagination,
        distinctValues: rangeOptions ? { options: rangeOptions } : undefined,
      };
    }
  }

  /**
   * Get a firmware by its UUID
   * @param uuid Identifier of the firmware
   * @returns The firmware for the UUID
   */
  getFirmwareByUuid(uuid: string): Promise<FirmwareI> {
    return this.$api.get(`${this.BASE_URL}/${uuid}`);
  }

  /**
   * Get a firmware stats by its UUID
   * @param uuid Identifier of the firmware
   * @returns The firmware stats for the UUID
   */
  getFirmwareStatsByUuid(uuid: string): Promise<FirmwareStatsI> {
    return this.$api.get(`${this.BASE_URL}/${uuid}/stats`);
  }

  /**
   * Upload a firmware file
   * @param firmwareFile
   * @param progress
   */
  uploadFirmwareFile(
    firmwareFile: File,
    progress: Ref<number | undefined | null>,
  ): Promise<FirmwareI> {
    const formData = new FormData();
    formData.append("firmwareFile", firmwareFile);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.value = progressEvent.total
          ? (progressEvent.loaded / progressEvent.total) * 100
          : 0;
      },
      timeout: Infinity,
    };

    return this.$api.post(`${this.BASE_URL}/upload`, formData, config);
  }

  /**
   * Create a firmware
   * @param firmware
   * @returns
   */
  createFirmware(firmware: FirmwareI) {
    return this.$api.post(`${this.BASE_URL}`, { firmware });
  }

  /**
   * Activate a firmware
   * @param firmware
   * @returns
   */
  activateFirmware(firmware: FirmwareI) {
    return this.$api.patch(`${this.BASE_URL}/${firmware.uuid}/activate`);
  }

  /**
   * Deactivate a firmware
   * @param firmware
   * @returns
   */
  deactivateFirmware(firmware: FirmwareI) {
    return this.$api.patch(`${this.BASE_URL}/${firmware.uuid}/deactivate`);
  }

  /**
   * Delete a firmware
   * @param firmware
   * @returns
   */
  deleteFirmware(firmware: FirmwareI) {
    return this.$api.delete(`${this.BASE_URL}/${firmware.uuid}`);
  }

  /**
   * Extract a FirmwareI from LxcBsFirmware
   * @param firmware
   * @returns
   */
  private static extractFirmwareDataV2(firmware: LxcBsFirmwareV2): FirmwareI {
    let descriptor;

    try {
      descriptor = JSON.parse(firmware.data);
      // TODO: refactor needed - `eslint-disable-next-line` rule added to avoid code smell for next developments after linter update
      // eslint-disable-next-line
    } catch (e) {
      descriptor = {};
    }

    return {
      uuid: firmware.uuid,
      name: firmware.name,
      range: descriptor?.deviceType,
      rangeFriendlyName: descriptor?.deviceTypeFriendlyName,
      declination: descriptor?.deviceDeclination,
      version: firmware.version,
      creationDate: firmware.createdAt,
      status: firmware.status,
      description: firmware.description,
      firmwareVersions: descriptor?.compatibleGlobalProductSwVersion,
      hardwareVersions: descriptor?.compatibleHwVersion,
      descriptor: firmware.data,
      buildDate: firmware.buildDate,
    };
  }

  /**
   * Update firmware name and description
   * @param uuid
   * @param updateNameAndDescriptionPayload
   */
  updateFirmwareNameAndDescription(
    uuid: string,
    updateNameAndDescriptionPayload: FirmwareUpdatePayload,
  ) {
    return this.$api.patch(
      `${this.BASE_URL}/${uuid}`,
      updateNameAndDescriptionPayload,
    );
  }
}

export default new FirmwareService();
