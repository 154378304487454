import type { Ref } from "vue";

/**
 * Return the unit translations
 */
function getUnitTranslations() {
  const { t } = useI18n();
  return {
    byte: t("unit.file.byte"),
    bytes: t("unit.file.bytes"),
    kB: t("unit.file.kB"),
    MB: t("unit.file.MB"),
    GB: t("unit.file.GB"),
  };
}

/**
 * Return true if the file is ZIP typed
 */
function isZipFile(file: File) {
  return file.name.toLowerCase().endsWith(".zip");
}

/**
 * Return true if the file is a certificate typed
 */
function isCertificateFile(file: File) {
  return /\.(pem|crt|cer)$/.test(file.name.toLowerCase());
}

function isValidFileType(file: File, types: string[]): boolean {
  return types.some((type) =>
    file.name.toLowerCase().endsWith(type.toLowerCase()),
  );
}

export interface FilesUploadErrorMessagesI {
  invalidType?: string;
  empty?: string;
  size?: string;
}

function canUploadFile(
  file: File,
  maxFileSize: number,
  fileFormats: string[],
  errorMessages: FilesUploadErrorMessagesI,
  filesUploadError: Ref<Error | undefined | null>,
): boolean {
  let error: string | undefined;
  filesUploadError.value =
    filesUploadError.value !== undefined ? null : undefined;

  if (!isValidFileType(file, fileFormats)) {
    error = errorMessages.invalidType;
  } else if (!file.size) {
    error = errorMessages.empty;
  } else if (file.size > maxFileSize) {
    error = errorMessages.size;
  }

  if (error) {
    filesUploadError.value = new Error(error);
  }

  return !error;
}

export default {
  getUnitTranslations,
  isCertificateFile,
  isZipFile,
  canUploadFile,
};
