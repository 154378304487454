<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useConfigStore } from "~/core/stores/useConfigStore";
import ILxcInfoFilledItalic from "~icons/lxc-custom/info-filled-italic";

const { t } = useI18n();
const { technicalVersion, marketingVersion } = storeToRefs(useConfigStore());
</script>

<template>
  <div class="version">
    <el-popover placement="top-start" trigger="hover" width="auto">
      <template #reference>
        <div>
          {{ marketingVersion ?? t("parameters.version.unknown") }}
          <el-icon :size="18" class="icon-information">
            <i-lxc-info-filled-italic />
          </el-icon>
        </div>
      </template>

      <template #default>
        <strong>{{ t("parameters.technicalVersion.label") }}</strong>
        {{ technicalVersion ?? t("parameters.technicalVersion.unknown") }}
      </template>
    </el-popover>
  </div>
</template>

<style lang="scss" scoped>
.version {
  position: absolute;
  bottom: 5px;
  right: 20px;
  font-size: 14px;
  opacity: 0.6;
}
.icon-information {
  top: 4px;
}
</style>
