<script setup lang="ts">
import { DtwinOperationStatus } from "@lxc/app-device-types";
import { Color } from "~/core/models/Color.enum";

const props = defineProps<{
  status: string;
}>();

const { t } = useI18n();

const badgeType = computed(() => {
  switch (props.status) {
    case DtwinOperationStatus.RUNNING:
    case DtwinOperationStatus.DELIVERED:
    case DtwinOperationStatus.RETRY:
      return Color.PRIMARY;
    case DtwinOperationStatus.CANCELLING:
      return Color.WARNING;
    case DtwinOperationStatus.DONE:
      return Color.SUCCESS;
    case DtwinOperationStatus.FAILED:
    case DtwinOperationStatus.EXPIRED:
    case DtwinOperationStatus.CANCELLED:
      return Color.DANGER;
    case DtwinOperationStatus.SCHEDULED:
    case DtwinOperationStatus.PENDING:
    case DtwinOperationStatus.QUEUED:
    default:
      return Color.SECONDARY;
  }
});
</script>
<template>
  <lxc-badge :type="badgeType" :text-white="badgeType === 'primary'">
    {{ status ? t(`operation.${status.toLowerCase()}`) : "" }}
  </lxc-badge>
</template>
