import RestService from "~/core/services/rest.service";
import type { PkiConnector } from "~/modules/pkiConnector/models/PkiConnector.interface";
import type { PkiUsages } from "~/modules/pkiConnector/models/PkiUsages.enum";

class PkiConnectorService extends RestService {
  private readonly BASE_URL = "/pki-connector";

  /**
   * @param usage
   */
  async fetchByUsage(usage: PkiUsages): Promise<PkiConnector> {
    return this.$api.get(`${this.BASE_URL}/${usage.toString()}`);
  }

  /**
   * @param usage
   * @param pkiConfiguration
   */
  async updateByUsage(
    usage: string,
    pkiConfiguration: PkiConnector,
  ): Promise<PkiConnector> {
    this.verifyPkiConfiguration(pkiConfiguration);
    return this.$api.patch(`${this.BASE_URL}/${usage.toString()}`, {
      pkiConfiguration,
    });
  }

  /**
   * @param pkiConfiguration
   * @private
   */
  private verifyPkiConfiguration(pkiConfiguration: PkiConnector) {
    const HTTPS_PREFIX = "https://";
    if (
      pkiConfiguration.url &&
      !pkiConfiguration.url.startsWith(HTTPS_PREFIX)
    ) {
      pkiConfiguration.url = HTTPS_PREFIX.concat(pkiConfiguration.url);
    }
    if (!pkiConfiguration.bootstrapTemplateName) {
      pkiConfiguration.bootstrapIntervalTime = undefined;
    }
  }
}

export default new PkiConnectorService();
