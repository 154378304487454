<script lang="ts" setup>
import LxcMandatory from "~/components/shared/LxcMandatory.vue";
import { PATHS } from "~/core/constants/paths";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import userGroupService from "~/modules/user/services/userGroup.service";
import { useUserSession } from "~/modules/user/stores/useUserSession";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { userSession } = useUserSession();

const userGroupCode = route?.params?.code as string;
const isCreate = userGroupCode === "new";
const isLoading = ref(true);
const error = ref<LxcError | null>(null);

const userGroupForm = ref();
const userGroup = reactive({
  label: "",
  orgCode: userSession?.organization?.code,
  sectors: [],
});
const userGroupLabel = ref("");
const userGroupFormValidationRules = {
  label: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 50, message: t("input.error.maxLength", { maxLength: 50 }) },
  ],
};

const name = computed(() => userGroupLabel.value || "userGroup.button.create");

onMounted(async () => await getUserGroup());

/**
 * Update or create userGroup resource
 */
async function onSaveUserGroup(): Promise<void> {
  const valid = await userGroupForm.value.validate().catch(() => false);

  if (valid) {
    const response = isCreate
      ? await userGroupService.createUserGroup(userGroup)
      : await userGroupService.updateUserGroup(userGroup);

    if (LxcError.check(response)) {
      response.notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      await router.push(PATHS.USER_MANAGEMENT_GROUPS);
    }
  }
}

/**
 * Retrieve UserGroup if updating
 */
async function getUserGroup() {
  if (!isCreate) {
    const userGroupResponse =
      await userGroupService.getUserGroupByCode(userGroupCode);

    if (LxcError.check(userGroupResponse)) {
      error.value = userGroupResponse;
    } else {
      Object.assign(userGroup, userGroupResponse);
      userGroupLabel.value = userGroupResponse.label;
    }
  }

  isLoading.value = false;
}
</script>

<template>
  <el-container data-cy="page-userGroup-component" direction="vertical">
    <breadcrumb-component
      :name="isLoading ? '' : name"
      back
      class="px-8 mt-8"
      :should-not-translate="!isCreate"
    />
    <container-component
      class="half-container"
      :error="error"
      :is-loading="isLoading"
    >
      <lxc-mandatory />
      <el-form
        ref="userGroupForm"
        :rules="userGroupFormValidationRules"
        :model="userGroup"
        label-position="left"
        label-width="200px"
        @submit.prevent="onSaveUserGroup"
      >
        <el-form-item :label="t('input.label')" prop="label">
          <el-input
            v-model="userGroup.label"
            data-cy="inputLabel"
            type="text"
          />
        </el-form-item>
      </el-form>

      <lxc-cancel-or-submit-buttons
        @cancel="router.back()"
        @submit="onSaveUserGroup"
      />
    </container-component>
  </el-container>
</template>
