<script setup lang="ts">
import type { Ref } from "vue";
import { BREADCRUMBS } from "~/core/constants/breadcrumbs";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { PkiConfigForm } from "~/modules/pkiConnector/models/PkiConfigForm.interface";
import { PkiUsages } from "~/modules/pkiConnector/models/PkiUsages.enum";
import PkiConnectorService from "~/modules/pkiConnector/services/pkiConnector.service";

const { t } = useI18n();

const isLoading = ref(false);
const pkiConfiguration = ref<PkiConfigForm | null>();
const error: Ref<LxcError | null> = ref(null);
let pkiConfigurationBackup: PkiConfigForm | undefined | null;

onMounted(async () => await fetchPkiConnectorConfiguration());

async function fetchPkiConnectorConfiguration() {
  isLoading.value = true;
  const response = await PkiConnectorService.fetchByUsage(PkiUsages.DEVICE);

  if (LxcError.check(response)) {
    error.value = response;
    pkiConfigurationBackup = null;
  } else {
    pkiConfiguration.value = response;
    pkiConfigurationBackup = Object.assign<PkiConfigForm, PkiConfigForm>(
      {},
      response,
    );
  }

  isLoading.value = false;
}

async function updatePkiConnectorConfiguration(formContent: PkiConfigForm) {
  isLoading.value = true;
  const response = await PkiConnectorService.updateByUsage(
    PkiUsages.DEVICE,
    formContent,
  );

  if (LxcError.check(response)) {
    response.notify(NotificationKey.saveError);
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
    pkiConfigurationBackup = pkiConfiguration.value
      ? Object.assign({}, pkiConfiguration.value)
      : null;
  }

  isLoading.value = false;
}

function cancelForm() {
  pkiConfiguration.value = pkiConfigurationBackup
    ? Object.assign({}, pkiConfigurationBackup)
    : null;
  showNotificationSuccess(t(NotificationKey.cancel));
}

function sendForm(event: PkiConfigForm) {
  pkiConfiguration.value = event;
  updatePkiConnectorConfiguration(event);
}
</script>

<template>
  <page-component
    :name="BREADCRUMBS.PKI_CONNECTOR.title"
    :error="error"
    :is-loading="isLoading"
  >
    <pki-connector-form
      :pki-configuration="pkiConfiguration"
      @cancel="cancelForm"
      @validate="sendForm"
    />
  </page-component>
</template>
