import { useFetchPage } from "~/core/composables/useFetchPage";
import { type SearchMode, useSearch } from "~/core/composables/useSearch";
import { useSort } from "~/core/composables/useSort";
import {
  Filters,
  type FiltersSelection,
  FiltersType,
  OPERATOR_GREATER_OR_EQUAL,
  OPERATOR_LOWER_OR_EQUAL,
  OPERATOR_RSQL_ARRAY_CONTAINS,
  OPERATOR_RSQL_EQUAL,
  OPERATOR_RSQL_ILIKE,
} from "~/core/models/filters";
import ContentService from "~/core/services/content.service";

export function useContent(
  searchMode?: SearchMode,
  useQueryParametersForPagination?: boolean,
) {
  const service = ContentService.findContentResources.bind(ContentService);

  const appliedFilters: FiltersSelection = new Map([
    [
      Filters.DTWIN_UID,
      {
        key: "deviceTwinUid",
        operator: OPERATOR_RSQL_EQUAL,
        value: "",
      },
    ],
    [
      Filters.DTWIN_CONFIGURATION_VERSION_NAME_OR_VERSION,
      {
        key: ["descriptor.versionName", "descriptor.version"],
        operator: OPERATOR_RSQL_ILIKE,
        value: "",
      },
    ],
    [
      Filters.DTWIN_CONFIGURATION_START_DATE,
      {
        key: "modifiedAt",
        operator: OPERATOR_GREATER_OR_EQUAL,
        value: "",
      },
    ],
    [
      Filters.DTWIN_CONFIGURATION_END_DATE,
      {
        key: "modifiedAt",
        operator: OPERATOR_LOWER_OR_EQUAL,
        value: "",
      },
    ],
    [
      Filters.CONTENT_TAGS,
      {
        key: "tags",
        operator: OPERATOR_RSQL_ARRAY_CONTAINS,
        value: [],
      },
    ],
  ]);

  const { searchParams, filters, setFilter, search, onSearch } = useSearch(
    FiltersType.RSQL,
    appliedFilters,
    searchMode,
  );
  const { sort, onSortChange } = useSort();
  const { isLoading, results, error, fetchData } = useFetchPage({
    service,
    searchParams,
    sort,
    useQueryParametersForPagination,
    disableWatchOnSort: true,
  });

  watch(sort, async () => await search(true));

  return {
    isLoading,
    results,
    error,
    filters,
    fetchData,
    setFilter,
    onSortChange,
    search,
    onSearch,
  };
}
