export const VALIDATION_NUMBER: Record<string, number> = {
  ALIAS_MAX_LENGTH: 50,
  MAIL_MAX_LENGTH: 255,
};

export const APP_NAME = "Lx Connect";

export enum DTWIN_OPERATION_TYPE {
  FIRMWARE_UPDATE = "FIRM_UPDATE",
}

export enum DTWIN_DEVICE_TYPE {
  LPP4 = "LPP4",
}
