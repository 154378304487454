<script setup lang="ts">
import { ref } from "vue";
import { FILTER_SEARCH_TXT_MIN_LENGTH } from "~/core/constants/constants";

const { t } = useI18n();

const props = defineProps<{
  modelValue: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const deviceName = ref(props.modelValue ?? "");

watch(
  () => deviceName.value,
  (text: string) => {
    if (text.length >= FILTER_SEARCH_TXT_MIN_LENGTH) {
      emit("update:modelValue", text);
    } else {
      emit("update:modelValue", "");
    }
  },
);
</script>
<template>
  <div class="px-6 pb-6">
    <search-input
      v-model="deviceName"
      :placeholder="t('filters.searchDevice')"
    />
  </div>
</template>
