export const PATHS = {
  ABOUT: "/about",
  APPLICATIONS: "/applications",
  APPLICATIONS_SUBPATH: "applications",
  CAMPAIGNS: "/campaigns",
  CAMPAIGNS_DEVICES: "/campaigns/devices",
  CAMPAIGNS_S4_LOCAL_STATION: "/campaigns/S4-local-station",
  CAMPAIGNS_REPORTS: "/campaigns/reports",
  DASHBOARD: "/dashboard",
  DETAILS_SUBPATH: "details",
  DEVICES_DVTM_ESOFT: "/devices",
  DEVICES_DVTM_ESOFT_SUBPATH: "devices",
  DTWINS: "/dtwins",
  DTWINS_DESCRIPTION: `/dtwins/:id/description`,
  DTWINS_FIRMWARE: `/dtwins/:id/firmware`,
  DTWINS_CONFIGURATION: `/dtwins/:id/configuration`,
  DTWINS_DATA_VISUALISATION: `/dtwins/:id/data-visualisation`,
  DTWINS_LOGS: `/dtwins/:id/logs`,
  DTWINS_SUBPATH: "dtwins",
  DTWINS_DESCRIPTION_SUBPATH: "description",
  DTWINS_FIRMWARE_SUBPATH: "firmware",
  DTWINS_CONFIGURATION_SUBPATH: "configuration",
  DTWINS_DATA_VISUALISATION_SUBPATH: "data-visualisation",
  DTWINS_LOGS_SUBPATH: "logs",
  ERROR: "/error",
  FLEETS: "/fleets",
  GROUPS_SUBPATH: "groups",
  LOGS: "/logs",
  MAP: "/map",
  PARAMETERS_APPLICATIONS: "/parameters/applications",
  PARAMETERS_APPLICATIONS_CREATE: "/parameters/applications/create",
  PARAMETERS_APPLICATIONS_MOBILE_APP: "/parameters/applications/mobile-app",
  PARAMETERS_APPLICATIONS_THIRD_PART_APP:
    "/parameters/applications/third-part-app",
  PARAMETERS_CERTIFICATES: "/parameters/certificates",
  PARAMETERS_CERTIFICATES_KEYSTORE: "/parameters/certificates/keystore",
  PARAMETERS_CERTIFICATES_TRUSTSTORE: "/parameters/certificates/truststore",
  PARAMETERS_CERTIFICATE_ALERT_REPORTING:
    "/parameters/certificate-alert-reporting",
  PARAMETERS_PKI_CONNECTOR: "/parameters/pki-connector",
  PARAMETERS_DEVICE_CONFIGURATION: "/parameters/device-configuration",
  PARAMETERS_FIRMWARES: "/parameters/firmwares",
  PARAMETERS_LICENSE: "/parameters/license",
  PARAMETERS_TRUSTSTORE: "/parameters/truststore",
  SECTORS: "/sectors",
  SECTORS_DEVICES: "/sectors/:code/devices",
  SECTORS_DTWINS: "/sectors/:code/dtwins",
  SECTORS_APPLICATIONS: "/sectors/:code/applications",
  SECTORS_USERS: "/sectors/:code/users",
  SECTORS_GROUPS: "/sectors/:code/groups",
  USER_ACCOUNT: "/user-account",
  USER_ACCOUNT_PROFILE: "/user-account/profile",
  USER_ACCOUNT_LANGUAGE: "/user-account/language",
  USER_ACCOUNT_CERTIFICATE: "/user-account/certificate",
  USER_MANAGEMENT: "/user-management",
  USER_MANAGEMENT_GROUPS: "/user-management/groups",
  USER_MANAGEMENT_GROUPS_NEW: "/user-management/groups/new",
  USER_MANAGEMENT_PROFILES: "/user-management/profiles",
  USER_MANAGEMENT_PROFILES_CREATE: "/user-management/profiles/create",
  USER_MANAGEMENT_USERS: "/user-management/users",
  USER_MANAGEMENT_USERS_NEW: "/user-management/users/new",
  USERS_SUBPATH: "users",
  NOT_FOUND: "/:pathMatch(.*)*",
  UNAUTHORIZED: "/unauthorized",
  CONNECT_MY_MOBILE_APP: "/connect-my-mobile-app",
};
