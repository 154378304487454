<script setup lang="ts">
import type { ComputedRef, Ref } from "vue";
import { ref } from "vue";
import type { DeviceTypeFilter } from "~/composables/useDeviceTypeFilters";
import { isESoftMagicDeviceTypeByValue } from "~/composables/useDeviceTypeFilters";
import { useDevices } from "~/composables/useDevices";
import { useDtwins } from "~/composables/useDtwins";
import { SearchMode } from "~/core/composables/useSearch";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import type { Device } from "~/core/models/Device.interface";
import { Filters } from "~/core/models/filters";
import type { Dtwin } from "~/modules/dtwin/models/Dtwin.interface";

const { t } = useI18n();

const props = defineProps<{
  /** Selected devices from the list> */
  modelValue?: Device[];
  /** Device types used as filter. */
  types: DeviceTypeFilter[];
  /** Device declinations used as filter. Only used for eSoft devices.> */
  declinations?: string[];
  /** Device name used as filter. */
  name?: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const page = ref(DEFAULT_FIRST_PAGE);
const pageSize = ref(DEFAULT_PAGE_SIZE);

/** Do not use eSoft if at least one type is not supported by the microservice. */
const useDtwinAsSource = computed(() => {
  return toRaw(props.types).every(
    (type) => !isESoftMagicDeviceTypeByValue(type.value),
  );
});

/** Setup of the device source via composable usage. */
const { isLoading, results, fetchData, setFilter, search, onSearch } =
  useDtwinAsSource.value
    ? useDtwins(SearchMode.FILTER_SEARCH)
    : useDevices(SearchMode.FILTER_SEARCH);

const filterMap: Ref<Map<Filters, any> | undefined> = ref();

async function loadData() {
  /*
   * If dtwin is used, every device fetch must be filtered using the selected
    types. In the current implementation only one type can be selected.
    */
  if (useDtwinAsSource) {
    if (props.types.length > 0 && props.types[0].uids !== undefined) {
      setFilter(Filters.DTWIN_MODEL_TYPE, props.types[0].uids);
    }
  }
  await fetchData(page.value, pageSize.value, new Map());
}

/**
 * Mapping function to fake dtwin as esoft device due to the lack of filter
 * content encapsulation. The idea is to extract the required information and
 * use the Device model as device filter encapsulation :(.
 * Use the serial number if the dtwin device does not have friendly name.
 *
 * @param device
 */
function dtwinToDevice(device: Dtwin): Device {
  return {
    id: device.uid,
    name:
      device.attributes?.friendlyName !== undefined &&
      device.attributes?.friendlyName !== ""
        ? device.attributes?.friendlyName
        : (device.attributes?.serialNumber ?? ""),
  };
}

/**
 * Contains the fetched devices from esoft or dtwin using the Device model as
 * encapsulation.
 */
const devices: ComputedRef<Device[]> = computed(() => {
  if (useDtwinAsSource.value) {
    return (toRaw(results.value?.data) as Dtwin[] | undefined)
      ?.map((device: Dtwin) => dtwinToDevice(device))
      ?.filter((device: Device) => {
        return device.name !== undefined;
      }) as Device[];
  }
  return results.value?.data ?? [];
});

const deviceNumber = computed(() => {
  return results.value?.context.totalCount;
});

// Apply provided default filters
const updateListFilter = () => {
  if (filterMap.value !== undefined) {
    for (const [filter, value] of filterMap.value) {
      setFilter(filter, value ?? "");
    }
  }
};

watch(
  () => props.name,
  (filterByName) => {
    // The dtwin device can have no friendly name defined. The serial number is
    // used as fallback in this case.
    const filterType = useDtwinAsSource
      ? Filters.DTWIN_NAME_OR_SERIAL_NUMBER
      : Filters.NAME;
    if (!filterMap.value) {
      filterMap.value = new Map<Filters, any>();
    }
    if (!filterByName) {
      if (filterMap.value.has(filterType)) {
        filterMap.value.set(filterType, "");
      }
    } else {
      filterMap.value.set(filterType, filterByName);
    }

    // When filtering by name, the list should be reset to it initial state.
    page.value = DEFAULT_FIRST_PAGE;
    updateListFilter();
    search();
  },
);

watch(
  () => props.types,
  (filterTypes) => {
    const typeValues = filterTypes.map((type) => type.value);
    if (!filterMap.value) {
      filterMap.value = new Map<Filters, any>();
    }
    if (!typeValues || typeValues.length === 0) {
      if (filterMap.value.has(Filters.MODEL_TYPE)) {
        filterMap.value.set(Filters.MODEL_TYPE, []);
      }
    } else {
      filterMap.value.set(Filters.MODEL_TYPE, typeValues);
    }
    page.value = DEFAULT_FIRST_PAGE;
    updateListFilter();
    search();
  },
);

watch(
  () => props.declinations,
  (filterValues) => {
    if (!filterMap.value) {
      filterMap.value = new Map<Filters, any>();
    }
    if (!filterValues || filterValues.length === 0) {
      if (filterMap.value.has(Filters.MODEL_DECLINATION)) {
        filterMap.value.set(Filters.MODEL_DECLINATION, []);
      }
    } else {
      filterMap.value.set(Filters.MODEL_DECLINATION, filterValues);
    }

    page.value = DEFAULT_FIRST_PAGE;
    updateListFilter();
    search();
  },
);

const selectedItems = computed({
  get() {
    if (props.modelValue) {
      return props.modelValue.map(
        (deviceDvtmEsoft) => deviceDvtmEsoft.id ?? "",
      );
    }

    return [];
  },
  set(selected?: string[]) {
    // Note: we are using destructuring assignment syntax here.
    if (selected != null) {
      const deviceDvtmEsoftItems: (Device | undefined)[] = selected
        .map((currentDeviceId) => {
          // If the device is not in the device list found, use modelValue where there was the previous selected device list
          let deviceDvtmEsoft: Device | undefined = devices.value?.find(
            (currentDevice) => currentDevice.id === currentDeviceId,
          );
          if (!deviceDvtmEsoft) {
            deviceDvtmEsoft = props.modelValue?.find(
              (currentDevice) => currentDevice.id === currentDeviceId,
            );
          }
          return deviceDvtmEsoft;
        })
        .filter((currentDevice) => currentDevice !== undefined);
      emit("update:modelValue", deviceDvtmEsoftItems);
    } else {
      emit("update:modelValue", []);
    }
  },
});

watch(() => page.value, loadData);

onSearch(loadData);
</script>

<template>
  <div class="pb-6 px-6">
    <div v-if="deviceNumber" class="flex justify-between mb-2">
      <span class="flex items-center font-medium">
        {{ t("dtwins.deviceFound", deviceNumber) }}
      </span>
      <pagination-buttons
        v-model:page="page"
        :page-size="pageSize"
        :total-count="deviceNumber"
      />
    </div>

    <filter-selectable-list
      v-model="selectedItems"
      :data="devices"
      :is-loading="isLoading"
      :empty-text="t('device.empty')"
      :header="t('logs.filters.entityClass.value.device-dvtm-esoft')"
      header-class="underline text-base"
      prop="name"
      item-prop="id"
    />
  </div>
</template>
