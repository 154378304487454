<script lang="ts" setup>
import type { ApplicationModelConfiguration } from "~/modules/certificateConfiguration/models/ApplicationModelConfiguration.interface";
import { CertificateConfigurationParameters } from "~/modules/certificateConfiguration/models/CertificateConfigurationParameters.enum";
import { S4Types } from "~/modules/certificateConfiguration/models/S4Types.enum";

defineProps<{
  s4Tool: ApplicationModelConfiguration;
  s4Manager: ApplicationModelConfiguration;
  s4View: ApplicationModelConfiguration;
  sg4000: ApplicationModelConfiguration;
}>();
defineEmits(["change"]);

const { t } = useI18n();
</script>

<template>
  <h3>{{ t("table.header.applications") }}</h3>
  <section class="config-row">
    <certificate-configuration-renewal
      :delay="s4Tool.delay"
      :polling="s4Tool.polling"
      :is-autoactivated="s4Tool.autoActivation"
      :title="t('parameters.certificates.s4ToolsApp')"
      :is-app="true"
      @select-change="
        $emit(
          'change',
          $event,
          S4Types.TOOLS,
          CertificateConfigurationParameters.DELAY,
        )
      "
      @polling-change="
        $emit(
          'change',
          $event,
          S4Types.TOOLS,
          CertificateConfigurationParameters.POLLING,
        )
      "
      @auto-activation-change="
        $emit(
          'change',
          $event,
          S4Types.TOOLS,
          CertificateConfigurationParameters.AUTO_ACTIVATION,
        )
      "
    />

    <certificate-configuration-renewal
      :delay="s4Manager.delay"
      :polling="s4Manager.polling"
      :is-autoactivated="s4Manager.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.s4ManagerApp')"
      @select-change="
        $emit(
          'change',
          $event,
          S4Types.MANAGER,
          CertificateConfigurationParameters.DELAY,
        )
      "
      @polling-change="
        $emit(
          'change',
          $event,
          S4Types.MANAGER,
          CertificateConfigurationParameters.POLLING,
        )
      "
      @auto-activation-change="
        $emit(
          'change',
          $event,
          S4Types.MANAGER,
          CertificateConfigurationParameters.AUTO_ACTIVATION,
        )
      "
    />

    <certificate-configuration-renewal
      :delay="s4View.delay"
      :polling="s4View.polling"
      :is-autoactivated="s4View.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.s4ViewApp')"
      @select-change="
        $emit(
          'change',
          $event,
          S4Types.VIEW,
          CertificateConfigurationParameters.DELAY,
        )
      "
      @polling-change="
        $emit(
          'change',
          $event,
          S4Types.VIEW,
          CertificateConfigurationParameters.POLLING,
        )
      "
      @auto-activation-change="
        $emit(
          'change',
          $event,
          S4Types.VIEW,
          CertificateConfigurationParameters.AUTO_ACTIVATION,
        )
      "
    />

    <certificate-configuration-renewal
      :delay="sg4000.delay"
      :polling="sg4000.polling"
      :is-autoactivated="sg4000.autoActivation"
      :is-app="true"
      :title="t('parameters.certificates.sg4000')"
      @select-change="
        $emit(
          'change',
          $event,
          S4Types.SG4000,
          CertificateConfigurationParameters.DELAY,
        )
      "
      @polling-change="
        $emit(
          'change',
          $event,
          S4Types.SG4000,
          CertificateConfigurationParameters.POLLING,
        )
      "
      @auto-activation-change="
        $emit(
          'change',
          $event,
          S4Types.SG4000,
          CertificateConfigurationParameters.AUTO_ACTIVATION,
        )
      "
    />
  </section>
</template>
