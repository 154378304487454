<script setup lang="ts">
import { renderToString } from "@vue/server-renderer";
import type { Position } from "geojson";
import type { LatLngExpression, LatLngLiteral, LatLngTuple } from "leaflet";
import * as L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import type { ComputedRef } from "vue";
import { computed, createSSRApp, onMounted } from "vue";
import { useCartography } from "~/modules/cartography/composables/useCartography";
import type { FeatureCollectionWithStyle } from "~/modules/cartography/models/FeatureCollectionWithStyle.interface";
import type { MapCenter } from "~/modules/cartography/models/MapCenter.interface";
import ILxcTarget from "~icons/lxc/target";

const props = defineProps<{
  points?: FeatureCollectionWithStyle | undefined;
  center?: MapCenter | undefined;
  zoom?: number | undefined;
}>();

const { cartographySettings, getCartographySettings } = useCartography();

const center: ComputedRef<LatLngExpression | undefined> = computed(() => {
  let computedCenter;
  if (
    props.center?.latitude !== undefined &&
    props.center?.longitude !== undefined
  ) {
    computedCenter = [
      props.center?.latitude,
      props.center?.longitude,
    ] as LatLngTuple;
  }
  return computedCenter;
});

const preventInvalidValue = (value?: number) => {
  if (
    value !== undefined &&
    cartographySettings.value &&
    cartographySettings.value.zoom?.min &&
    cartographySettings.value.zoom?.max &&
    (value < cartographySettings.value.zoom.min ||
      value > cartographySettings.value.zoom.max)
  ) {
    return undefined;
  }
  return value;
};
const defaultZoom: ComputedRef<number | undefined> = computed(
  () =>
    preventInvalidValue(props.zoom) ?? cartographySettings.value?.zoom?.default,
);

const formatLatLng = (coordinates?: number[]): LatLngLiteral => {
  if (coordinates !== undefined && coordinates?.length >= 2) {
    return { lat: coordinates[0], lng: coordinates[1] };
  }
  return { lat: 0, lng: 0 };
};

/**
 * Use server-side rendering to use the raw HTML in the map (required by leaflet `iconDiv`). The icon is wrapped in a
 * div to allow style modifications (for instance the icon color).
 *
 * @param component
 * @param componentClass
 * @param color
 * @param overlayComponent
 */
const renderComponentAsHtmlWithStyle = async (
  component: object,
  componentClass?: string[] | string,
  color?: string,
  overlayComponent?: object | string,
) => {
  const element = createSSRApp(component);
  const componentAsHtml = await renderToString(element);
  // overlay component refers to the device type icon. This icon is displayed on top of the main marker icon.
  if (overlayComponent) {
    if (typeof overlayComponent === "string") {
      return `<div style="color: ${color}" class="${componentClass} icon-class">
        ${componentAsHtml}
        <div class="inner-icon-class">${overlayComponent}</div>
      </div>`;
    }
    const overlayElement = createSSRApp(overlayComponent);
    const overlayComponentAsHtml = await renderToString(overlayElement);
    return `<div ${color ? `style="color: ${color}"` : ""} class="${componentClass ?? ""} icon-class">
      ${componentAsHtml}
      <div class="inner-icon-class">${overlayComponentAsHtml}</div>
    </div>`;
  }
  return `<div ${color ? `style="color: ${color}"` : ""} class="${componentClass}">${componentAsHtml}</div>`;
};

const createMarker = async (
  coordinates: Position,
  icon?: object | undefined,
  iconClass?: string[] | string | undefined,
  color?: string | undefined,
  iconAnchor?: L.PointExpression | undefined,
  overlayIcon?: object | string | undefined,
  onClick?: () => void | undefined,
) => {
  const iconAsHtml = await renderComponentAsHtmlWithStyle(
    icon ?? ILxcTarget,
    iconClass,
    color,
    overlayIcon,
  );
  const marker = L.marker(formatLatLng(coordinates), {
    icon: L.divIcon({
      html: iconAsHtml,
      className: "map-icon",
      iconAnchor,
    }),
  });
  if (onClick !== undefined) {
    marker.on("click", () => {
      onClick();
    });
  }
  return marker;
};

const createMarkersWithClustering = async () => {
  const markers = L.markerClusterGroup({ chunkedLoading: true });
  for (const feature of props.points?.features ?? []) {
    if (feature?.geometry?.coordinates !== undefined) {
      const marker = await createMarker(
        feature?.geometry?.coordinates,
        feature?.properties.icon,
        feature?.properties.class,
        feature?.properties.color,
        feature?.properties.iconAnchor,
        feature?.properties.overlayIcon,
        feature?.properties.onClick,
      );
      markers.addLayer(marker);
    }
  }
  return markers;
};

onMounted(async () => {
  await getCartographySettings();
  const baseMaps: Record<string, L.TileLayer> = {};

  if (cartographySettings.value) {
    for (const provider of cartographySettings.value.providers) {
      baseMaps[provider.name] = L.tileLayer(provider.url, provider.options);
    }
  }
  const baseMapFirstKey = Object.keys(baseMaps)[0];
  const markers = await createMarkersWithClustering();

  // Prevent zooming and dragging if there are no layers
  const mapInteraction = markers.getLayers().length > 0;

  const map = L.map("mapContainer", {
    center: center.value,
    zoom: defaultZoom.value,
    dragging: mapInteraction,
    scrollWheelZoom: mapInteraction,
    touchZoom: mapInteraction,
    zoomControl: mapInteraction,
    doubleClickZoom: mapInteraction,
    layers: [baseMaps[baseMapFirstKey]],
  });

  if (Object.keys(baseMaps).length > 1) {
    L.control.layers(baseMaps).addTo(map);
  }

  map.addLayer(markers);
  // If there is no layers, display the full map without bounds.
  if (markers.getLayers().length > 0) {
    map.fitBounds(markers.getBounds());
  } else {
    map.fitWorld();
  }
});
</script>

<template>
  <!-- The div containing the map initialized in the onMounted method -->
  <div id="mapContainer" class="!z-0" />
</template>

<style lang="scss">
.map-icon {
  background: transparent; /* Remove any background - leaflet adds an ugly white background by default */
}
/* Override of classes from leaflet components */
.leaflet-control-layers-base,
.leaflet-control-layers-overlays {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  label {
    span {
      display: flex;
      column-gap: 0.2rem;
      justify-content: start;
      align-items: center;
      .leaflet-control-layers-selector {
        margin-top: 0;
        position: static;
        &:disabled {
          opacity: 50%;
          background: #667085;
        }
      }
    }
  }
}
a[href] {
  &.leaflet-control-zoom-in,
  &.leaflet-control-zoom-out {
    text-decoration: none;
  }
}
.leaflet-marker-icon {
  .icon-class {
    position: relative;
    .inner-icon-class {
      color: white;
      position: absolute;
      top: 15%;
      left: 35%;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &.marker-cluster {
    background-color: rgba(78, 114, 163, 0.6);
    div {
      background-color: rgba(14, 73, 129, 1);
      span {
        color: white;
      }
    }
  }
}
</style>
