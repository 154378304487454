<script setup lang="ts">
import type {
  DeviceModelUiConfigI,
  OperationStatus,
} from "@lxc/app-device-types";
import type { PointExpression } from "leaflet";
import { NO_CONTENT } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { useConfigStore } from "~/core/stores/useConfigStore";
import type { FeatureCollectionWithStyle } from "~/modules/cartography/models/FeatureCollectionWithStyle.interface";
import type { MapCenter } from "~/modules/cartography/models/MapCenter.interface";
import { computeIconClass } from "~/modules/cartography/utils/deviceMapTools";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import ILxcDropletDown from "~icons/lxc-custom/droplet-down";

export interface LxcDtwinsDescriptionGeolocationI {
  address: string;
  latitude: string;
  longitude: string;
  isAllowedToEdit: boolean;
  doesUpdateSucceed: boolean | undefined;
}

interface Props {
  modelValue: LxcDtwinsDescriptionGeolocationI;
  dtwinModelUid?: string;
  operationStatuses?: Record<string, OperationStatus>;
  deviceModelUiConfig?: DeviceModelUiConfigI;
  isLoadingModelUiConfig?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:address"]);

const { t } = useI18n();
const { isCartographyEnabled } = useConfigStore();

const addressFieldRef = ref();
const address: Ref<string> = ref(props.modelValue.address);
const isEditing: Ref<boolean> = ref(false);
const isSending: Ref<boolean> = ref(false);
const latLongRepresentation: Ref<string> = ref(
  t("dtwins.form.description.geolocation.attributes.latlong", {
    latitude: props.modelValue.latitude,
    longitude: props.modelValue.longitude,
  }),
);
const isEdited: ComputedRef<boolean> = computed(
  () => addressFieldRef.value?.hasValueChanged ?? false,
);

// When the request to the backend succeed, reset the editing flag to true
watch(
  () => props.modelValue,
  (modelValue) => {
    if (modelValue.doesUpdateSucceed) {
      isEditing.value = false;
      address.value = modelValue.address;
    }
    isSending.value = false;
  },
);

const shouldDisplayMap: ComputedRef<boolean> = computed(() => {
  return (
    props.modelValue.latitude !== NO_CONTENT &&
    props.modelValue.longitude !== NO_CONTENT &&
    isCartographyEnabled
  );
});

// By default, center on the point.
const mapCenter: ComputedRef<MapCenter> = computed(() => {
  return {
    latitude:
      props.modelValue.latitude !== NO_CONTENT
        ? parseFloat(props.modelValue.latitude)
        : undefined,
    longitude:
      props.modelValue.longitude !== NO_CONTENT
        ? parseFloat(props.modelValue.longitude)
        : undefined,
  };
});

/**
 * These magic numbers place the tip of the icon on the device's gps point.
 */
const iconAnchorMagicNumbers: PointExpression = [13.1, 32];

const mapGeoJson: ComputedRef<FeatureCollectionWithStyle> = computed(() => {
  const coordinates = [];
  if (
    props.modelValue.latitude !== NO_CONTENT &&
    props.modelValue.longitude !== NO_CONTENT
  ) {
    coordinates.push(parseFloat(props.modelValue.latitude));
    coordinates.push(parseFloat(props.modelValue.longitude));
  }
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates,
        },
        properties: {
          icon: ILxcDropletDown,
          iconAnchor: iconAnchorMagicNumbers,
          overlayIcon: props.deviceModelUiConfig?.properties.svgIcon,
          class: computeIconClass(
            props.operationStatuses,
            props.deviceModelUiConfig?.properties.svgIcon,
          ),
        },
      },
    ],
  };
});

function onSave(newValue: string): void {
  isSending.value = true;
  emit("update:address", newValue);
}

// activate the leave page confirm message if edited
watch(
  () => isEdited.value,
  (isEdited) => useConfirmLeavePageStore().activate(isEdited),
);
</script>

<template>
  <description-container
    :title="t(`dtwins.form.description.geolocation.title`)"
  >
    <template #left>
      <editable-field
        ref="addressFieldRef"
        v-model:is-editing="isEditing"
        :field-value="address"
        :label="t('dtwins.form.description.geolocation.attributes.address')"
        :is-saving="isSending"
        class="mb-4"
        @validate="onSave"
      />
      <lxc-form-item
        :label="t('dtwins.form.description.geolocation.attributes.status')"
      >
        <lxc-input v-model="latLongRepresentation" type="text" read-only />
      </lxc-form-item>
    </template>
    <template #right>
      <container-component :px="0" :py="0" :is-loading="isLoadingModelUiConfig">
        <map-component
          v-if="shouldDisplayMap"
          :center="mapCenter"
          :zoom="18"
          :points="mapGeoJson"
          class="flex aspect-video"
        />
      </container-component>
    </template>
  </description-container>
</template>
<style lang="scss">
// This css property is define here because tailwind css doesn't allow to target a grandchildren
.default-icon {
  svg {
    path:nth-child(2) {
      color: white;
    }
  }
}
</style>
