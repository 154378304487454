import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { Operation } from "~/core/models/Operation.type";
import LxcError from "~/core/utils/LxcError";
import type { CampaignType } from "~/modules/campaign/models/CampaignType.enum";
import CampaignService from "~/modules/campaign/services/campaign.service";

export function useCampaignObjectOperations(
  objectId: string,
  campaignType?: CampaignType,
) {
  const operations = ref<ApiListResult<Operation> | null>(null);
  const error = ref<LxcError | null>(null);
  const isLoading = ref<boolean>(false);

  async function fetchData(page?: number, pageSize?: number, sort?: string) {
    isLoading.value = true;
    error.value = null;

    const response = await CampaignService.getCampaignObjectOperations(
      objectId,
      page,
      pageSize,
      sort,
      campaignType,
    );

    if (LxcError.check(response)) {
      operations.value = null;
      error.value = response;
    } else {
      operations.value = response;
    }

    isLoading.value = false;
  }

  return {
    fetchData,
    isLoading,
    operations,
    error,
  };
}
