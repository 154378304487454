import { TreeNodes } from "~/core/models/TreeNodes.class";
import RestService from "~/core/services/rest.service";
import type { Sector } from "~/modules/sector/models/Sector.interface";
import type { SectorItemActionResult } from "~/modules/sector/models/SectorItemActionResult.interface";
import type { SectorOrg } from "~/modules/sector/models/SectorOrg.type";
import type { SectorStatus } from "~/modules/sector/models/SectorStatus.interface";
import type { UserSessionSector } from "~/modules/user/models/UserSessionSector.interface";

export class SectorService extends RestService {
  protected BASE_URL = "/sectors";

  /**
   * Retrieve sector with his parents
   * @param code
   */
  getSectorByCode(code: string): Promise<Sector> {
    return this.$api.get(`${this.BASE_URL}/${code}`);
  }

  /**
   * Retrieves sectors by codes
   * @param codes
   */
  getSectorsByCodes(codes: string[]): Promise<UserSessionSector[]> {
    return this.$api.get(`${this.BASE_URL}/flatten`, { params: { codes } });
  }

  getAllFlattenSectors(): Promise<Sector[]> {
    return this.$api.get(`${this.BASE_URL}/flatten`);
  }

  getSectors(search?: string): Promise<Sector> {
    const params: { search?: string } = search ? { search } : {};

    return this.$api.get(this.BASE_URL, { params });
  }

  flattenSectors(node: Sector, result: Sector[] = []) {
    result.push(node);
    if ((node.children?.length ?? 0) > 0) {
      for (const child of node.children!) {
        this.flattenSectors(child, result);
      }
    }
    return result;
  }

  /**
   * Create a sector
   * @param sector
   */
  createSector(sector: SectorOrg) {
    return this.$apiV2.post(this.BASE_URL, { sector });
  }

  /**
   * Update a sector
   * @param sectorId
   * @param sector
   */
  updateSector(sectorId: string, sector: SectorOrg) {
    return this.$apiV2.put(`${this.BASE_URL}/${sectorId}`, { sector });
  }

  async getSectorStatus(sectorId: string): Promise<SectorStatus> {
    return (await this.$api.get(
      `${this.BASE_URL}/${sectorId}/status`,
    )) as SectorStatus;
  }

  async isAllowedToRemoveSector(sectorId: string): Promise<boolean> {
    return (await this.getSectorStatus(sectorId))?.deletable ?? false;
  }

  /**
   * Map a given sector to a tree node used by tree component
   * @param sector sector to map into a LxcTreeNodesModel
   */
  sectorToTreeNode(sector: Sector) {
    return new TreeNodes(
      sector.code,
      sector.label,
      !sector.grantedStatus.granted,
    );
  }

  /**
   * Rebuild the sector hierarchy as a LxcTreeNodesModel hierarchy recursively
   * @param sector Root sector to transform
   * @param result the list of all LxcTreeNodesModel
   */
  mapSectorsToTreeNodes(sector: Sector, result: TreeNodes[] = []) {
    const sectorTreeNode = this.sectorToTreeNode(sector);
    if ((sector.children?.length ?? 0) > 0) {
      const childrenTreeNodes: TreeNodes[] = [];
      for (const child of sector.children!) {
        this.mapSectorsToTreeNodes(child, childrenTreeNodes);
      }
      sectorTreeNode.children = childrenTreeNodes;
    }

    result.push(sectorTreeNode);

    return result;
  }

  /**
   * Remove a sector
   * @param sectorId
   */
  removeSector(sectorId: string) {
    return this.$apiV2.delete(`${this.BASE_URL}/${sectorId}`);
  }

  /**
   * Detach from a sector
   * @param sectorCode
   * @param ids
   * @param itemsType
   */
  detachFromSector(
    sectorCode: string,
    ids: string[],
    itemsType: string,
  ): Promise<SectorItemActionResult[]> {
    return this.$api.put(`${this.BASE_URL}/${sectorCode}/detach/${itemsType}`, {
      ids,
    });
  }

  attachToSector(
    sectorCode: string,
    ids: string[],
    itemsType: string,
  ): Promise<SectorItemActionResult[]> {
    return this.$api.put(`${this.BASE_URL}/${sectorCode}/attach/${itemsType}`, {
      ids,
    });
  }
}

export default new SectorService();
