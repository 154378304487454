<script setup lang="ts">
import type { SectorI, UserI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted } from "vue";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import { PATHS } from "~/core/constants/paths";
import { useUser } from "~/modules/user/composables/useUser";
import { router } from "~/plugins/router";
import { SectorResource } from "~/types";

const props = defineProps<{
  sector: SectorI;
}>();

const { t } = useI18n();
const {
  isLoading,
  results: users,
  error,
  fetchData,
  canManageItems,
  getDescription,
} = useUser();

const selectedItems: Ref<any[]> = ref([]);
const displayActions = computed(() => canManageItems(props.sector));

onMounted(fetchSectorUsers);

async function fetchSectorUsers(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  const params = new Map();
  params.set("sectorsInclude", [props.sector.code]);
  await fetchData(page, pageSize, params);
}

async function refresh() {
  await fetchSectorUsers();
  selectedItems.value = [];
}

function onRowClick(user: UserI) {
  router.push(`${PATHS.USER_MANAGEMENT_USERS}/${user.id}`);
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="id"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.USER"
    @change="refresh"
  >
    <lxc-table-column prop="lastName" :label="t('table.header.lastname')" />
    <lxc-table-column prop="firstName" :label="t('table.header.firstname')" />
    <lxc-table-column :label="t('table.header.profile')" width="12rem">
      <template #default="scope">
        <TagForList :tags="scope.row.userProfiles" :max="1" />
      </template>
    </lxc-table-column>
  </lxc-sector-toolbar>

  <container-component
    :px="0"
    :py="0"
    class="mt-4"
    :is-loading="isLoading && !users"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="id"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="users"
      :display-actions="displayActions"
      :resource="SectorResource.USER"
      :get-description="getDescription"
      @pagination:get-next-page="fetchSectorUsers"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <lxc-table-column
        prop="lastName"
        :label="t('table.header.lastname')"
        min-width="20rem"
      />
      <lxc-table-column
        prop="firstName"
        :label="t('table.header.firstname')"
        min-width="20rem"
      />
      <lxc-table-column :label="t('table.header.profile')" width="15rem">
        <template #default="scope">
          <TagForList :tags="scope.row.userProfiles" :max="1" />
        </template>
      </lxc-table-column>
    </lxc-sectors-details-table>
  </container-component>
</template>
