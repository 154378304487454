import type { FilterOptions } from "~/core/models/filters";

export const CONNECTIVITY_OPTIONS: FilterOptions = {
  label: "filters.connectivity",
  options: [
    {
      value: "Active",
      label: "filters.connectivityState.active",
    },
    {
      value: "Inactive",
      label: "filters.connectivityState.inactive",
    },
  ],
};
