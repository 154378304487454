import { storeToRefs } from "pinia";
import { useAcl } from "vue-simple-acl";
import type { SearchMode } from "~/core/composables/useSearch";
import { useSearch } from "~/core/composables/useSearch";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import type { ApiListResult } from "~/core/models/ApiListResult.interface";
import type { Pagination } from "~/core/models/Pagination.interface";
import type { FiltersSelection } from "~/core/models/filters";
import { Filters, FiltersType } from "~/core/models/filters";
import LxcError from "~/core/utils/LxcError";
import { fetchAllApiListResult } from "~/core/utils/fetch";
import { toLowerCaseNoAccents } from "~/core/utils/string-tools";
import { ActionsOrActionTypesContext } from "~/modules/log/models/ActionsOrActionTypesContext.enum";
import type { TranslatedLogAttribute } from "~/modules/log/models/TranslatedLogAttribute.interface";
import actionsService from "~/modules/log/services/actions.service";
import logsService from "~/modules/log/services/logs.service";
import { useLogActionActionTypeStore } from "~/modules/log/stores/useLogActionActionTypeStore";
import { useUserSession } from "~/modules/user/stores/useUserSession";

export function useActionsActionTypes(
  actionsOrActionTypesContext?: ActionsOrActionTypesContext,
  searchMode?: SearchMode,
) {
  const { can } = useAcl();

  const {
    allActions,
    allActionTypes,
    allActionsLanguage,
    allActionTypesLanguage,
  } = storeToRefs(useLogActionActionTypeStore());

  const userSessionStore = useUserSession();

  const results: Ref<ApiListResult<TranslatedLogAttribute> | undefined> = ref();
  const error: Ref<LxcError | undefined> = ref();
  const isLoading: Ref<boolean> = ref(false);

  const appliedFilters: FiltersSelection = new Map([
    [Filters.NAME, { key: "name", operator: "=", value: "" }],
  ]);

  const { searchParams, filters, setFilter, onSearch } = useSearch(
    FiltersType.RAW,
    appliedFilters,
    searchMode,
  );

  function fetchData(page: number, pageSize: number) {
    isLoading.value = true;
    const response = searchActionActionTypes(
      page,
      pageSize,
      searchParams.value as string,
    );
    if (LxcError.check(response)) {
      error.value = response;
    } else {
      results.value = response;
    }
    isLoading.value = false;
  }

  function searchActionActionTypes(
    page: number,
    pageSize: number,
    search: string,
  ): ApiListResult<TranslatedLogAttribute> | LxcError | undefined {
    // Normalize (remove accents) and lowercase the search
    const lowercasedSearch = toLowerCaseNoAccents(search);

    let filteredList: TranslatedLogAttribute[] = [];

    // Filter allActions or allActionTypes lists to find items matching the search
    if (actionsOrActionTypesContext === ActionsOrActionTypesContext.ACTION) {
      if (allActions.value && !LxcError.check(allActions.value)) {
        filteredList = (allActions.value as TranslatedLogAttribute[]).filter(
          (action) =>
            toLowerCaseNoAccents(action.translation).includes(lowercasedSearch),
        );
      } else {
        return allActions.value;
      }
    } else {
      if (allActionTypes.value && !LxcError.check(allActionTypes.value)) {
        filteredList = (
          allActionTypes.value as TranslatedLogAttribute[]
        ).filter((actionType) =>
          toLowerCaseNoAccents(actionType.translation).includes(
            lowercasedSearch,
          ),
        );
      } else {
        return allActionTypes.value;
      }
    }

    // Slice the filtered list to return only the relevant items for the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedList = filteredList.slice(startIndex, endIndex);

    // Calculate pagination
    const context: Pagination = {
      page: page,
      pageSize: pageSize,
      totalCount: filteredList.length,
      count: paginatedList.length,
    };

    return {
      context,
      data: paginatedList,
    };
  }

  function canManageItems() {
    return can(ACLRoles.LOGS_LIST_AUDIT);
  }

  async function fetchAllActions(): Promise<
    TranslatedLogAttribute[] | LxcError
  > {
    if (
      !allActions.value ||
      LxcError.check(allActions.value) ||
      userSessionStore.userSession?.language !== allActionsLanguage.value
    ) {
      allActions.value = await fetchAllApiListResult(
        (page: number, pageSize: number) =>
          actionsService.getActions(page, pageSize),
      );
      allActionsLanguage.value = userSessionStore.userSession?.language;
    }
    // HACK: cast to avoid a strange error raised by IDE -> to be fixed
    return allActions.value as TranslatedLogAttribute[] | LxcError;
  }

  async function fetchAllActionTypes(): Promise<
    TranslatedLogAttribute[] | LxcError
  > {
    if (
      !allActionTypes.value ||
      LxcError.check(allActionTypes.value) ||
      userSessionStore.userSession?.language !== allActionTypesLanguage.value
    ) {
      allActionTypes.value = await fetchAllApiListResult(
        (page: number, pageSize: number) =>
          logsService.getActionTypes(page, pageSize),
      );
      allActionTypesLanguage.value = userSessionStore.userSession?.language;
    }
    // HACK: cast to avoid a strange error raised by IDE -> to be fixed
    return allActionTypes.value as TranslatedLogAttribute[] | LxcError;
  }

  return {
    canManageItems,
    error,
    fetchData,
    filters,
    isLoading,
    onSearch,
    results,
    setFilter,
    fetchAllActions,
    fetchAllActionTypes,
  };
}
