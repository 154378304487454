<script setup lang="ts">
import { useUpdateState } from "~/core/composables/useUpdateState";
import type { AppDeviceState } from "~/core/models/AppDeviceState.enum";
import { ErrorCode } from "~/core/models/ErrorCode.enum";
import LxcError from "~/core/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/core/utils/notifications";
import type { Application } from "~/modules/application/models/Application.interface";
import ApplicationService from "~/modules/application/services/application.service";
import ILxcClose from "~icons/lxc/x";

const { t } = useI18n();
const route = useRoute();
const { createDataForStateUpdate, getState } = useUpdateState();
const [confirmUpdateStateVisible, toggleConfirmUpdateState] = useToggle();

const application: Ref<Application | null> = ref(null);
const error: Ref<LxcError | null> = ref(null);
const applicationId = route.params.appId;
const isLoading = ref(true);
const activeName = ref("description");
const disabledConfirm = ref(false);

async function fetchApplication() {
  const response = await ApplicationService.getApplicationDetails(
    applicationId.toString(),
  );

  if (LxcError.check(response)) {
    application.value = null;
    error.value = response;
  } else {
    application.value = response;
  }

  isLoading.value = false;
}

async function updateState(state: AppDeviceState, id: string) {
  disabledConfirm.value = true;
  const response = await ApplicationService.updateApplicationState(
    id,
    getState(state),
  );

  if (LxcError.check(response)) {
    if (response.code === ErrorCode.DEACTIVATION_CONFLICT) {
      showNotificationError(t("application.updateState.deactivate.error"));
    } else {
      response.notify(NotificationKey.saveError);
    }
  } else {
    showNotificationSuccess(t(NotificationKey.saveSuccess));
  }

  toggleConfirmUpdateState();
  disabledConfirm.value = false;
  await fetchApplication();
}

const options = computed(() => {
  if (application.value) {
    return createDataForStateUpdate(
      application.value.state!,
      "application",
      application.value.name!,
    );
  }
});

const connectivityStateBadge = computed(() => {
  return application.value?.connectivity?.state === "inactive"
    ? "danger"
    : "secondary";
});

onMounted(async () => {
  await fetchApplication();
});
</script>

<template>
  <page-component
    back
    :name="application?.name ?? ''"
    should-not-translate
    :is-loading="isLoading"
    :error="error"
  >
    <template v-if="application" #subtitle>
      <div class="self-center ml-4">
        <lxc-badge :type="connectivityStateBadge">
          {{
            t(`filters.connectivityState.${application?.connectivity?.state}`)
          }}
        </lxc-badge>
      </div>
    </template>
    <template #top-right>
      <lxc-button
        v-if="application && options"
        class="self-end"
        type="tertiary"
        :icon="ILxcClose"
        :title="options?.activatedButton"
        @click="toggleConfirmUpdateState(true)"
      >
        {{ options.activatedButton }}
      </lxc-button>
    </template>

    <lxc-tabs v-model="activeName" class="tabs">
      <lxc-tab-pane
        :label="t('application.description.title')"
        name="description"
      >
        <application-description :application="application" />
      </lxc-tab-pane>
      <lxc-tab-pane
        :label="t('application.additionalMetadata.title')"
        name="additionalMetadata"
      >
        <application-metadata :application="application!" />
      </lxc-tab-pane>
      <lxc-tab-pane :label="t('application.certificate')" name="certificate">
        <application-certificate :application="application!" />
      </lxc-tab-pane>
    </lxc-tabs>
  </page-component>

  <lxc-confirm-modal
    v-if="options"
    :is-dialog-visible="confirmUpdateStateVisible"
    :title="options.title"
    :description="options.description"
    :info="options.warning"
    :disabled-confirm="disabledConfirm"
    @cancel="toggleConfirmUpdateState"
    @confirm="updateState(application?.state!, application?.id!)"
  />
</template>
