<script setup lang="ts">
import { useAcl } from "vue-simple-acl";
import { BREADCRUMBS } from "~/core/constants/breadcrumbs";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import ILxcPlus from "~icons/lxc/plus";

const acl = useAcl();
const { t } = useI18n();

const canManageFirmwares = computed(() => acl.can(ACLRoles.MANAGE_FIRMWARES));
const canViewFirmwares = computed(() => acl.can(ACLRoles.DISPLAY_FIRMWARES));
const triggerSearch = ref<boolean>(false);
const addNewFirmwareShown = ref<boolean>(false);

const refreshLoad = () => {
  triggerSearch.value = !triggerSearch.value;
};

const onAddNewFirmware = () => {
  addNewFirmwareShown.value = true;
};
</script>
<template>
  <page-component v-if="canViewFirmwares" :name="BREADCRUMBS.FIRMWARE.title">
    <template #top-right>
      <lxc-button
        v-if="canManageFirmwares"
        :icon="ILxcPlus"
        :title="t('firmware.newFirmware')"
        class="self-center"
        html-type="button"
        type="primary"
        @click="onAddNewFirmware"
      >
        {{ t("firmware.newFirmware") }}
      </lxc-button>
    </template>

    <lxc-firmwares-list
      :trigger-search="triggerSearch"
      use-query-parameters-for-pagination
    />
    <lxc-firmware-form
      v-model:side-canvas-shown="addNewFirmwareShown"
      @upload="refreshLoad"
    />
  </page-component>
</template>
