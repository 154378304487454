<script lang="ts" setup>
import type { TreeViewDataObject } from "@lxc/app-device-common";
import type {
  CertificateChainLinkI,
  SubjectAlternativeNameI,
} from "@lxc/app-device-types";
import { useParseCertificateFromString } from "~/composables/useCertificateParse";
import LxcError from "~/core/utils/LxcError";

const props = defineProps<{
  chainLinks?: CertificateChainLinkI[];
  leafSubjectAlternativeNames?: SubjectAlternativeNameI[];
}>();

const { getTreeviewDataFromChainLinks } = useParseCertificateFromString();

/**
 * return the treeview data
 */
const treeViewData: ComputedRef<TreeViewDataObject[] | LxcError> = computed(
  () => {
    if (props.chainLinks) {
      return getTreeviewDataFromChainLinks(
        props.chainLinks,
        props.leafSubjectAlternativeNames ?? [],
      );
    } else {
      return [];
    }
  },
);
</script>

<template>
  <div v-if="LxcError.check(treeViewData)">
    <error-component :error="treeViewData" />
  </div>
  <div
    v-else-if="treeViewData"
    class="bg-gray-50 border border-solid border-gray-300 p-1 overflow-x-hidden"
  >
    <lxc-tree-view :key="treeViewData" :data="treeViewData" :expanded="true" />
  </div>
</template>
