import { AppDeviceState } from "~/core/models/AppDeviceState.enum";
import type { FilterOptions } from "~/core/models/filters";
import type { ApplicationTypeDeclinationMap } from "~/modules/application/models/ApplicationTypeDeclinationMap.type";

export const APP_MODEL_TYPE_OPTIONS: FilterOptions = {
  label: "filters.type",
  options: [
    {
      value: "S4-View",
      label: "S4-View",
    },
    {
      value: "S4G-Tools",
      label: "S4-Tools",
    },
    {
      value: "S4-Manager",
      label: "S4-Manager",
    },
    {
      value: "SG4000",
      label: "SG4000",
    },
  ],
};

export const APP_MODEL_DECLINATION_OPTIONS: FilterOptions = {
  label: "filters.model",
  options: [
    {
      value: "S4GT",
      label: "S4GT",
    },
    {
      value: "S4TH",
      label: "S4TH",
    },
    {
      value: "S4W",
      label: "S4W",
    },
  ],
};

export const APP_TYPE_DECLINATION_MAP: ApplicationTypeDeclinationMap = {
  "S4-Manager": {
    applicationDeclinations: [],
  },
  "S4G-Tools": {
    applicationDeclinations: ["S4GT", "S4TH", "S4W"],
  },
  SG4000: {
    applicationDeclinations: [],
  },
  "S4-View": {
    applicationDeclinations: [],
  },
};

export const APP_STATE_OPTION_NEW = AppDeviceState.REGISTERED;

export const APP_STATE_OPTIONS: FilterOptions = {
  label: "filters.state",
  options: [
    {
      value: AppDeviceState.ACTIVATED,
      label: "application.states.activated",
    },
    {
      value: AppDeviceState.DEACTIVATED,
      label: "application.states.deactivated",
    },
    {
      value: APP_STATE_OPTION_NEW,
      label: "application.states.registered",
    },
  ],
};
