import type { AboutI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import { FeatureToggleComponent as featureToggle } from "vue-feature-toggle";
import { AppFeatures } from "~/core/models/AppFeatures.enum";
import AboutService from "~/modules/about/services/about.service";

export const useConfigStore = defineStore("configStore", () => {
  const state = reactive<AboutI>({
    defaultSectorCode: null,
    technicalVersion: null,
    marketingVersion: null,
    isModeAD: false,
    isLoraActivated: false,
    aboutGrafana: null,
    isFeatureFotaEnabled: false,
    isFeatureApplicationManagementEnabled: false,
    isFeatureDataManagementEnabled: false,
    isFeatureUserCertificateEnabled: false,
    isFeatureDtwinsEnabled: false,
    isFeatureCampaignDtwinsEnabled: false,
    isMobileAppOfflineMode: false,
    isCartographyEnabled: false,
  });

  async function getConfig() {
    const response = await AboutService.getAbout();
    Object.assign(state, response);

    featureToggle.visibility(AppFeatures.FOTA, state.isFeatureFotaEnabled);
    featureToggle.visibility(
      AppFeatures.APPLICATION_MANAGEMENT,
      state.isFeatureApplicationManagementEnabled,
    );
    featureToggle.visibility(
      AppFeatures.LOGS,
      state.isFeatureDataManagementEnabled,
    );
    featureToggle.visibility(
      AppFeatures.USER_CERTIFICATE,
      state.isFeatureUserCertificateEnabled,
    );
    featureToggle.visibility(AppFeatures.DTWINS, state.isFeatureDtwinsEnabled);
    featureToggle.visibility(
      AppFeatures.CAMPAIGN_DTWINS,
      state.isFeatureCampaignDtwinsEnabled,
    );
    featureToggle.visibility(
      AppFeatures.CARTOGRAPHY,
      state.isCartographyEnabled,
    );
  }

  return {
    ...toRefs(state),
    getConfig,
    featureToggle,
  };
});
