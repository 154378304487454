import type { Sort } from "@lxc/app-device-common/src/types";
import { SortOrder } from "@lxc/app-device-common/src/types";

export function useSort(defaultSort?: string) {
  const sort: Ref<string | null | undefined> = ref(defaultSort);

  /**
   * Callback on sort changed => build & update the query sort value
   * @param event Sort
   */
  async function onSortChange(event: Sort) {
    if (event?.prop) {
      switch (event.order) {
        case SortOrder.DESC:
          sort.value = `-${event.prop}`;
          break;
        case SortOrder.ASC:
          sort.value = `+${event.prop}`;
          break;
        default:
          sort.value = null;
      }
    } else {
      sort.value = defaultSort;
    }
  }

  return {
    sort,
    onSortChange,
  };
}
