<script setup lang="ts">
import type { Context } from "@lxc/app-device-common";
import ILxcPlus from "~icons/lxc/plus";

defineProps<{
  closeButtonLabel: string;
  validateButtonLabel: string;
  disabledValidateButton?: boolean;
  isLoading?: boolean;
  /**
   * The pagination context
   * if not defined pagination will not be displayed
   */
  context?: Context;
}>();
const emit = defineEmits([
  "close",
  "validate",
  "changePage",
  "changePageSize",
  "changePageAndPageSize",
]);

const { t } = useI18n();

const changePage = (page: number) => {
  emit("changePage", page);
};

const changePageSize = (pageSize: number) => {
  emit("changePageSize", pageSize);
};

const changePageAndPageSize = (page: number, pageSize: number) => {
  emit("changePageAndPageSize", page, pageSize);
};
</script>
<template>
  <div>
    <lxc-pagination
      v-if="context"
      :context="context"
      :px="0"
      :pt="0"
      @change-page="changePage"
      @change-page-size="changePageSize"
      @change-page-and-page-size="changePageAndPageSize"
    ></lxc-pagination>
    <div
      :class="`grid grid-cols-[max-content_auto] gap-4 bg-white ${context ? `pt-4 border-t border-gray-300` : ''}`"
    >
      <lxc-button
        type="secondary"
        :title="closeButtonLabel"
        @click="$emit('close')"
      >
        {{ t("button.close") }}
      </lxc-button>
      <lxc-button
        :title="validateButtonLabel"
        :icon="ILxcPlus"
        :disabled="disabledValidateButton"
        :is-loading="isLoading"
        @click="$emit('validate')"
      >
        {{ validateButtonLabel }}
      </lxc-button>
    </div>
  </div>
</template>
