<script setup lang="ts">
import type { DateShortcutCallback } from "@lxc/app-device-common";
import type { Ref } from "vue";
import {
  deviceModelOptions as defaultDeviceModelOptions,
  stateOptions as defaultStateOptions,
} from "~/constants/deviceFilters.config";
import { CONNECTIVITY_OPTIONS } from "~/core/constants/connectivity";
import type {
  FilterFormSection,
  FilterOptions,
  FilterSelectionValue,
  FiltersSelection,
} from "~/types";
import { FilterInputType, Filters } from "~/types";

const { t } = useI18n();

/**
 * It is possible to override the default options (for example to disable some options) by setting the corresponding options params to the LxcApplicationList component.
 */
const props = defineProps<{
  filters: FiltersSelection;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
}>();

const emit = defineEmits(["change", "enter"]);

const stateLabel = t(defaultStateOptions.label as string);
const deviceModelLabel = t(defaultDeviceModelOptions.label as string);
const connectivityLabel = t(CONNECTIVITY_OPTIONS.label as string);
const certificateLabel = t("filters.certificate");

const filterStateOptions = ref(
  props.stateOptions?.options ?? defaultStateOptions.options,
);
const filterDeviceModelOptions = ref(
  props.modelOptions?.options ?? defaultDeviceModelOptions.options,
);
const filterConnectivityOptions = ref(CONNECTIVITY_OPTIONS.options);

/* Filter form sections settings */
const filterFormSections: FilterFormSection[] = [
  {
    disabled: false,
    filter: Filters.STATE,
    footerEnabled: true,
    footerId: "state-footer",
    header: stateLabel,
    id: "state",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: stateLabel,
    options: filterStateOptions,
    tagPrefix: stateLabel,
    translate: true,
  },
  {
    disabled: false,
    filter: Filters.MODEL_DECLINATION,
    footerEnabled: true,
    footerId: "model-footer",
    header: deviceModelLabel,
    id: "model",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: deviceModelLabel,
    options: filterDeviceModelOptions,
    tagPrefix: deviceModelLabel,
  },
  {
    disabled: false,
    filter: Filters.CONNECTIVITY,
    footerEnabled: true,
    footerId: "connectivity-footer",
    header: connectivityLabel,
    id: "connectivity",
    inputType: FilterInputType.CHECKBOX,
    menuLabel: connectivityLabel,
    options: filterConnectivityOptions,
    tagPrefix: connectivityLabel,
    translate: true,
  },
  {
    additionalFilter: Filters.CERTIFICATE_EXPIRE_BEFORE,
    disabled: false,
    filter: Filters.CERTIFICATE_EXPIRE_AFTER,
    footerEnabled: true,
    footerId: "certificate-footer",
    header: certificateLabel,
    id: "certificate",
    inputType: FilterInputType.PERIOD_PICKER,
    menuLabel: certificateLabel,
    tagPrefix: t("filters.certificateValidity.label"),
  },
];

const dateShortcuts: Ref<DateShortcutCallback> = ref(() => [
  {
    label: t("filters.certificateValidity.expired"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(date.setFullYear(date.getFullYear() - 10)), new Date()];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder1"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 1))];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder3"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 3))];
    },
  },
  {
    label: t("filters.certificateValidity.validityUnder6"),
    atClick: (): Date[] => {
      const date = new Date();
      return [new Date(), new Date(date.setMonth(date.getMonth() + 6))];
    },
  },
  {
    label: t("filters.certificateValidity.validityAfter6"),
    atClick: (): Date[] => {
      const date = new Date();
      const dateTmp = new Date();
      const dateWithSiwMonth = new Date(
        dateTmp.setMonth(dateTmp.getMonth() + 6),
      );
      return [
        new Date(date.setMonth(date.getMonth() + 6)),
        new Date(
          dateWithSiwMonth.setFullYear(dateWithSiwMonth.getFullYear() + 10),
        ),
      ];
    },
  },
]);

function setFilter(filter: Filters, value: FilterSelectionValue) {
  emit("change", filter, value);
}
</script>

<template>
  <div class="relative mb-4">
    <filters-component
      :date-shortcuts="dateShortcuts"
      :filter-sections="filterFormSections"
      :filters="filters"
      is-button-right
      @change="setFilter"
      @enter="emit('enter', $event)"
    />
  </div>
</template>
