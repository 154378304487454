<script setup lang="ts">
import { ref } from "vue";

const { t } = useI18n();

const props = defineProps<{
  modelValue: string;
}>();
const emit = defineEmits(["update:modelValue"]);

const userName = ref(props.modelValue ?? "");

watch(
  () => userName.value,
  (text: string) => {
    if (text.length >= 3) {
      emit("update:modelValue", text);
    } else {
      emit("update:modelValue", "");
    }
  },
);
</script>
<template>
  <div class="px-6 pb-6">
    <search-input
      v-model="userName"
      :placeholder="t('logs.filters.users.placeholder')"
    />
  </div>
</template>
