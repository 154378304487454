<script setup lang="ts">
import type { Ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { deviceStateOptionNew } from "~/constants/deviceFilters.config";
import { PATHS } from "~/core/constants/paths";
import { ACLRoles } from "~/core/models/ACLRoles.enum";
import { APP_STATE_OPTION_NEW } from "~/modules/application/constants";
import type { DashboardActions } from "~/modules/dashboard/models/DashboardActions.interface";
import type { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";
import { verifyCardStatus } from "~/modules/dashboard/utils/dashboard";
import ILxExternalLink from "~icons/lxc/external-link";

const props = defineProps<{
  status: DashboardCardStatus;
  actions?: DashboardActions;
}>();

const { t } = useI18n();
const { can } = useAcl();
const router = useRouter();

const canDisplayDevices = computed(() => can(ACLRoles.DISPLAY_DEVICES));
const canDisplayApplications = computed(() =>
  can(ACLRoles.DISPLAY_APPLICATIONS),
);

function navigateToDevices() {
  if (canDisplayDevices.value) {
    router.push({
      path: PATHS.DEVICES_DVTM_ESOFT,
      query: { state: deviceStateOptionNew },
    });
  }
}

function navigateToApplications() {
  if (canDisplayApplications.value) {
    router.push({
      path: PATHS.APPLICATIONS,
      query: { state: APP_STATE_OPTION_NEW },
    });
  }
}

const deviceDisplay: Ref<string> = computed(
  () => `${props.actions?.devices ?? "-"}`,
);
const applicationsDisplay: Ref<string> = computed(
  () => `${props.actions?.applications ?? "-"}`,
);
</script>

<template>
  <dashboard-card
    :title="t('dashboard.actions.title')"
    :display-header-margin="true"
    :status="verifyCardStatus(props.status, props.actions)"
  >
    <div>
      <dl class="description-container" @click="navigateToDevices">
        <dt class="description-title">
          {{ deviceDisplay }}
        </dt>
        <dd class="description-content">
          {{ t("dashboard.actions.devices") }}
          <el-icon v-if="canDisplayDevices" :size="8">
            <ILxExternalLink />
          </el-icon>
        </dd>
      </dl>
      <dl class="description-container" @click="navigateToApplications">
        <dt class="description-title">
          {{ applicationsDisplay }}
        </dt>
        <dd class="description-content">
          {{ t("dashboard.actions.applications") }}
          <el-icon v-if="canDisplayApplications" :size="8">
            <ILxExternalLink />
          </el-icon>
        </dd>
      </dl>
    </div>
  </dashboard-card>
</template>

<style lang="scss" scoped>
.description-container {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
}

.description-title {
  color: $light-blue-color;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 30px;
}

.description-content {
  margin-left: 25px;
  font-size: 14px;
  cursor: pointer;
}
</style>
