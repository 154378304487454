import { useAcl } from "vue-simple-acl";
import { ACLRoles } from "~/core/models/ACLRoles.enum";

export function useCertificatesACLRoles() {
  const { can } = useAcl();

  function canManageCertificates() {
    return can(ACLRoles.DVT_CACRT_ADM);
  }

  function canViewCertificates() {
    return can(ACLRoles.DVT_CACRT_VIEW);
  }

  return {
    canManageCertificates,
    canViewCertificates,
  };
}
