<script setup lang="ts">
import { IntlDateTools, type PeriodI } from "@lxc/app-device-common";
import dayjs from "dayjs";
import { useContent } from "~/core/composables/useContent";
import { SearchMode } from "~/core/composables/useSearch";
import { FILTER_DATE_FORMAT } from "~/core/constants/constants";
import { Filters } from "~/core/models/filters";
import { formatIsoDate, getTodayPeriod } from "~/core/utils/date";
import { DtwinConfigurationType } from "~/modules/dtwin/models/DtwinConfigurationType.enum";

const props = defineProps<{
  showListPanel: boolean;
  dtwinUid: string;
  configurationType: string;
  reload: boolean;
}>();

const emit = defineEmits(["update:showListPanel", "update:reload"]);

const { locale, t } = useI18n();
const {
  isLoading,
  results,
  error,
  filters,
  fetchData,
  setFilter,
  search,
  onSearch,
} = useContent(SearchMode.FILTER_SEARCH);

const sidePanelTitle = computed(() => {
  if (props.configurationType === DtwinConfigurationType.SELF_SUPPORTING) {
    return t("dtwins.form.configuration.list.title.selfSupporting");
  } else {
    return t("dtwins.form.configuration.list.title.specific");
  }
});

const sideCanvasShown = computed({
  get: () => {
    return props.showListPanel;
  },
  set: (shown: boolean) => {
    emit("update:showListPanel", shown);
  },
});

const searchQuery = computed({
  get() {
    return (filters.get(Filters.DTWIN_CONFIGURATION_VERSION_NAME_OR_VERSION) ??
      "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.DTWIN_CONFIGURATION_VERSION_NAME_OR_VERSION, searchQuery);
  },
});

const canClearPeriodSelection = ref(false);
const periodPickerValues = computed({
  get(): PeriodI {
    const appliedStartDate = filters.get(Filters.DTWIN_CONFIGURATION_START_DATE)
      ?.value as string | undefined;
    const appliedEndDate = filters.get(Filters.DTWIN_CONFIGURATION_END_DATE)
      ?.value as string | undefined;

    if (appliedStartDate && appliedEndDate) {
      return {
        startDate: IntlDateTools.parse(
          appliedStartDate,
          FILTER_DATE_FORMAT,
          locale.value,
        ),
        endDate: IntlDateTools.parse(
          appliedEndDate,
          FILTER_DATE_FORMAT,
          locale.value,
        ),
      };
    } else {
      return {
        startDate: undefined,
        endDate: undefined,
      };
    }
  },
  async set(newValue: PeriodI) {
    const todayPeriod = getTodayPeriod();
    const newStartDateFormatted = dayjs(newValue?.startDate).format(
      FILTER_DATE_FORMAT,
    );
    const newEndDateFormatted = dayjs(newValue?.endDate).format(
      FILTER_DATE_FORMAT,
    );
    const todayStartDateFormatted = dayjs(todayPeriod?.startDate).format(
      FILTER_DATE_FORMAT,
    );
    const todayEndDateFormatted = dayjs(todayPeriod?.endDate).format(
      FILTER_DATE_FORMAT,
    );

    canClearPeriodSelection.value =
      newStartDateFormatted !== todayStartDateFormatted ||
      newEndDateFormatted !== todayEndDateFormatted;

    if (
      newValue.startDate == null ||
      newValue.endDate == null ||
      newValue.startDate.getTime() == newValue.endDate.getTime()
    ) {
      setFilter(Filters.DTWIN_CONFIGURATION_START_DATE, "");
      setFilter(Filters.DTWIN_CONFIGURATION_END_DATE, "");
    } else {
      setFilter(Filters.DTWIN_CONFIGURATION_START_DATE, newStartDateFormatted);
      setFilter(Filters.DTWIN_CONFIGURATION_END_DATE, newEndDateFormatted);
    }
  },
});

// Use watch on periodPickerValues to avoid multiple calls if loadData function is called in the set()
watch(
  () => periodPickerValues.value,
  async () => {
    await loadData();
  },
);

async function loadData(page?: number, pageSize?: number) {
  setFilter(Filters.DTWIN_UID, props.dtwinUid);
  await fetchData(page, pageSize);
  emit("update:reload", false);
}

function onClose() {
  sideCanvasShown.value = false;
}

watch(
  () => props.reload,
  async () => {
    if (props.reload) {
      await loadData();
    }
  },
);

onMounted(async () => {
  onSearch(loadData);
});
</script>

<template>
  <lxc-side-canvas
    v-model:show="sideCanvasShown"
    :header="sidePanelTitle"
    size="2/5"
    :close-tooltip="t('filters.close')"
    @discard="onClose"
  >
    <div class="relative mb-6 flex gap-2 w-full">
      <div class="flex-1">
        <search-bar
          v-model:search-query="searchQuery"
          :search-placeholder="
            t('dtwins.form.configuration.list.search.placeholder')
          "
          @clear="search"
          @search="search"
        />
      </div>
      <lxc-period-picker
        v-model="periodPickerValues"
        type="primary"
        :can-clear-selection="canClearPeriodSelection"
        class="flex-1 mt-2"
        button-size
        :placeholder="
          t('dtwins.form.configuration.list.periodPicker.placeholder')
        "
        :formatter="t('filters.date.formatter.tag')"
        :i18n="locale"
        separator=" - "
      />
    </div>
    <lxc-table
      :is-loading="isLoading"
      :context="results?.pagination"
      :data="results?.results"
      :error="error?.toError()"
      data-cy="configuration-table"
      :empty-text="t('dtwins.form.configuration.list.noConfiguration')"
      @change-page-and-page-size="loadData"
    >
      <lxc-table-column
        prop="descriptor.versionName"
        :label="t('dtwins.form.configuration.list.columns.versionName')"
      />
      <lxc-table-column
        prop="descriptor.version"
        :label="t('dtwins.form.configuration.list.columns.version')"
        width="7rem"
      />
      <lxc-table-column
        prop="modifiedAt"
        :label="t('dtwins.form.configuration.list.columns.uploadedDate')"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.modifiedAt, t("dateFormat.datetime")) }}
        </template>
      </lxc-table-column>
    </lxc-table>
    <template #footer>
      <div class="flex justify-end">
        <lxc-button
          html-type="button"
          type="tertiary"
          :title="t('button.close')"
          @click="onClose"
        >
          {{ t("button.close") }}
        </lxc-button>
      </div>
    </template>
  </lxc-side-canvas>
</template>
