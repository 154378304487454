<script setup lang="ts">
import type { DeviceI, SectorI } from "@lxc/app-device-types";
import type { Ref } from "vue";
import { onMounted } from "vue";
import { useDevices } from "~/composables/useDevices";
import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_PAGE_SIZE,
} from "~/core/constants/constants";
import { PATHS } from "~/core/constants/paths";
import { router } from "~/plugins/router";
import { Filters, SectorResource } from "~/types";

const props = defineProps<{
  sector: SectorI;
}>();

const { t } = useI18n();
const {
  isLoading,
  results: devices,
  error,
  fetchData,
  setFilter,
  canManageItems,
  getDescription,
} = useDevices();

const selectedItems: Ref<any[]> = ref([]);
const displayActions = computed(() => canManageItems(props.sector));

onMounted(async () => {
  setFilter(Filters.SECTORS, [props.sector.code]);
  await fetchData();
});

async function refresh() {
  await fetchData();
  selectedItems.value = [];
}

async function getNextByPageAndSize(
  page: number = DEFAULT_FIRST_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
) {
  await fetchData(page, pageSize);
}

function onRowClick(device: DeviceI) {
  router.push(`${PATHS.DEVICES_DVTM_ESOFT}/${device.id}`);
}
</script>

<template>
  <lxc-sector-toolbar
    ref="sectorToolbar"
    primary-key="id"
    :selected-items="selectedItems"
    :sector="sector"
    :resource="SectorResource.DEVICE"
    @change="refresh"
  >
    <lxc-table-column prop="name" :label="t('device.name')" width="20rem" />
    <lxc-table-column
      prop="model.type"
      :label="t('device.type')"
      width="8rem"
    />
    <lxc-table-column
      prop="model.declination"
      :label="t('device.model')"
      width="20rem"
    />
    <lxc-table-column :label="t('device.state')" width="8rem">
      <template #default="scope">
        {{ scope.row.state ? t(`device.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
  </lxc-sector-toolbar>

  <container-component
    :px="0"
    :py="0"
    class="mt-4"
    :is-loading="isLoading && !devices"
    :error="error"
  >
    <lxc-sectors-details-table
      v-model="selectedItems"
      primary-key="id"
      :is-loading="isLoading"
      :selection="displayActions"
      :items="devices"
      :display-actions="displayActions"
      :resource="SectorResource.DEVICE"
      :get-description="getDescription"
      @pagination:get-next-page="getNextByPageAndSize"
      @actions:detach="(item) => $refs.sectorToolbar.openDetachModal(item)"
      @row-click="onRowClick"
    >
      <lxc-table-column
        prop="name"
        :label="t('device.name')"
        min-width="20rem"
      />
      <lxc-table-column
        prop="model.type"
        :label="t('device.type')"
        width="8rem"
      />
      <lxc-table-column
        prop="model.declination"
        :label="t('device.model')"
        width="8rem"
      />
      <lxc-table-column
        prop="serialNumber"
        :label="t('device.serialNumber')"
        width="12rem"
      />
      <lxc-table-column :label="t('device.state')" width="8rem">
        <template #default="scope">
          {{ scope.row.state ? t(`device.states.${scope.row.state}`) : "" }}
        </template>
      </lxc-table-column>
    </lxc-sectors-details-table>
  </container-component>
</template>
