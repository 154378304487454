<script setup lang="ts">
import type { DashboardCardStatus } from "~/modules/dashboard/models/DashboardCardStatus.enum";

const props = defineProps<{
  status: DashboardCardStatus;
  percentage?: number;
}>();

const { t } = useI18n();
</script>

<template>
  <dashboard-card
    :display-header-margin="false"
    :should-expand-body="true"
    :status="props.status"
  >
    <div class="gauge-container">
      <dashboard-tile-certificates-gauge :percentage="percentage ?? 0" />
      <dl class="percentage-container">
        <dt class="percentage-value">
          {{ percentage ? `${percentage}%` : "--" }}
        </dt>
        <dd class="percentage-label">
          {{ t("dashboard.certificates.valid") }}
        </dd>
      </dl>
    </div>
  </dashboard-card>
</template>

<style lang="scss" scoped>
.gauge-container {
  width: 100%;
  position: relative;
}

.percentage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 65%;
  right: 20%;
  left: 20%;
  padding-top: 8px;
}

.percentage-value {
  color: $primary-color;
  font-size: 21px;
  font-weight: bold;
}

.percentage-label {
  color: $primary-color;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
</style>
