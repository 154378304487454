import RestService from "~/core/services/rest.service";
import type { RolesStructure } from "~/modules/user/models/RolesStructure.interface";

export class RoleService extends RestService {
  protected BASE_URL = "/roles";

  /**
   * Retrieve all grantable roles
   */
  getAllGrantableRoles(): Promise<RolesStructure> {
    return this.$api.get(this.BASE_URL);
  }
}

export default new RoleService();
