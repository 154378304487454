export enum LogEntityClass {
  DEVICE = "device",
  DEVICE_DVTM_ESOFT = "device-dvtm-esoft",
  GATEWAY = "gateway",
  GROUP = "group",
  PLATFORM = "platform",
  PROFILE = "profile",
  ROLE = "role",
  SECTOR = "sector",
  USER = "user",
  CERTIFICATE = "certificate",
  TRUSTCHAIN = "trustchain",
}
