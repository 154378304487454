<script setup lang="ts">
import { SortMode } from "@lxc/app-device-common";
import type { CampaignType, DeviceI } from "@lxc/app-device-types";
import type { ElTable } from "element-plus";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { useDevices } from "~/composables/useDevices";
import type { SearchMode } from "~/core/composables/useSearch";
import { PATHS } from "~/core/constants/paths";
import { useSectorStore } from "~/modules/sector/stores/useSectorStore";
import { router } from "~/plugins/router";
import type { FilterOptions } from "~/types";
import { Filters } from "~/types";

const props = defineProps<{
  noAction?: boolean;
  selectedDevices?: DeviceI[];
  defaultFilters?: Map<Filters, any>;
  campaignType?: CampaignType;
  searchMode?: SearchMode;
  typeOptions?: FilterOptions;
  modelOptions?: FilterOptions;
  stateOptions?: FilterOptions;
  useQueryParametersForPagination?: boolean;
}>();

const emit = defineEmits(["update:selectedDevices"]);

const tableRef = ref<InstanceType<typeof ElTable>>();

const { t } = useI18n();
const {
  isLoading,
  results: devices,
  filters,
  error,
  fetchData,
  setFilter,
  getHasActionStatusLabel,
  onSearch,
  search,
  searchOnSectors,
  onSortChange,
} = useDevices(props.searchMode, props.useQueryParametersForPagination);

const { selectedSectors, availableSectors } = storeToRefs(useSectorStore());

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () =>
  searchOnSectors(selectedSectors.value, availableSectors.value, true),
);

const rowSelection: Ref<DeviceI[]> = ref([]);

/**
 * Select devices
 */
const setSelection = () => {
  if (props.selectedDevices !== undefined && tableRef.value) {
    for (const row of tableRef.value.data) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedDevices.some(
          (selectedDevice) => selectedDevice.id === row.id,
        ),
      );
    }

    rowSelection.value = props.selectedDevices || [];
  }
};

watch(() => props.selectedDevices, setSelection);

/**
 * Retrieve devices and sectors
 * @param page
 * @param pageSize
 */
async function loadData(page?: number, pageSize?: number) {
  const params = new Map();
  params.set("campaignType", props.campaignType);
  await fetchData(page, pageSize, params);

  setSelection();
}

/**
 * Compute and emit the selected devices
 */
const handleSelect = (handledSelected: DeviceI[]) => {
  // Keep the selected devices which were selected on another page and not present in handled selected
  emit(
    "update:selectedDevices",
    props.selectedDevices
      ?.filter(
        (selectedDevice: DeviceI) =>
          !tableRef.value ||
          !tableRef.value.data.find((row) => row.id === selectedDevice.id),
      )
      .filter(
        (selectedDevice: DeviceI) =>
          !handledSelected.find((row) => row.id === selectedDevice.id),
      )
      .concat(handledSelected),
  );
};

// Apply provided default filters
if (props.defaultFilters) {
  for (const [filter, value] of props.defaultFilters) {
    setFilter(filter, value || "");
  }
}

const onSelectDevice = (app: DeviceI) => {
  router.push(`${PATHS.DEVICES_DVTM_ESOFT}/${app.id}`);
};

const searchQuery = computed({
  get() {
    return (filters.get(Filters.NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.NAME, searchQuery);
  },
});

function reloadWithContext() {
  loadData(devices.value?.context?.page, devices.value?.context?.pageSize);
}

onMounted(async () => {
  onSearch(loadData, false);
  await searchOnSectors(selectedSectors.value, true);
});
</script>

<template>
  <search-bar
    v-model:search-query="searchQuery"
    :search-placeholder="t('filters.searchByName')"
    @clear="search"
    @search="search"
  />
  <lxc-device-filters
    :filters="filters"
    :type-options="typeOptions"
    :model-options="modelOptions"
    :state-options="stateOptions"
    @change="setFilter"
    @enter="search"
  />
  <lxc-table
    ref="tableRef"
    min-width="90rem"
    :is-loading="isLoading"
    :data="devices?.data"
    :empty-text="t('device.empty')"
    :context="devices?.context"
    data-cy="device-table"
    :error="error?.toError()"
    :clickable="true"
    @change-page-and-page-size="loadData"
    @row-click="onSelectDevice"
    @select="handleSelect"
    @select-all="handleSelect"
    @sort-change="onSortChange"
  >
    <lxc-table-column v-if="selectedDevices !== undefined" type="selection" />
    <lxc-table-column prop="name" :label="t('device.name')" min-width="16rem" />
    <lxc-table-column
      prop="model.type"
      :label="t('device.type')"
      width="8rem"
    />
    <lxc-table-column
      prop="model.declination"
      :label="t('device.model')"
      width="8rem"
    />
    <lxc-table-column
      prop="serialNumber"
      :label="t('device.serialNumber')"
      width="12rem"
    />
    <lxc-table-column
      prop="connectivity.state"
      :label="t('device.connectivity')"
      class="!py-0"
      width="8rem"
    >
      <template #default="scope">
        <connectivity-status
          :connectivity-state="scope.row.connectivity.state"
        />
      </template>
    </lxc-table-column>
    <lxc-table-column
      :label="t('device.certificate')"
      prop="certificateExpireBefore"
      class="!py-0"
      :sort-mode="SortMode.CUSTOM"
      width="8rem"
    >
      <template #default="scope">
        <lxc-certificate-status :certificate="scope.row.certificate" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="firmwareVersion"
      :label="t('device.firmwareVersion')"
      width="10rem"
    />
    <lxc-table-column :label="t('device.state')" width="8rem">
      <template #default="scope">
        {{ scope.row.state ? t(`device.states.${scope.row.state}`) : "" }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      v-if="campaignType"
      :label="t('device.actions.scheduled.title')"
      width="8rem"
    >
      <template #default="scope">
        <span>{{ getHasActionStatusLabel(scope.row, campaignType) }}</span>
      </template>
    </lxc-table-column>
    <lxc-table-column v-if="!noAction" class="w-16 !py-0" width="12rem">
      <template #default="scoped">
        <lxc-device-actions
          :key="scoped.row.id"
          :is-activated="scoped.row.state"
          :device="scoped.row"
          @change="reloadWithContext"
        />
      </template>
    </lxc-table-column>
  </lxc-table>

  <div class="mt-4">
    <el-select
      v-if="rowSelection?.length !== 0"
      v-model="rowSelection"
      class="footer-selection"
      value-key="id"
      multiple
      collapse-tags
      collapse-tags-tooltip
      reserve-keyword
      :teleported="false"
      @change="$emit('update:selectedDevices', rowSelection)"
    >
      <el-option
        v-for="device in rowSelection"
        :key="device.id"
        :label="device.name"
        :value="device"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>
